import { useState } from "react";
import { Checkbox } from "primereact/checkbox";
import { Slider } from "primereact/slider";
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";
import "./Filter.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  categoryListObject,
  setCategoryList,
} from "../../../redux/reducers/categoryList";
import addDeleteGetLocalStorage from "../../../prototype/addDeleteGetLocalStorage";
import { STORAGE } from "../../../Enum";
const Filter = ({
  setSubcategory,
  subcategory,
  setFilter,
  filter,
  debouncedOnChange,
  pricerange,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let language = localStorage.getItem("i18nextLng");
  let currency_am = addDeleteGetLocalStorage(STORAGE.CURRENCY, {}, "get");
  const [isOpen, setIsOpen] = useState(false);
  const [clickFontFamily, setClickFontFamily] = useState(16);
  const [clickColor, setClickColor] = useState("black");
  const [toggleClass, setToggleClass] = useState("");
  const handleClick = () => {
    setIsOpen(!isOpen);
    setClickFontFamily(isOpen ? "OpenSans-Regular" : "OpenSans-Bold");
    setClickColor(isOpen ? "#12262F" : "#EFB34C");
    setToggleClass(isOpen ? "" : "open");
  };

  //get all categories from redux store
  let categoryObj = useSelector(categoryListObject);
  let selectedCategory = categoryObj?.selectedCategory;

  return (
    <>
      <div className="filter-wrapper">
        <h6 className="h6 mb-5">{t("Filters")}</h6>
        {selectedCategory?.sub_category?.length > 0 && (
          <p className="p2 mb-3">{t("SubCategory")}</p>
        )}
        <div className="tree-wrap mb-4">
          <div
            onClick={handleClick}
            className={toggleClass}
            style={{
              fontFamily: clickFontFamily,
              color: clickColor,
              display: "flex",
              justifyContent: "space-between",
              cursor: "pointer",
              // marginBottom: "16px",
            }}
          ></div>
          {/* {isOpen && ( */}
          <div className="checkboxes-wrap">
            {selectedCategory?.sub_category?.length > 0 &&
              selectedCategory?.sub_category.map((subCat, index) => {
                return (
                  <div className="flex align-items-start mb-2" key={index}>
                    <Checkbox
                      onChange={(e) => {
                        let temp = [];
                        if (typeof subcategory == "object")
                          temp = [...subcategory];
                        let elementToFind = subCat?.id;
                        if (temp.indexOf(elementToFind) == -1) {
                          temp.push(elementToFind);
                        } else {
                          temp.splice(temp.indexOf(elementToFind), 1);
                        }
                        setSubcategory(temp);
                        dispatch(
                          setCategoryList({
                            ...categoryObj,
                            selectedSubCategoryArrayOfIds: temp,
                          })
                        );
                      }}
                      checked={subcategory?.includes(subCat?.id)}
                    />
                    <label className="ml-2 capitalize-first-letter">
                      {language == "en"
                        ? t(`${subCat?.name_en}`)
                        : t(`${subCat?.name_ar}`)}{" "}
                        {subCat?.COUNT>0 && <span className="count pl-1">{`(${subCat?.COUNT})`}</span>}
                    </label>
                  </div>
                );
              })}
          </div>
          {/* )} */}
        </div>

        <p className="p2 mb-3">{t("ProductType")}</p>
        <div className="tree-wrap mb-4">
          <div className="flex align-items-start mb-2">
            <Checkbox
              checked={filter === "NewArrivals"}
              onChange={() => {
                if (filter === "NewArrivals"){
                   setFilter("");
                    dispatch(
                      setCategoryList({
                        ...categoryObj,
                        productType: "",
                      })
                    );
                }else {
                  setFilter("NewArrivals");
                  dispatch(
                    setCategoryList({
                      ...categoryObj,
                      productType: "NewArrivals",
                    })
                  );
                }
              }}
              inputId="NewArrivals"
              name="NewArrivals"
              value="NewArrivals"
            />
            <label htmlFor="NewArrivals" className="ml-2">
              {t("NewArrivals")} <span className="count pl-1">{}</span>
            </label>
          </div>
          <div className="flex align-items-start mb-2">
            <Checkbox
              checked={filter === "Featured"}
              onChange={() => {
                if (filter === "Featured") {
                  setFilter("");
                  dispatch(
                    setCategoryList({
                      ...categoryObj,
                      productType: "",
                    })
                  );
                }else {
                  setFilter("Featured");
                  dispatch(
                    setCategoryList({
                      ...categoryObj,
                      productType: "Featured",
                    })
                  );
                }
              }}
              inputId="Featured"
              name="Featured"
              value="Featured"
            />
            <label htmlFor="Featured" className="ml-2">
              {t("Featured")} <span className="count pl-1">{}</span>
            </label>
          </div>
        </div>
        <p className="p2 mb-3">{t("Price")}</p>
        <div className="slider-range-wrap">
          <div className="flex align-items-start mb-3">
            <div className="pricebox-p">
              <span className="pricetag-p">{currency_am}</span>
              <InputText
                value={pricerange[0]}
                onChange={(e) => debouncedOnChange(e.target.value, "first")}
                className="slider-range-input"
              />
            </div>
            <span className="p3 p-3">to</span>
            <div className="pricebox-p">
              <span className="pricetag-p">{currency_am}</span>
              <InputText
                value={pricerange[1]}
                onChange={(e) => debouncedOnChange(e.target.value, "second")}
                className="slider-range-input"
              />
            </div>
          </div>
          <Slider
            value={pricerange}
            onChange={(e) => debouncedOnChange(e.value, "both")}
            className="range-slider"
            min={0}
            max={300000}
            range
          />
        </div>
      </div>
    </>
  );
};

export default Filter;
