import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Divider } from "primereact/divider";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Checkbox } from "primereact/checkbox";
import "./PlaceOrder.scss";
import pickupOrDelivery from "../../assets/images/structure/pickup-or-delivery.svg";
import editIcon from "../../assets/images/structure/edit-icon.svg";
import plusYellow from "../../assets/images/structure/plus-yellow.svg";
import CompanyDetails from "../../DialogBox/CompanyDetails";
import AddAddress from "../../DialogBox/AddAddress";
import { API_ROUTES, STORAGE } from "../../Enum";
import globalRequest from "../../prototype/globalRequest";
import { setSnackbar } from "../../redux/reducers/snackbar";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../redux/reducers/loader";
import { Radio } from "@material-ui/core";
import {
  currentPlaceOrderData,
  setPlaceOrderData,
} from "../../redux/reducers/placeOrderData";
import addDeleteGetLocalStorage from "../../prototype/addDeleteGetLocalStorage";
import deleteIcon from "../../assets/images/structure/delete-icon.svg";
import DeleteAddress from "../../DialogBox/DeleteAddress";

const Address = (props) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const [openCompanyDetails, setOpenCompanyDetails] = useState(false);
  let DB = addDeleteGetLocalStorage(STORAGE.COUNTRY_DB, {}, "get");
  const [address, setAddress] = useState([]);
  const [pickupAddress, setPickupAddress] = useState([]);
  const dispatch = useDispatch();
  const placeData = useSelector(currentPlaceOrderData);
  const openCompanyDetailsPopup = () => {
    setOpenCompanyDetails(true);
  };
  const [openAddAddress, setOpenAddAddress] = useState(false);
  const [openDeleteAddress, setOpenDeleteAddress] = useState(false);
  const openAddAddressPopup = () => {
    setOpenAddAddress(true);
  };
  const [existingAddress, setExistingAddress] = useState({});
  const [selectedAddressId, setSelectedAddressId] = useState("");
  const [deleteAddressId, setDeleteAddressId] = useState(null);

  const [selectedDeliveryOption, setSelectedDeliveryOption] = useState(
    placeData?.shippingCompany ? placeData?.shippingCompany : ""
  );
  const [shippingCharge, setShippingCharge] = useState(
    placeData?.shippingCharge ? placeData?.shippingCharge : 0
  );

  useEffect(() => {
    dispatch(
      setPlaceOrderData({
        ...placeData,
        shippingCompany: selectedDeliveryOption,
        shippingCharge: shippingCharge,
        isCompanyStatus: checked,
      })
    );
  }, [selectedDeliveryOption, shippingCharge, checked]);

  useEffect(() => {
    if (placeData?.allShippingCharges?.length > 0) {
      setSelectedDeliveryOption(
        placeData?.allShippingCharges[0]?.shippingCompany
      );
      setShippingCharge(placeData?.allShippingCharges[0]?.shippingCharge);
    }
  }, [placeData?.allShippingCharges]);

  //get pickup address
  const getPickupAddress = async () => {
    await globalRequest("get", API_ROUTES.GET_PICKUP_ADDRESS, {}, {}, true)
      .then((data) => {
        if (data.ack == 1) {
          setPickupAddress(data.data);
          if (placeData?.addressId && placeData?.deliveryType === "pickup") {
            let list = data?.data;
            list?.map((ele) => {
              if (placeData?.addressId == ele?.id) {
                setSelectedAddressId(ele?.id);
                dispatch(
                  setPlaceOrderData({
                    ...placeData,
                    addressId: ele?.id,
                    deliveryType: "pickup",
                    userDetails: ele,
                  })
                );
              }
            });
          }
        }
        console.log("pickupaddress", data);
      })
      .catch((err) => {
        let msg = err.response.data.msg;
        dispatch(
          setSnackbar({
            message: msg,
            isOpen: true,
            state: "error",
          })
        );
      });
  };

  //get address
  const getAddress = async () => {
    dispatch(changeLoader(true));
    await globalRequest("get", API_ROUTES.GET_ADDRESS, {}, {}, true)
      .then((data) => {
        if (data.ack == 1) {
          setAddress(data.data);
          console.log("address", data);
          if (placeData?.addressId && placeData?.deliveryType === "delivery") {
            let list = data?.data;
            list?.map((ele) => {
              if (placeData?.addressId == ele?.id) {
                setSelectedAddressId(ele?.id);
                dispatch(
                  setPlaceOrderData({
                    ...placeData,
                    addressId: ele?.id,
                    deliveryType: "delivery",
                    userDetails: ele,
                  })
                );
              }
            });
          }
        }
      })
      .catch((err) => {
        let msg = err.response.data.msg;
        dispatch(
          setSnackbar({
            message: msg,
            isOpen: true,
            state: "error",
          })
        );
      })
      .finally((e) => {
        dispatch(changeLoader(false));
      });
  };

  // delete address
  const deleteAddressApi = () => {
    if (!deleteAddressId) return;
    dispatch(changeLoader(true));
    globalRequest(
      "post",
      API_ROUTES.DELETE_ADDRESS,
      { addressId: deleteAddressId },
      {},
      true
    )
      .then((data) => {
        if (data.ack == 1) {
          dispatch(
            setSnackbar({
              message: t("AddressDeletedSuccessfully"),
              isOpen: true,
              state: "success",
            })
          );
          setOpenDeleteAddress(false);
          getAddress();
          if (placeData?.addressId === deleteAddressId) {
            dispatch(
              setPlaceOrderData({
                ...placeData,
                addressId: "",
              })
            );
          }
          setDeleteAddressId(null);
        }
      })
      .catch((err) => {
        let msg = err.response.data.msg;
        dispatch(
          setSnackbar({
            message: msg,
            isOpen: true,
            state: "error",
          })
        );
      })
      .finally((e) => {
        dispatch(changeLoader(false));
      });
  };

  useEffect(() => {
    getAddress();
    getPickupAddress();
  }, []);

  useEffect(() => {
    if (!openAddAddress) {
      getAddress();
    }
  }, [openAddAddress]);

  useEffect(() => {
    if (address.length && !placeData?.addressId) {
      let obj = address[0];
      setSelectedAddressId(obj?.id);
      dispatch(
        setPlaceOrderData({
          ...placeData,
          addressId: obj?.id,
          deliveryType: "delivery",
          userDetails: obj,
        })
      );
    }
  }, [address]);

  return (
    <>
      <div className="cart-left">
        <div className="flex align-items-start checkout-heading mb-4">
          <img src={pickupOrDelivery} alt="" />
          <div className="pl-3">
            <p className="p2 p2-bold mb-2">{t("pickupOrDelivery")}</p>
            <p className="p2 gray">
              {t("chooseYourDeliveryPreferenceAsPickupOrDelivery")}
            </p>
          </div>
        </div>
        <div className="accordion">
          <Accordion activeIndex={0}>
            <AccordionTab
              className="accordion-tab"
              header={
                <div className="">
                  <span className="vertical-align-middle p2 p2-semibold">
                    {t("Delivery")}
                  </span>
                  <p className="p2 gray mt-2" style={{ fontWeight: "400" }}>
                    {t("selectAddressWhereYouWantYourOrderToBeDelivered")}
                  </p>
                </div>
              }
            >
              <div className="accordion-panel">
                <div className="last-divider-none">
                  {address.map((obj, index) => {
                    return (
                      <div className="flex align-items-start" key={index}>
                        <Radio
                          checked={
                            placeData?.deliveryType == "delivery" &&
                            selectedAddressId === obj?.id
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedAddressId(obj?.id);
                              dispatch(
                                setPlaceOrderData({
                                  ...placeData,
                                  addressId: obj?.id,
                                  deliveryType: "delivery",
                                  userDetails: obj,
                                })
                              );
                            }
                          }}
                          value={obj?.id}
                          name="radio-buttons"
                          inputProps={{ "aria-label": obj?.id }}
                        />
                        <label htmlFor="ingredient1" className="pl-3 w-full">
                          <p className="p2 p2-semibold mb-2 capitalize-first-letter">
                            {obj?.name} | {obj?.mobile}{" "}
                            <span className="chips-outline capitalize-first-letter">
                              {obj?.address_name}
                            </span>{" "}
                            <img
                              src={editIcon}
                              className="cursor-pointer"
                              onClick={() => {
                                setOpenAddAddress(true);
                                setExistingAddress(obj);
                              }}
                              alt=""
                            />
                          </p>
                          <p className="p2">
                            {obj?.address ? `${obj?.address}` : ""}
                            {obj?.address2 ? `, ${obj?.address2}` : ""}
                            {obj?.city ? `, ${obj?.city}` : ""}
                            {obj?.state ? `, ${obj?.state}` : ""}
                            {obj?.country ? `, ${obj?.country}` : ""}
                            {obj?.postal_code ? `, ${obj?.postal_code}` : ""}
                          </p>
                          <Divider />
                        </label>

                        <img
                          src={deleteIcon}
                          alt="delete"
                          style={{ cursor: "pointer" }}
                          className="icon24"
                          onClick={() => {
                            setDeleteAddressId(obj?.id);
                            setOpenDeleteAddress(true);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
                {address.length < 3 && (
                  <div className="flex align-items-start">
                    <label className="w-100">
                      <p
                        className="p2 p2-semibold mb-2 yellow flex align-items-center cursor-pointer"
                        onClick={openAddAddressPopup}
                      >
                        <img src={plusYellow} alt="" className="mr-2" />{" "}
                        {t("addNewAddress")}
                      </p>
                    </label>
                  </div>
                )}
              </div>
            </AccordionTab>
            <AccordionTab
              className="accordion-tab"
              header={
                <div className="">
                  <span className="vertical-align-middle p2 p2-semibold">
                    {t("pickup")}
                  </span>
                  <p className="p2 gray mt-2" style={{ fontWeight: "400" }}>
                    {t("pickTheProductFromTheStore")}
                  </p>
                </div>
              }
            >
              <div className="accordion-panel">
                {pickupAddress.map((obj, index) => {
                  return (
                    <div className="flex align-items-start" key={index}>
                      <Radio
                        checked={
                          placeData?.deliveryType == "pickup" &&
                          selectedAddressId === obj?.id
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedAddressId(obj?.id);
                            dispatch(
                              setPlaceOrderData({
                                ...placeData,
                                addressId: obj?.id,
                                deliveryType: "pickup",
                              })
                            );
                          }
                        }}
                        value={"0"}
                        name="radio-buttons"
                        inputProps={{ "aria-label": "0" }}
                      />
                      <label htmlFor="ingredient1" className="pl-3 w-full">
                        <p className="p2 p2-semibold mb-2">
                          <span style={{ marginRight: "15px" }}>
                            {obj?.name}| {obj?.mobile}{" "}
                          </span>
                          <span
                            className="chips-outline"
                            style={{ marginLeft: "0" }}
                          >
                            {t("store")}
                          </span>
                        </p>
                        <p className="p2">
                          {obj?.address ? `${obj?.address}` : ""}
                          {obj?.address2 ? `, ${obj?.address2}` : ""}
                          {obj?.city ? `, ${obj?.city}` : ""}
                          {obj?.state ? `, ${obj?.state}` : ""}
                          {obj?.country ? `, ${obj?.country}` : ""}
                          {obj?.postal_code ? `, ${obj?.postal_code}` : ""}
                        </p>
                      </label>
                    </div>
                  );
                })}
              </div>
            </AccordionTab>
          </Accordion>
        </div>
        <div className="">
          <div className="flex align-items-center trn-no-block">
            <Checkbox
              onChange={(e) => {
                setChecked(e.checked);
                if (!e.checked) {
                  dispatch(
                    setPlaceOrderData({
                      ...placeData,
                      trnNumber: "",
                      companyName: "",
                    })
                  );
                }
              }}
              checked={checked}
            ></Checkbox>
            <label className="ml-2 p2">
              {t("areYouACompanyIHaveA")}{" "}
              <span className="p2 p2-semibold">{t("trnNumber")}.</span>
            </label>
          </div>
          {checked ? (
            <div className="trn-no-block-bg-gray">
              <p className="p2 mb-2 flex align-items-start">
                <span
                  className="p2 p2-semibold"
                  style={{ minWidth: "20%", maxWidth: "20%" }}
                >
                  {t("companyName")} :{" "}
                </span>{" "}
                <span
                  className="ml-1 text-overflow-ellipsis white-space-nowrap overflow-hidden"
                  style={{ minWidth: "70%", maxWidth: "70%" }}
                >
                  {placeData?.companyName}
                </span>{" "}
                <img
                  onClick={openCompanyDetailsPopup}
                  src={editIcon}
                  className="cursor-pointer ml-4 vertical-bottom"
                  alt=""
                />
              </p>
              <p className="p2 mb-2 flex align-items-start">
                <span
                  className="p2 p2-semibold"
                  style={{ minWidth: "20%", maxWidth: "20%" }}
                >
                  {t("trnNumber")} :{" "}
                </span>{" "}
                <span
                  className="ml-1 text-overflow-ellipsis white-space-nowrap overflow-hidden"
                  style={{ minWidth: "70%", maxWidth: "70%" }}
                >
                  {placeData?.trnNumber}
                </span>
              </p>
            </div>
          ) : null}
        </div>
      </div>

      {openCompanyDetails ? (
        <CompanyDetails
          closePopup={() => {
            setOpenCompanyDetails(false);
          }}
          popupIsOpen={true}
        />
      ) : null}
      {openAddAddress ? (
        <AddAddress
          closePopup={() => {
            setOpenAddAddress(false);
            setExistingAddress({});
          }}
          popupIsOpen={true}
          isEditable={Object.keys(existingAddress).length == 0 ? false : true}
          existingData={existingAddress}
        />
      ) : null}
      {openDeleteAddress && (
        <DeleteAddress
          handleClose={() => {
            setOpenDeleteAddress(false);
            setDeleteAddressId(null);
          }}
          handleSubmit={() => {
            deleteAddressApi();
          }}
        />
      )}
    </>
  );
};
export default Address;
