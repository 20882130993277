import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./CompareProduct.scss";
import FrontToolbar from "../../global-modules/FrontToolbar";
import NewsLatter from "../layout/footer/NewsLatter";
import Footer from "../layout/footer/Footer";
import Breadcrumb from "../../global-modules/Breadcrumb";
import xCircle from "../../assets/images/structure/x-circle.svg";
import noImage from "../../assets/images/products/noImage.webp";
import { Button } from "primereact/button";
import { Base64 } from "js-base64";
import addDeleteGetLocalStorage from "../../prototype/addDeleteGetLocalStorage";
import { useEffect } from "react";
import { API_ROUTES, STORAGE } from "../../Enum";
import { useCallback } from "react";
import globalRequest from "../../prototype/globalRequest";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../redux/reducers/loader";
import { setSnackbar } from "../../redux/reducers/snackbar";
import { convertCurrency, productPrice, returnRandomGuest } from "../../Helpers/HelperFunctions";
import { currencyCoversionObject } from "../../redux/reducers/currencyConversion";
import { fetchUserProductsCount } from "../../redux/reducers/userProductsCount";
import NotifyMe from "../../DialogBox/NotifyMe";
import encryptDcrypt from "../../prototype/encryptDcrypt";

const CompareProduct = () => {
  const param = useParams();
  const navigate = useNavigate();
  const catName = decodeURIComponent(Base64.decode(param?.category_name));
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let currency_am = addDeleteGetLocalStorage(STORAGE.CURRENCY, {}, "get");
  const conversionRate = useSelector(currencyCoversionObject);
  let token = JSON.parse(
    addDeleteGetLocalStorage(STORAGE.AL_MAHARA_USER, {}, "get")
  )?.token;
  let language = localStorage.getItem("i18nextLng");

  const [allProduct, setAllProduct] = useState([
    {
      ind: 0,
      image: "",
      id: "",
      title: "",
      sub_category: "",
      sub_category_name: "",
      specification: [],
      price: 0,
      stock: false,
      isInCart: false,
      slug: null,
      category_name: "",
    },
    {
      ind: 1,
      image: "",
      id: "",
      title: "",
      sub_category: "",
      sub_category_name: "",
      specification: [],
      price: 0,
      stock: false,
      isInCart: false,
      slug: null,
      category_name: "",
    },
    {
      ind: 2,
      image: "",
      id: "",
      title: "",
      sub_category: "",
      sub_category_name: "",
      specification: [],
      price: 0,
      stock: false,
      isInCart: false,
      slug: null,
      category_name: "",
    },
    {
      ind: 3,
      image: "",
      id: "",
      title: "",
      sub_category: "",
      sub_category_name: "",
      specification: [],
      price: 0,
      stock: false,
      isInCart: false,
      slug: null,
      category_name: "",
    },
    {
      category_name: "",
      id: "",
      image: "",
      ind: 4,
      isInCart: false,
      price: 0,
      slug: null,
      specification: [],
      stock: false,
      sub_category: "",
      sub_category_name: "",
      title: "",
    },
  ]);
  const [setProducts, setSetProducts] = useState(false);
  const [specification, setSpecification] = useState([]);
  const [bradCrumbTitles, setBradCrumbTitles] = useState({
    eng: catName,
    ara: catName,
    id: "",
  });

  //get compare product details by id from db
  const getCompareProductDetails = useCallback(async (ids) => {
    if (!ids.length) return;
    let params = {
      productId: JSON.stringify(ids),
    };
    dispatch(changeLoader(true));
    globalRequest(
      "get",
      API_ROUTES.GET_COMPARE_PRODUCT_DETAILS,
      {},
      { params: params },
      token ? true : false
    )
      .then((res) => {
        dispatch(changeLoader(false));
        if (res?.ack == 1) {
          let a = [...allProduct];
          let data = res?.data;
          setBradCrumbTitles({
            eng: data[0]?.category_details?.name_en,
            ara: data[0]?.category_details?.name_ar,
            id: data[0]?.category_details?.id,
          });
          data?.forEach((obj, ind) => {
            let product = {
              image: obj?.product_images[0]?.image_url,
              id: obj?.id,
              title: obj?.title,
              sub_category: obj?.subcategory_id,
              category_id: obj?.category_id,
              category_name:
                language == "en"
                  ? obj?.category_details?.name_en
                  : obj?.category_details?.name_ar,
              specification: obj?.product_specification,
              price: productPrice(
                obj?.discount_price,
                1,
                obj?.price_ranges,
                obj?.is_wholesale
              ), //parseFloat(obj?.discount_price).toFixed(2), // set price according to wholesale range
              slug: obj?.slug,
              selling_price: parseFloat(obj?.selling_price).toFixed(2),
              stock: obj?.stock == null ? false : true,
              sub_category_name:
                language == "en"
                  ? obj?.subcategory_details?.name_en
                  : obj?.subcategory_details?.name_ar,
              isInCart: obj?.is_in_cart == 0 ? false : true,
              productType: obj?.product_type,
            };

            if (ind < 5) {
              a[ind] = product;
            }
            setAllProduct(a);
            setSetProducts(true);
          });
        } else {
          console.error("err", res);
        }
      })
      .catch((err) => {
        console.error("err", err);
      });
  });

  // set all product
  useEffect(() => {
    let get = addDeleteGetLocalStorage(STORAGE.COMPARE_PRODUCT, {}, "get");
    //get all product ids
    let ids = [];
    if (get) {
      if (get.length) {
        JSON.parse(get).forEach((obj, ind) => {
          ids.push(obj?.id);
        });
      }
    }
    getCompareProductDetails(ids);
  }, []);

  //redirect to home page when all product is empty
  useEffect(() => {
    let get = addDeleteGetLocalStorage(STORAGE.COMPARE_PRODUCT, {}, "get");
    if (!get) {
      navigate("/");
    } else {
      if (!JSON.parse(get).length) {
        navigate("/");
      }
    }
  }, [allProduct]);

  //need to update specification array
  useEffect(() => {
    if (!setProducts) return;
    if (allProduct?.length) {
      let specificationArr = [];
      //check for duplicate specification
      allProduct?.forEach((obj, inds) => {
        if (obj?.specification?.length) {
          let filter = obj?.specification?.filter((obj, index, self) => {
            return index === self.findIndex((o) => o.name === obj.name);
          });
          filter?.forEach((obj1, ind1) => {
            let index = specificationArr.findIndex((a) => a.name == obj1.name);
            if (index == -1) {
              specificationArr.push({
                name: obj1.name,
                productIds: [obj?.id],
              });
            } else {
              specificationArr[index].productIds.push(obj?.id);
            }
          });
        }
      });

      //get specification value for each product with respect to specification name
      // set productIds in specificationArr
      let actualSpec = [];
      specificationArr?.forEach((obj, ind) => {
        let tempObj = {
          specName: obj?.name,
          productIds: obj?.productIds || [],
          values: [],
        };
        obj?.productIds?.forEach((obj1, ind1) => {
          let find = allProduct.find((a) => a.id == obj1); //get product
          let projectIndex = allProduct.findIndex((a) => a.id == obj1); //get product index
          let product_specification = find?.specification; //get product specification
          let findSpec = product_specification.find((a) => a.name == obj?.name); //get product specification with respect to specification name
          let val = findSpec?.product_specification_value?.specification_value; //get product specification value
          tempObj.values[projectIndex] = {
            name: val ? val : "-",
          };
        });
        actualSpec.push(tempObj);
      });
      setSpecification(actualSpec);
    }
  }, [allProduct]);


  ///add to cart function
  const addToCart = useCallback(async (item, ind, type = null) => {
    let localGuestId = addDeleteGetLocalStorage(STORAGE.GUEST_ID, {}, "get");
    let guestId = localGuestId
      ? localGuestId
      : returnRandomGuest();
    let data = {
      productId: item?.id,
      quantity: 1,
      price: item?.price,
      productType: item?.productType,
    };
    if (!token && guestId) {
      data = { ...data, guestid: token ? "" : guestId.trim() };
    }
    dispatch(changeLoader(true));
    try {
      let res = await globalRequest(
        "post",
        API_ROUTES.ADD_TO_CART,
        data,
        {},
        token ? true : false
      );
      if (res?.ack == 1) {
        dispatch(changeLoader(false));
        addDeleteGetLocalStorage(STORAGE.GUEST_ID, guestId, "add", "single");
        dispatch(
          setSnackbar({
            isOpen: true,
            state: "success",
            message: t("AddedToCartSuccessfully"),
          })
        );
        dispatch(fetchUserProductsCount());
        if (type == "buyNow") {
          navigate("/cart");
        }
        // make add cart to false
        let a = [...allProduct];
        a[ind] = {
          ...a[ind],
          isInCart: true,
        };
        setAllProduct(a);
        /// update local product list
        let getCompareProducts = addDeleteGetLocalStorage(
          STORAGE.COMPARE_PRODUCT,
          {},
          "get"
        );
        if (getCompareProducts)
          getCompareProducts = JSON.parse(getCompareProducts);
        let isExist = getCompareProducts?.find((a) => a?.id == item?.id);
        if (isExist) {
          let index = getCompareProducts.findIndex((a) => a?.id == item?.id);
          getCompareProducts[index] = {
            ...getCompareProducts[index],
            isInCart: true,
          };
          let t = encryptDcrypt("en", JSON.stringify(getCompareProducts));
          localStorage.setItem(STORAGE.COMPARE_PRODUCT, t);
        }
      } else {
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            isOpen: true,
            state: "error",
            message: t("SomethingWentWrong"),
          })
        );
      }
    } catch (err) {
      dispatch(changeLoader(false));
      dispatch(
        setSnackbar({
          isOpen: true,
          state: "error",
          message: t("SomethingWentWrong"),
        })
      );
    }
  });

  //notify me function
  const [notifyMeBtn, setNotifyMeBtn] = useState(false);
  const [notifyMeProductId, setNotifyMeProductId] = useState("");
  const notifyMeFunction = useCallback((id) => {
    setNotifyMeProductId(id);
    setNotifyMeBtn(true);
  });

  //router product detail page
  const routerProductDetail = (slug, id) => {
    navigate(
      `/product-detail-page/p/${slug}/${Base64.encode(encodeURIComponent(id))}`
    );
  };

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Check if the user has scrolled down a certain amount (e.g., 100 pixels)
      if (window.scrollY > 150) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <FrontToolbar />
      <div className="container">
        <div className=" mb-4">
          <Breadcrumb
            title={
              language == "en" ? bradCrumbTitles?.eng : bradCrumbTitles?.ara
            }
            li={[
              {
                title: t("Compare"),
                backToCategory: true,
                path: bradCrumbTitles?.id,
              },
            ]}
          />
        </div>
        <div className="grid grid-overflow">
          <h3 className="h3 mb-3">{t("CompareProducts")}</h3>
          <div className="col-12 sm:col-12 md:col-12 lg:col-12 pb-5">
            <div className="compare-product-wrapper mb-4">
              <div
                className={`compare-product-block ${
                  isScrolled ? "scrolled-down" : ""
                }`}
              >
                <div className="top-boxes">
                  <div className="product-blank-view"></div>
                  {allProduct.map((obj, ind) => {
                    if (obj?.title) {
                      const removeImg = () => {
                        let a = [...allProduct];
                        a.splice(ind, 1);
                        a.push({
                          ind: ind,
                          image: "",
                          id: "",
                          title: "",
                          sub_category: "",
                          specification: "",
                          price: "",
                          stock: "",
                          slug: null,
                        });
                        setAllProduct(a);
                        addDeleteGetLocalStorage(
                          STORAGE.COMPARE_PRODUCT,
                          { id: obj.id },
                          "remove"
                        );
                      };
                      return (
                        <div key={ind} className="product-top-view">
                          <div className="product-downloaded">
                            <div className="product-img-block">
                              <img
                                src={xCircle}
                                onClick={() => {
                                  removeImg();
                                }}
                                className="circle-close"
                                alt=""
                              />
                              <img
                                src={obj?.image}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = noImage;
                                }}
                                alt=""
                                onClick={() => {
                                  if (!obj?.slug) return;
                                  routerProductDetail(obj?.slug, obj?.id);
                                }}
                              />
                            </div>
                            <p className="p2 p2-semibold mb-2 capitalize-first-letter truncated-text">
                              {obj?.sub_category_name}
                            </p>
                            <p className="p2 p2-semibold mb-4 capitalize-first-letter truncated-text">
                              {String(obj?.title).length <= 40
                                ? String(obj?.title)
                                : String(obj?.title).substring(0, 40) +
                                  "......"}
                            </p>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div key={ind} className="product-top-view">
                          <div className="product-download">
                            {/* <img src={plusBlack} alt="" /> */}
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>

                <div className="khjfsdjksdfk">
                  <div className="bottom-boxes">
                    <div className="p-20 fill-column outline-column">
                      <span className="p2 p2-bold">{t("Price")}</span>
                    </div>
                    {allProduct.map((obj, ind) => {
                      return (
                        <div key={ind} className="p-20 outline-column">
                          <span className="p2">
                            {obj?.price
                              ? `${currency_am} ${convertCurrency(
                                  obj?.price,
                                  conversionRate
                                )}`
                              : ""}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                  <div className="bottom-boxes">
                    <div className="p-20 fill-column outline-column">
                      <span className="p2 p2-bold">{t("availability")}</span>
                    </div>
                    {allProduct.map((obj, ind) => {
                      return (
                        <div key={ind} className="p-20 outline-column">
                          <span className="p2">
                            {obj.id != ""
                              ? obj?.stock
                                ? "In stock"
                                : " Out of stock"
                              : ""}
                          </span>
                        </div>
                      );
                    })}
                  </div>

                  {specification?.map((obj, ind) => {
                    return (
                      <div key={ind} className="bottom-boxes">
                        <div className="p-20 fill-column outline-column">
                          <span className="p2 p2-bold">{obj?.specName}</span>
                        </div>
                        <div className="p-20 outline-column">
                          <span className="p2">
                            {allProduct[0]?.id == ""
                              ? ""
                              : obj?.productIds?.includes(allProduct[0]?.id)
                              ? obj?.values[0]?.name
                              : "-"}
                          </span>
                        </div>
                        <div className="p-20 outline-column">
                          <span className="p2">
                            {allProduct[1]?.id == ""
                              ? ""
                              : obj?.productIds?.includes(allProduct[1]?.id)
                              ? obj?.values[1]?.name
                              : "-"}
                          </span>
                        </div>
                        <div className="p-20 outline-column">
                          <span className="p2">
                            {allProduct[2]?.id == ""
                              ? ""
                              : obj?.productIds?.includes(allProduct[2]?.id)
                              ? obj?.values[2]?.name
                              : "-"}
                          </span>
                        </div>
                        <div className="p-20 outline-column">
                          <span className="p2">
                            {allProduct[3]?.id == ""
                              ? ""
                              : obj?.productIds?.includes(allProduct[3]?.id)
                              ? obj?.values[3]?.name
                              : "-"}
                          </span>
                        </div>
                        <div className="p-20 outline-column">
                          <span className="p2">
                            {allProduct[4]?.id == ""
                              ? ""
                              : obj?.productIds?.includes(allProduct[4]?.id)
                              ? obj?.values[4]?.name
                              : "-"}
                          </span>
                        </div>
                      </div>
                    );
                  })}

                  <div className="bottom-boxes">
                    <div className="p-20 fill-column outline-column"></div>
                    {allProduct.map((obj, ind) => {
                      return (
                        <div key={ind} className="p-20 outline-column">
                          {obj.id != "" ? (
                            obj?.stock ? (
                              <>
                                {obj?.isInCart ? (
                                  <Button
                                    severity="Success"
                                    size="large"
                                    className="w-full"
                                    label={t("GoToCart")}
                                    onClick={() => {
                                      navigate("/cart");
                                    }}
                                  />
                                ) : (
                                  <>
                                    <Button
                                      severity="Success"
                                      size="large"
                                      className="w-full"
                                      label={t("AddToCart")}
                                      onClick={() => {
                                        addToCart(obj, ind);
                                        //need to check if product is added to cart or not
                                        //if added then make isInCart true
                                      }}
                                    />
                                    <Button
                                      severity="danger"
                                      size="large"
                                      outlined
                                      className="w-full mt-2"
                                      label={t("buyNow")}
                                      onClick={() => {
                                        addToCart(obj, ind, "buyNow");
                                        //need to check if product is added to cart or not
                                        //if added then make isInCart true
                                      }}
                                    />
                                  </>
                                )}
                              </>
                            ) : (
                              <Button
                                severity="Success"
                                size="large"
                                className="w-full"
                                label={t("NotifyMe")}
                                onClick={() => {
                                  notifyMeFunction(obj?.id);
                                }}
                              />
                            )
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <NewsLatter />
      <Footer />
      {notifyMeBtn && (
        <NotifyMe
          handleClose={() => setNotifyMeBtn(false)}
          productId={notifyMeProductId}
        />
      )}
    </>
  );
};
export default CompareProduct;
