import { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./SortedProducts.scss";
import sortbyIcon from "../../../assets/images/structure/sortby-icon.svg";
import noImage from "../../../assets/images/products/noImage.webp";
import { Button } from "primereact/button";
import {
  convertCurrency,
  productPrice,
  returnRandomGuest,
} from "../../../Helpers/HelperFunctions";
import { axiosConstant } from "../../../Helpers/Axios/Constants";
import EmptyWishlist from "../../Wishlist/Empty-wishlist";
import { API_ROUTES, STORAGE } from "../../../Enum";
import addDeleteGetLocalStorage from "../../../prototype/addDeleteGetLocalStorage";
import {
  categoryListObject,
  setCategoryList,
} from "../../../redux/reducers/categoryList";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import { useCallback } from "react";
import { setSnackbar } from "../../../redux/reducers/snackbar";
import { fetchUserProductsCount } from "../../../redux/reducers/userProductsCount";
import NotifyMe from "../../../DialogBox/NotifyMe";
import { currencyCoversionObject } from "../../../redux/reducers/currencyConversion";
import { Checkbox } from "primereact/checkbox";
import { Sidebar } from "primereact/sidebar";
import { InputNumber } from "primereact/inputnumber";
import { CompareBottomBar } from "../CompareProduct/CompareBottomBar";
import { sassFalse } from "sass";
import { Base64 } from "js-base64";
import uniqid from "uniqid";
import { Icon } from "@material-ui/core";
import globalRequest from "../../../prototype/globalRequest";

let timer;
const SortedProducts = ({
  loading,
  products,
  setSortby,
  totalProducts,
  limit,
  setLimit,
  setFilteredProductList,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let language = localStorage.getItem("i18nextLng");
  let currency_am = addDeleteGetLocalStorage(STORAGE.CURRENCY, {}, "get");
  let token = JSON.parse(
    addDeleteGetLocalStorage(STORAGE.AL_MAHARA_USER, {}, "get")
  )?.token;
  let categoryObj = useSelector(categoryListObject);
  let selectedCategory = categoryObj?.selectedCategory;
  const [notifyMeBtn, setNotifyMeBtn] = useState(false);
  const [notifyMeProductId, setNotifyMeProductId] = useState(null);
  const conversionRate = useSelector(currencyCoversionObject);
  const [page, setPage] = useState(1);
  const [selectedSort, setSelectedSort] = useState({
    name: t("Recommended"),
    code: "recommended",
  });
  const sorting = [
    { name: t("BestSelling"), code: "bestselling" },
    { name: t("Recommended"), code: "recommended" },
    { name: t("RecentlyAdded"), code: "recentlyadded" },
    { name: t("PriceLowtoHigh"), code: "priceLH" },
    { name: t("PriceHightoLow"), code: "priceHL" },
  ];
  const country =
    addDeleteGetLocalStorage(STORAGE.COUNTRY_DB, {}, "get") || "Iraq";
  const [showCompairUpBtn, setShowCompairUpBtn] = useState(false);

  //set selected sort
  useEffect(() => {
    if (categoryObj?.sortBy) {
      sorting.map((item) => {
        if (item.code === categoryObj?.sortBy) {
          setSelectedSort(item);
        }
      });
    } else {
      setSelectedSort({ name: t("Recommended"), code: "recommended" });
    }
  }, [selectedCategory]);

  //router product detail page
  const routerProductDetail = (slug, id) => {
    navigate(
      `/product-detail-page/p/${slug}/${Base64.encode(encodeURIComponent(id))}`
    );
  };

  ///pagination
  useEffect(() => {
    if (limit >= totalProducts) return;
    setLimit((prevLimit) => prevLimit + 20);
  }, [page]);

  //infinite scroll
  useEffect(() => {
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: "20px", // Margin around the root (if you want to trigger earlier or later)
      threshold: 1.0, // Trigger when the entire target is visible
    };
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setPage((prevPage) => prevPage + 1);
      }
    }, options);
    // Set the target to observe
    const target = document.querySelector("#observe-target");
    if (target) {
      observer.observe(target);
    }
    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, []);

  ///add to cart function
  const addToCart = useCallback(async (item, index) => {
    let localGuestId = addDeleteGetLocalStorage(STORAGE.GUEST_ID, {}, "get");
    let guestId = localGuestId
      ? localGuestId
      : returnRandomGuest();
    // let sendPrice = item?.product_type == "product" ? productPrice(item?.discount_price, 1, item?.price_ranges, item?.is_wholesale) : item?.discount_price;
    let data = {
      productId: item?.id,
      quantity: 1,
      price:
        item?.is_todays_offer === 1
          ? item?.discount_price
          : item?.selling_price,
      productType: item?.product_type,
    };
    if (!token && guestId) {
      data = { ...data, guestid: token ? "" : guestId.trim() };
    }
    dispatch(changeLoader(true));
    try {
      let res = await globalRequest(
        "post",
        API_ROUTES.ADD_TO_CART,
        data,
        {},
        token ? true : false
      );
      if (res?.ack == 1) {
        dispatch(changeLoader(false));
        addDeleteGetLocalStorage(STORAGE.GUEST_ID, guestId, "add", "single");
        dispatch(
          setSnackbar({
            isOpen: true,
            state: "success",
            message: t("AddedToCartSuccessfully"),
          })
        );
        dispatch(fetchUserProductsCount());
        let tempProductList = [...products];
        tempProductList[index].is_in_cart = 1;
        tempProductList[index].cart_count = 1;
        tempProductList[index].cart_item_id = res?.data?.id;
        setFilteredProductList(tempProductList);
        callSetTimeOut(index);
      } else {
        dispatch(changeLoader(false));
        showSomethingWentWrong();
      }
    } catch (err) {
      dispatch(changeLoader(false));
      showSomethingWentWrong();
    }
  });

  //update product quantity
  const updateProductQuantity = useCallback(
    async (id, quantity, price, addMinusValue, index, item) => {
      if (!id) return;
      let data = {
        id: id,
        quantity: quantity + addMinusValue,
        price: price,
        productType: item?.product_type,
      };
      dispatch(changeLoader(true));
      try {
        let res = await globalRequest(
          "post",
          API_ROUTES.UPDATE_CART_PRODUCT,
          data,
          {},
          token ? true : false
        );
        if (res?.ack == 1) {
          dispatch(changeLoader(false));
          let tempProductList = [...products];
          tempProductList[index].cart_count = quantity + addMinusValue;
          setFilteredProductList(tempProductList);
          callSetTimeOut(index);
          dispatch(fetchUserProductsCount());
        } else {
          dispatch(changeLoader(false));
          showSomethingWentWrong();
        }
      } catch (err) {
        dispatch(changeLoader(false));
        showSomethingWentWrong();
      }
    }
  );

  //remove product from cart (delete)
  const removeProductFromCart = useCallback(
    async (id, index, type = "delete") => {
      let guest_id = addDeleteGetLocalStorage(STORAGE.GUEST_ID, {}, "get");
      let params = {
        type: type,
      };
      if (!token && guest_id) {
        params = {
          ...params,
          guestid: guest_id,
        };
      }
      if (!id) return;
      dispatch(changeLoader(true));
      try {
        let res = await globalRequest(
          "delete",
          API_ROUTES.REMOVE_CART_PRODUCT(id),
          {},
          { params: params },
          token ? true : false
        );
        if (res?.ack == 1) {
          dispatch(changeLoader(false));
          let tempProductList = [...products];
          tempProductList[index].is_in_cart = 0;
          tempProductList[index].cart_count = 0;
          tempProductList[index].cart_item_id = null;
          tempProductList[index].isShowAddMinusBtn = false;
          setFilteredProductList(tempProductList);
          dispatch(fetchUserProductsCount());
        } else {
          dispatch(changeLoader(false));
          showSomethingWentWrong();
        }
      } catch (err) {
        dispatch(changeLoader(false));
        showSomethingWentWrong();
      }
    }
  );

  //notify me function
  const notifyMeFunction = useCallback((id) => {
    setNotifyMeProductId(id);
    setNotifyMeBtn(true);
  });

  const [visibleBottom, setVisibleBottom] = useState(false);
  const [previousBtn, setPreviousBtn] = useState(null);

  // for show hide add minus button
  const callSetTimeOut = (index) => {
    let tempProductList = [...products];
    tempProductList[index].isShowAddMinusBtn = true;
    // for show hide add minus button
    clearTimeout(timer);
    setPreviousBtn(index);
    timer = setTimeout(() => {
      let tempProductList = [...products];
      tempProductList[index].isShowAddMinusBtn = false;
      setFilteredProductList(tempProductList);
    }, 3000);
  };

  let showSomethingWentWrong = () => {
    dispatch(
      setSnackbar({
        isOpen: true,
        state: "error",
        message: t("SomethingWentWrong"),
      })
    );
  };

  //show hide compare up button
  useEffect(() => {
    let getCompareProducts = addDeleteGetLocalStorage(
      STORAGE.COMPARE_PRODUCT,
      {},
      "get"
    );
    if (getCompareProducts) getCompareProducts = JSON.parse(getCompareProducts);
    if (getCompareProducts?.length > 0) {
      setShowCompairUpBtn(true);
    } else {
      setShowCompairUpBtn(false);
    }
  }, [products]);

  return (
    <>
      <div className="product-sortby-block flex align-items-start justify-content-between">
        <p className="p2">
          {totalProducts} {totalProducts > 1 ? t("products") : t("product")}
        </p>
        <div className="flex">
          <img src={sortbyIcon} alt="" />
          <Dropdown
            value={selectedSort}
            onChange={(e) => {
              setSelectedSort(e.value);
              setSortby(e.value.code);
              dispatch(
                setCategoryList({
                  ...categoryObj,
                  sortBy: e.value.code,
                })
              );
            }}
            options={sorting}
            optionLabel="name"
            placeholder={t("SortBy")}
            className="sortby p-inputtext-sm"
          />
        </div>
      </div>
      <div className="product-row">
        {/* new mapping  */}
        {products.length > 0 &&
          products.map((item, index) => {
            let discount_percentage = 0;
            if (item?.selling_price - item?.discount_price > 0) {
              discount_percentage =
                ((item?.selling_price - item?.discount_price) /
                  item?.selling_price) *
                100;
              discount_percentage = Math.floor(discount_percentage * 100) / 100;
            }
            return (
              <div key={index} className="product-box">
                <div className="product-image-box">
                  <div className="chips-parent">
                    {item?.is_todays_offer == 1 && (
                      <>
                        <span className="chips warning">
                          {t("TodaysOffers")}
                        </span>
                        {discount_percentage.toFixed(2) > 0 && (
                          <span className="chips error">
                            {`${t("Sale")} - ${discount_percentage.toFixed(
                              2
                            )}%`}
                          </span>
                        )}
                      </>
                    )}
                  </div>
                  <img
                    src={item?.product_images[0]?.image_url}
                    alt="product image"
                    className="product-image"
                    onClick={() => {
                      routerProductDetail(item?.slug, item?.id);
                    }}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = noImage;
                    }}
                  />

                  <Button
                    icon={item?.cart_count == 0 && "pi pi-plus"}
                    rounded
                    text
                    className={`P_addBtn ${
                      item?.stock == null && "addButton-no-item"
                    }`}
                    onClick={() => {
                      //hide previous button
                      if (previousBtn !== null && previousBtn !== undefined) {
                        let tempProductList = [...products];
                        tempProductList[previousBtn].isShowAddMinusBtn = false;
                        setFilteredProductList(tempProductList);
                        setPreviousBtn(null);
                      }
                      if (item?.stock !== null) {
                        if (item?.is_in_cart == 0) {
                          addToCart(item, index); //add to cart
                        } else {
                          callSetTimeOut(index);
                        }
                      } else {
                        dispatch(
                          setSnackbar({
                            isOpen: true,
                            state: "error",
                            message: t("OutofStock"),
                          })
                        );
                      }
                    }}
                  >
                    {item?.cart_count > 0 ? item?.cart_count : ""}
                  </Button>
                  <div className="productSizeBox">
                    {item?.isShowAddMinusBtn && (
                      <div className="P_inc_dec_box">
                        <i
                          className="pi pi-minus"
                          onClick={() => {
                            let newValue = item?.cart_count - 1;
                            let addMinusValue = newValue - item?.cart_count;
                            if (item?.is_in_cart) {
                              //check if stock is available or not
                              if (item?.stock?.stock >= newValue) {
                                updateProductQuantity(
                                  item?.cart_item_id,
                                  item?.cart_count,
                                  item?.is_todays_offer === 1
                                    ? item?.discount_price
                                    : item?.selling_price,
                                  addMinusValue,
                                  index,
                                  item
                                );
                              } else if (item?.stock?.stock < newValue) {
                                dispatch(
                                  setSnackbar({
                                    isOpen: true,
                                    state: "error",
                                    message: `${t("Only")} ${
                                      item?.stock?.stock
                                    } ${t("QuantityIsAvailable")}`,
                                  })
                                );
                              }
                            }
                            if (item?.cart_count == 1 && addMinusValue == -1) {
                              removeProductFromCart(
                                item?.cart_item_id,
                                index,
                                "delete"
                              );
                            }
                          }}
                        ></i>
                        <span className="P_count">{item?.cart_count}</span>
                        <i
                          className="pi pi-plus"
                          onClick={() => {
                            let newValue = item?.cart_count + 1;
                            let addMinusValue = newValue - item?.cart_count;
                            if (item?.is_in_cart) {
                              //check if stock is available or not
                              if (item?.stock?.stock >= newValue) {
                                updateProductQuantity(
                                  item?.cart_item_id,
                                  item?.cart_count,
                                  item?.is_todays_offer === 1
                                    ? item?.discount_price
                                    : item?.selling_price,
                                  addMinusValue,
                                  index,
                                  item
                                );
                              } else if (item?.stock?.stock < newValue) {
                                dispatch(
                                  setSnackbar({
                                    isOpen: true,
                                    state: "error",
                                    message: `${t("Only")} ${
                                      item?.stock?.stock
                                    } ${t("QuantityIsAvailable")}`,
                                  })
                                );
                              }
                            }
                            if (item?.cart_count == 1 && addMinusValue == -1) {
                              removeProductFromCart(
                                item?.cart_item_id,
                                index,
                                "delete"
                              );
                            }
                          }}
                        ></i>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="product-content"
                  onClick={() => {
                    routerProductDetail(item?.slug, item?.id);
                  }}
                >
                  <p className="p3 capitalize-first-letter">
                    {language == "en"
                      ? t(
                          `${
                            item?.subcategory_details?.name_en
                              ? item?.subcategory_details?.name_en
                              : ""
                          }`
                        )
                      : t(
                          `${
                            item?.subcategory_details?.name_ar
                              ? item?.subcategory_details?.name_ar
                              : ""
                          }`
                        )}
                  </p>
                  <h6 className="h6 capitalize-first-letter">{item?.title}</h6>
                  <p
                    className="p3 truncated-text"
                    dangerouslySetInnerHTML={{
                      __html: item?.short_description,
                    }}
                  ></p>
                  <div className="price-row">
                    {/* new price data with conditions */}
                    <>
                      <p className="p2">
                        {currency_am +
                          " " +
                          convertCurrency(
                            item?.is_todays_offer === 1
                              ? item?.discount_price
                              : item?.selling_price,
                            conversionRate
                          )}
                      </p>
                      {item?.is_todays_offer === 1 && (
                        <>
                          {item?.discount_price !== item?.selling_price ? (
                            <p
                              className={`p3 ${
                                item?.discount_price !== item?.selling_price
                                  ? "cross"
                                  : ""
                              }`}
                            >
                              {currency_am +
                                " " +
                                convertCurrency(
                                  item?.selling_price,
                                  conversionRate
                                )}
                            </p>
                          ) : null}
                        </>
                      )}
                    </>
                  </div>
                  {country === "UAE" && (
                    <p
                      className="p3 mt-1"
                      style={{
                        color: "#f9b000",
                        fontFamily: "OpenSans-Medium",
                      }}
                    >
                      {t("Exclusiveoftax")}
                    </p>
                  )}
                </div>
                <div className="flex align-items-start compCheckbox">
                  <Checkbox
                    onChange={(e) => {
                      let get = addDeleteGetLocalStorage(
                        STORAGE.COMPARE_PRODUCT,
                        {},
                        "get"
                      );
                      if (get) get = JSON.parse(get);
                      if (item?.isInCompare) {
                        let tempProductList = [...products];
                        tempProductList[index].isInCompare = false;
                        setFilteredProductList(tempProductList);
                        // remove from local storage
                        if (!get.length) return;
                        let isExist = get?.find((obj) => obj?.id == item?.id);
                        if (isExist) {
                          addDeleteGetLocalStorage(
                            STORAGE.COMPARE_PRODUCT,
                            { id: item.id },
                            "remove"
                          );
                        }
                      } else {
                        if (get?.length) {
                          if (get?.length >= 5) {
                            dispatch(
                              setSnackbar({
                                isOpen: true,
                                state: "warn",
                                message: t(
                                  "YouCanAddOnly5PoductsIntoTheComapre"
                                ),
                              })
                            );
                            return;
                          }
                          if (get[0]?.category_id != item?.category_id) {
                            dispatch(
                              setSnackbar({
                                isOpen: true,
                                state: "warn",
                                message: t(
                                  "YouCanAddOnlySameCategoryProductsIntoTheCompare"
                                ),
                              })
                            );
                            return;
                          }
                        }

                        let tempProductList = [...products];
                        tempProductList[index].isInCompare = true;
                        setFilteredProductList(tempProductList);
                        // add to local storage
                        let obj = {
                          image: item?.product_images[0]?.image_url,
                          id: item?.id,
                          title: item?.title,
                          sub_category: item?.subcategory_id,
                          category_id: item?.category_id,
                          category_name:
                            language == "en"
                              ? item?.category_details?.name_en
                              : item?.category_details?.name_ar,
                          specification: item?.product_specification,
                          price: parseFloat(item?.discount_price).toFixed(2),
                          slug: item?.slug,
                          selling_price: parseFloat(
                            item?.selling_price
                          ).toFixed(2),
                          stock: item?.stock == null ? false : true,
                          sub_category_name:
                            language == "en"
                              ? item?.subcategory_details?.name_en
                              : item?.subcategory_details?.name_ar,
                          isInCart: item?.is_in_cart == 0 ? false : true,
                          productType: item?.product_type,
                        };
                        addDeleteGetLocalStorage(
                          STORAGE.COMPARE_PRODUCT,
                          obj,
                          "add",
                          "multiple"
                        );
                      }
                    }}
                    checked={item?.isInCompare ? true : false}
                    inputId="ingredient1"
                    onClick={() => setVisibleBottom(true)}
                  ></Checkbox>
                  <label className="ml-2">{t("Compare")}</label>
                </div>
              </div>
            );
          })}
      </div>

      <div id="observe-target" style={{ height: "10px" }}></div>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        {products.length == 0 && !loading && (
          <EmptyWishlist page="product_list" />
        )}
      </div>

      {notifyMeBtn && (
        <NotifyMe
          handleClose={() => setNotifyMeBtn(false)}
          productId={notifyMeProductId}
        />
      )}

      <Sidebar
        className="compareProductDrawer"
        visible={visibleBottom}
        position="bottom"
        onHide={() => {
          setVisibleBottom(false);
        }}
      >
        <CompareBottomBar
          products={products}
          setFilteredProductList={setFilteredProductList}
          setVisibleBottom={setVisibleBottom}
        />
      </Sidebar>

      {!visibleBottom && showCompairUpBtn && (
        <button
          className="compareproductshow-btn"
          title="Open Compare Product"
          onClick={() => {
            setVisibleBottom(true);
          }}
        ></button>
      )}
    </>
  );
};

export default SortedProducts;
