import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import modalClose from "../assets/images/structure/modal-close.svg";

const SendChangePasswordUrl = ({ handleClose }) => {
  const { t } = useTranslation();

  return (
    <>
      <Dialog
        visible={true}
        onHide={() => handleClose()}
        draggable={false}
        resizable={false}
        closable={false}
        showHeader={false}
        className="dialog-340"
      >
        <img
          src={modalClose}
          alt="close"
          className="modal-close"
          onClick={() => handleClose()}
        />
        {/* <h3 className="h3 mb-2">{t("Delete")}</h3> */}
        <p className="p2 mt-4">
          {t("ResetPasswordUrlSendedPleaseCheckYourMailId")}
        </p>
        <div className="modal-end-btn mt-0">
          <Button
            label={t("OK")}
            className="yellow"
            onClick={() => {
              handleClose();
            }}
            text
          />
        </div>
      </Dialog>
    </>
  );
};

export default SendChangePasswordUrl;
