import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import "./Wishlist.scss";
// images
import emptyWishlist from "../../assets/images/structure/empty-wishlist.svg";

const EmptyWishlist = ({ page }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const routerHome = () => {
    navigate("/");
  };

  if (page == "order_list") {
    return (
      <div className="empty-parent">
        <div className="empty-page">
          <img
            src={emptyWishlist}
            alt="empty wishlist"
            className="empty-image"
          />
          <h3 className="h3 ">{t("NoOrdersFound!")}</h3>
        </div>
      </div>
    );
  }
  if (page == "product_list")
    return (
      <>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="empty-page">
            <img
              src={emptyWishlist}
              alt="empty wishlist"
              className="empty-image"
            />
            <h3 className="h3 ">{t("NoProductsFound!")}</h3>
          </div>
        </div>
      </>
    );
  if (page == "whishlist")
    return (
      <>
        <div className="empty-page">
          <img
            src={emptyWishlist}
            alt="empty wishlist"
            className="empty-image"
          />
          <h3 className="h3 ">{t("Heyitfeelssolight")}</h3>
          <p className="p2">
            {t("Thereisnothinginyourwishlistletsaddsomeitems")}
          </p>
          <Button severity="Success" size="large" onClick={routerHome}>
            {t("Gotohomepage")}
          </Button>
        </div>
      </>
    );
  if (page == "cart")
    return (
      <>
        <div className="empty-page">
          <div className="empty-page">
            <img
              src={emptyWishlist}
              alt="empty wishlist"
              className="empty-image"
            />
            <h3 className="h3 ">{t("Yourcartisempty!")}</h3>
          </div>
        </div>
      </>
    );
  if (page == "bundle_list") {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="empty-page">
          <img
            src={emptyWishlist}
            alt="empty wishlist"
            className="empty-image"
          />
          <h3 className="h3 ">{t("NoBundlesFound!")}</h3>
        </div>
      </div>
    );
  }
};

export default EmptyWishlist;
