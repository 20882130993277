/**
 * @file Loaders.js
 * @description This file contains the loader component
 * @exports {Object} Loaders
 */

// import BounceLoader from "react-spinners/BounceLoader";
import { css } from "@emotion/react";
import "./Loaders.scss";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
`;

const Loaders = () => {
  return (
    // <div className={"sweet-loading"}>
    //   <BounceLoader css={override} size={100} color={"red"} loading={true} speedMultiplier={1.5} />
    // </div>
    <div className="lds-ring">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    </div>
  );
};

export default Loaders
