import CheckoutHeader from "../layout/CheckoutHeader/CheckoutHeader";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import "./PlaceOrder.scss";
import tagIcon from "../../assets/images/structure/tag-icon.svg";
import shopMoreGif from "../../assets/images/structure/shop-more-gif.gif";
import arrowRight from "../../assets/images/structure/arrow-right.svg";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { API_ROUTES, STORAGE } from "../../Enum";
import { useCallback } from "react";
import deleteIcon from "../../assets/images/structure/delete-icon.svg";
import noImage from "../../assets/images/products/noImage.webp";
import emptyWishlist from "../../assets/images/structure/empty-wishlist.svg";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader, currentLoader } from "../../redux/reducers/loader";
import addDeleteGetLocalStorage from "../../prototype/addDeleteGetLocalStorage";
import { setSnackbar, snackObj } from "../../redux/reducers/snackbar";
import { currentAuth } from "../../redux/reducers/auth";
import ApplyCoupon from "../../DialogBox/ApplyCoupon";
import Address from "./Address";
import {
  currentPlaceOrderData,
  setPlaceOrderData,
} from "../../redux/reducers/placeOrderData";
import PaymentMode from "./PaymentMode";
import Footer from "../layout/footer/Footer";
import RemoveModal from "../../DialogBox/RemoveModal";
import {
  currencyCoversionObject,
  fetchCurrencyConverionApi,
} from "../../redux/reducers/currencyConversion";
import Loaders from "../../Loaders";
import { Base64 } from "js-base64";
import {
  changeUserProductsCount,
  fetchUserProductsCount,
  userProductsObj,
} from "../../redux/reducers/userProductsCount";
import Login from "../../DialogBox/Login";
import ForgetPassword from "../../DialogBox/ForgotPassword";
import SendChangePasswordUrl from "../../DialogBox/SendChangePasswordUrl";
import { InputNumber } from "primereact/inputnumber";
import { Checkbox } from "primereact/checkbox";
import globalRequest from "../../prototype/globalRequest";

const debounce = (func, delay) => {
  let timeoutId;
  return function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func.apply(null, args);
    }, delay);
  };
};

const PlaceOrder = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector(currentAuth);
  let language = localStorage.getItem("i18nextLng");
  let DB = addDeleteGetLocalStorage(STORAGE.COUNTRY_DB, {}, "get");
  let currency_am = DB === "Iraq" ? "USD" : "AED";
  let token = auth?.token;
  const productCount = useSelector(userProductsObj);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [cartProducts, setCartProducts] = useState([]);
  const [selectedID, setSelectedID] = useState(null);
  const [selecteProductId, setSelectedProductId] = useState(null);
  const [couponOpen, setCouponOpen] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [subTotal, setSubTotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [couponAmount, setCouponAmount] = useState(0);
  const [step, setStep] = useState(0);
  const placeData = useSelector(currentPlaceOrderData);
  const conversionRate = useSelector(currencyCoversionObject);
  const loaderFlag = useSelector(currentLoader);
  const [loadingShiping, setLoadingShiping] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [openForgetPassword, setOpenForgetPassword] = useState(false);
  const [openResetPassword, setOpenResetPassword] = useState(false);
  const [isPartial, setIsPartial] = useState(false);
  const [isDisableToPartial, setIsDisableToPartial] = useState(false);

  const [stepClears, setStepClears] = useState({
    step1: true,
    step2: false,
    step3: false,
  });

  const routeShopmore = () => {
    let path = `/`;
    navigate(path);
  };

  useEffect(() => {
    if (subTotal != 0) {
      if (subTotal < couponAmount) {
        setSelectedCoupon(null);
        setCouponAmount(0);
      }
      dispatch(
        setPlaceOrderData({
          ...placeData,
          subtotalAmount: subTotal,
          taxAmount: tax,
          taxPercentage: tax ? conversionRate?.tax : "",
          totalAmount: subTotal - couponAmount + tax,
          discountAmount: couponAmount,
          currency: currency_am,
          paymentType: placeData?.paymentType,
          // userDetails: stepClears?.step1 ? {} : placeData?.userDetails,
          userDetails: placeData?.userDetails,
        })
      );
    }
  }, [subTotal, tax, couponAmount]);

  useEffect(() => {
    if (cartProducts.length) {
      let total = 0;
      cartProducts.forEach((item) => {
        total += parseFloat(item?.product?.discount_price) * item?.quantity;
      });
      setSubTotal(total);
    }
  }, [cartProducts]);

  useEffect(() => {
    if (subTotal == 0) return;
    //tax apply only on UAE country
    if (DB === "UAE") {
      //tax calculation (tax = (subtotal + shipping charge - coupon amount) * tax percentage
      let couponValue = placeData?.discountAmount
        ? placeData?.discountAmount
        : 0;
      let taxAmount =
        ((subTotal + placeData?.shippingCharge - couponValue) / 100) *
        conversionRate?.tax;
      setTax(taxAmount);
    } else {
      setTax(0);
    }
  }, [
    conversionRate?.tax,
    subTotal,
    placeData?.shippingCharge,
    placeData?.discountAmount,
  ]);

  //shipping charge with respect to shipping company
  useEffect(() => {
    let cartId = placeData?.cartId;
    let addressId = placeData?.addressId;
    if (!cartId || !addressId) return;
    if (placeData?.deliveryType == "delivery") {
      getAramexShippingCharge();
    } else {
      dispatch(
        setPlaceOrderData({
          ...placeData,
          shippingCharge: 0,
          shippingCompany: "",
          allShippingCharges: [],
        })
      );
    }
  }, [placeData?.cartId, placeData?.addressId, placeData?.paymentType, step]);

  //get back to step 2 if shipping not available
  const getBackToSecondStep = () => {
    setStep(1);
    setStepClears({
      step1: true,
      step2: true,
      step3: false,
    });
    dispatch(
      setSnackbar({
        message: t("NoShippingCompanyAvailableForThisOrder"),
        isOpen: true,
        state: "error",
      })
    );
    dispatch(
      setPlaceOrderData({
        ...placeData,
        shippingCharge: 0,
        shippingCompany: "",
        allShippingCharges: [],
      })
    );
  };

  //get aramex shipping charge
  const getAramexShippingCharge = useCallback(async () => {
    // return; // remove this line when aramex api is ready
    //check payment type , cart id and address id is available or not
    if (
      !token ||
      !placeData?.paymentType ||
      !placeData?.addressId ||
      !placeData?.cartId
    )
      return;

    let data = {
      cartId: placeData?.cartId,
      addressId: placeData?.addressId,
      paymentType: placeData?.paymentType,
    };
    dispatch(changeLoader(true));
    setLoadingShiping(true);
    globalRequest(
      "post",
      API_ROUTES.GET_SHIPPING_CHARGES,
      data,
      {},
      token ? true : false
    )
      .then((data) => {
        if (data?.ack == 1) {
          let shippingObj = data?.data;

          let allShiping = [];

          if (shippingObj?.shopini && shippingObj?.shopini?.ack == 1) {
            allShiping.push({
              shippingCompany: "shopini",
              shippingCharge:
                shippingObj?.shopini?.Value / conversionRate?.usd_to_ikd,
            });
          }
          if (shippingObj?.aramax && shippingObj?.aramax?.ack == 1) {
            allShiping.push({
              shippingCompany: "aramex",
              shippingCharge: shippingObj?.aramax?.Value,
            });
          }
          if (shippingObj?.fedex && shippingObj?.fedex?.ack == 1) {
            allShiping.push({
              shippingCompany: "fedex",
              shippingCharge: shippingObj?.fedex?.Value,
            });
          }

          if (allShiping.length == 0) {
            setStep(1);
            setStepClears({
              step1: true,
              step2: true,
              step3: false,
            });
            dispatch(
              setSnackbar({
                message: t("NoShippingCompanyAvailableForThisOrder"),
                isOpen: true,
                state: "error",
              })
            );
            dispatch(
              setPlaceOrderData({
                ...placeData,
                shippingCharge: 0,
                shippingCompany: "",
                allShippingCharges: [],
              })
            );
          } else {
            dispatch(
              setPlaceOrderData({
                ...placeData,
                shippingCharge: allShiping[0]?.shippingCharge,
                shippingCompany: allShiping[0]?.shippingCompany,
                allShippingCharges: allShiping,
              })
            );
          }
        } else {
          getBackToSecondStep();
        }
        dispatch(changeLoader(false));
        setLoadingShiping(false);
      })
      .catch((err) => {
        getBackToSecondStep();
        dispatch(changeLoader(false));
        setLoadingShiping(false);
        console.error("error=>", err);
      });
  });

  //product price with respect to price range
  const productPrice = (price, quantity, price_ranges, is_wholesale) => {
    let priceRange = price_ranges?.find(
      (obj) => obj?.start_range <= quantity && obj?.end_range >= quantity
    );
    if (priceRange && is_wholesale == 1) {
      return priceRange?.wholesale_price;
    } else {
      return price;
    }
  };

  const getCartProducts = useCallback(async (type) => {
    //check token is available or not
    let localGuestId = addDeleteGetLocalStorage(STORAGE.GUEST_ID, {}, "get");
    let params = {};
    if (!token && localGuestId) {
      params = {
        guestid: localGuestId,
      };
    }
    dispatch(changeLoader(true));
    globalRequest(
      "get",
      API_ROUTES.GET_CART_PRODUCTS,
      {},
      { params: params },
      token ? true : false
    )
      .then((data) => {
        dispatch(changeLoader(false));
        if (data?.ack == 1) {
          //set all product price with respect to price range
          let cartItems = data?.data[0]?.cart_items;
          let partialStatus = false;
          cartItems?.forEach((item) => {
            if (item?.product?.product_type == "bundle") partialStatus = true;
            item.product.pre_discount_price = item.product.discount_price;
            item?.product?.product_type == "product" &&
              (item.product.discount_price = productPrice(
                item?.product?.is_todays_offer === 1
                  ? item?.product?.discount_price
                  : item?.product?.selling_price,
                item.quantity,
                item.product.price_ranges,
                item?.product?.is_wholesale
              ));
          });
          setIsDisableToPartial(partialStatus);
          if (!partialStatus) setIsPartial(true);
          setCartProducts(cartItems || []);
          // for deleted coupon from admin
          setCouponAmount(type == "removeCoupon" ? 0 : couponAmount);
          setSelectedCoupon(type == "removeCoupon" ? null : selectedCoupon);
          dispatch(
            setPlaceOrderData({
              ...placeData,
              cartId: data?.data[0]?.id,
              couponId: type == "removeCoupon" ? "" : placeData?.couponId,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        let msg = err?.response?.data?.msg;
        dispatch(
          setSnackbar({
            message: msg,
            isOpen: true,
            state: "error",
          })
        );
      });
  });

  useEffect(() => {
    getCartProducts();
  }, []);

  //remove product from cart (delete/add to wishlist)
  const removeProductFromCart = useCallback(async (id, type, productId) => {
    let localGuestId = addDeleteGetLocalStorage(STORAGE.GUEST_ID, {}, "get");
    let params = {
      type: type,
    };
    //check token is available and guest id is available then type should be remove and add to local whishlist
    if (!token && localGuestId) {
      params = {
        type: "delete",
        guestid: localGuestId,
      };
    }
    dispatch(changeLoader(true));
    try {
      let res = await globalRequest(
        "delete",
        API_ROUTES.REMOVE_CART_PRODUCT(id),
        {},
        { params: params },
        token ? true : false
      );
      if (res?.ack == 1) {
        setOpenRemoveModal(false);
        setSelectedID(null);
        dispatch(changeLoader(false));
        getCartProducts();
        dispatch(
          setSnackbar({
            isOpen: true,
            state: "success",
            message:
              type == "wishlist"
                ? t("ProductAddedToWishlistSuccessfully")
                : t("ProductRemovedFromCartSuccessfully"),
          })
        );
        //if type is wishlist then add product to local wishlist
        if (type == "wishlist" && !token && localGuestId) {
          let wishlistFromLocal =
            JSON.parse(
              addDeleteGetLocalStorage(STORAGE.GUEST_WHISLIST_LOCAL, {}, "get")
            ) || {};
          if (wishlistFromLocal) {
            const updatedWishlist = { ...wishlistFromLocal };

            if (DB === "Iraq" || DB === "UAE") {
              const countryWishlist = wishlistFromLocal[DB] || [];

              if (countryWishlist.includes(productId)) {
                //if product is already in wishlist then do nothing
                console.log("");
              } else {
                updatedWishlist[DB] = [...countryWishlist, productId];
              }
            }

            wishlistFromLocal = updatedWishlist;
          } else {
            wishlistFromLocal = {
              [DB]: [productId],
            };
          }
          //update wishlist count in redux
          dispatch(
            changeUserProductsCount({
              ...productCount,
              wishlistcount:
                DB == "Iraq"
                  ? wishlistFromLocal?.Iraq?.length
                  : DB == "UAE"
                  ? wishlistFromLocal?.UAE?.length
                  : 0,
            })
          );
          // call count api
          dispatch(fetchUserProductsCount());
          // update local storage
          addDeleteGetLocalStorage(
            STORAGE.GUEST_WHISLIST_LOCAL,
            wishlistFromLocal,
            "add",
            "single"
          );
        }
      } else {
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            isOpen: true,
            state: "error",
            message: t("SomethingWentWrong"),
          })
        );
      }
      // call count api
      dispatch(fetchUserProductsCount());
    } catch (err) {
      dispatch(changeLoader(false));
      console.error("error=>", err);
      dispatch(
        setSnackbar({
          isOpen: true,
          state: "error",
          message: err?.response?.data?.msg || t("SomethingWentWrong"),
        })
      );
    }
  });

  //update product quantity
  const updateProductQuantity = useCallback(
    async (id, item, no_of_quantity) => {
      let sendPrice =
        item?.product?.product_type == "product"
          ? productPrice(
              item?.product?.is_todays_offer == 1
                ? item?.product?.pre_discount_price
                : item?.product?.selling_price,
              no_of_quantity,
              item?.product?.price_ranges,
              item?.product?.is_wholesale
            )
          : item?.product?.pre_discount_price;

      let data = {
        id: id,
        quantity: no_of_quantity,
        price: sendPrice,
        productType: item?.product?.product_type,
      };
      dispatch(changeLoader(true));
      try {
        let res = await globalRequest(
          "post",
          API_ROUTES.UPDATE_CART_PRODUCT,
          data,
          {},
          token ? true : false
        );
        if (res?.ack == 1) {
          dispatch(changeLoader(false));
          getCartProducts();
        } else {
          dispatch(changeLoader(false));
          dispatch(
            setSnackbar({
              isOpen: true,
              state: "error",
              message: t("SomethingWentWrong"),
            })
          );
        }
        // call count api
        dispatch(fetchUserProductsCount());
      } catch (err) {
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            isOpen: true,
            state: "error",
            message: err.response.data.msg || t("SomethingWentWrong"),
          })
        );
      }
    }
  );

  useEffect(() => {
    if (selectedCoupon != null) {
      //if click on apply coupon then only store coupon id and amount in redux

      //if coupon type is fixed amount then directly set amount
      //else if coupon type is percentage then find percentage of subtotalAmount
      //if percentage is greater than max_amount then set max_amount else set percentage

      if (selectedCoupon?.coupon_type == "fixed_amount") {
        dispatch(
          setPlaceOrderData({
            ...placeData,
            couponId: selectedCoupon?.id,
            couponAmount: selectedCoupon?.amount,
          })
        );
        setCouponAmount(selectedCoupon?.amount);
      } else {
        let amount = parseFloat(
          (selectedCoupon?.amount / 100) * subTotal
        ).toFixed(2);
        typeof amount === "string" && (amount = parseFloat(amount));
        dispatch(
          setPlaceOrderData({
            ...placeData,
            couponId: selectedCoupon?.id,
            couponAmount:
              amount > selectedCoupon?.max_amount
                ? selectedCoupon?.max_amount
                : amount,
          })
        );
        setCouponAmount(
          amount > selectedCoupon?.max_amount
            ? selectedCoupon?.max_amount
            : amount
        );
      }

      //find value after apply coupon on subtotalAmount and if this value is less than max_amount then set maximumSaving else set max_amount
    } else {
      dispatch(
        setPlaceOrderData({
          ...placeData,
          couponId: "",
          couponAmount: 0,
          discountAmount: 0,
        })
      );
      setCouponAmount(0);
    }
  }, [selectedCoupon]);

  const placeOrderApi = (paymentType = "") => {
    let data = {
      addressId: placeData?.addressId,
      couponId: placeData?.couponId ? placeData?.couponId : 0,
      // totalAmount: placeData?.totalAfterShipping, //this is total amount after adding all charges
      totalAmount: subTotal - couponAmount + tax + placeData?.shippingCharge,
      subtotalAmount: placeData?.subtotalAmount,
      discountAmount: placeData?.discountAmount,
      shippingCharge: placeData?.shippingCharge,
      taxAmount: placeData?.taxAmount,
      taxPercentage: placeData?.taxPercentage ? placeData?.taxPercentage : 0,
      codCharge: placeData?.codCharge ? placeData?.codCharge : 0,
      currency: placeData?.currency,
      paymentType: paymentType ? paymentType : placeData?.paymentType,
      deliveryType: placeData?.deliveryType,
      shippingCompany: placeData?.shippingCompany,
      trackingId: placeData?.trackingId,
      paymentStatus: placeData?.paymentStatus,
      trnNumber: placeData?.trnNumber,
      companyName: placeData?.companyName,
      cartId: placeData?.cartId,
      is_partially: isPartial ? 1 : 0,
    };

    dispatch(changeLoader(true));
    globalRequest(
      "post",
      API_ROUTES.PLACE_ORDER,
      data,
      {},
      token ? true : false
    )
      .then((data) => {
        if (data.ack == 1) {
          if (
            paymentType
              ? paymentType == "cash"
              : placeData?.paymentType == "cash"
          ) {
            navigate(`/order-placed/${data.order_id}`);
          }
        } else {
          dispatch(
            setSnackbar({
              message: data?.msg,
              isOpen: true,
              state: "error",
            })
          );
          setStep(0);
          setStepClears({
            step1: true,
            step2: false,
            step3: false,
          });
          getCartProducts("removeCoupon");
          // window.location.reload();
        }
      })
      .catch((err) => {
        let msg = err?.response?.data?.msg;
        console.error("err", err);
        dispatch(
          setSnackbar({
            message: msg,
            isOpen: true,
            state: "error",
          })
        );
        setStep(0);
        setStepClears({
          step1: true,
          step2: false,
          step3: false,
        });
        getCartProducts("removeCoupon");
      })
      .finally((e) => {
        dispatch(changeLoader(false));
      });
  };

  // proceed to purchase order
  const ProceedToPurchase = () => {
    if (!token) {
      // navigate("/signup");
      setOpenLogin(true);
      return;
    }
    if (step == 0) {
      // getCartProducts(); //to update cart products
      dispatch(fetchCurrencyConverionApi()); // to get latest currency
      setStep(1);
      setStepClears({
        ...stepClears,
        step2: true,
      });
    } else if (step == 1) {
      if (placeData?.addressId) {
        if (
          placeData?.isCompanyStatus &&
          !placeData?.companyName &&
          !placeData?.trnNumber
        ) {
          dispatch(
            setSnackbar({
              message: "Please enter company details first",
              isOpen: true,
              state: "error",
            })
          );
        } else {
          // getCartProducts("card"); //to update cart products
          setStep(2);
          setStepClears({
            ...stepClears,
            step3: true,
          });
        }
      } else {
        dispatch(
          setSnackbar({
            message: t("PleaseSelectAddressOrPickupPoint"),
            isOpen: true,
            state: "error",
          })
        );
      }
    } else {
      if (!placeData?.shippingCompany && placeData?.deliveryType !== "pickup") {
        dispatch(
          setSnackbar({
            message: t("shippingCompanyRequiredToPlaceOrder"),
            isOpen: true,
            state: "error",
          })
        );
        return;
      }
      if (
        placeData?.paymentType == "cash" &&
        placeData?.shippingCompany === "fedex"
      ) {
        dispatch(
          setSnackbar({
            message: t(
              "SorryCODpaymentsarenotacceptedforFedExshipmentspleaseusecardtothepayment"
            ),
            isOpen: true,
            state: "error",
          })
        );
        return;
      }
      placeOrderApi();
    }
  };

  const OrderDetails = () => {
    return (
      <>
        {loadingShiping ? <Loaders /> : null}
        <div className="cart-right">
          <div className="cart-right-box">
            {step !== 2 && (
              <>
                <h6 className="h6">{t("Discountcode")}</h6>
                <div className="apply-coupon-row">
                  <div className="apply-coupon-column">
                    <img src={tagIcon} alt="tag" className="icon24" />
                    {selectedCoupon == null ? (
                      <span className="apply-coupon-text">
                        {t("ApplyCoupon")}
                      </span>
                    ) : (
                      <span className="apply-coupon-text">
                        1 {t("CouponApplied")}
                      </span>
                    )}
                  </div>
                  {selectedCoupon == null ? (
                    <Button
                      disabled={step == 2 ? true : false}
                      size="small"
                      label={t("Apply")}
                      onClick={() => {
                        if (!token) {
                          // navigate("/signup");
                          setOpenLogin(true);
                          return;
                        }
                        setCouponOpen(true);
                      }}
                    />
                  ) : (
                    <Button
                      disabled={step == 2 ? true : false}
                      size="small"
                      label={t("remove")}
                      onClick={() => {
                        setSelectedCoupon(null);
                        setCouponAmount(0);
                      }}
                    />
                  )}
                </div>
                <Divider />
              </>
            )}
            <h6 className="h6">{t("PriceDetails")}</h6>
            <div className="price-details-row">
              <p className="p2">{t("Subtotal")}</p>
              <p className="p2">
                {" "}
                {currency_am + " " + parseFloat(subTotal).toFixed(2)}
              </p>
            </div>
            {selectedCoupon !== null ? (
              <div className="price-details-row">
                <p className="p2" style={{ maxWidth: "70%" }}>
                  <span>{t("CouponDiscount")}</span>{" "}
                  <span className="green" style={{ wordBreak: "break-word" }}>
                    ({String(selectedCoupon?.code).toUpperCase()})
                  </span>
                </p>
                <p className="p2 yellow" style={{ maxWidth: "70%" }}>
                  {" "}
                  - {currency_am + " " + parseFloat(couponAmount).toFixed(2)}
                </p>
              </div>
            ) : (
              <></>
            )}

            {!placeData?.shippingCompany || step != 2 ? null : (
              <div className="price-details-row">
                <p className="p2">
                  {t("shipping")} ({placeData?.shippingCompany})
                </p>
                <p className="p2">
                  {currency_am +
                    " " +
                    parseFloat(placeData?.shippingCharge).toFixed(2)}
                </p>
              </div>
            )}
            {DB == "UAE" &&
              step == 2 && ( //placeData?.paymentType
                <div className="price-details-row">
                  <p className="p2">
                    {t("Tax")}
                    {conversionRate?.tax ? ` (${conversionRate?.tax}%)` : null}
                  </p>
                  <p className="p2">
                    {currency_am + " " + parseFloat(tax).toFixed(2)}
                    {/* ({DB == "UAE" ? conversionRate?.tax : 0}%) */}
                  </p>
                </div>
              )}
            <Divider className="my-3" />
            <div className="price-details-row">
              <p className="p2 bold">{t("Total")}</p>
              <p className="p2 bold">
                {currency_am +
                  " " +
                  parseFloat(
                    subTotal -
                      couponAmount +
                      (step == 2 ? tax : 0) +
                      (step == 2 ? placeData?.shippingCharge : 0)
                  ).toFixed(2)}
              </p>
            </div>
            {/* partial order check */}
            <div
              className={`mt-4 after-checked ${
                step == 2 ? "read-only-no-cursor" : ""
              } ${isDisableToPartial || step == 2 ? "gray-out-content" : ""} `}
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <div
                style={{ width: "10%" }}
                onClick={() => {
                  if (isDisableToPartial) {
                    dispatch(
                      setSnackbar({
                        message: t("forBundlePartialOrderNotPossible"),
                        isOpen: true,
                        state: "error",
                      })
                    );
                  }
                }}
              >
                <Checkbox
                  disabled={isDisableToPartial}
                  checked={isPartial}
                  onChange={() => {
                    setIsPartial(!isPartial);
                  }}
                />
              </div>
              <div style={{ width: "90%" }}>
                <p className="p3 mb-0">{t("markAsPartial")}</p>
              </div>
            </div>
            {step !== 2 ? (
              <>
                <Button
                  className="w-full mt-4"
                  severity="Success"
                  size="large"
                  onClick={() => {
                    ProceedToPurchase();
                  }}
                >
                  {t("Proceed")}
                </Button>
              </>
            ) : placeData?.paymentType === "cash" ? (
              <>
                <Button
                  className="w-full mt-4"
                  severity="Success"
                  size="large"
                  onClick={() => {
                    ProceedToPurchase();
                  }}
                >
                  {t("Proceed")}
                </Button>
              </>
            ) : null}
          </div>
        </div>
      </>
    );
  };

  const CartDetails = () => {
    //router product detail page
    const routerProductDetail = useCallback((slug, page, id) => {
      if (page == "bundle") {
        navigate(
          `/bundle-detail-page/${slug}/${Base64.encode(encodeURIComponent(id))}`
        );
      } else if (page == "product") {
        navigate(
          `/product-detail-page/p/${slug}/${Base64.encode(
            encodeURIComponent(id)
          )}`
        );
      }
    });

    const handleInputChange = debounce((id, item, value) => {
      updateProductQuantity(id, item, value);
    }, 500); // 300ms delay

    return (
      <>
        <div className="cart-left">
          {cartProducts.length > 0 &&
            cartProducts.map((item, index) => {
              let productImage1 = "";
              item?.product?.product_images?.map((item) => {
                if (item?.is_featured == 1) {
                  productImage1 = item?.image_url;
                }
              });
              return (
                <div key={index + item?.product?.title}>
                  <div className="cart-content-row">
                    <div className="cart-column-left cart-product-list">
                      <div className="cart-image">
                        <img
                          src={productImage1}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = noImage;
                          }}
                          alt="product"
                          className="product-image"
                          onClick={() => {
                            routerProductDetail(
                              item?.product?.slug,
                              item?.product?.product_type,
                              item?.product?.id
                            );
                          }}
                        />
                      </div>
                      <div className="cart-content-text">
                        {item?.product?.product_type == "product" && (
                          <>
                            <div className="flex mb-1">
                              <span className="p2 green capitalize-first-letter">
                                {language == "en"
                                  ? t(
                                      `${
                                        item?.product?.subcategory_details
                                          ?.name_en
                                          ? item?.product?.subcategory_details
                                              ?.name_en
                                          : ""
                                      }`
                                    )
                                  : t(
                                      `${
                                        item?.product?.subcategory_details
                                          ?.name_ar
                                          ? item?.product?.subcategory_details
                                              ?.name_ar
                                          : ""
                                      }`
                                    )}
                              </span>
                              &nbsp; | &nbsp;
                              <span className="p2">
                                {item?.product?.model_number}
                              </span>
                            </div>
                            <h5
                              className="h5 capitalize-first-letter"
                              onClick={() => {
                                routerProductDetail(
                                  item?.product?.slug,
                                  item?.product?.product_type,
                                  item?.product?.id
                                );
                              }}
                            >
                              {item?.product?.title}
                            </h5>
                          </>
                        )}
                        {item?.product?.product_type == "bundle" && (
                          <>
                            <span
                              className="p2 green"
                              style={{ whiteSpace: "normal" }}
                              onClick={() => {
                                routerProductDetail(
                                  item?.product?.slug,
                                  item?.product?.product_type,
                                  item?.product?.id
                                );
                              }}
                            >
                              {item?.product?.title}
                            </span>
                            <div className="d-flex mb-1 shortDesc">
                              {item?.product?.short_description}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="cart-column-right">
                      <div className="relative">
                        {/* new price data with conditions */}
                        <>
                          <h5 className="h5">
                            {currency_am +
                              " " +
                              item?.product?.discount_price.toFixed(2)}
                          </h5>
                          {item?.product?.is_todays_offer === 1 && (
                            <>
                              {item?.product?.discount_price !=
                              item?.product?.selling_price ? (
                                <p className="discount-text">
                                  {currency_am +
                                    " " +
                                    item?.product?.selling_price.toFixed(2)}
                                </p>
                              ) : null}
                            </>
                          )}
                        </>
                      </div>
                      <div className="quantity-box">
                        {/* <span
                          type="number"
                          style={{ width: "30px", textAlign: "center" }}
                        >
                          {item?.quantity}
                        </span> */}
                        <InputNumber
                          className="input"
                          min={1}
                          value={item?.quantity}
                          onChange={(e) => {
                            if (e.value > 0) {
                              handleInputChange(item.id, item, e.value);
                            }
                          }}
                        />
                        <div className="quantity-btn">
                          <i
                            className="pi pi-angle-up"
                            onClick={() => {
                              updateProductQuantity(
                                item.id,
                                item,
                                item?.quantity + 1
                              );
                            }}
                          ></i>
                          <i
                            className="pi pi-angle-down"
                            onClick={() => {
                              if (item?.quantity == 1) return;
                              updateProductQuantity(
                                item.id,
                                item,
                                item?.quantity - 1
                              );
                            }}
                          ></i>
                        </div>
                      </div>
                      <img
                        src={deleteIcon}
                        alt="delete"
                        className="icon24"
                        onClick={() => {
                          setSelectedID(item?.id);
                          setSelectedProductId(item?.product?.id);
                          setOpenRemoveModal(true);
                        }}
                      />
                    </div>
                  </div>
                  {index != cartProducts.length - 1 && <Divider />}
                </div>
              );
            })}

          <div className="shop-more-prodcut-box flex" onClick={routeShopmore}>
            <img src={shopMoreGif} alt="Shop More" className="shopMoreGif" />
            <div className="pl-3">
              <p className="p2 mb-1">{t("Shopmoreproducts")}</p>
              <p className="p3 mb-0">
                {t("Exploreavarietyofitemswithexcitingoffersallwaitingforyou")}
              </p>
            </div>
            <img src={arrowRight} alt="Shop More" className="arrowRight" />
          </div>
        </div>

        {openRemoveModal ? (
          <RemoveModal
            handleClose={() => {
              setOpenRemoveModal(false);
              setSelectedID(null);
              setSelectedProductId(null);
            }}
            handleRemove={() => {
              removeProductFromCart(selectedID, "delete", selecteProductId);
            }}
            handleMoveToWishlist={() => {
              removeProductFromCart(selectedID, "wishlist", selecteProductId);
            }}
          />
        ) : null}
      </>
    );
  };

  return (
    <>
      <CheckoutHeader
        activeState={step}
        callback={(ind) => {
          if (cartProducts.length) {
            if (stepClears.step1 == true && ind == 0) {
              if (step != 0) dispatch(fetchCurrencyConverionApi()); // to get latest currency
              setStep(0);
              dispatch(
                setPlaceOrderData({
                  ...placeData,
                  paymentType: "",
                  shippingCompany: "",
                })
              );
            }
            if (stepClears.step2 == true && ind == 1) {
              if (step != 1) dispatch(fetchCurrencyConverionApi()); // to get latest currency
              setStep(1);
              dispatch(
                setPlaceOrderData({
                  ...placeData,
                  paymentType: "",
                  shippingCompany: "",
                })
              );
            }
            if (stepClears.step3 == true && ind == 2) {
              setStep(2);
            }
          }
        }}
      />

      <div className="container">
        <div className="cart-parent">
          {cartProducts.length ? (
            <>
              {step == 0 ? <CartDetails /> : null}
              {step == 1 ? <Address /> : null}
              {step == 2 ? (
                <PaymentMode
                  placeOrderApi={() => {
                    placeOrderApi("card");
                  }}
                />
              ) : null}
              {cartProducts.length ? <OrderDetails /> : null}
            </>
          ) : cartProducts.length == 0 && !loaderFlag ? (
            <div className="empty-page">
              <img
                src={emptyWishlist}
                alt="empty wishlist"
                className="empty-image"
              />
              <h3 className="h3 ">{t("cartIsEmpty")}</h3>
            </div>
          ) : null}
        </div>
      </div>
      <Footer />
      {couponOpen ? (
        <ApplyCoupon
          handleClose={() => setCouponOpen(false)}
          selectedCoupon={(obj) => {
            setSelectedCoupon(obj);
            setCouponOpen(false);
          }}
        />
      ) : null}
      {openLogin ? (
        <Login
          handleClose={() => {
            setOpenLogin(false);
          }}
          openForget={() => {
            setOpenForgetPassword(true);
            setOpenLogin(false);
          }}
        />
      ) : null}
      {openForgetPassword ? (
        <ForgetPassword
          handleClose={() => {
            setOpenForgetPassword(false);
          }}
          openReset={() => {
            setOpenResetPassword(true);
            setOpenForgetPassword(false);
          }}
          openVerifyOtp={() => {
            setOpenVerifyOtp(true);
            setOpenForgetPassword(false);
          }}
        />
      ) : null}
      {openResetPassword ? (
        <SendChangePasswordUrl
          handleClose={() => {
            setOpenResetPassword(false);
          }}
        />
      ) : null}
    </>
  );
};

export default PlaceOrder;
