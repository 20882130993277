import { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import modalClose from "../assets/images/structure/modal-close.svg";
import { InputText } from "primereact/inputtext";
import globalRequest from "../prototype/globalRequest";
import { API_ROUTES, STORAGE } from "../Enum";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../redux/reducers/snackbar";
import { changeLoader } from "../redux/reducers/loader";
import { axiosConstant } from "../Helpers/Axios/Constants";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { Dropdown } from "primereact/dropdown";
import { currentAuth } from "../redux/reducers/auth";
import addDeleteGetLocalStorage from "../prototype/addDeleteGetLocalStorage";

const FloatingLabelGooglePlacesAutocomplete = ({
  label,
  googleAddress,
  setGoogleAddress,
}) => {
  const [focused, setFocused] = useState(false);
  let DB = addDeleteGetLocalStorage(STORAGE.COUNTRY_DB, {}, "get");

  return (
    <div
      className={`p-float-label google-search ${
        focused || googleAddress ? "p-inputwrapper-focus" : ""
      }`}
    >
      <label htmlFor="googlePlaces">{label}</label>
      <GooglePlacesAutocomplete
        apiKey={axiosConstant.GOOGLE_PLACES_API_KEY}
        query={{
          key: axiosConstant.GOOGLE_PLACES_API_KEY,
        }}
        autocompletionRequest={{
          componentRestrictions: {
            country: [DB === "Iraq" ? "iq" : "ae"],
          },
        }}
        inputComponent={(props) => (
          <InputText
            {...props}
            id="googlePlaces"
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
          />
        )}
        selectProps={{
          isDisabled: false,
          className: "search-input search-new-input",
          placeholder: (
            <span>
              {label}
              <span style={{ color: "red" }}> *</span>
            </span>
          ),
          onChange: async (val) => {
            setGoogleAddress(val);
          },
          onKeyPress: (e) => {
            headerSearchBarHandler(e.key);
          },
          value: googleAddress,
        }}
        value={googleAddress}
      />
    </div>
  );
};

const AddAddress = ({ closePopup, isEditable = true, existingData = {} }) => {
  const { t } = useTranslation();
  const auth = useSelector(currentAuth);
  let token = auth?.token;
  let DB = addDeleteGetLocalStorage(STORAGE.COUNTRY_DB, {}, "get");
  //states
  const dispatch = useDispatch();
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState(0);
  const [isOtpSend, setIsOtpSend] = useState(false);
  const [isMobileVerified, setIsMobileVerified] = useState(false);
  const [address, setAddress] = useState("");
  const [name, setName] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [emirateName, setEmirateName] = useState("");
  const [isGetOtpOpen, setIsGetOtpOpen] = useState(true);
  const [id, setId] = useState(0);
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");

  const verifyOtp = () => {
    dispatch(changeLoader(true));
    globalRequest(
      "post",
      API_ROUTES.VERIFY_OTP,
      {
        mobile: mobile,
        otp: otp,
      },
      {},
      true
    )
      .then((data) => {
        let msg = data.msg;
        if (data.ack == 1) {
          dispatch(
            setSnackbar({
              message: t("Otpverifiedsuccessfully."),
              isOpen: true,
              state: "success",
            })
          );
          setIsMobileVerified(true);
        } else {
          dispatch(
            setSnackbar({
              message: msg,
              isOpen: true,
              state: "error",
            })
          );
        }
      })
      .catch((err) => {
        let msg = err.response.data.msg;
        dispatch(
          setSnackbar({
            message: msg,
            isOpen: true,
            state: "error",
          })
        );
      })
      .finally(() => {
        dispatch(changeLoader(false));
      });
  };

  useEffect(() => {
    if (isEditable) {
      if (Object.keys(existingData).length !== 0) {
        setAddress(existingData?.address_name);
        setMobile(existingData?.mobile);
        setCity(existingData?.city);
        setState(existingData?.state);
        setCountry(existingData?.country);
        setAddressLine1(existingData?.address);
        setAddressLine2(existingData?.address2);
        setEmirateName(existingData?.emirate_name);
        setName(existingData?.name);
        setIsGetOtpOpen(false);
        setId(existingData?.id);
        setOtp("");
        setIsMobileVerified(true);
        let geoAddress = JSON.parse(existingData?.geoAddress);
        setGoogleAddress(geoAddress);
        setSelectedCountyId({
          id: existingData?.county_id?.id,
          name: existingData?.county_id?.name,
          countyId: existingData?.county_id?.ids,
        });
        setSelectedCityId({
          id: existingData?.cities_id?.id,
          name: existingData?.cities_id?.name,
          cityId: existingData?.cities_id?.ids,
        });
        setLat(existingData?.latitude);
        setLng(existingData?.longitude);

        if (DB === "Iraq") {
          setCity(existingData?.cities_id?.name);
        }
      }
    }
  }, [existingData, isEditable]);

  const addAddress = (e) => {
    e.preventDefault();

    let url = API_ROUTES.ADD_ADDRESS;
    let method = "post";
    let body = {
      addressName: address,
      name: name,
      mobile: mobile,
      country: country,
      address: addressLine1,
      address2: addressLine2,
      otp: otp,
      state: state,
      city: city,
      emirateName: emirateName,
      latitude: lat,
      longitude: lng,
      geoAddress: JSON.stringify(googleAddress),
    };
    if (DB === "Iraq") {
      body = {
        ...body,
        city: selectedCityId?.name,
        countyid: selectedCountyId?.id,
        cityid: selectedCityId?.id,
      };
    }

    if (id !== 0) {
      url = API_ROUTES.UPDATE_ADDRESS;
      body.addressId = id;
      method = "post";
    }

    globalRequest(method, url, body, {}, token ? true : false)
      .then((data) => {
        if (data.ack == 1) {
          let msg = t(data?.msg);

          dispatch(
            setSnackbar({
              message: msg,
              isOpen: true,
              state: "success",
            })
          );
          closePopup();
        }
      })
      .catch((err) => {
        let msg = err.response.data.msg;
        dispatch(
          setSnackbar({
            message: msg,
            isOpen: true,
            state: "error",
          })
        );
      });
  };

  const sendOtp = () => {
    globalRequest(
      "post",
      API_ROUTES.SEND_OTP,
      {
        mobile: mobile,
      },
      {},
      true
    )
      .then((data) => {
        if (data.ack == 1) {
          dispatch(
            setSnackbar({
              message: t("Otpsendsuccessfully."),
              isOpen: true,
              state: "success",
            })
          );
        }
      })
      .catch((err) => {
        let msg = err.response.data.msg;
        dispatch(
          setSnackbar({
            message: msg,
            isOpen: true,
            state: "error",
          })
        );
      })
      .finally(() => {
        dispatch(changeLoader(false));
      });
  };

  const getOtpAndCheckNumber = () => {
    if (mobile) {
      dispatch(changeLoader(true));
      globalRequest(
        "post",
        API_ROUTES.CHECK_MOBILE_NUMBER,
        {
          mobile: mobile,
        },
        {},
        true
      )
        .then((data) => {
          if (data.ack == 1) {
            let mobileVerified = data.is_mobile_verified;
            if (mobileVerified) {
              setIsMobileVerified(true);
              setOtp(1234);
              setIsGetOtpOpen(false);
            } else {
              sendOtp("");
              setIsOtpSend(true);
              setIsMobileVerified(false);
              setOtp("");
            }
          }
        })
        .catch((err) => {
          let msg = err.response.data.msg;
          dispatch(
            setSnackbar({
              message: msg,
              isOpen: true,
              state: "error",
            })
          );
        })
        .finally(() => {
          dispatch(changeLoader(false));
        });
    } else {
      dispatch(
        setSnackbar({
          message: t("Pleaseentermobilenumberfirst"),
          isOpen: true,
          state: "error",
        })
      );
    }
  };

  //google address
  const [googleAddress, setGoogleAddress] = useState(null);
  const popolateAddress = async (address) => {
    if (address) {
      await setGoogleAddress(address);
      let geocode = await geocodeByPlaceId(address.value.place_id);
      let googleAddress = {
        id: 0,
        userAddress: false,
        addressLabel: address.label,
        address: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        google_object: address,
        latitude: JSON.stringify(geocode[0].geometry.location.lat()),
        longitude: JSON.stringify(geocode[0].geometry.location.lng()),
      };
      googleAddress.address = address.value.structured_formatting.main_text;
      geocode[0].address_components.forEach((element) => {
        if (element.types.includes("locality")) {
          googleAddress.city = element.long_name;
        }
        if (element.types.includes("postal_code")) {
          googleAddress.zip = element.long_name;
        }
        if (element.types.includes("country")) {
          googleAddress.country = element.long_name;
        }
        if (element.types.includes("administrative_area_level_1")) {
          googleAddress.state = element.long_name;
        }
      });

      // console.log("googleAddress", googleAddress);
      setCity(googleAddress.city);
      // setPostalCode(googleAddress.zip);
      setCountry(googleAddress.country);
      setAddressLine1(googleAddress.address);
      setState(googleAddress.state);
      setLat(googleAddress.latitude);
      setLng(googleAddress.longitude);
    }
  };

  //get cityIds api
  let [cityIds, setCityIds] = useState([]); //[{id:1,name:"Dubai"}]
  const [selectedCityId, setSelectedCityId] = useState(null);
  const getCityIdsApi = () => {
    dispatch(changeLoader(true));
    globalRequest("get", API_ROUTES.GET_CITY_IDS, {}, {}, token ? true : false)
      .then((data) => {
        dispatch(changeLoader(false));
        if (data?.ack == 1) {
          let tempCityIds = [];
          data?.data?.forEach((ele) => {
            tempCityIds.push({ id: ele.id, name: ele.name, cityId: ele.ids });
          });
          setCityIds(tempCityIds);
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        let msg = err?.response?.data?.msg;
        dispatch(
          setSnackbar({
            message: msg,
            isOpen: true,
            state: "error",
          })
        );
      });
  };

  //get countyIds api
  let [countyIds, setCountyIds] = useState([]); //[{id:1,name:"Dubai"}]
  let [selectedCountyId, setSelectedCountyId] = useState(null);
  const getCountyIdsApi = () => {
    if (!token || !selectedCityId?.id) return;

    dispatch(changeLoader(true));
    globalRequest(
      "get",
      API_ROUTES.GET_COUNTY_IDS(selectedCityId?.id),
      {},
      {},
      token ? true : false
    )
      .then((data) => {
        dispatch(changeLoader(false));
        if (data?.ack == 1) {
          let tempCountyIds = [];
          data?.data?.forEach((ele) => {
            tempCountyIds.push({
              id: ele.id,
              name: ele.name,
              countyId: ele.ids,
            });
          });
          setCountyIds(tempCountyIds);
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        let msg = err?.response?.data?.msg;
        dispatch(
          setSnackbar({
            message: msg,
            isOpen: true,
            state: "error",
          })
        );
      });
  };

  useEffect(() => {
    getCityIdsApi();
  }, []);

  useEffect(() => {
    getCountyIdsApi();
  }, [selectedCityId]);

  //enable button only when all required fields are filled
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  useEffect(() => {
    if (
      address &&
      name &&
      country &&
      addressLine1 &&
      mobile &&
      city &&
      state &&
      emirateName &&
      isMobileVerified
    ) {
      if (DB === "UAE") {
        setIsButtonDisabled(false);
      } else if (
        selectedCityId?.name &&
        selectedCityId?.cityId &&
        selectedCountyId?.countyId
      ) {
        setIsButtonDisabled(false);
      }
    } else {
      setIsButtonDisabled(true);
    }
  }, [
    address,
    name,
    country,
    addressLine1,
    isOtpSend,
    otp,
    mobile,
    city,
    state,
    emirateName,
    selectedCityId,
    selectedCountyId,
    isMobileVerified,
  ]);

  return (
    <>
      <Dialog
        visible={true}
        onHide={() => closePopup()}
        draggable={false}
        resizable={false}
        closable={false}
        showHeader={false}
        className="dialog-520"
      >
        <form onSubmit={addAddress}>
          <img
            src={modalClose}
            alt="close"
            className="modal-close"
            onClick={() => closePopup()}
          />
          <h3 className="h3 mb-5">
            {isEditable ? t("updateAddress") : t("addAddress")}
          </h3>
          <div className="inner-scroll-modal">
            <div className="grid">
              {/* address name */}
              <div className="col-12 sm:col-12 md:col-6 lg:col-6 mb-4">
                <span className="p-float-label">
                  <InputText
                    required={true}
                    onChange={(e) => {
                      let val = e.target.value?.trimStart();
                      if (val.length > 250) {
                        return;
                      }
                      setAddress(val);
                    }}
                    id="addressName"
                    placeholder={t("addressName")}
                    value={address}
                  />
                  <label htmlFor="addressName">
                    {t("addressName")} <span className="req-star">*</span>
                  </label>
                </span>
              </div>
              {/* name */}
              <div className="col-12 sm:col-12 md:col-6 lg:col-6 mb-4">
                <span className="p-float-label">
                  <InputText
                    value={name}
                    onChange={(e) => {
                      let val = e.target.value.trimStart();
                      if (val.length > 250) {
                        return;
                      }
                      setName(val);
                    }}
                    required={true}
                    id="name"
                    placeholder={t("name")}
                  />
                  <label htmlFor="name">
                    {t("name")} <span className="req-star">*</span>
                  </label>
                </span>
              </div>
              {/* mobile number */}
              <div className="input-parent col-12 sm:col-12 md:col-6 lg:col-6 mb-4">
                <span className="p-float-label otpbox-p">
                  <InputText
                    required={true}
                    onChange={(e) => {
                      let value = String(e.target.value).replace(/\D+/g, "");
                      setMobile(value);
                      setIsOtpSend(false);
                      setIsGetOtpOpen(true);
                      setOtp("");
                      setIsMobileVerified(false);
                    }}
                    maxLength={15}
                    value={mobile}
                    id="MobileNumber"
                    placeholder={t("MobileNumber")}
                  />
                  {isMobileVerified && (
                    <span className="otp-input-text">
                      <Button
                        disabled
                        icon="pi pi-check"
                        severity="Success"
                        rounded
                        text
                        aria-label="Filter"
                      />
                    </span>
                  )}
                  {isOtpSend ? null : isGetOtpOpen ? (
                    <span
                      onClick={getOtpAndCheckNumber}
                      className="otp-input-text"
                    >
                      {t("GET_OTP")}
                    </span>
                  ) : null}
                  <label htmlFor="MobileNumber">
                    {t("MobileNumber")} <span className="req-star">*</span>
                  </label>
                </span>
              </div>
              {isOtpSend ? (
                isMobileVerified ? null : (
                  <div className="col-12 sm:col-12 md:col-6 lg:col-6 mb-4">
                    <div className="input-parent">
                      <span className="p-float-label otpbox-p">
                        <InputText
                          value={otp}
                          onChange={(e) => {
                            let value = String(e.target.value).replace(
                              /\D+/g,
                              ""
                            );
                            setOtp(value);
                          }}
                          required={true}
                          id="OTP"
                          placeholder={t("OTP")}
                        />
                        {otp ? (
                          <span onClick={verifyOtp} className="otp-input-text">
                            {t("VERIFY_OTP")}
                          </span>
                        ) : (
                          <span onClick={sendOtp} className="otp-input-text">
                            {t("RESEND_OTP")}
                          </span>
                        )}

                        <label htmlFor="OTP">
                          {t("OTP")} <span className="req-star">*</span>
                        </label>
                      </span>
                    </div>
                  </div>
                )
              ) : null}
              {/* address -1 (google) */}
              <div className="col-12 sm:col-12 md:col-6 lg:col-6 mb-4">
                <FloatingLabelGooglePlacesAutocomplete
                  label={t("address")}
                  googleAddress={googleAddress}
                  setGoogleAddress={popolateAddress}
                />
              </div>
              {/* address -1 (google) */}
              <div className="col-12 sm:col-12 md:col-6 lg:col-6 mb-4">
                <span className="p-float-label">
                  <InputText
                    onChange={(e) => setAddressLine2(e.target.value)}
                    id="addressLine2"
                    placeholder={t("addressLine2")}
                    value={addressLine2}
                  />
                  <label htmlFor="addressLine2">{t("addressLine2")}</label>
                </span>
              </div>
              {/* city select and county select (Iraq) */}
              {DB === "Iraq" && (
                <div className="col-12 sm:col-12 md:col-6 lg:col-6 mb-4">
                  <span className="p-float-label">
                    <Dropdown
                      inputId="dd-city"
                      value={selectedCityId}
                      onChange={(e) => setSelectedCityId(e.value)}
                      options={cityIds}
                      optionLabel="name"
                      className="w-full"
                    />
                    <label htmlFor="dd-city">
                      {t("SelectACity")}
                      <span style={{ color: "red" }}> *</span>
                    </label>
                  </span>
                </div>
              )}
              {DB === "Iraq" && (
                <div className="col-12 sm:col-12 md:col-6 lg:col-6 mb-4">
                  <span className="p-float-label">
                    <Dropdown
                      style={{ width: "100% !important" }}
                      value={selectedCountyId}
                      onChange={(e) => setSelectedCountyId(e.value)}
                      options={countyIds}
                      optionLabel="name"
                      className="w-full"
                    />
                    <label htmlFor="dd-city">
                      {t("SelectACounty")}
                      <span style={{ color: "red" }}> *</span>
                    </label>
                  </span>
                </div>
              )}
              {/* city name input (UAE) */}
              {DB === "UAE" && (
                <div className="col-12 sm:col-12 md:col-6 lg:col-6 mb-4">
                  <span className="p-float-label">
                    <InputText
                      onChange={(e) => setCity(e.target.value)}
                      value={city}
                      required={true}
                      id="state"
                      placeholder={t("EnterCityName")}
                      disabled={true}
                    />
                    <label htmlFor="state">
                      {t("City")} <span className="req-star">*</span>
                    </label>
                  </span>
                </div>
              )}
              {/* state  */}
              <div className="col-12 sm:col-12 md:col-6 lg:col-6 mb-4">
                <span className="p-float-label">
                  <InputText
                    onChange={(e) => setState(e.target.value)}
                    value={state}
                    required={true}
                    id="state"
                    placeholder={t("state")}
                    disabled={true}
                  />
                  <label htmlFor="state">
                    {t("state")} <span className="req-star">*</span>
                  </label>
                </span>
              </div>
              {/* country  */}
              <div className="col-12 sm:col-12 md:col-6 lg:col-6 mb-4">
                <span className="p-float-label">
                  <InputText
                    onChange={(e) => setCountry(e.target.value)}
                    required={true}
                    id="country"
                    value={country}
                    placeholder={t("country")}
                    disabled={true}
                  />
                  <label htmlFor="country">
                    {t("country")} <span className="req-star">*</span>
                  </label>
                </span>
              </div>
              {/* Emirate */}
              <div className="col-12 sm:col-12 md:col-12 lg:col-12 mb-4">
                <span className="p-float-label">
                  <InputText
                    onChange={(e) => setEmirateName(e.target.value)}
                    required={true}
                    id="emirate_name"
                    placeholder={t("emirateName")}
                    value={emirateName}
                  />
                  <label htmlFor="emirate_name">
                    {t("emirateName")} <span className="req-star">*</span>
                  </label>
                </span>
              </div>
            </div>
          </div>
          <Button
            type="submit"
            disabled={isButtonDisabled}
            severity="Success"
            size="large"
            className="w-full mt-2"
            label={isEditable ? t("updateAddress") : t("addAddress")}
          />
        </form>
      </Dialog>
    </>
  );
};

export default AddAddress;
