import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import "./SignUp.scss";
import { useTranslation } from "react-i18next";
import LeftContent from "../LeftContent";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "libphonenumber-js";
import { Link } from "react-router-dom";
// images
import facebookIcon from "../../../assets/images/structure/facebook-round-icon.svg";
import googleIcon from "../../../assets/images/structure/google-round-icon.svg";
import modalClose from "../../../assets/images/structure/modal-close.svg";
import verifyEmailIcon from "../../../assets/images/structure/verify-email.svg";
import {
  removeHyphens,
  validateEmail,
  validatePassword,
} from "../../../Helpers/HelperFunctions";
import { Dialog } from "primereact/dialog";
import SocialButton from "../../../Helpers/Social-Media/SocialButton";
import { axiosConstant } from "../../../Helpers/Axios/Constants";
import { InputNumber } from "primereact/inputnumber";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import { setSnackbar } from "../../../redux/reducers/snackbar";
import { API_ROUTES, STORAGE } from "../../../Enum";
import addDeleteGetLocalStorage from "../../../prototype/addDeleteGetLocalStorage";
import { currentAuth, setData } from "../../../redux/reducers/auth";
import axios from "axios";

//google login imports
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import {
  fetchUserProductsCount,
  userProductsObj,
} from "../../../redux/reducers/userProductsCount";
import globalRequest from "../../../prototype/globalRequest";

const SignUp = ({}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useRef(null);
  //states
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState();
  const [checked, setChecked] = useState(false);
  const [checkedNewsLatter, setCheckedNewsLatter] = useState(true);
  const [verified, setVerified] = useState(false);
  const [popup_open, setPopup_open] = useState(false);
  const [popup_open2, setPopup_open2] = useState(false);
  const [sended_otp, setSended_otp] = useState(false);
  const [type_checkbox, setType_checkbox] = useState("terms");
  let [signUpToken, setSignUpToken] = useState(null);
  let DB = addDeleteGetLocalStorage(STORAGE.COUNTRY_DB, {}, "get");
  //coundown timer
  const [countdown, setCountdown] = useState(30);
  const [countdownIntervalId, setCountdownIntervalId] = useState(null);
  const [visibleReplaceCartAction, setVisibleReplaceCartAction] =
    useState(false);
  const productsCount = useSelector(userProductsObj);

  const Countdown_handler = () => {
    setCountdown(30);
    const intervalId = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);
    setCountdownIntervalId(intervalId);
  };

  const stopCountdown = () => {
    clearInterval(countdownIntervalId);
    setCountdownIntervalId(null);
    setCountdown(30);
  };

  useEffect(() => {
    if (countdown === 0) {
      clearInterval(countdownIntervalId);
      setCountdownIntervalId(null);
    }
  }, [countdown, countdownIntervalId]);

  //errors
  const [firstNameErr, setFirstNameErr] = useState("");
  const [lastNameErr, setLastNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");
  const [otpErr, setOtpErr] = useState("");
  const [checkedErr, setCheckedErr] = useState("");
  const [mobileErr, setMobileErr] = useState("");
  const auth = useSelector(currentAuth);

  ///manage phone number input
  const handlePhoneNumberChange = (value) => {
    setMobileErr("");
    if (!value) {
      setMobile("");
      return;
    }
    if (!isValidPhoneNumber(value)) {
      setMobileErr("EnterValidMobileNumber");
    }
    setMobile(value);
  };

  //send otp to verify email
  const handleSendOtp = async () => {
    if (!validateEmail(email)) {
      setEmailErr("EnterValidEmailAddress");
      return;
    }
    if (!email) {
      setEmailErr("EmailIsRequired");
      return;
    }
    dispatch(changeLoader(true));
    try {
      const res = await globalRequest(
        "post",
        API_ROUTES.SEND_EMAIL_VERIFICATION_OTP,
        { email },
        {},
        false
      );
      if (res.ack == 1) {
        dispatch(changeLoader(false));
        setSended_otp(true);
        setPopup_open(true);
        setEmailErr("");

        Countdown_handler();
      } else {
        dispatch(changeLoader(false));
        if (res?.msg) {
          setEmailErr(res.msg);
        }
        if (res?.errMsg[0]) {
          if (res.errMsg[0].email == "Email already exists.") {
            setEmailErr("EmailIdIsAlreadyExists");
          }
        }
      }
    } catch (err) {
      dispatch(changeLoader(false));
      dispatch(
        setSnackbar({
          isOpen: true,
          state: "error",
          message: err.response.data.msg || t("SomethingWentWrong"),
        })
      );
    }
  };
  //verify email
  const handleVerifyEmail = async () => {
    if (!otp) {
      setOtpErr("OtpIsRequired");
      return;
    }
    dispatch(changeLoader(true));
    try {
      const res = await globalRequest(
        "post",
        API_ROUTES.EMAIL_VERIFICATION,
        { email, otp },
        {},
        false
      );
      if (res.ack == 1) {
        dispatch(changeLoader(false));
        setVerified(true);
        setPopup_open(false);
      } else {
        dispatch(changeLoader(false));
        if (res?.msg == "Invalid OTP.") {
          setOtpErr("InvalidOTP");
        } else {
          setOtpErr(res.msg);
        }
      }
    } catch (err) {
      dispatch(changeLoader(false));
      dispatch(
        setSnackbar({
          isOpen: true,
          state: "error",
          message: err.response.data.msg || t("SomethingWentWrong"),
        })
      );
    }
  };

  //manage errors
  const handleErrors = () => {
    let error = false;
    if (firstName.length > 200) {
      setFirstNameErr("Max200Characters");
      error = true;
    }
    if (lastName.length > 200) {
      setLastNameErr("Max200Characters");
      error = true;
    }
    if (!firstName) {
      setFirstNameErr("FirstNameIsRequired");
      error = true;
    }
    if (!lastName) {
      setLastNameErr("LastNameIsRequired");
      error = true;
    }
    if (verified == false && validateEmail(email)) {
      setEmailErr("PleaseVerifyYourEmailFirst");
      error = true;
    }
    if (!validateEmail(email)) {
      setEmailErr("EnterValidEmailAddress");
      error = true;
    }
    if (!email) {
      setEmailErr("EmailIsRequired");
      error = true;
    }
    if (!validatePassword(password)) {
      setPasswordErr(
        "PasswordShouldBeWithUppercaseLowercaseNumberSymbolandMin8Characters"
      );
      error = true;
    }
    if (!password) {
      setPasswordErr("PasswordIsRequired");
      error = true;
    }
    if (confirmPassword) {
      if (password !== confirmPassword) {
        setConfirmPasswordErr("PasswordAndConfirmPasswordShouldBeSame");
        error = true;
      } else if (!validatePassword(confirmPassword)) {
        setConfirmPasswordErr(
          "PasswordShouldBeWithUppercaseLowercaseNumberSymbolandMin8Characters"
        );
        error = true;
      }
    }
    if (!confirmPassword) {
      setConfirmPasswordErr("ConfirmPasswordIsRequired");
      error = true;
    }
    if (mobile) {
      if (!isValidPhoneNumber(removeHyphens(mobile))) {
        setMobileErr("EnterValidMobileNumber");
        error = true;
      }
    }
    if (!checked) {
      setCheckedErr("PleaseAcceptTermsAndConditions");
      if (
        firstName &&
        lastName &&
        email &&
        password &&
        confirmPassword &&
        verified &&
        password === confirmPassword
      ) {
        dispatch(
          setSnackbar({
            message: "PleaseAcceptTermsAndConditions",
            isOpen: true,
            state: "warn",
          })
        );
      }
      error = true;
    }
    return error;
  };

  ///submit function
  const handleSubmit = async () => {
    if (handleErrors()) return;
    let temp_mo = mobile.substring(1); //remove + from mobile number

    const data = {
      email,
      first_name: firstName,
      last_name: lastName,
      password,
      confirm_password: confirmPassword,
      mobile_no: temp_mo ? removeHyphens(temp_mo) : "",
      is_agree: checked ? 1 : 0,
      otp: otp ? otp : "",
      newslatter: checkedNewsLatter ? 1 : 0,
    };
    //call api and then in response redirect user to home page
    dispatch(changeLoader(true));
    try {
      const res = await globalRequest(
        "post",
        API_ROUTES.SIGNUP,
        data,
        {},
        false
      );
      if (res.ack == "1") {
        dispatch(changeLoader(false));
        // update userId with guestId
        updateUserIdWithGuestId(res?.token);
        addDeleteGetLocalStorage(
          STORAGE.AL_MAHARA_USER,
          { token: res?.token },
          "add",
          "single"
        );
        dispatch(setData());
        dispatch(
          setSnackbar({
            message: t("RegisteredSuccessfully"),
            isOpen: true,
            state: "success",
          })
        );
        navigate("/");
      } else {
        dispatch(changeLoader(false));
        if (res.errMsg[0].msg == "Email already exists.")
          setEmailErr("EmailIdIsAlreadyExists");
      }
    } catch (err) {
      dispatch(changeLoader(false));
      dispatch(
        setSnackbar({
          isOpen: true,
          state: "error",
          message: err.response.data.msg || t("SomethingWentWrong"),
        })
      );
    }
  };
  //update userId with guestId
  const updateUserIdWithGuestId = async (signupToken) => {
    let guestId = addDeleteGetLocalStorage(STORAGE.GUEST_ID, {}, "get");
    if (!guestId) return;

    //get wishlist from local storage
    let wishlistFromLocal =
      JSON.parse(
        addDeleteGetLocalStorage(STORAGE.GUEST_WHISLIST_LOCAL, {}, "get")
      ) || {};

    const data = {
      guestid: guestId,
      productId: wishlistFromLocal?.[DB]
        ? JSON.stringify(wishlistFromLocal?.[DB])
        : JSON.stringify([]),
    };
    dispatch(changeLoader(true));
    let getA = addDeleteGetLocalStorage(STORAGE.COUNTRY_DB, {}, "get");
    let url = axiosConstant.BASE_URL + API_ROUTES.UPDATE_USER_ID;
    axios
      .post(url, data, {
        headers: {
          "x-api-key": axiosConstant.API_KEY,
          location: getA ? (getA == "Iraq" ? "baghdad" : "dubai") : "baghdad",
          authorization: "Bearer " + signupToken,
        },
      })
      .then((res) => {
        dispatch(changeLoader(false));
        if (res?.data?.ack == 1) {
          localStorage.removeItem(STORAGE.GUEST_ID);
          localStorage.removeItem(STORAGE.GUEST_WHISLIST_LOCAL);
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        //delete guestId from local storage
        localStorage.removeItem(STORAGE.GUEST_ID);
        console.error("error while updating userId", err);
      });
  };

  // replace cart Items with login user cart items
  const replaceCartItems = async (signupToken = null, remove = "no") => {
    if (remove == "no") {
      setVisibleReplaceCartAction(false);
      addDeleteGetLocalStorage(
        STORAGE.AL_MAHARA_USER,
        { token: signUpToken },
        "add",
        "single"
      );
      dispatch(setData());
      dispatch(fetchUserProductsCount());
      return;
    }
    if (!signupToken) return;
    let guestId = addDeleteGetLocalStorage(STORAGE.GUEST_ID, {}, "get");
    if (!guestId) return;

    const data = {
      guestid: guestId,
      type: "new",
    };
    dispatch(changeLoader(true));
    let getA = addDeleteGetLocalStorage(STORAGE.COUNTRY_DB, {}, "get");
    let url = axiosConstant.BASE_URL + API_ROUTES.REPLACE_LOGIN_USER_CART;
    axios
      .post(url, data, {
        headers: {
          "x-api-key": axiosConstant.API_KEY,
          location: getA ? (getA == "Iraq" ? "baghdad" : "dubai") : "baghdad",
          authorization: "Bearer " + signupToken,
        },
      })
      .then((res) => {
        dispatch(changeLoader(false));
        setVisibleReplaceCartAction(false);
        dispatch(fetchUserProductsCount());
        if (res?.data?.ack == 1) {
          //delete guestId and wishlist from local storage
          localStorage.removeItem(STORAGE.GUEST_ID);
          localStorage.removeItem(STORAGE.GUEST_WHISLIST_LOCAL);
          //set token to local
          addDeleteGetLocalStorage(
            STORAGE.AL_MAHARA_USER,
            { token: signUpToken },
            "add",
            "single"
          );
          dispatch(setData());
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        localStorage.removeItem(STORAGE.GUEST_ID);
      });
  };

  //social login api
  const socialLoginApi = async (data) => {
    await globalRequest("post", API_ROUTES.SOCIAL_LOGIN, data, {}, false)
      .then((res) => {
        if (res.status == 200) {
          let data = res.data;
          if (data?.user?.status == "inactive") {
            dispatch(
              setSnackbar({
                message: t("AccountIsInactivePleaseContactToAdmin"),
                isOpen: true,
                state: "error",
              })
            );
            return;
          }
          dispatch(changeLoader(false));
          // if already user
          if (data?.flage == 1 && productsCount?.cartcount > 0) {
            setSignUpToken(data?.token);
            setVisibleReplaceCartAction(true);
          } else {
            //if new user
            if (data?.flage == 0) {
              updateUserIdWithGuestId(data?.token);
            }
            addDeleteGetLocalStorage(
              STORAGE.AL_MAHARA_USER,
              { token: data?.token },
              "add",
              "single"
            );
            dispatch(setData());
            navigate("/");
          }
          {
            data?.flage == 0 &&
              dispatch(
                setSnackbar({
                  message:
                    data?.flage == 0
                      ? t("RegisteredSuccessfully")
                      : t("LoggedinSuccessfully"),
                  isOpen: true,
                  state: "success",
                })
              );
          }
        } else {
          dispatch(
            setSnackbar({
              isOpen: true,
              state: "error",
              message: res?.msg || t("SomethingWentWrong"),
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            isOpen: true,
            state: "error",
            message: err?.message || t("SomethingWentWrong"),
          })
        );
      });
  };

  //google signup
  const handleGoogleLogin = (token) => {
    var decoded = jwt_decode(token.credential);
    const data = {
      first_name: decoded.given_name,
      last_name: decoded.family_name,
      email: decoded.email,
      social_id: decoded.sub,
      social_media_type: "google",
    };

    socialLoginApi(data);
  };

  //fb signup
  const handleSocialLogin = async (user) => {
    const data = {
      first_name: user.profile.firstName,
      last_name: user.profile.lastName,
      email: user.profile.email,
      social_id: user.profile.id,
      social_media_type: user.provider,
    };
    socialLoginApi(data);
  };

  const handleSocialLoginFailure = (err) => {
    console.error("err", err);
    // dispatch(
    //   setSnackbar({
    //     isOpen: true,
    //     state: "error",
    //     message: t("LogInFailed"),
    //   })
    // );
  };

  const [show_NPass, setShowNPass] = useState(true);
  const [show_NPass1, setShowNPass1] = useState(true);

  return (
    <>
      <Toast ref={toast} />
      <section className="signup-section">
        <div className="signup-left">
          <LeftContent />
        </div>
        <div className="signup-right">
          <div className="signup-right-content">
            <h3 className="h3">{t("CreateAccount")}</h3>
            <p className="p2">
              {t("Pleaseenteryourdetailswecantwaittoshowyouwhatwehave")}
            </p>
            <div className="grid spacing-24">
              <div className="col-12 sm:col-6 md:col-6 lg:col-6">
                <div className="input-parent">
                  <span className="p-float-label">
                    <InputText
                      className={firstNameErr ? "p-invalid" : null}
                      value={firstName}
                      onChange={(e) => {
                        setFirstNameErr("");
                        if (!e.target.value.trimStart().length) {
                          setFirstNameErr("FirstNameIsRequired");
                        }
                        if (e.target.value.trimStart().length > 200) {
                          setFirstNameErr("Max200Characters");
                        }
                        setFirstName(e.target.value.trimStart());
                      }}
                      id="FirstName"
                      placeholder={t("FirstName")}
                    />
                    <label htmlFor="FirstName">
                      {t("FirstName")} <span className="req-star">*</span>
                    </label>
                  </span>
                  {firstNameErr && (
                    <div className="p-error">{t(firstNameErr)}</div>
                  )}
                </div>
              </div>
              <div className="col-12 sm:col-6 md:col-6 lg:col-6">
                <div className="input-parent">
                  <span className="p-float-label">
                    <InputText
                      className={lastNameErr ? "p-invalid" : null}
                      value={lastName}
                      onChange={(e) => {
                        setLastNameErr("");
                        if (!e.target.value.trimStart().length) {
                          setLastNameErr("LastNameIsRequired");
                        }
                        if (e.target.value.trimStart().length > 200) {
                          setLastNameErr("Max200Characters");
                        }
                        setLastName(e.target.value.trimStart());
                      }}
                      id="LastName"
                      placeholder={t("LastName")}
                    />
                    <label htmlFor="LastName">
                      {t("LastName")} <span className="req-star">*</span>
                    </label>
                  </span>
                  {lastNameErr && (
                    <div className="p-error">{t(lastNameErr)}</div>
                  )}
                </div>
              </div>
              <div className="col-12 sm:col-6 md:col-6 lg:col-6">
                <div className="input-parent">
                  <span className="p-float-label otpbox-p">
                    <InputText
                      className={emailErr ? "p-invalid" : null}
                      value={email}
                      onChange={(e) => {
                        setEmailErr("");
                        if (!validateEmail(e.target.value.trimStart())) {
                          setEmailErr("EnterValidEmailAddress");
                        }
                        if (!e.target.value.trimStart().length) {
                          setEmailErr("EmailIsRequired");
                        }
                        setEmail(e.target.value.trimStart());
                      }}
                      id="Email"
                      placeholder={t("Email")}
                      readOnly={verified}
                    />
                    {!verified && validateEmail(email) && (
                      <span
                        onClick={() => {
                          handleSendOtp();
                        }}
                        className="otp-input-text"
                      >
                        {t("Verify")}
                      </span>
                    )}

                    {verified && (
                      <span className="otp-input-text">
                        <Button
                          icon="pi pi-check"
                          severity="Success"
                          rounded
                          text
                          aria-label="Filter"
                        />
                      </span>
                    )}

                    <label htmlFor="OTP"></label>
                    <label htmlFor="Email">
                      {t("Email")} <span className="req-star">*</span>
                    </label>
                  </span>
                  {emailErr && <div className="p-error">{t(emailErr)}</div>}
                </div>
              </div>
              <div className="col-12 sm:col-6 md:col-6 lg:col-6">
                <div className="input-parent mobile-input-parent">
                  <PhoneInput
                    style={{ border: mobileErr ? "1px solid red" : null }}
                    international
                    defaultCountry="AE"
                    value={mobile}
                    onChange={handlePhoneNumberChange}
                    placeholder={t("MobileNumber")}
                  />
                  <label
                    className={
                      mobile ? "mobile-input label-top" : "mobile-input"
                    }
                  >
                    {t("MobileNumber")}
                  </label>
                  {/**add this class .label-top */}
                  {mobileErr && <div className="p-error">{t(mobileErr)}</div>}
                </div>
              </div>
              <div className="col-12 sm:col-6 md:col-6 lg:col-6">
                <div className="input-parent">
                  <span className="p-float-label">
                    <InputText
                      className={passwordErr ? "p-invalid" : null}
                      type={!show_NPass ? "text" : "password"}
                      id="Password"
                      value={password}
                      onChange={(e) => {
                        setPasswordErr("");
                        if (!validatePassword(e.target.value.trimStart())) {
                          setPasswordErr(
                            "PasswordShouldBeWithUppercaseLowercaseNumberSymbolandMin8Characters"
                          );
                        }
                        if (!e.target.value.trimStart().length) {
                          setPasswordErr("PasswordIsRequired");
                        }
                        if (confirmPassword) {
                          if (e.target.value.trimStart() !== confirmPassword) {
                            setConfirmPasswordErr(
                              "PasswordAndConfirmPasswordShouldBeSame"
                            );
                          }
                        }
                        setPassword(e.target.value.trimStart());
                      }}
                      autoComplete="off"
                    />
                    <label htmlFor="Password">
                      {t("Password")} <span className="req-star">*</span>
                    </label>
                    <i
                      className={
                        !show_NPass
                          ? "pi pi-eye passwordIcon"
                          : "pi pi-eye-slash passwordIcon"
                      }
                      onClick={() => {
                        setShowNPass(!show_NPass);
                      }}
                    ></i>
                  </span>
                  {passwordErr && (
                    <div className="p-error">{t(passwordErr)}</div>
                  )}
                </div>
              </div>
              <div className="col-12 sm:col-6 md:col-6 lg:col-6">
                <div className="input-parent">
                  <span className="p-float-label">
                    <InputText
                      type={!show_NPass1 ? "text" : "password"}
                      className={confirmPasswordErr ? "p-invalid" : null}
                      id="ConfirmPassword"
                      value={confirmPassword}
                      onChange={(e) => {
                        setConfirmPasswordErr("");
                        if (!validatePassword(e.target.value.trimStart())) {
                          setConfirmPasswordErr(
                            "PasswordShouldBeWithUppercaseLowercaseNumberSymbolandMin8Characters"
                          );
                        }
                        if (password) {
                          if (e.target.value.trimStart() !== password) {
                            setConfirmPasswordErr(
                              "PasswordAndConfirmPasswordShouldBeSame"
                            );
                          }
                        }
                        if (!e.target.value.trimStart().length) {
                          setConfirmPasswordErr("ConfirmPasswordIsRequired");
                        }
                        setConfirmPassword(e.target.value.trimStart());
                      }}
                      autoComplete="off"
                    />
                    <label htmlFor="ConfirmPassword">
                      {t("ConfirmPassword")} <span className="req-star">*</span>
                    </label>
                    <i
                      className={
                        !show_NPass1
                          ? "pi pi-eye passwordIcon"
                          : "pi pi-eye-slash passwordIcon"
                      }
                      onClick={() => {
                        setShowNPass1(!show_NPass1);
                      }}
                    ></i>
                  </span>
                  {confirmPasswordErr && (
                    <div className="p-error">{t(confirmPasswordErr)}</div>
                  )}
                </div>
              </div>
              <div className="col-12 sm:col-12 md:col-12 lg:col-12">
                <div className="flex align-items-center">
                  <Checkbox
                    // className={checkedErr ? "p-invalid" : null}
                    inputId="IAgree"
                    name="IAgree"
                    onChange={(e) => setChecked(e.checked)}
                    checked={checked}
                  />
                  <label className="check-label mx-2">
                    {t("Iagree")}{" "}
                    <span
                      onClick={() => {
                        setPopup_open2(true);
                        setType_checkbox("terms");
                      }}
                    >
                      {t("TermsandConditions")}
                    </span>{" "}
                    {t("and")}{" "}
                    <span
                      onClick={() => {
                        setPopup_open2(true);
                        setType_checkbox("security");
                      }}
                    >
                      {t("SecuritySafetyPoilcies")}
                    </span>
                  </label>
                </div>
              </div>
              <div className="col-12 sm:col-12 md:col-12 lg:col-12">
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="IAgree"
                    name="IAgree"
                    onChange={(e) => setCheckedNewsLatter(e.checked)}
                    checked={checkedNewsLatter}
                  />
                  <label className="check-label mx-2">
                    {t("RegisterForNewsLetter")}
                  </label>
                </div>
              </div>
              <div className="col-12 sm:col-12 md:col-12 lg:col-6">
                <Button
                  onClick={(e) => {
                    handleSubmit();
                  }}
                  severity="Success"
                  size="large"
                  className="w-full"
                  label={t("CreateAccount")}
                />

                <div className="with-account-div">
                  {t("Orcreateanaccountwith")}
                </div>

                <div className="login-with">
                  <SocialButton
                    provider="facebook"
                    appId={axiosConstant.FACEBOOK_SOCIAL_KEY}
                    onLoginSuccess={handleSocialLogin}
                    onLoginFailure={handleSocialLoginFailure}
                    icon={facebookIcon}
                  >
                    {t("Facebook")}
                  </SocialButton>
                  <div style={{ width: "50%", boxSizing: "border-box" }}>
                    <GoogleLogin
                      className="social-google"
                      buttonText="Sign up"
                      onSuccess={handleGoogleLogin}
                      onFailure={handleSocialLoginFailure}
                      // type="icon"
                      size="large"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* VERIFY EMAIL POPUP */}
      <Dialog
        visible={popup_open}
        draggable={false}
        resizable={false}
        closable={false}
        showHeader={false}
        className="dialog-407"
      >
        <img src={verifyEmailIcon} />
        <img
          src={modalClose}
          alt="close"
          className="modal-close"
          onClick={() => {
            setPopup_open(false);
            setOtpErr("");
            setOtp();
            stopCountdown();
          }}
        />
        <h3 className="h3 mb-1">{t("verifyEmail")}</h3>
        <p className="p2 mb-4">
          {t("WeHaveSentTheVerificationCodeToYourMail")}
        </p>
        {!sended_otp && (
          <div className="input-parent mb-4">
            <span className="p-float-label">
              <InputText
                className={emailErr ? "p-invalid" : null}
                value={email}
                onChange={(e) => {
                  setEmailErr("");
                  if (!validateEmail(e.target.value.trimStart())) {
                    setEmailErr("EnterValidEmail");
                  }
                  if (!e.target.value.trimStart().length) {
                    setEmailErr("EmailIsRequired");
                  }
                  setEmail(e.target.value.trimStart());
                }}
                id="Email"
                placeholder="Email"
              />
              <label htmlFor="OTP"></label>
              <label htmlFor="Email">
                {t("Email")} <span className="req-star">*</span>
              </label>
            </span>
            {emailErr && <div className="p-error">{t(emailErr)}</div>}
          </div>
        )}
        {sended_otp && (
          <div className="input-parent mb-4">
            <span className="p-float-label otpbox-p">
              <InputText
                className={otpErr ? "p-invalid" : null}
                value={otp ? otp : ""}
                onChange={(e) => {
                  let value = e.target.value.trimStart();
                  setOtpErr("");
                  if (!value) {
                    setOtpErr("OtpIsRequired");
                  }
                  if (isNaN(value)) {
                    setOtp(otp);
                  } else {
                    setOtp(value);
                  }
                }}
                useGrouping={false}
                id="Otp"
                placeholder={t("OTP")}
              />
              {countdown !== 0 && (
                <span className="otp-input-text">
                  00:{countdown > 9 ? countdown : "0" + countdown}
                </span>
              )}
              {countdown == 0 && (
                <div
                  className="otp-input-text"
                  onClick={() => {
                    handleSendOtp();
                  }}
                >
                  {t("ResendOTP")}
                </div>
              )}

              <label htmlFor="OTP"></label>
              <label htmlFor="OTP">
                {t("OTP")} <span className="req-star">*</span>
              </label>
            </span>
            {otpErr ? <div className="p-error">{t(otpErr)}</div> : null}
          </div>
        )}
        <Button
          // disabled={countdown==0}
          style={{ marginBottom: "10px" }}
          onClick={(e) => {
            if (!sended_otp) {
              handleSendOtp();
            } else {
              handleVerifyEmail();
            }
          }}
          severity="Success"
          size="large"
          className="w-full"
          label={t(sended_otp ? t("Verify") : t("SendOTP"))}
        />
      </Dialog>

      {/* terms and conditions popup */}
      <Dialog
        visible={popup_open2}
        draggable={false}
        resizable={false}
        closable={false}
        showHeader={false}
        className="dialog-492"
      >
        <img
          src={modalClose}
          alt="close"
          className="modal-close"
          onClick={() => {
            setPopup_open2(false);
          }}
        />
        {type_checkbox == "terms" && (
          <>
          <h3 className="h3 mb-3 text-center">{t("TermsAndConditions")}</h3>
          
          <div className="terms-and-conditions">
            <p className="p2">{t("pleaseReadTheseTermsAndConditions")}</p>
            <p className="p2">{t("yourAccessToAndUse")}</p>
            <ul>
              <li className="p2 mb-3">
                 <h6 className="h6 mb-2 p2-bold">1. {t("accounts")}</h6> 
                <p className="p2">1.1. {t("youAreResponsibleForMaintainingTheConfidentiality")}</p>
              </li>
              <li className="p2 mb-3">
                 <h6 className="h6 mb-2 p2-bold">2. {t("PrivacyPolicy")}</h6> 
                <p className="p2">2.1. {t("yourUseOfTheService")} <Link to="/https://chat.openai.com/c/ad4f8d23-181d-476f-9c6f-a3ef8f20f3b3" style={{color:'$yellow', textDecoration:'underline'}}>{t("here")}</Link> {t("forInformationOnHowWeCollect")}</p>
              </li>
              <li className="p2 mb-3">
                 <h6 className="h6 mb-2">3. {t("productsAndServices")}</h6> 
                <p className="p2 mb-2">3.1. {t("theCompanyMayOfferITEquipment")}</p>
                <p className="p2">3.2. {t("allPurchasesMadeThrough")}</p>
              </li>
              <li className="p2 mb-3">
                 <h6 className="h6 mb-2 p2-bold">4. {t("pricingAndPayment")}</h6> 
                <p className="p2 mb-2">4.1. {t("pricesForProductsAndServices")}</p>
                <p className="p2">4.2. {t("paymentForProductsOrServices")}</p>
              </li>
              <li className="p2 mb-3">
                 <h6 className="h6 mb-2 p2-bold">5. {t("shippingAndDelivery")}</h6> 
                <p className="p2 mb-2">5.1. {t("theCompanyWillMakeReasonable")}</p>
                <p className="p2">5.2. {t("riskOfLossAndTitle")}</p>
              </li>
              <li className="p2 mb-3">
                 <h6 className="h6 mb-2 p2-bold">6. {t("returnsAndRefunds")}</h6> 
                <p className="p2">6.1. {t("pleaseReferToOurReturnAndRefund")}</p>
              </li>
              <li className="p2 mb-3">
                 <h6 className="h6 mb-2 p2-bold">7. {t("intellectualProperty")}</h6> 
                  <p className="p2">7.1. {t("theServiceAndItsOriginalContent")}</p>
              </li>
              <li className="p2 mb-3">
                 <h6 className="h6 mb-2 p2-bold">8. {t("limitationOfLiability")}</h6> 
                  <p className="p2 mb-2">8.1. {t("inNoEventShallTheCompany")}</p>
                  <div className="pl-2">
                    <p className="p2 mb-2">(i) {t("yourAccessToOrUse")}</p>
                    <p className="p2 mb-2">(ii) {t("anyConductOrContent")}</p>
                    <p className="p2 mb-2">(iii) {t("anyContentObtainedFrom")}</p>
                    <p className="p2 mb-2">(iv) {t("anyContentObtainedFrom")}</p>
                    <p className="p2 mb-2">(v) {t("unauthorizedAccess")}</p>
                  </div>
              </li>
              <li className="p2 mb-3">
                 <h6 className="h6 mb-2 p2-bold">9. {t("useOfOpenSourceSoftware")}</h6> 
                  <p className="p2">9.1. {t("theCompanyMayUseOpenSourceSoftware")}</p>
              </li>
              <li className="p2 mb-3">
                 <h6 className="h6 mb-2 p2-bold">10. {t("collectionOfPersonalInformation")}</h6> 
                  <p className="p2">10.1. {t("theServiceMayCollectPersonalInformation")}</p>
              </li>
              <li className="p2 mb-3">
                 <h6 className="h6 mb-2 p2-bold">11. {t("warrantyAndReturns")}</h6> 
                  <p className="p2 mb-2">11.1. {t("itemsSoldOnTheWebsiteAreCovered")}</p>
                  <p className="p2 mb-2">11.2. {t("theCompanyIsNotResponsibleFor")}</p>
              </li>
              <li className="p2 mb-3">
                 <h6 className="h6 mb-2 p2-bold">12. {t("limitationOfLiabilityForWarrantyClaims")}</h6> 
                  <p className="p2">12.1. {t("inNoEventShallTheCompanyBeLiable")}</p>
              </li>
              <li className="p2 mb-3">
                 <h6 className="h6 mb-2 p2-bold">13. {t("governingLaw")}</h6> 
                  <p className="p2">13.1. {t("theseTrmsAndConditions")}</p>
              </li>
              <li className="p2 mb-3">
                 <h6 className="h6 mb-2 p2-bold">14. {t("changes")}</h6> 
                  <p className="p2">14.1. {t("weReserveTheRight")}</p>
              </li>
              <li className="p2 mb-3">
                 <h6 className="h6 mb-2 p2-bold">15. {t("ContactUs")}</h6> 
                  <p className="p2 mb-3">15.1. {t("ifYouHaveAnyQuestions")} <Link style={{color:'$yellow'}}>bawabatek@bawabatek.com</Link></p>
                  <p className="p2">{t("byUsingTheBawabatek")}</p>
              </li>
            </ul>
          </div>    
          </>
        )}
        {type_checkbox == "security" && (
          <>
            <h3 className="h3 mb-3 text-center">{t("SecuritySafetyPoilcies")}</h3>
            <div className="terms-and-conditions">
              <p className="p2">{t("yourSafetyAndTheSecurityOfYourInformationAre")}</p>
              <ul>
                <li className="p2 mb-3">
                  <h6 className="h6 mb-2 p2-bold">1. {t("secureAccountAccess")}</h6> 
                  <p className="p2 mb-2">1.1. <span className="p2-bold">{t("loginCredentials")}</span> {t("youAreResponsibleForMaintaining")}</p>
                  <p className="p2">1.2. <span className="p2-bold">{t("secureConnection")}</span> {t("useASecureAndEncryptedConnection")}</p>
                </li>
                <li className="p2 mb-3">
                  <h6 className="h6 mb-2 p2-bold">2. {t("dataProtection")}</h6> 
                  <p className="p2 mb-2">2.1. <span className="p2-bold">{t("PrivacyPolicy")}</span> {t("referToOurPrivacyPolicy")}</p>
                  <p className="p2">2.2. <span className="p2-bold">{t("secureTransactions")}</span> {t("weEmploySecurePaymentMethods")}</p>
                </li>
                <li className="p2 mb-3">
                  <h6 className="h6 mb-2 p2-bold">3. {t("informationSecurity")}</h6> 
                  <p className="p2 mb-2">3.1. <span className="p2-bold">{t("intellectualPropertyProtection")}</span> {t("theServiceAndItsOriginalContents")}</p>
                  <p className="p2">3.2. <span className="p2-bold">{t("useOfOpenSourceSoftware")}</span> {t("ourUseofOpenSourceSoftware")}</p>
                </li>
                <li className="p2 mb-3">
                  <h6 className="h6 mb-2 p2-bold">4. {t("physicalProductSecurity")}</h6> 
                  <p className="p2 mb-2">4.1. <span className="p2-bold">{t("deliverySecurity")}</span> {t("whileWeMakeReasonable")}</p>
                  <p className="p2">4.2. <span className="p2-bold">{t("riskofLoss")}</span> {t("riskOfLossAndTitleForProducts")}</p>
                </li>
                <li className="p2 mb-3">
                  <h6 className="h6 mb-2 p2-bold">5. {t("safetyInProductUsage")}</h6> 
                  <p className="p2 mb-2">5.1. <span className="p2-bold">{t("manufacturerGuidelines")}</span> {t("followTheManufacturerGuidelines")}</p>
                  <p className="p2">5.2. <span className="p2-bold">{t("warrantyClaims")}</span> {t("referToTheWarrantyAndReturns")}</p>
                </li>
                <li className="p2 mb-3">
                  <h6 className="h6 mb-2 p2-bold">6. {t("informationSecurityMeasures")}</h6> 
                  <p className="p2 mb-2">6.1. <span className="p2-bold">{t("protectionAgainstUnauthorizedAccess")}</span> {t("weEmploySecurityMeasures")}</p>
                </li>
                <li className="p2 mb-3">
                  <h6 className="h6 mb-2 p2-bold">7. {t("reportingSecurityConcerns")}</h6> 
                  <p className="p2 mb-2">7.1. <span className="p2-bold">{t("incidentReporting")}</span> {t("ifYouBecomeAwareOfAnySecurity")}  <Link style={{color:'$yellow'}}>bawabatek@bawabatek.com</Link></p>
                </li>
                <li className="p2 mb-3">
                  <h6 className="h6 mb-2 p2-bold">8. {t("governingLaw")}</h6> 
                  <p className="p2 mb-2">8.1. <span className="p2-bold">{t("jurisdiction")}</span> {t("theseSecurityAndSafetyPolicies")}</p>
                </li>
                <li className="p2 mb-3">
                  <h6 className="h6 mb-2 p2-bold">9. {t("changesToSecurityPolicies")}</h6> 
                  <p className="p2 mb-2">9.1. <span className="p2-bold">{t("policyRevisions")}</span> {t("weReserveTheRightToModify")}</p>
                </li>
                <li className="p2 mb-3">
                  <p className="p2 mb-2">10. <span className="p2-bold">{t("ContactUs")}</span>  {t("weReserveTheRightToModify")} <Link style={{color:'$yellow'}}>bawabatek@bawabatek.com</Link></p>
                  <p className="p2 mb-2">{t("byUsingTheBawabatekWebsite")}</p>
                </li>
              </ul>
            </div> 
          </>
        )}
        
      </Dialog>

      {/* cart item replacement action */}
      <Dialog
        visible={visibleReplaceCartAction}
        onHide={() => {
          replaceCartItems(signUpToken, "no");
        }}
        draggable={false}
        resizable={false}
        closable={false}
        showHeader={false}
        className="dialog-340"
      >
        <h3 className="h3 mb-2">{t("DoYouWantToReplaceCart?")}</h3>
        <p className="p2 mb-4">
          {t(
            "ThereAreAlreadyItemsAvailableInYourCartDoYouWantToReplaceYourCartAndAddNewItems?"
          )}
        </p>
        <div className="modal-end-btn">
          <Button
            label={t("No")}
            className="gray"
            onClick={() => {
              replaceCartItems(signUpToken, "no");
            }}
            text
          />
          <Button
            label={t("Yes")}
            className="yellow"
            onClick={() => {
              replaceCartItems(signUpToken, "yes");
            }}
            text
          />
        </div>
      </Dialog>
    </>
  );
};

export default SignUp;
