import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Base64 } from "js-base64";
import { useDispatch, useSelector } from "react-redux";
import {
  categoryListObject,
  setCategoryList,
} from "../redux/reducers/categoryList";
import { useTranslation } from "react-i18next";

export default function Breadcrumb(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const categoryObj = useSelector(categoryListObject);
  const { title, li } = props;

  //go to category page
  const goToCategoryPage = (id) => {
    if (id === "Bundle") return navigate(`/bundle`);
    if (id === "my-order") return navigate(`/my-order`);
    const item = categoryObj?.categories?.find((obj) => obj?.id == id);
    if (!item) return;
    dispatch(
      setCategoryList({
        ...categoryObj,
        selectedCategory: item,
        selectedSubCategoryArrayOfIds: [],
        priceRange: [0, 300000],
        productType: "",
        sortBy: "recommended",
      })
    );
    navigate(`/${item?.name_en.toLowerCase().replace(/ /g, "_")}`);
  };

  return (
    <>
      <div className="breadcrumb">
        <ul>
          <li>
            <Link to="/">{t("home")}</Link>
          </li>
          <li>
            <span
              className="capitalize-first-letter"
              style={{
                cursor: li?.length > 0 ? "pointer" : "",
                color: li?.length > 0 ? "var(--gray)" : "",
              }}
              onClick={() => {
                if (li?.length > 0) {
                  // navigate(-1);
                  /// navigate to the category page
                  // const newUrl = `${window.location.origin}/${li[0]?.path
                  //   ?.toLowerCase()
                  //   .replace(/ /g, "_")}`;
                  // window.location.href = newUrl;

                  goToCategoryPage(li[0]?.path);
                }
              }}
            >
              {title}
            </span>
          </li>
          {li?.length
            ? li?.map((obj, index) => {
                return (
                  <li
                    className="capitalize-first-letter"
                    key={index}
                    style={{
                      cursor: obj?.path ? "pointer" : "",
                      color: index < li?.length - 1 ? "var(--gray)" : "",
                    }}
                    onClick={() => {
                      if (obj?.path) {
                        goToCategoryPage(obj?.path);
                      }
                    }}
                  >
                    {obj.title?.slice(0, 50)}{" "}
                    {obj.title?.length > 50 ? "..." : null}
                  </li>
                );
              })
            : null}
        </ul>
      </div>
    </>
  );
}
