import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import shippingIcon from "../../../assets/images/structure/shipping-icon.svg";
import { validatePassword } from "../../../Helpers/HelperFunctions";
import { InputText } from "primereact/inputtext";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader, currentLoader } from "../../../redux/reducers/loader";
import { setSnackbar } from "../../../redux/reducers/snackbar";
import { useNavigate, useParams } from "react-router-dom";
import { API_ROUTES } from "../../../Enum";
import globalRequest from "../../../prototype/globalRequest";

const CustomerChangePassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loader = useSelector(currentLoader);
  //states
  const [password, setPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [show_NPass, setShowNPass] = useState(true);
  const [show_NPass1, setShowNPass1] = useState(true);
  const { token } = useParams();

  //errors
  const [passwordErr, setPasswordErr] = useState("");
  const [ConfirmPasswordErr, setConfirmPasswordErr] = useState("");
  const handleClose = () => {};

  //submit function
  const handleSubmit = async () => {
    let error = false;
    if (!validatePassword(password)) {
      setPasswordErr(
        "PasswordShouldBeWithUppercaseLowercaseNumberSymbolandMin8Characters"
      );
      error = true;
    }
    if (!password) {
      setPasswordErr("PasswordIsRequired");
      error = true;
    }
    if (ConfirmPassword) {
      if (password !== ConfirmPassword) {
        setConfirmPasswordErr("PasswordAndConfirmPasswordShouldBeSame");
        error = true;
      } else if (!validatePassword(ConfirmPassword)) {
        setConfirmPasswordErr(
          "PasswordShouldBeWithUppercaseLowercaseNumberSymbolandMin8Characters"
        );
        error = true;
      }
    }
    if (!ConfirmPassword) {
      setConfirmPasswordErr("ConfirmPasswordIsRequired");
      error = true;
    }
    if (error) return;
    ///close reset password popup
    let data = {
      password,
      confirm_password: ConfirmPassword,
    };
    dispatch(changeLoader(true));
    try {
      const res = await globalRequest(
        "post",
        API_ROUTES.SET_CHANGE_CUSTOMER_PASSWORD(token),
        data,
        {},
        false
      );
      if (res.ack == 1) {
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            message: "PasswordHasbeenChangeSuccessfully",
            isOpen: true,
            state: "success",
          })
        );
        handleClose();
        navigate("/")
      } else {
        dispatch(changeLoader(false));
        setOtpErr(res.msg);
      }
    } catch (err) {
      dispatch(changeLoader(false));
      dispatch(
        setSnackbar({
          isOpen: true,
          state: "error",
          message: err.response.data.msg || t("SomethingWentWrong"),
        })
      );
    }
  };

  return (
    <>
      <Dialog
        visible={true}
        // onHide={() => handleClose()}
        draggable={false}
        resizable={false}
        closable={false}
        showHeader={false}
        className="dialog-407"
      >
        <img src={shippingIcon} alt="shipping" className="icon48" />
        <h3 className="h3">{t("ResetPassword")}</h3>
        <p className="p2 mb-4">
          {t("Noworriesyoucanchangethepasswordanytime")}
        </p>
        <div className="input-parent mb-4">
          <span className="p-float-label">
            <InputText
              type={show_NPass ? "password" : "text"}
              className={passwordErr ? "p-invalid" : null}
              id="Password"
              value={password}
              onChange={(e) => {
                setPasswordErr("");
                if (!validatePassword(e.target.value.trimStart())) {
                  setPasswordErr(
                    "PasswordShouldBeWithUppercaseLowercaseNumberSymbolandMin8Characters"
                  );
                }
                if (!e.target.value.trimStart().length) {
                  setPasswordErr("PasswordIsRequired");
                }
                if (ConfirmPassword) {
                  if (e.target.value.trimStart() !== ConfirmPassword) {
                    setConfirmPasswordErr(
                      "PasswordAndConfirmPasswordShouldBeSame"
                    );
                  }
                }
                setPassword(e.target.value.trimStart());
              }}
              autoComplete="off"
            />
            <label htmlFor="Password">
              {t("Password")} <span className="req-star">*</span>
            </label>
            <i
              className={
                !show_NPass
                  ? "pi pi-eye passwordIcon"
                  : "pi pi-eye-slash passwordIcon"
              }
              onClick={() => {
                setShowNPass(!show_NPass);
              }}
            ></i>
          </span>
          {passwordErr && <div className="p-error">{t(passwordErr)}</div>}
        </div>

        <div className="input-parent mb-4">
          <span className="p-float-label">
            <InputText
              type={!show_NPass1 ? "text" : "password"}
              className={ConfirmPasswordErr ? "p-invalid" : null}
              id="ConfirmPassword"
              value={ConfirmPassword}
              onChange={(e) => {
                setConfirmPasswordErr("");
                if (!validatePassword(e.target.value.trimStart())) {
                  setConfirmPasswordErr(
                    "PasswordShouldBeWithUppercaseLowercaseNumberSymbolandMin8Characters"
                  );
                }
                if (password) {
                  if (e.target.value.trimStart() !== password) {
                    setConfirmPasswordErr(
                      "PasswordAndConfirmPasswordShouldBeSame"
                    );
                  }
                }
                if (!e.target.value.trimStart().length) {
                  setConfirmPasswordErr("ConfirmPasswordIsRequired");
                }
                setConfirmPassword(e.target.value.trimStart());
              }}
              autoComplete="off"
            />
            <label htmlFor="ConfirmPassword">
              {t("ConfirmPassword")} <span className="req-star">*</span>
            </label>
            <i
              className={
                !show_NPass1
                  ? "pi pi-eye passwordIcon"
                  : "pi pi-eye-slash passwordIcon"
              }
              onClick={() => {
                setShowNPass1(!show_NPass1);
              }}
            ></i>
          </span>
          {ConfirmPasswordErr && (
            <div className="p-error">{t(ConfirmPasswordErr)}</div>
          )}
        </div>
        <Button
          onClick={() => {
            handleSubmit();
          }}
          disabled={loader}
          severity="Success"
          size="large"
          className="w-full mt-10"
          label={t("ResetPassword")}
        />
      </Dialog>
    </>
  );
};

export default CustomerChangePassword;
