import { useCallback, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
// images
import modalClose from "../assets/images/structure/modal-close.svg";
import returnOrder from "../assets/images/structure/return-order.svg";
import { InputText } from "primereact/inputtext";
import { changeLoader } from "../redux/reducers/loader";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../redux/reducers/snackbar";
import globalRequest from "../prototype/globalRequest";
import { API_ROUTES } from "../Enum";

const CancelOrder = ({ closePopup, id, recallApi, setRecallApi }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [cancelReason, setCancelReason] = useState("");
  const [cancelReasonError, setCancelReasonError] = useState("");

  ///submit function
  const handleConfirm = useCallback(async () => {
    //call api here and show success msg
    let error = false;
    if (cancelReason.length == 0) {
      setCancelReasonError("CancelReasonisrequired");
      error = true;
    }
    if (cancelReason.length > 1000) {
      setCancelReasonError("Cancelreasonshouldbemax1000characters");
      error = true;
    }
    if (error) return;

    dispatch(changeLoader(true));
    //api call here
    const data = {
      orderId: id,
      reason: cancelReason,
      status: "canceled",
    };
    await globalRequest("post", API_ROUTES.CHANGE_ORDER_STATUS, data, {}, true)
      .then((data) => {
        dispatch(changeLoader(false));
        if (data?.ack == 1) {
          dispatch(
            setSnackbar({
              isOpen: true,
              state: "success",
              message: t("cancelOrderRequestSentSuccessfully"),
            })
          );
          closePopup();
        } else if (data?.ack == 0) {
          dispatch(changeLoader(false));
          dispatch(
            setSnackbar({
              isOpen: true,
              state: "error",
              message: data?.msg,
            })
          );
          closePopup();
        }
        setRecallApi(!recallApi);
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            isOpen: true,
            state: "error",
            message: t("SomethingWentWrong"),
          })
        );
      });
  });

  return (
    <>
      <Dialog
        visible={true}
        onHide={() => closePopup()}
        draggable={false}
        resizable={false}
        closable={false}
        showHeader={false}
        className="dialog-492"
      >
        <img
          src={modalClose}
          alt="close"
          className="modal-close"
          onClick={() => closePopup()}
        />
        <img src={returnOrder} alt="Return Order" className="icon48" />
        <h3 className="h3">{t("cancelOrder")}</h3>
        <p className="p2 mb-4">
          {t("enterTheCancellationReasonBeforeConfirmingTheCancelRequest")}
        </p>
        <span className="p-float-label">
          <InputText
            id="cancelReason"
            placeholder={t("cancelReason")}
            value={cancelReason}
            onChange={(e) => {
              setCancelReasonError("");
              if (e.target.value.length > 1000) {
                setCancelReasonError("Cancelreasonshouldbemax1000characters");
              }
              setCancelReason(e.target.value.trimStart());
            }}
          />
          <label htmlFor="cancelReason">
            {t("cancelReason")} <span className="req-star">*</span>
          </label>
        </span>
        {cancelReasonError.length > 0 && (
          <p className="p2 text-red-500">{t(cancelReasonError)}</p>
        )}

        <Button
          severity="Success"
          size="large"
          className="w-full mt-30"
          label={t("confirm")}
          disabled={!cancelReason}
          onClick={handleConfirm}
        />
      </Dialog>
    </>
  );
};

export default CancelOrder;
