import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "./SliderSlick.scss";
import { axiosConstant } from "../../../Helpers/Axios/Constants";
import { useDispatch, useSelector } from "react-redux";
import {
  categoryListObject,
  setCategoryList,
} from "../../../redux/reducers/categoryList";
import noImage from "../../../assets/images/products/noImage.webp";
const SliderSlick = ({ selectedCategory }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let language = localStorage.getItem("i18nextLng");
  const categoryObj = useSelector(categoryListObject);

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow:
      selectedCategory?.sub_category?.length > 4
        ? 4
        : selectedCategory?.sub_category?.length,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {selectedCategory?.sub_category?.length > 0 && (
        <div className="slider-wrapper">
          <Slider {...settings}>
            {selectedCategory?.sub_category?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="slider-box"
                  style={{ border: "1px solid red !important" }}
                >
                  <img
                    src={item?.image_url}
                    onClick={() => {
                      let temp = [...categoryObj?.selectedSubCategoryArrayOfIds]
                      if(temp?.includes(item?.id)){
                        let index = temp?.indexOf(item?.id);
                        temp?.splice(index, 1);
                      }else{
                        temp?.push(item?.id);
                      }
                      dispatch(
                        setCategoryList({
                          ...categoryObj,
                          selectedSubCategoryArrayOfIds:temp
                        })
                      );
                    }}
                    className="slider-img"
                    alt="Category Image"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = noImage;
                    }}
                  />
                  <p
                    className="p2 flex align-items-center justify-content-center mt-4 capitalize-first-letter"
                    onClick={() => {
                      let temp = [...categoryObj?.selectedSubCategoryArrayOfIds]
                      if(temp?.includes(item?.id)){
                        let index = temp?.indexOf(item?.id);
                        temp?.splice(index, 1);
                      }else{
                        temp?.push(item?.id);
                      }
                      dispatch(
                        setCategoryList({
                          ...categoryObj,
                          selectedSubCategoryArrayOfIds:temp
                        })
                      );
                    }}
                    style={{ cursor: "pointer",color:categoryObj?.selectedSubCategoryArrayOfIds?.includes(item?.id)?"#E8336E":null, }}
                  >
                    {language == "en"
                      ? t(`${item?.name_en ? item?.name_en : ""}`)
                      : t(`${item?.name_ar ? item?.name_ar : ""}`)}
                  </p>
                </div>
              );
            })}
          </Slider>
        </div>
      )}
    </>
  );
};
export default SliderSlick;
