import React, { useState, useRef } from "react";
import { useNavigate, Link, Outlet } from "react-router-dom";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Badge } from "primereact/badge";
import { useClickOutside } from "primereact/hooks";
import { TabView, TabPanel } from "primereact/tabview";
import { Sidebar } from "primereact/sidebar";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// images
import dawnArrowBlack from "../../../assets/images/structure/down-arrow.svg";
import logoTop from "../../../assets/images/logo/header-logo.svg";
import heardIcon from "../../../assets/images/structure/heard-white.svg";
import basketIcon from "../../../assets/images/structure/basket-white.svg";
import bundleIcon from "../../../assets/images/structure/bundle-yellow.svg";
// other
import UserDropdown from "./UserDropdown";
import "./header.scss";
import TopToolbar from "./top-toolbar/TopToolbar";
import { useEffect } from "react";
import { axiosConstant } from "../../../Helpers/Axios/Constants";
import { API_ROUTES, STORAGE } from "../../../Enum";
import addDeleteGetLocalStorage from "../../../prototype/addDeleteGetLocalStorage";
import {
  searchProductsObject,
  setSearchProducts,
} from "../../../redux/reducers/searchProducts";
import { changeLoader } from "../../../redux/reducers/loader";
import { setSnackbar } from "../../../redux/reducers/snackbar";
import {
  categoryListObject,
  setCategoryList,
} from "../../../redux/reducers/categoryList";
import { useCallback } from "react";
import {
  fetchUserProductsCount,
  userProductsObj,
} from "../../../redux/reducers/userProductsCount";
import {
  currentPlaceOrderData,
  initialPlaceOrderData,
  setPlaceOrderData,
} from "../../../redux/reducers/placeOrderData";
import globalRequest from "../../../prototype/globalRequest";

export default function Header({}) {
  /// integration
  let token = JSON.parse(
    addDeleteGetLocalStorage(STORAGE.AL_MAHARA_USER, {}, "get")
  )?.token;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let language = localStorage.getItem("i18nextLng");
  const [visibleSidebar, setVisibleSidebar] = useState(false);
  const [visible, setVisible] = useState(false);
  const overlayRef = useRef(null);
  const searchObj = useSelector(searchProductsObject);
  const categoryObj = useSelector(categoryListObject);
  const productsCount = useSelector(userProductsObj);
  const placeData = useSelector(currentPlaceOrderData);

  useClickOutside(overlayRef, () => {
    setVisible(false);
  });

  const [search, setSearch] = useState("");
  const routerSearchList = () => {
    if (!search) return;
    dispatch(
      setSearchProducts({
        ...searchObj,
        searchInput: search ? search : "",
      })
    );
    navigate("/search-results");
  };
  const [isMenuOpenIndex, setIsMenuOpenIndex] = useState(null);

  //get all categories from db and store in redux
  const getAllCategoriesApi = useCallback(() => {
    dispatch(changeLoader(true));
    globalRequest("get", API_ROUTES.GET_CATEGORY, {}, {})
      .then((data) => {
        if (data?.ack == 1) {
          // filter categories with only has COUNT > 0
          let tempCategories = [];
          data?.data?.map((obj) => {
            if (obj?.COUNT > 0) {
              tempCategories.push(obj);
            }
          });
          dispatch(
            setCategoryList({
              ...categoryObj,
              categories: tempCategories,
              selectedSubCategoryArrayOfIds: [],
              priceRange: [0, 300000],
              productType: "",
              sortBy: "recommended",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            message: "Something went wrong with the API " + err,
            isOpen: true,
            state: "error",
          })
        );
      })
      .finally(() => {
        dispatch(changeLoader(false));
      });
  });

  useEffect(() => {
    getAllCategoriesApi();
    // dispatch(getAllCategories());
    dispatch(setPlaceOrderData(initialPlaceOrderData));
  }, []);

  const [activeCategory, setActiveCategory] = useState(null);
  const [higlitedCategory, setHiglitedCategory] = useState(null);
  //get all categories from redux store
  let categories = categoryObj?.categories;
  let selectedCategory = categoryObj?.selectedCategory;
  let fisrtCategory = categories[0];

  //set active category on page load
  useEffect(() => {
    if (selectedCategory && !activeCategory) {
      setHiglitedCategory(selectedCategory.id);
    }
    if (activeCategory) {
      setHiglitedCategory(activeCategory.id);
    }
    if (!selectedCategory && !activeCategory) {
      setHiglitedCategory(fisrtCategory?.id);
    }
  }, [selectedCategory, activeCategory]);

  useEffect(() => {
    if (token?.length > 0) {
      dispatch(fetchUserProductsCount());
    }
  }, []);

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Check if the user has scrolled down a certain amount (e.g., 100 pixels)
      if (window.scrollY > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //change language from userDrop
  const [isChangeLang, setIsChangeLang] = useState(false);

  return (
    <>
      <div className={`header-wrapper ${isScrolled ? "scrolled" : ""}`}>
        <TopToolbar isChangeLang={isChangeLang} />
        <div className="header">
          <div className="container">
            <div className="header-flex">
              <div className="header-left">
                <div className="header__logo">
                  <Link to="/">
                    <img src={logoTop} alt="logo" />
                  </Link>
                </div>
                {/* <Menubar model={items} /> */}
                <div className="relative">
                  <Button onClick={() => setVisible(true)}>
                    {t("ViewCatalog")}{" "}
                    <img
                      src={dawnArrowBlack}
                      alt="arrow-down"
                      className="down-arrow"
                    />
                  </Button>
                  {visible ? (
                    <div ref={overlayRef} className="view-catalog-drop">
                      <ul className="catalog-menu">
                        {categories.length > 0 &&
                          categories.map((item, index) => {
                            return (
                              <li
                                key={index}
                                className="catalog-li"
                                onMouseOver={() => {
                                  if (item?.sub_category?.length > 0) {
                                    setActiveCategory(item);
                                  }
                                }}
                                onClick={() => {
                                  if (item?.sub_category?.length == 0) {
                                    dispatch(
                                      setCategoryList({
                                        ...categoryObj,
                                        selectedCategory: item,
                                        selectedSubCategoryArrayOfIds: [],
                                        priceRange: [0, 300000],
                                        productType: "",
                                        sortBy: "recommended",
                                      })
                                    );
                                    setVisible(false);
                                    setActiveCategory(null);
                                    navigate(
                                      `/${item?.name_en
                                        .toLowerCase()
                                        .replace(/ /g, "_")}`
                                    );
                                    return;
                                  }
                                }}
                              >
                                <div className="relative mnCatrelativeDv">
                                  <img
                                    src={item?.iconImage}
                                    alt=".."
                                    className="icon24"
                                  />
                                  <span
                                    className="catalog-span capitalize-first-letter"
                                    style={{
                                      color:
                                        higlitedCategory == item.id
                                          ? "#E8336E"
                                          : null,
                                    }}
                                  >
                                    {language == "en"
                                      ? t(`${item?.name_en}`)
                                      : t(`${item?.name_ar}`)}
                                  </span>
                                </div>
                                {item?.sub_category?.length > 0 && (
                                  <img
                                    src={dawnArrowBlack}
                                    alt=".."
                                    className="arrow-icon"
                                  />
                                )}
                                {activeCategory?.name_ar == item?.name_ar && (
                                  <TabView
                                    activeIndex={0}
                                    // onTabChange={(e) => setActiveIndex(e.index)}
                                  >
                                    <TabPanel>
                                      <div className="catalog-tab">
                                        {/*if already selected category and not clicked on any then show already selected */}
                                        {selectedCategory?.sub_category
                                          ?.length > 0 &&
                                          !activeCategory &&
                                          selectedCategory?.sub_category?.map(
                                            (item, index) => {
                                              return (
                                                <div
                                                  key={index}
                                                  className="catalog-content capitalize-first-letter"
                                                  style={{
                                                    fontSize: "14px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    dispatch(
                                                      setCategoryList({
                                                        ...categoryObj,
                                                        selectedCategory:
                                                          selectedCategory,
                                                        selectedSubCategoryArrayOfIds:
                                                          [item?.id],
                                                        priceRange: [0, 300000],
                                                        productType: "",
                                                        sortBy: "recommended",
                                                      })
                                                    );
                                                    setVisible(false);
                                                    setActiveCategory(null);
                                                    navigate(
                                                      `/${selectedCategory?.name_en
                                                        .toLowerCase()
                                                        .replace(/ /g, "_")}`
                                                    ); //replace space with underscore
                                                  }}
                                                >
                                                  {language == "en"
                                                    ? t(`${item?.name_en}`)
                                                    : t(`${item?.name_ar}`)}
                                                </div>
                                              );
                                            }
                                          )}
                                        {/*if clicked on any then show clicked category */}
                                        {activeCategory?.sub_category?.length >
                                          0 &&
                                          activeCategory?.sub_category?.map(
                                            (item, index) => {
                                              return (
                                                <div
                                                  key={index}
                                                  className="catalog-content"
                                                  style={{
                                                    fontSize: "14px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    dispatch(
                                                      setCategoryList({
                                                        ...categoryObj,
                                                        selectedCategory:
                                                          activeCategory,
                                                        selectedSubCategoryArrayOfIds:
                                                          [item?.id],
                                                        priceRange: [0, 300000],
                                                        productType: "",
                                                        sortBy: "recommended",
                                                      })
                                                    );
                                                    setVisible(false);
                                                    setActiveCategory(null);
                                                    navigate(
                                                      `/${activeCategory?.name_en
                                                        .toLowerCase()
                                                        .replace(/ /g, "_")}`
                                                    ); //replace space with underscore
                                                  }}
                                                >
                                                  {language ==
                                                  "en capitalize-first-letter"
                                                    ? t(`${item?.name_en}`)
                                                    : t(`${item?.name_ar}`)}
                                                </div>
                                              );
                                            }
                                          )}
                                        {/*if there is no already selected category and not clicked on any then show first category in array */}
                                        {!selectedCategory?.name_ar &&
                                          activeCategory?.name_ar &&
                                          selectedCategory?.sub_category
                                            ?.length == 0 &&
                                          activeCategory?.sub_category
                                            ?.length == 0 &&
                                          fisrtCategory.sub_category?.length >
                                            0 &&
                                          fisrtCategory.sub_category?.map(
                                            (item, index) => {
                                              return (
                                                <div
                                                  key={index}
                                                  className="catalog-content capitalize-first-letter"
                                                  style={{
                                                    fontSize: "14px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    dispatch(
                                                      setCategoryList({
                                                        ...categoryObj,
                                                        selectedCategory:
                                                          fisrtCategory,
                                                        selectedSubCategoryArrayOfIds:
                                                          [item?.id],
                                                        priceRange: [0, 300000],
                                                        productType: "",
                                                        sortBy: "recommended",
                                                      })
                                                    );
                                                    setVisible(false);
                                                    setActiveCategory(null);
                                                    navigate(
                                                      `/${fisrtCategory?.name_en
                                                        .toLowerCase()
                                                        .replace(/ /g, "_")}`
                                                    ); //replace space with underscore
                                                  }}
                                                >
                                                  {language == "en"
                                                    ? t(`${item?.name_en}`)
                                                    : t(`${item?.name_ar}`)}
                                                </div>
                                              );
                                            }
                                          )}
                                      </div>
                                    </TabPanel>
                                  </TabView>
                                )}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="header-center">
                <span className="p-input-icon-right">
                  {search && (
                    <i
                      className="pi pi-times"
                      onClick={() => {
                        setSearch("");
                      }}
                    />
                  )}
                  {!search && <i className="pi pi-search" />}
                  <InputText
                    value={search}
                    onChange={(e) => {
                      if (e.target.value.length > 50) return;
                      setSearch(e.target.value.trimStart());
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        routerSearchList();
                      }
                    }}
                    placeholder={t("SearchForProductsBrandsMore")}
                  />
                </span>
              </div>
              <div className="header-right">
                <Link to="/wishlist" className="p-overlay-badge">
                  <img src={heardIcon} alt="heard" className="icon24" />
                  {productsCount?.wishlistcount > 0 && (
                    <Badge value={productsCount?.wishlistcount} />
                  )}
                </Link>
                <UserDropdown
                  setIsChangeLang={() => {
                    setIsChangeLang(Math.random());
                  }}
                />
                <Link to="/cart" className="p-overlay-badge">
                  <img src={basketIcon} alt="basket" className="icon24" />
                  {productsCount?.cartcount > 0 && (
                    <Badge value={productsCount?.cartcount} />
                  )}
                </Link>
              </div>
            </div>
            <div className="header-flex-mobile">
              <div className="card flex flex-grow-1 justify-content-between">
                <Sidebar
                  visible={visibleSidebar}
                  onHide={() => setVisibleSidebar(false)}
                >
                  <div className="collapsible-menu">
                    {categories?.length > 0 &&
                      categories?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="catalog-content capitalize-first-letter"
                            onClick={() => {
                              if (isMenuOpenIndex == index) {
                                setIsMenuOpenIndex(null);
                                return;
                              }
                              setIsMenuOpenIndex(index);
                              setActiveCategory(item);
                            }}
                          >
                            <img src={item?.iconImage} className="icon24" />
                            <span className="px-2">
                              {language == "en"
                                ? t(`${item?.name_en}`)
                                : t(`${item?.name_ar}`)}
                            </span>
                            <i className="arrow pi pi-angle-down"></i>
                            {isMenuOpenIndex == index ? (
                              <ul>
                                {item?.sub_category?.length > 0 &&
                                  item?.sub_category?.map((item, index) => {
                                    return (
                                      <li
                                        key={index}
                                        className="catalog-content"
                                        onClick={() => {
                                          dispatch(
                                            setCategoryList({
                                              ...categoryObj,
                                              selectedCategory: activeCategory,
                                              selectedSubCategoryArrayOfIds: [
                                                item?.id,
                                              ],
                                              priceRange: [0, 300000],
                                              productType: "",
                                              sortBy: "recommended",
                                            })
                                          );
                                          setActiveCategory(null);
                                          setVisibleSidebar(false);
                                          navigate(
                                            `/${activeCategory?.name_en
                                              .toLowerCase()
                                              .replace(/ /g, "_")}`
                                          ); //replace space with underscore
                                        }}
                                      >
                                        {language == "en"
                                          ? t(
                                              `${
                                                item?.name_en
                                                  ? item?.name_en
                                                  : ""
                                              }`
                                            )
                                          : t(
                                              `${
                                                item?.name_ar
                                                  ? item?.name_ar
                                                  : ""
                                              }`
                                            )}
                                      </li>
                                    );
                                  })}
                              </ul>
                            ) : null}
                          </div>
                        );
                      })}
                  </div>
                  <Link
                    onClick={() => {
                      setVisibleSidebar(false);
                    }}
                    to="/bundle"
                    className="mt-1 flex-center"
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    <img src={bundleIcon} className="icon24" />
                    <span className="px-2">Bundle</span>
                  </Link>
                  {/* <div className="collapsible-menu">
                    <Tree value={nodes} className="w-full md:w-30rem" />
                  </div> */}
                </Sidebar>
                <Button
                  icon="pi pi-arrow-right"
                  onClick={() => setVisibleSidebar(true)}
                />
                <div className="header-left">
                  <div className="header__logo">
                    <Link to="/">
                      <img src={logoTop} height={35} alt="logo" />
                    </Link>
                  </div>
                </div>
                <div className="header-right">
                  <Link to="/wishlist" className="p-overlay-badge">
                    <img src={heardIcon} alt="heard" className="icon24" />
                    {productsCount?.wishlistcount > 0 && (
                      <Badge value={productsCount?.wishlistcount} />
                    )}
                  </Link>
                  <UserDropdown
                    setIsChangeLang={() => {
                      setIsChangeLang(Math.random());
                    }}
                  />
                  <Link to="/cart" className="p-overlay-badge">
                    <img src={basketIcon} alt="basket" className="icon24" />
                    {productsCount?.cartcount > 0 && (
                      <Badge value={productsCount?.cartcount} />
                    )}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="header-center header-center-mobile text-center m-auto">
        <span className="p-input-icon-right">
          {search && (
            <i
              className="pi pi-times"
              onClick={() => {
                setSearch("");
              }}
            />
          )}
          {!search && <i className="pi pi-search" />}
          <InputText
            value={search}
            onChange={(e) => {
              if (e.target.value.length > 50) return;
              setSearch(e.target.value.trimStart());
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                routerSearchList();
              }
            }}
            placeholder={t("SearchForProductsBrandsMore")}
          />
        </span>
      </div>
      <Outlet />
    </>
  );
}
