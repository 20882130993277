import { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
// images
import modalClose from "../assets/images/structure/modal-close.svg";
import returnOrder from "../assets/images/structure/return-order.svg";
import { InputText } from "primereact/inputtext";
import { changeLoader } from "../redux/reducers/loader";
import { setSnackbar } from "../redux/reducers/snackbar";
import { useDispatch } from "react-redux";
import globalRequest from "../prototype/globalRequest";
import { API_ROUTES } from "../Enum";
import { AddBankDetailsPopup } from "./AddBankDetailsPopup";
import { RadioButton } from "primereact/radiobutton";

const ReturnOrder = ({
  closePopup,
  id,
  recallApi,
  setRecallApi,
  orderType,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [returnReason, setreturnReason] = useState("");
  const [returnReasonErr, setReturenReasonErr] = useState("");
  const [openAddBankDetails, setOpenAddBankDetails] = useState(false);

  const [ingredient, setIngredient] = useState("");
  const [showOther, setShowOther] = useState(true);

  //handel error
  const handleError = () => {
    if (ingredient == "Other" || ingredient == "") {
      let error = false;
      if (returnReason.length == 0) {
        setReturenReasonErr("ReturnReasonisrequired");
        error = true;
      }
      if (returnReason.length > 1000) {
        setReturenReasonErr("Retuenreasonshouldbemax1000characters");
        error = true;
      }
      return error;
    }
  };

  ///submit function
  const handleConfirm = () => {
    //api call here
    const data = {
      orderId: id,
      reason:
        ingredient == "Other" || ingredient == "" ? returnReason : ingredient,
      status: "returned",
    };
    globalRequest("post", API_ROUTES.CHANGE_ORDER_STATUS, data, {}, true)
      .then((data) => {
        if (data?.ack == 1) {
          closePopup();
          dispatch(
            setSnackbar({
              isOpen: true,
              state: "success",
              message: t("ReturnOrderRequestSentSuccessfully"),
            })
          );
          dispatch(changeLoader(false));
          setRecallApi(!recallApi);
        }
      })
      .catch((err) => {
        console.error(err);
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            isOpen: true,
            state: "error",
            message: t("somethingWentWrong"),
          })
        );
      });
  };

  useEffect(() => {
    if (ingredient == "Other") {
      setShowOther(true);
    } else {
      setreturnReason("");
      setReturenReasonErr("");
      setShowOther(false);
    }
  }, [ingredient]);

  return (
    <>
      <Dialog
        visible={true}
        onHide={() => closePopup()}
        draggable={false}
        resizable={false}
        closable={false}
        showHeader={false}
        className="dialog-407"
      >
        <img
          src={modalClose}
          alt="close"
          className="modal-close"
          onClick={() => closePopup()}
        />
        <img src={returnOrder} alt="Return Order" className="icon48" />
        <h3 className="h3">{t("returnItems")}</h3>
        <p className="p2 mb-4">
          {t("SelectTheReturnReasonBeforeConfirmingTheReturnRequest")}
        </p>

        <div className="">
          <div className="flex align-items-center mb-3">
            <RadioButton
              inputId="ingredient1"
              name="pizza"
              value="Found a different product that better suits my needs."
              onChange={(e) => setIngredient(e.value)}
              checked={
                ingredient ===
                "Found a different product that better suits my needs."
              }
            />
            <label htmlFor="ingredient1" className="ml-2">
              {t("Foundadifferentproductthatbettersuitsmyneeds")}
            </label>
          </div>
          <div className="flex align-items-center mb-3">
            <RadioButton
              inputId="ingredient2"
              name="pizza"
              value="No longer interested in the item."
              onChange={(e) => setIngredient(e.value)}
              checked={ingredient === "No longer interested in the item."}
            />
            <label htmlFor="ingredient2" className="ml-2">
              {t("Nolongerinterestedintheitem")}
            </label>
          </div>
          <div className="flex align-items-center mb-3">
            <RadioButton
              inputId="ingredient3"
              name="pizza"
              value="Dissatisfaction with overall product."
              onChange={(e) => setIngredient(e.value)}
              checked={ingredient === "Dissatisfaction with overall product."}
            />
            <label htmlFor="ingredient3" className="ml-2">
              {t("Dissatisfactionwithoverallproductratings")}
            </label>
          </div>
          <div className="flex align-items-center mb-3">
            <RadioButton
              inputId="ingredient4"
              name="pizza"
              value="Product is damaged."
              onChange={(e) => setIngredient(e.value)}
              checked={
                ingredient === "Product is damaged."
              }
            />
            <label htmlFor="ingredient4" className="ml-2">
              {t("ProductIsDamaged")}
            </label>
          </div>
          <div className="flex align-items-center mb-3">
            <RadioButton
              inputId="ingredient5"
              name="pizza"
              value="Other"
              onChange={(e) => setIngredient(e.value)}
              checked={ingredient === "Other"}
            />
            <label htmlFor="ingredient5" className="ml-2">
              {t("Other")}
            </label>
          </div>
        </div>

        {showOther && (
          <>
            <span className="p-float-label">
              <InputText
                id="returnReason"
                placeholder={t("returnReason")}
                value={returnReason}
                onChange={(e) => {
                  setReturenReasonErr("");
                  if (e.target.value.length > 1000) {
                    setReturenReasonErr(
                      "Retuenreasonshouldbemax1000characters"
                    );
                  }
                  setreturnReason(e.target.value.trimStart());
                }}
              />
              <label htmlFor="returnReason">
                {t("returnReason")} <span className="req-star">*</span>
              </label>
            </span>
            {returnReasonErr && (
              <small className="p-error">{t(returnReasonErr)}</small>
            )}
          </>
        )}

        <Button
          severity="Success"
          size="large"
          className="w-full mt-30"
          label={t("confirm")}
          disabled={ingredient ? false : true}
          onClick={() => {
            if (handleError()) return;
            if (orderType === "cash") {
              setOpenAddBankDetails(true);
              return;
            }
            handleConfirm();
          }}
        />
      </Dialog>

      {openAddBankDetails ? (
        <AddBankDetailsPopup
          orderId={id}
          closePopup={() => {
            setOpenAddBankDetails(false);
          }}
          submitData={() => {
            handleConfirm();
          }}
          popupIsOpen={true}
        />
      ) : null}
    </>
  );
};

export default ReturnOrder;
