/** @format */
import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";

import UserDropdown from "../header/UserDropdown";
import "./CheckoutHeader.scss";
import { useTranslation } from "react-i18next";

// images
import logoTop from "../../../assets/images/logo/header-logo.svg";

const CheckoutHeader = ({ activeState = 0, callback }) => {
  const { t } = useTranslation();

  const states = ["Cart", "address", "Payment"];

  //change language from userDrop
  const [isChangeLang, setIsChangeLang] = useState(false);
  return (
    <>
      <div className="header-checkout">
        <div className="container">
          <div className="header-flex">
            <div className="header-left">
              <div className="header__logo">
                <Link to="/">
                  <img src={logoTop} alt="logo" />
                </Link>
              </div>
            </div>
            <div className="header-center">
              <div className="card-payment-tab">
                {states.map((obj, ind) => {
                  return (
                    <div
                      onClick={() => callback(ind)}
                      style={activeState >= ind ? { cursor: "pointer" } : {}}
                      className={`option ${
                        activeState >= ind ? "active" : null
                      }`}
                      key={ind}
                    >
                      <span className="count">{ind + 1}</span>
                      <span className="mobile-none">{t(obj)}</span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="header-right">
              <UserDropdown
               setIsChangeLang={()=>{setIsChangeLang(!isChangeLang)}}
              />
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default CheckoutHeader;
