import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
// images
import modalClose from "../assets/images/structure/modal-close.svg";
import { useState } from "react";
import { InputNumber } from "primereact/inputnumber";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { changeLoader } from "../redux/reducers/loader";
import { setSnackbar } from "../redux/reducers/snackbar";
import { API_ROUTES, STORAGE } from "../Enum";
import addDeleteGetLocalStorage from "../prototype/addDeleteGetLocalStorage";
import globalRequest from "../prototype/globalRequest";

const OtpVerify = ({ handleClose, openReset }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  //get existing email from local storage
  const existingEmail = addDeleteGetLocalStorage(STORAGE.FORGOT_MAIL, {}, "get");
  //states
  const [otp, setOtp] = useState();
  const [countdown, setCountdown] = useState(30);
  //errors
  const [otpErr, setOtpErr] = useState("");

  const Countdown_handler = () => {
    setCountdown(30);
    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    // After 30 seconds, clear the interval, re-enable the button and reset the countdown
    setTimeout(() => {
      clearInterval(countdownInterval);
      setCountdown(0);
    }, 30000);
  };

  useEffect(() => {
    Countdown_handler();
  }, []);

  ///send otp to email
  const handleSendOtp = async () => {
    dispatch(changeLoader(true));
    try {   
      const res = await globalRequest("post", API_ROUTES.FORGOT_PASSWORD, { email: existingEmail }, {}, false);
      if (res.ack == 1) {
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            message: res.msg,
            isOpen: true,
            state: "success",
          })
        );
        setOtp();
        Countdown_handler();
      } else {
        dispatch(changeLoader(false));
        if (res?.errMsg[0]?.email) {
          console.log(res.errMsg[0].email);
        }
      }
    }catch (err) {
      dispatch(changeLoader(false));
      dispatch(
        setSnackbar({
          isOpen: true,
          state: "error",
          message: err.response.data.msg || t("SomethingWentWrong"),
        })
      );
    }
  };

  ///verify otp
  const verify_otp = async () => {
    if (!otp) {
      setOtpErr("OtpIsRequired");
      return;
    }
    dispatch(changeLoader(true));
    try {   
      const res = await globalRequest(
        "post",
        API_ROUTES.EMAIL_VERIFICATION,
        {
          email: existingEmail ? existingEmail : "",
          otp,
        },
        {},
        {},
        false
      );
      if (res.ack == 1) {
        dispatch(changeLoader(false));
        setVerified(true);
        setEmail_edit(false);
      } else {
        setOtpErr(res.msg);
        dispatch(changeLoader(false));
      }
    }catch (err) {
      dispatch(changeLoader(false));
      dispatch(
        setSnackbar({
          isOpen: true,
          state: "error",
          message: err.response.data.msg || t("SomethingWentWrong"),
        })
      );
    }
  };
  return (
    <>
      <Dialog
        visible={true}
        onHide={() => handleClose()}
        draggable={false}
        resizable={false}
        closable={false}
        showHeader={false}
        className="dialog-407"
      >
        <img src={modalClose} alt="close" className="modal-close" onClick={() => handleClose()} />
        {/* <img src={shippingIcon} alt="shipping" className="icon48" /> */}
        <h3 className="h3">{t("VerifyOTP")}</h3>
        <div className="input-parent mt-4">
          <span className="p-float-label">
            {/* <p className="p1 mt-2 mb-4">{t("Are you sure you want to logout?")}</p> */}
            <div className="input-parent mb-4">
              <span className="p-float-label otpbox-p">
                <InputNumber
                  className={otpErr ? "p-invalid" : null}
                  value={otp}
                  onChange={(e) => {
                    setOtp(e.value);
                  }}
                  useGrouping={false}
                  id="Otp"
                  placeholder={t("OTP")}
                />
                {countdown !== 0 && (
                  <span className="otp-input-text">00:{countdown > 9 ? countdown : "0" + countdown}</span>
                )}
                {countdown == 0 && (
                  <div
                    className="otp-input-text"
                    onClick={() => {
                      handleSendOtp();
                    }}
                  >
                    {t("ResendOTP")}
                  </div>
                )}

                <label htmlFor="OTP"></label>
                <label htmlFor="OTP">
                  {t("OTP")} <span className="req-star">*</span>
                </label>
              </span>
              {otpErr ? <div className="p-error">{t(otpErr)}</div> : null}
            </div>
          </span>
          <Button
            severity="Success"
            size="large"
            className="w-full mt-10"
            onClick={() => {
              verify_otp();
            }}
            label={t("Verify")}
          />
        </div>
      </Dialog>
    </>
  );
};

export default OtpVerify;
