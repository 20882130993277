import { createSlice } from "@reduxjs/toolkit";
import addDeleteGetLocalStorage from "../../prototype/addDeleteGetLocalStorage";
import { STORAGE } from "../../Enum";

const getLocalData = () => {
  let data = addDeleteGetLocalStorage(STORAGE.AL_MAHARA_USER, {}, "get");
  if (data) {
    try {
      return JSON.parse(data);
    } catch (e) {
      return {};
    }
  } else {
    return {};
  }
};

export const auth = createSlice({
  name: "auth",
  initialState: {
    value: getLocalData(),
  },
  reducers: {
    setData: (state, action) => {
      state.value = getLocalData();
    },
  },
});

export const { setData } = auth.actions;
export const currentAuth = (state) => state.auth.value;
export default auth.reducer;
