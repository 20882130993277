import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
// images
import modalClose from "../assets/images/structure/modal-close.svg";
import returnOrder from "../assets/images/structure/return-order.svg";
import { InputText } from "primereact/inputtext";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { API_ROUTES } from "../Enum";
import globalRequest from "../prototype/globalRequest";
import { changeLoader } from "../redux/reducers/loader";
import { setSnackbar } from "../redux/reducers/snackbar";

export const AddBankDetailsPopup = ({ closePopup, submitData, orderId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [bankNumber, setBankNumber] = useState("");
  const [ibanNumber, setIbanNumber] = useState("");
  const [name, setName] = useState("");
  const [bankNumberError, setBankNumberError] = useState("");
  const [ibanNumberError, setIbanNumberError] = useState("");
  const [nameError, setNameError] = useState("");

  //handle error
  const handleError = () => {
    let error = false;
    if (!bankNumber) {
      setBankNumberError("BankNumberIsRequired");
      error = true;
    }
    if (!ibanNumber) {
      setIbanNumberError("IBANNumberIsRequired");
      error = true;
    }
    if (!name) {
      setNameError("NameIsRequired");
      error = true;
    }
    return error;
  };

  ///submit function
  const handleSubmit = () => {
    if (handleError()) return;
    const data = {
      order_id: orderId,
      bank_number: bankNumber,
      iban: ibanNumber,
      name_on_bank: name,
    };
    dispatch(changeLoader(true));
    globalRequest("post", API_ROUTES.ADD_BANK_DETAIL, data, {}, true)
      .then((data) => {
        if (data?.ack == 1) {
          submitData(); //submit reason
        }
      })
      .catch((err) => {
        console.error(err);
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            isOpen: true,
            state: "error",
            message: t("somethingWentWrong"),
          })
        );
      });
  };

  return (
    <>
      <Dialog
        visible={true}
        onHide={() => closePopup()}
        draggable={false}
        resizable={false}
        closable={false}
        showHeader={false}
        className="dialog-407"
      >
        <img
          src={modalClose}
          alt="close"
          className="modal-close"
          onClick={() => closePopup()}
        />
        <img src={returnOrder} alt="Return Order" className="icon48" />
        <h3 className="h3 mb-4">{t("AddBankDetails")}</h3>
        <div className="mb-4 relative">
          <span className="p-float-label ">
            <InputText
              id="returnReason"
              placeholder={t("BankNumber")}
              value={bankNumber}
              onChange={(e) => {
                let val = e.target.value?.trimStart();
                if (isNaN(val)) return;
                if (val.length > 15) return;
                setBankNumber(val);
                setBankNumberError("");
              }}
            />
            <label htmlFor="returnReason">
              {t("BankNumber")} <span className="req-star">*</span>
            </label>
          </span>
          {bankNumberError && (
            <small className="p-error absolute">{t(bankNumberError)}</small>
          )}
        </div>
        <div className="mb-4 relative">
          <span className="p-float-label ">
            <InputText
              id="returnReason"
              placeholder={t("IBAN")}
              value={ibanNumber}
              onChange={(e) => {
                let val = e.target.value?.trimStart();
                if (val.length > 15) return;
                setIbanNumber(val);
                setIbanNumberError("");
              }}
            />
            <label htmlFor="returnReason">
              {t("IBAN")} <span className="req-star">*</span>
            </label>
          </span>
          {ibanNumberError && (
            <small className="p-error absolute">{t(ibanNumberError)}</small>
          )}
        </div>
        <div className="mb-4 relative">
          <span className="p-float-label">
            <InputText
              id="returnReason"
              placeholder={t("NameregisteredonBank")}
              value={name}
              onChange={(e) => {
                let val = e.target.value?.trimStart();
                if (val.length > 30) return;
                setName(val);
                setNameError("");
              }}
            />
            <label htmlFor="returnReason">
              {t("NameregisteredonBank")} <span className="req-star">*</span>
            </label>
          </span>
          {nameError && (
            <small className="p-error absolute">{t(nameError)}</small>
          )}
        </div>
        <Button
          severity="Success"
          size="large"
          className="w-full mt-3"
          label={t("confirm")}
          onClick={() => {
            handleSubmit();
          }}
        />
      </Dialog>
    </>
  );
};
