import "./Banner.scss";
import imgNotFound from "../../../assets/images/products/imgNotFound.webp";

const Banner = (props) => {
    const { imgBanner, title, paragraph } = props;
    return (
      <>
        <div className="banner1-image-box">
          <img 
          src={imgBanner} 
          alt={title} 
          className="home1-img"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = imgNotFound;
          }}
           />
          <div className="overlay-text">
            <h2 className="h2">{title} </h2>
            <p className="p2">{paragraph}</p>
          </div>
        </div>
      </>
    );
};
export default Banner;