import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./OrderDetail.scss";
import FrontToolbar from "../../global-modules/FrontToolbar";
import Footer from "../layout/footer/Footer";
import Breadcrumb from "../../global-modules/Breadcrumb";
import noImage from "../../assets/images/products/noImage.webp";
import downloadIcon from "../../assets/images/structure/download-icon.svg";
import ReturnOrder from "../../DialogBox/ReturnOrder";
import CancelOrder from "../../DialogBox/CancelOrder";
import OrderSummary from "../../DialogBox/OrderSummary";
import { API_ROUTES } from "../../Enum";
import { useCallback } from "react";
import globalRequest from "../../prototype/globalRequest";
import { changeLoader } from "../../redux/reducers/loader";
import { useDispatch } from "react-redux";
import { Base64 } from "js-base64";
import { OrderTracking } from "./OrderTracking";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Divider } from "primereact/divider";
import { Tooltip } from "primereact/tooltip";
import visaWhite from "../../assets/images/structure/visa-white.svg";
import { ReturnRequestLogs } from "./ReturnRequestLogs";

const OrderDetailNew = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useParams();
  let productSlug = location?.slug;

  //states
  const [orderDetails, setOrderDetails] = useState(null);
  const [orderSummary, setOrderSummary] = useState({});
  const [orderTracking, setOrderTracking] = useState([]);
  const [openReturnOrder, setOpenReturnOrder] = useState(false);
  const [openCancelOrder, setOpenCancelOrder] = useState(false);
  const [openOrderSummary, setOpenOrderSummary] = useState(false);
  const [recallApi, setRecallApi] = useState(false);
  const [canReturn, setCanReturn] = useState(true);
  const [returnRequestList, setReturnRequestList] = useState(null);

  const routerProductDetail = (slug, page, id) => {
    if (page == "product") {
      navigate(
        `/product-detail-page/p/${slug}/${Base64.encode(
          encodeURIComponent(id)
        )}`
      );
    } else if (page == "bundle") {
      navigate(
        `/bundle-detail-page/${slug}/${Base64.encode(encodeURIComponent(id))}`
      );
    }
  };

  //order details
  const orderDetailsApi = useCallback(() => {
    dispatch(changeLoader(true));
    globalRequest("get", API_ROUTES.ORDER_DETAILS(productSlug), {}, {}, true)
      .then((data) => {
        if (data?.ack == 1) {
          let order_details = data?.data;
          setOrderDetails(order_details);
          setOrderTracking(order_details?.order_status_logs || []);
          setReturnRequestList(order_details?.return_request);
          //order summary
          let order_summary = {
            total: order_details?.total_amount,
            subTotal: order_details?.subtotal_amount,
            discount: order_details?.discount_amount,
            shipping: order_details?.shipping_charge,
            refund: order_details?.refund_amount,
            tax: order_details?.tax_amount,
            codCharge: order_details?.cod_charge,
            paymentType: order_details?.payment_type,
            currency: order_details?.currency,
            couponDetails: order_details?.coupon_details
              ? order_details?.coupon_details
              : null,
            couponName: order_details?.coupon_name,
            shippingCompany: order_details?.shipping_company,
            cardNumber: order_details?.card_number,
            taxInPercentage: order_details?.tax_percentage,
          };
          setOrderSummary(order_summary);
        }
        dispatch(changeLoader(false));
      })
      .catch((err) => {
        navigate(-1);
        dispatch(changeLoader(false));
      });
  });

  useEffect(() => {
    orderDetailsApi();
  }, [recallApi]);

  //date formater
  const convertTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const period = hour >= 12 ? "PM" : "AM";
    const formattedHour = hour % 12 || 12;

    const formattedDate = `${day} ${month} ${year} - ${formattedHour}:${minute
      .toString()
      .padStart(2, "0")} ${period}`;

    // return formattedDate;
    return (
      <span style={{ direction: "ltr", display: "inline-block" }}>
        <span>{`${day} ${t(month)} ${year}`}</span> -
        <span>{`${formattedHour}:${minute
          .toString()
          .padStart(2, "0")} ${period}`}</span>
      </span>
    );
  };

  //order tracking status
  const [orderTrackingDates, setOrderTrackingDates] = useState({
    confirmed: "",
    shipped: "",
    delivered: "",
    cancelled: "",
    returned: "",
  });
  const [returnDate, setReturnDate] = useState("");

  useEffect(() => {
    const updateOrderTrackingDates = (status, createdAt) => {
      if (status == "canceled") status = "cancelled";
      setOrderTrackingDates((prev) => ({
        ...prev,
        [status]: convertTimestamp(createdAt),
      }));
    };

    const handleDeliveredStatus = (createdAt, type) => {
      updateOrderTrackingDates(type, createdAt);

      // Show which date should be last to return product
      let orderDate = new Date(createdAt);
      let returnDate = new Date(createdAt);
      returnDate.setDate(orderDate.getDate() + 15);

      setReturnDate(formatDate(returnDate));
      calculateDateDifference(returnDate);
    };

    orderTracking?.forEach((item) => {
      switch (item?.status) {
        case "confirmed":
        case "shipped":
        case "canceled":
        case "returned":
          updateOrderTrackingDates(item.status, item.createdAt);
          break;
        case "pickup":
          handleDeliveredStatus(item.createdAt, "pickup");
          break;
        case "delivered":
          handleDeliveredStatus(item.createdAt, "delivered");
          break;
        default:
          break;
      }
    });
  }, [orderTracking]);

  const formatDate = (inputDateStr) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const inputDate = new Date(inputDateStr);
    const day = inputDate.getDate();
    const month = months[inputDate.getMonth()];
    const year = inputDate.getFullYear();
    return `${day} ${month} ${year}`;
  };

  function calculateDateDifference(date1Str) {
    const date1 = new Date(date1Str);
    const date2 = new Date();
    setCanReturn(date1 > date2);
  }
  const showSerialNumber = (rowData, obj) => {
    return <>{obj?.rowIndex + 1}</>;
  };
  const productBodyTemplate = (rowData) => {
    let featuredImg;
    let arr = rowData?.product?.product_images;
    arr?.map((ele1) => {
      if (ele1?.is_featured == 1) {
        featuredImg = ele1?.image_url;
      }
    });
    return (
      <div className="order-outline-box flex flex-column sm:flex-row sm:align-items-start justify-content-between px-0 py-0">
        <div className="flex align-items-start justify-content-between order-dtl-productLftCol">
          <img
            src={featuredImg}
            alt="product image"
            className="order-product-img hand-on"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = noImage;
            }}
            onClick={() => {
              routerProductDetail(
                rowData?.product?.slug,
                rowData?.product?.product_type,
                rowData?.product?.id
              );
            }}
          />
          <div className="hand-on">
            <p className="p2 p2-semibold mb-1">
              {rowData?.product_title}{" "}
              <span className="p2 ml-2" style={{ fontFamily: "OpenSans-Bold" }}>
                {` X ${rowData?.quantity}`}
              </span>
            </p>
            <p className="p2 wrapcontent w-210">
              {rowData?.product?.short_description}{" "}
            </p>
          </div>
        </div>
      </div>
    );
  };
  const pricePerProduct = (rowData) => {
    return (
      <>
        {orderDetails?.currency} {parseFloat(rowData?.product_price).toFixed(2)}{" "}
      </>
    );
  };
  const AvailableBodyTemplate = (rowData, obj) => {
    let quantity = rowData?.approveQty;
    if (orderDetails?.is_partially === 1) {
      if (orderDetails?.status === "confirmed") {
        if (orderDetails?.erp_order_update === 1) {
          quantity = rowData?.approveQty;
        } else {
          quantity = rowData?.quantity;
        }
      } else if (orderDetails?.status == "canceled") {
        if (orderDetails?.erp_order_update === 1) {
          quantity = rowData?.approveQty;
        } else {
          quantity = rowData?.quantity;
        }
      }
    } else {
      if (
        orderDetails?.status == "canceled" &&
        orderDetails?.erp_order_update === 1
      ) {
        quantity = rowData?.approveQty;
      } else {
        quantity = rowData?.quantity;
      }
    }
    return (
      <div className="flex align-items-center justify-content-center">
        <span>{quantity}</span>
        {quantity < rowData?.quantity ? (
          <>
            <Tooltip
              target={`.knob${obj?.rowIndex}`}
              className="custom-tooltip"
              content={`${t("Only")} ${quantity} ${t("quantityoutof")} ${
                rowData?.quantity
              } ${t(
                "wasavailableinthestockRefundoftheremainingproducthasbeeninitiatedtothesamepaymentsource"
              )}`}
            />
            <i
              className={`pi pi-info-circle mx-1 knob${obj?.rowIndex}`}
              style={{ color: "#E93D3D" }}
            ></i>
          </>
        ) : null}
      </div>
    );
  };
  const ShowStatus = ({ status }) => {
    return (
      <span className={`product-chips-status ${status}`}>
        {status == "canceled" ? t("cancelled") : t(status)}
      </span>
    );
  };
  const productStatus = (rowData) => {
    return (
      <>
        {orderDetails?.is_partially === 1 ? (
          <>
            {orderDetails?.status === "confirmed" ? (
              <>
                {orderDetails?.erp_order_update === 1 &&
                orderDetails?.delivery_type == "pickup" ? (
                  rowData?.approveQty > 0 ? (
                    <ShowStatus status={"confirmed"} />
                  ) : (
                    <ShowStatus status={"cancelled"} />
                  )
                ) : (
                  <ShowStatus status={"confirmed"} />
                )}
              </>
            ) : orderDetails?.status === "canceled" ? (
              <>
                <ShowStatus status={"cancelled"} />
              </>
            ) : orderDetails?.status === "pickup" ? (
              <>
                {rowData?.approveQty > 0 ? (
                  <ShowStatus status={"pickup"} />
                ) : (
                  <ShowStatus status={"cancelled"} />
                )}
              </>
            ) : orderDetails?.status === "delivered" ? (
              <>
                {rowData?.approveQty > 0 ? (
                  <ShowStatus status={"delivered"} />
                ) : (
                  <ShowStatus status={"cancelled"} />
                )}
              </>
            ) : orderDetails?.status === "returned" ? (
              <>
                <ShowStatus status={"returned"} />
              </>
            ) : orderDetails?.status === "shipped" ? (
              <>
                {rowData?.approveQty > 0 ? (
                  <ShowStatus status={"shipped"} />
                ) : (
                  <ShowStatus status={"cancelled"} />
                )}
              </>
            ) : null}
          </>
        ) : (
          <>
            <ShowStatus status={orderDetails?.status} />
          </>
        )}
      </>
    );
  };
  const subTotalPerProduct = (rowData) => {
    let quantity = rowData?.approveQty;
    if (orderDetails?.is_partially === 1) {
      if (orderDetails?.status === "confirmed") {
        if (orderDetails?.erp_order_update === 1) {
          quantity = rowData?.approveQty;
        } else {
          quantity = rowData?.quantity;
        }
      } else if (orderDetails?.status == "canceled") {
        if (orderDetails?.erp_order_update === 1) {
          quantity = rowData?.approveQty;
        } else {
          quantity = rowData?.quantity;
        }
      }
    } else {
      if (
        orderDetails?.status == "canceled" &&
        orderDetails?.erp_order_update === 1
      ) {
        quantity = rowData?.approveQty;
      } else {
        quantity = rowData?.quantity;
      }
    }
    return (
      <>
        {orderDetails?.currency}{" "}
        {parseFloat(rowData?.product_price * quantity).toFixed(2)}
      </>
    );
  };

  return (
    <>
      <FrontToolbar />
      <div className="container">
        <div className="flex flex-column sm:flex-row justify-content-between mb-4">
          <Breadcrumb
            title={t("myOrder")}
            li={[{ title: `#${orderDetails?.order_id}`, path: "my-order" }]}
          />
        </div>
        <div className="grid">
          <div className="col-12 sm:col-12 md:col-10 lg:col-12 mx-auto pb-5">
            <div className="order-detail-wrapper">
              <div className="order-detail-header flex flex-column sm:flex-row align-items-start sm:align-items-end justify-content-between mb-4">
                <div className="header__left">
                  <h6 className="p2 p-bold pb-2">{t("orderDetails")}</h6>
                  {orderDetails !== null && (
                    <>
                      <div className="flex flex-column sm:flex-row">
                        <div className="flex mb-1">
                          <span className="p2 p2-semibold pr-2">
                            {t("orderDate")}
                          </span>
                          <span className="p2">
                            {convertTimestamp(orderDetails?.createdAt)}
                          </span>
                        </div>
                        <span className="p2 p2-semibold px-2 sm:flex hidden">
                          |
                        </span>
                        <div className="flex mb-1">
                          <span className="p2 p2-semibold pr-2">
                            {t("orderID")}
                          </span>
                          <span className="p2">#{orderDetails?.order_id}</span>
                        </div>
                      </div>

                      <div className="flex flex-column ">
                        <div className="flex mb-1">
                          <span className="p2 p2-semibold pr-2">
                            {orderDetails?.delivery_type == "pickup"
                              ? t("pickUpAddress")
                              : t("deliveryAddress")}
                            :
                          </span>
                          <p className="p2">
                            <span className="capitalize-first-letter">
                              {orderDetails?.customer_name}
                            </span>
                            {orderDetails?.mobile && (
                              <> | {orderDetails?.mobile}</>
                            )}
                            {orderDetails?.address_name && (
                              <span className="capitalize-first-letter">
                                {" "}
                                | {orderDetails?.address_name}
                              </span>
                            )}
                          </p>
                        </div>
                        <div className="flex mb-1">
                          <p className="p2">
                            {orderDetails?.address ? orderDetails?.address : ""}
                            {orderDetails?.address2
                              ? `, ${orderDetails?.address2}`
                              : ""}
                            {orderDetails?.city
                              ? `, ${orderDetails?.city}`
                              : ""}
                            {orderDetails?.state
                              ? `, ${orderDetails?.state}`
                              : ""}
                            {orderDetails?.country
                              ? `, ${orderDetails?.country}`
                              : ""}
                            {orderDetails?.postal_code
                              ? `, ${orderDetails?.postal_code}`
                              : ""}
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {orderDetails !== null && (
                  <div className="header__right flex flex-column align-items-end justify-content-between mt-2 sm:mt-0">
                    <div
                      className="flex align-items-center mb-auto-auto cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(`${orderDetails?.invoice}`, "_blank");
                      }}
                    >
                      <img src={downloadIcon} alt="" />
                      <span className="p2 p2-semibold pl-2">
                        {t("downloadInvoice")}
                      </span>
                    </div>
                    <div className="flex align-items-center mt-auto ">
                      <h6 className="p2 p-bold pb-2">
                        {t("Total")} {orderDetails?.currency}{" "}
                        {parseFloat(orderDetails?.total_amount).toFixed(2)}{" "}
                      </h6>
                    </div>
                  </div>
                )}
              </div>
              {orderDetails !== null && (
                <>
                  <div className="order-detail-body">
                    <div className="body-top">
                      <h6 className="p2 p-bold">{t("productDetails")}</h6>
                      {/* partial order check */}
                      {orderDetails?.is_partially == 1 ? (
                        <div
                          className={`mt-4 read-only-no-cursor`}
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {/* <Checkbox
                            style={{ margin: "4px" }}
                            disabled={true}
                            checked={
                              orderDetails?.is_partially == 1 ? true : false
                            }
                          /> */}
                          <p className="p3 mb-0">{t("markAsPartialDetail")}</p>
                        </div>
                      ) : null}
                      <div className="divider"></div>
                      <div className="order-tracking-wrapper">
                        <DataTable
                          value={orderDetails?.order_details}
                          className="detail-table"
                        >
                          <Column
                            field="id"
                            header="#"
                            body={showSerialNumber}
                          ></Column>
                          <Column
                            field="Product"
                            header={t("product")}
                            body={productBodyTemplate}
                          ></Column>
                          <Column
                            field="Price_Unit"
                            header={t("Price/Unit")}
                            className="align-center"
                            body={pricePerProduct}
                          ></Column>
                          {/* <Column
                            field="quantity"
                            header={t("TotalQuantity")}
                            className="align-center"
                          ></Column> */}
                          <Column
                            field="TotalQuantity"
                            header={t("TotalQuantity")}
                            className="align-center"
                            body={AvailableBodyTemplate}
                          ></Column>
                          <Column
                            field="Status"
                            header={t("status")}
                            className="align-center"
                            body={productStatus}
                          ></Column>
                          <Column
                            field="SubTotal"
                            header={t("subtotal")}
                            className="align-center"
                            body={subTotalPerProduct}
                          ></Column>
                        </DataTable>
                        <div className="order-tracking-wrapper">
                          <div className="tracking-box">
                            <p className="p2 mb-3 p-bold">
                              {t("orderTracking")}
                            </p>
                            {orderDetails?.status !== "confirmed" &&
                            orderDetails?.tracking_id ? (
                              <p className="p2 mb-3">
                                <span className="p-bold">
                                  {" "}
                                  {t("trackingID")}{" "}
                                </span>{" "}
                                {orderDetails?.tracking_id}
                              </p>
                            ) : null}
                            <div className="order-tracking-block">
                              <OrderTracking
                                orderStatus={orderDetails?.status}
                                deliveryType={orderDetails?.delivery_type}
                                orderTrackingDates={orderTrackingDates}
                              />
                            </div>
                          </div>
                        </div>

                        {(orderDetails?.status == "confirmed" ||
                          ((orderDetails?.status == "delivered" ||
                            orderDetails?.status == "pickup") &&
                            canReturn)) && (
                          <div className="flex align-items-center justify-content-between">
                            {orderDetails?.status == "confirmed" && (
                              <p
                                className="p2 yellow p-semibold mt-3 hand-on"
                                onClick={() => {
                                  setOpenCancelOrder(true);
                                }}
                              >
                                {t("doYouWantToCancelYourOrder")}
                              </p>
                            )}
                            {orderDetails?.delivery_type === "delivery" &&
                            orderSummary?.paymentType === "card" ? (
                              <>
                                {(orderDetails?.status === "delivered" ||
                                  orderDetails?.status === "pickup") &&
                                  canReturn && (
                                    <>
                                      <p
                                        className="p2 yellow p-semibold mt-3 hand-on"
                                        onClick={() => {
                                          navigate(
                                            `/choose-return-order/${productSlug}`
                                          );
                                          // setOpenReturnOrder(true);
                                        }}
                                      >
                                        {t("doYouWantToReturnYourOrder")}
                                      </p>
                                      <p className="p2">
                                        {t("EligibleTill")} {returnDate}
                                      </p>
                                    </>
                                  )}
                              </>
                            ) : null}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* canceled and return details  */}
                  {orderDetails?.status == "canceled" ||
                  orderDetails?.status == "returned" ? (
                    <>
                      {orderDetails?.refund_amount > 0 ||
                      orderDetails?.bank_details != null ||
                      orderDetails?.reason ? (
                        <div className="order-detail-body">
                          <div className="px-4 py-3">
                            <div className="divider">
                              {orderDetails?.payment_type == "card" && (
                                <>
                                  {orderDetails?.payment_status ==
                                    "RefundedPending" &&
                                  orderDetails?.status == "returned" ? (
                                    <p className="p2 pb-1">
                                      {t("refundInComingDay")}
                                    </p>
                                  ) : null}
                                  {orderDetails?.refund_amount > 0 &&
                                  orderDetails?.payment_status ===
                                    "Refunded" ? (
                                    <>
                                      <p className="p2 p-bold pb-1">
                                        {t("refundDetails")}
                                      </p>
                                      <p className="p2 pb-1">
                                        {t("RefundOf")}{" "}
                                        <b>
                                          {orderDetails?.currency +
                                            " " +
                                            orderDetails?.refund_amount?.toFixed(
                                              2
                                            )}{" "}
                                        </b>
                                        {t("CreditedToTheInitialPaymentSource")}
                                      </p>
                                      {orderDetails?.refund_ref_no && (
                                        <p className="p2 pb-1">
                                          {t("ReferenceNo")} :
                                          {orderDetails?.refund_ref_no}
                                        </p>
                                      )}
                                    </>
                                  ) : null}
                                </>
                              )}
                              {orderDetails?.bank_details != null ? (
                                <>
                                  <h6 className="p2 mb-1">
                                    {t("RefundBankDetails")}{" "}
                                  </h6>
                                  {orderDetails?.payment_status ==
                                    "RefundedPending" &&
                                  orderDetails?.status == "returned" ? (
                                    <p className="p2 pb-1">
                                      {t("refundInComingDay")}
                                    </p>
                                  ) : null}

                                  {orderDetails?.refund_amount > 0 &&
                                  orderDetails?.payment_status ===
                                    "Refunded" ? (
                                    <p className="p2 pb-1">
                                      {t("RefundOf")}{" "}
                                      <b>
                                        {orderDetails?.currency +
                                          " " +
                                          orderDetails?.refund_amount?.toFixed(
                                            2
                                          )}{" "}
                                      </b>
                                      {t("CreditedToYourAccount")}
                                    </p>
                                  ) : null}
                                  <p
                                    className="p2 pb-1"
                                    style={{ wordBreak: "break-all" }}
                                  >
                                    <span>{t("BankNumber")} :</span>
                                    <span>
                                      {" "}
                                      {
                                        orderDetails?.bank_details?.bank_number
                                      }{" "}
                                    </span>
                                  </p>
                                  <p
                                    className="p2 pb-1"
                                    style={{ wordBreak: "break-all" }}
                                  >
                                    <span>{t("IBAN")} :</span>
                                    <span>
                                      {" "}
                                      {orderDetails?.bank_details?.iban}{" "}
                                    </span>
                                  </p>
                                  <p
                                    className="p2 pb-1"
                                    style={{ wordBreak: "break-all" }}
                                  >
                                    <span>{t("NameregisteredonBank")} :</span>
                                    <span>
                                      {" "}
                                      {
                                        orderDetails?.bank_details?.name_on_bank
                                      }{" "}
                                    </span>
                                  </p>
                                </>
                              ) : null}
                              {orderDetails?.reason && (
                                <p className="p2">
                                  {orderDetails?.status == "returned"
                                    ? t("ReturnReason")
                                    : t("cancellationReason")}
                                  :{" "}
                                  {orderDetails?.reason ==
                                  "Found a different product that better suits my needs."
                                    ? t(
                                        "Foundadifferentproductthatbettersuitsmyneeds"
                                      )
                                    : orderDetails?.reason ==
                                      "No longer interested in the item."
                                    ? t("Nolongerinterestedintheitem")
                                    : orderDetails?.reason ==
                                      "Dissatisfaction with overall product."
                                    ? t(
                                        "Dissatisfactionwithoverallproductratings"
                                      )
                                    : orderDetails?.reason ==
                                      "Accidentally placed the order multiple times."
                                    ? t(
                                        "Accidentallyplacedtheordermultipletimes"
                                      )
                                    : orderDetails?.reason ==
                                      "Product is damaged."
                                    ? t("ProductIsDamaged")
                                    : orderDetails?.reason}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </>
                  ) : null}

                  {orderDetails?.company_name && (
                    <div className="order-detail-body">
                      <div className="px-4 py-3">
                        <h6 className="p2 mb-1">{t("CompanyDetails")}</h6>
                        <p className="p2 pb-1">
                          <span>{t("CompanyName")}</span>:{" "}
                          {orderDetails?.company_name}
                        </p>
                        <p className="p2 pb-1">
                          <span>{t("TRNNumber")}</span>:{" "}
                          {orderDetails?.trn_number}
                        </p>
                      </div>
                    </div>
                  )}

                  <div className="order-detail-body">
                    <div className="px-4 py-4">
                      <h6 className="p2 mb-3">{t("TotalOrderSummary")}</h6>
                      <div className="flex align-items-center justify-content-between mb-2">
                        <p className="p2">{t("subtotal")}</p>
                        <p className="p2">
                          {orderSummary?.currency}{" "}
                          {parseFloat(orderSummary?.subTotal).toFixed(2)}
                        </p>
                      </div>
                      {+orderSummary?.discount > 0 && (
                        <div className="flex align-items-center justify-content-between mb-2">
                          <p className="p2">
                            {" "}
                            {t("couponDiscount")}
                            {orderSummary?.couponName && (
                              <span className="p2" style={{ color: "#EFB34C" }}>
                                {" "}
                                ({orderSummary?.couponName})
                              </span>
                            )}
                          </p>
                          <p className="p2 yellow">
                            {" "}
                            - {orderSummary?.currency}{" "}
                            {parseFloat(orderSummary?.discount).toFixed(2)}
                          </p>
                        </div>
                      )}
                      <div className="flex align-items-center justify-content-between mb-2">
                        <p className="p2">
                          {" "}
                          {t("shipping")}
                          {orderSummary?.shippingCompany && (
                            <span
                              className="p2 capitalize-first-letter"
                              style={{ color: "#EFB34C" }}
                            >
                              {" "}
                              ({orderSummary?.shippingCompany})
                            </span>
                          )}
                        </p>
                        <p className="p2">
                          {orderSummary?.currency}{" "}
                          {parseFloat(orderSummary?.shipping).toFixed(2)}
                        </p>
                      </div>
                      <div className="flex align-items-center justify-content-between mb-2">
                        <p className="p2">
                          {" "}
                          {t("tax")}{" "}
                          {orderSummary?.taxInPercentage > 0
                            ? ` (${orderSummary?.taxInPercentage}%)`
                            : ""}
                        </p>
                        <p className="p2">
                          {orderSummary?.currency}{" "}
                          {parseFloat(orderSummary?.tax).toFixed(2)}
                        </p>
                      </div>
                      {orderSummary?.paymentType == "cash" &&
                      orderSummary?.codCharge > 0 ? (
                        <div className="flex align-items-center justify-content-between mb-2">
                          <p className="p2">{t("codCharges")}</p>
                          <p className="p2">
                            {orderSummary?.currency}{" "}
                            {parseFloat(orderSummary?.codCharge).toFixed(2)}
                          </p>
                        </div>
                      ) : null}
                      <Divider className="mx-0 my-3" />
                      <div className="flex align-items-center justify-content-between mb-2">
                        <p className="p2 font-bold">{t("total")}</p>
                        <p className="p2 font-bold">
                          {orderSummary?.currency}{" "}
                          {parseFloat(orderSummary?.total).toFixed(2)}
                        </p>
                      </div>
                      {orderDetails?.erp_order_update === 1 &&
                      orderDetails?.is_partially === 1 ? (
                        <>
                          <div className="flex align-items-center justify-content-between mb-2">
                            <p className="p2 yellow">
                              {orderSummary?.paymentType == "cash"
                                ? t("CancellationDeduction")
                                : t("refund")}
                            </p>
                            <p className="p2 yellow">
                              - {orderSummary?.currency}{" "}
                              {parseFloat(orderDetails?.refund_amount).toFixed(
                                2
                              )}
                            </p>
                          </div>
                          <Divider className="mx-0 my-3" />
                          <div className="flex align-items-center justify-content-between mb-2">
                            <p className="p2 p-bold">
                              {orderSummary?.paymentType == "cash"
                                ? t("toPay")
                                : t("netPay")}
                            </p>
                            <p className="p2 p-bold">
                              {orderSummary?.currency}{" "}
                              {parseFloat(orderDetails?.net_amount).toFixed(2)}
                            </p>
                          </div>
                        </>
                      ) : null}

                      <div className="bottom-bg-box flex mt-4 align-items-center w-full">
                        {/* <img src={visaWhite} alt="" />
                        <div className="px-2">
                          <p className="p2 mb-1">
                            Paid by Card ending in xxxx 1301{" "}
                          </p>
                          <p className="p2">
                            {t("transactionID")} #547924398645
                          </p>
                        </div> */}
                        {orderSummary?.paymentType == "cash" ? (
                          <>
                            <div>{t("cashOnDelivery")}</div>
                          </>
                        ) : (
                          <>
                            <img src={visaWhite} alt="" />
                            <div className="pl-2">
                              <p className="p2">
                                {t("PaidbyCard")}
                                {orderSummary?.cardNumber &&
                                  ` ${t(
                                    "endingin"
                                  )} xxxx ${orderSummary?.cardNumber
                                    ?.toString()
                                    ?.slice(-4)}`}
                              </p>
                              {/* <p className="p2">{t("transactionID")} #547924398645</p> */}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
              <ReturnRequestLogs
                orderDetails={orderDetails}
                returnRequestList={returnRequestList}
                formatDate={formatDate}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {openReturnOrder ? (
        <ReturnOrder
          closePopup={() => {
            setOpenReturnOrder(false);
          }}
          id={orderDetails?.id}
          setRecallApi={setRecallApi}
          recallApi={recallApi}
          orderType={orderSummary?.paymentType}
        />
      ) : null}
      {openCancelOrder ? (
        <CancelOrder
          closePopup={() => {
            setOpenCancelOrder(false);
          }}
          id={orderDetails?.id}
          setRecallApi={setRecallApi}
          recallApi={recallApi}
          orderType={orderSummary?.paymentType}
        />
      ) : null}
      {openOrderSummary ? (
        <OrderSummary
          orderSummary={orderSummary}
          closePopup={() => {
            setOpenOrderSummary(false);
          }}
          popupIsOpen={true}
        />
      ) : null}
    </>
  );
};

export default OrderDetailNew;
