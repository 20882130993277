/** @format */

import "./ZoomSlider.scss";
import React, { useState } from "react";
import { Galleria } from "primereact/galleria";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useEffect } from "react";
import { axiosConstant } from "../../../Helpers/Axios/Constants";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import globalRequest from "../../../prototype/globalRequest";
import { API_ROUTES, STORAGE } from "../../../Enum";
import { currentAuth } from "../../../redux/reducers/auth";
import {
  changeUserProductsCount,
  fetchUserProductsCount,
  userProductsObj,
} from "../../../redux/reducers/userProductsCount";
import { useTranslation } from "react-i18next";
import { setSnackbar } from "../../../redux/reducers/snackbar";
import addDeleteGetLocalStorage from "../../../prototype/addDeleteGetLocalStorage";
import noImage from "../../../assets/images/products/noImage.webp";
const ZoomSliderDetail = ({ productImages = [], productId = 0 }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(50);
  const [isHeart, setHeart] = useState(false);
  const [images, setImages] = useState([]);
  const dispatch = useDispatch();
  const auth = useSelector(currentAuth);
  const productCount = useSelector(userProductsObj);
  let dataBase = addDeleteGetLocalStorage(STORAGE.COUNTRY_DB, {}, "get");

  const toggleHeart = () => {
    setHeart(!isHeart);
  };

  const responsiveOptions = [
    {
      breakpoint: "991px",
      numVisible: 4,
    },
    {
      breakpoint: "767px",
      numVisible: 3,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
    },
  ];

  useEffect(() => {
    if (productImages !== null) {
      if (productImages.length) {
        setImages([]);
        productImages.forEach((obj) => {
          setImages((image) => [
            ...image,
            {
              source: obj?.image_url,
              alt: String(obj?.image_url).split("/")[1],
              title: "",
            },
          ]);
        });
      } else {
        setImages([
          {
            source: "products/no-image.jpg",
            alt: "",
            title: "",
          },
        ]);
      }
    }
  }, [productImages]);

  const itemTemplate = (item) => {
    return (
      <img
        src={item?.source}
        alt={item.alt}
        onClick={() => setVisible(true)}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = noImage;
        }}
      />
    );
  };

  const thumbnailTemplate = (item) => {
    return (
      <img
        src={item?.source}
        alt={item.alt}
        className="thumbnail-img"
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = noImage;
        }}
      />
    );
  };

  // for zoom
  const itemTemplateForZoom = (item) => {
    return (
      <img
        src={item.source}
        alt={item.alt}
        style={getImageStyle()}
        onWheel={handleZoomChange}
        className="zoom-in-trans"
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = noImage;
        }}
      />
    );
  };
  const handleZoomIn = () => {
    setZoomLevel((prevZoomLevel) => prevZoomLevel + 10);
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoomLevel) => prevZoomLevel - 10);
  };

  const handleZoomChange = (event) => {
    const delta = Math.sign(event.deltaY);
    const newZoomLevel = zoomLevel + delta * 10;
    setZoomLevel(Math.min(Math.max(newZoomLevel, 50), 80));
  };

  const getImageStyle = () => {
    return {
      width: `${zoomLevel}%`,
    };
  };

  const [disabledFavorite, setDisabledFavorite] = useState(false); // for disabling favorite button for 1 sec
  const postFavorite = () => {
    if (Object.keys(auth).length == 0) {
      // GET WISHLIST FROM LOCAL STORAGE AND IF PRODUCT IS ALREADY IN WISHLIST THEN REMOVE IT ELSE ADD IT with respect to the database
      let wishlistFromLocal =
        JSON.parse(
          addDeleteGetLocalStorage(STORAGE.GUEST_WHISLIST_LOCAL, {}, "get")
        ) || {};
      if (wishlistFromLocal) {
        const updatedWishlist = { ...wishlistFromLocal };

        if (dataBase === "Iraq" || dataBase === "UAE") {
          const countryWishlist = wishlistFromLocal[dataBase] || [];
          // if product is already in wishlist then remove it else add it
          setDisabledFavorite(true);
          if (countryWishlist.includes(productId)) {
            updatedWishlist[dataBase] = countryWishlist.filter(
              (item) => item !== productId
            );
            dispatch(
              setSnackbar({
                isOpen: true,
                state: "success",
                message: t("ProductRemovedFromWishlist"),
              })
            );
            setTimeout(() => {
              setDisabledFavorite(false);
            }, 2200);
          } else {
            updatedWishlist[dataBase] = [...countryWishlist, productId];
            dispatch(
              setSnackbar({
                isOpen: true,
                state: "success",
                message: t("ProductAddedToWishlistSuccessfully"),
              })
            );
            setTimeout(() => {
              setDisabledFavorite(false);
            }, 2200);
          }
        }

        wishlistFromLocal = updatedWishlist;
      } else {
        wishlistFromLocal = {
          [dataBase]: [productId],
        };
      }
      //update wishlist count in redux
      dispatch(
        changeUserProductsCount({
          ...productCount,
          wishlistcount:
            dataBase == "Iraq"
              ? wishlistFromLocal?.Iraq?.length
              : dataBase == "UAE"
              ? wishlistFromLocal?.UAE?.length
              : 0,
        })
      );
      //update heart icon
      setHeart(wishlistFromLocal[dataBase].includes(productId) ? true : false);

      // Uncomment the following lines if you want to update the local storage
      addDeleteGetLocalStorage(
        STORAGE.GUEST_WHISLIST_LOCAL,
        wishlistFromLocal,
        "add",
        "single"
      );
    } else {
      dispatch(changeLoader(true));
      globalRequest(
        "post",
        API_ROUTES.P_FAVORITE_PRODUCT,
        {
          product_id: productId,
        },
        {},
        true
      )
        .then((res) => {
          let data = res;
          if (data?.ack == 1) {
            dispatch(
              setSnackbar({
                isOpen: true,
                state: "success",
                message: data?.msg,
              })
            );
            setHeart(!isHeart);
          }
        })
        .catch((err) => {})
        .finally(() => {
          dispatch(changeLoader(false));
          dispatch(fetchUserProductsCount());
        });
    }
  };

  const getFavorite = () => {
    dispatch(changeLoader(true));
    globalRequest("get", API_ROUTES.G_FAVORITE_PRODUCT(productId), {}, {}, true)
      .then((res) => {
        let data = res;
        if (data?.ack == 1) {
          setHeart(data?.is_favorite ? true : false);
        }
      })
      .catch((err) => {})
      .finally(() => {
        dispatch(changeLoader(false));
      });
  };

  useEffect(() => {
    if (productId != 0) {
      if (Object.keys(auth).length !== 0) {
        getFavorite();
      } else {
        //GET WISHLIST FROM LOCAL STORAGE AND IF PRODUCT IS ALREADY IN WISHLIST THEN REMOVE IT ELSE ADD IT with respect to dataBase
        let wishlistFromLocal =
          JSON.parse(
            addDeleteGetLocalStorage(STORAGE.GUEST_WHISLIST_LOCAL, {}, "get")
          ) || {};
        if (wishlistFromLocal) {
          if (dataBase === "Iraq" || dataBase === "UAE") {
            const countryWishlist = wishlistFromLocal[dataBase] || [];
            setHeart(countryWishlist.includes(productId) ? true : false);
          }
        }
      }
    }
  }, [productId]);

  return (
    <>
      <div className="zoom-slider-parent">
        <div className="favorite-icon">
          {/* {Object.keys(auth).length == 0 ? null : isHeart ? (
            <i onClick={postFavorite} className="pi pi-heart"></i>
          ) : (
            <i
              className="pi pi-heart-fill"
              onClick={postFavorite}
              style={{ color: "#E8336E" }}
            ></i>
          )} */}

          {isHeart ? (
            <i
              className="pi pi-heart-fill"
              onClick={() => {
                if (disabledFavorite) return;
                postFavorite();
              }}
              style={{ color: "#E8336E" }}
            ></i>
          ) : (
            <i
              onClick={() => {
                if (disabledFavorite) return;
                postFavorite();
              }}
              className="pi pi-heart"
            ></i>
          )}
        </div>
        <Galleria
          value={images}
          responsiveOptions={responsiveOptions}
          showItemNavigators
          numVisible={5}
          item={itemTemplate}
          thumbnailsPosition={"left"}
          thumbnail={thumbnailTemplate}
        />
        <Dialog
          visible={visible}
          style={{ width: "60vw", height: "80vh" }}
          onHide={() => setVisible(false)}
          className="light-box"
        >
          <div className="plus-minut-top">
            <Button className="plus-minus-btn" onClick={handleZoomIn}>
              <i className="pi pi-plus-circle" style={{ fontSize: "2rem" }}></i>
            </Button>
            <Button className="plus-minus-btn" onClick={handleZoomOut}>
              <i
                className="pi pi-minus-circle"
                style={{ fontSize: "2rem" }}
              ></i>
            </Button>
          </div>
          <Galleria
            value={images}
            responsiveOptions={responsiveOptions}
            showItemNavigators
            showItemNavigatorsOnHover
            item={itemTemplateForZoom}
            showThumbnails={false}
          />
        </Dialog>
      </div>
    </>
  );
};

export default ZoomSliderDetail;
