import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
// images
import modalClose from "../assets/images/structure/modal-close.svg";
import shippingIcon from "../assets/images/structure/shipping-icon.svg";
import { validatePassword } from "../Helpers/HelperFunctions";
import { useDispatch } from "react-redux";
import { changeLoader } from "../redux/reducers/loader";
import { setSnackbar } from "../redux/reducers/snackbar";
import { API_ROUTES } from "../Enum";
import globalRequest from "../prototype/globalRequest";

const ChangePassword = ({ handleClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //states
  const [OldPassword, setOldPassword] = useState("");
  const [NewPassword, setNewPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [upperCase, setUpperCase] = useState("");
  const [lowerCase, setLowerCase] = useState("");
  const [number, setNumber] = useState("");
  const [symbol, setSymbol] = useState("");
  const [min8, setMin8] = useState("");
  const [loading, setLoading] = useState(false);
  //errors
  const [OldPasswordErr, setOldPasswordErr] = useState("");
  const [NewPasswordErr, setNewPasswordErr] = useState("");
  const [ConfirmPasswordErr, setConfirmPasswordErr] = useState("");

  ///submit function
  const handleChangePassword = async () => {
    let error = false;
    if (!OldPassword) {
      setOldPasswordErr("OldPasswordIsRequired");
      error = true;
    }
    if (!validatePassword(NewPassword)) {
      setNewPasswordErr("  ");
      error = true;
    }
    if (ConfirmPassword) {
      if (NewPassword !== ConfirmPassword) {
        setConfirmPasswordErr("PasswordAndConfirmPasswordShouldBeSame");
        error = true;
      } else if (!validatePassword(ConfirmPassword)) {
        setConfirmPasswordErr("PasswordShouldBeWithUppercaseLowercaseNumberSymbolandMin8Characters");
        error = true;
      }
    }
    if (!NewPassword) {
      setNewPasswordErr("NewPasswordIsRequired");
      error = true;
    }
    if (!ConfirmPassword) {
      setConfirmPasswordErr("ConfirmPasswordIsRequired");
      error = true;
    }
    if (error) return;
    const data = {
      old_password: OldPassword,
      new_password: NewPassword,
    };

    //call api here and show success msg
    dispatch(changeLoader(true));
    setLoading(true);
    const res = await globalRequest("post", API_ROUTES.CHANG_PASSWORD, data, {}, true);
    if (res.ack == 1) {
      dispatch(changeLoader(false));
      dispatch(
        setSnackbar({
          message: "PasswordChangedSuccessfully",
          isOpen: true,
          state: "success",
        })
      );
      setLoading(false);
      handleClose();
    } else {
      dispatch(changeLoader(false));
      setLoading(false);
      dispatch(
        setSnackbar({
          message: res.msg,
          isOpen: true,
          state: "error",
        })
      );
      setOldPasswordErr(res.msg);
    }
  };

  ///check password strength
  const handleChangeNewPassword = (value) => {
    if (ConfirmPassword) {
      if (value !== ConfirmPassword) {
        setConfirmPasswordErr("PasswordAndConfirmPasswordShouldBeSame");
      } else if (!validatePassword(ConfirmPassword)) {
        setConfirmPasswordErr("PasswordShouldBeWithUppercaseLowercaseNumberSymbolandMin8Characters");
      } else {
        setConfirmPasswordErr("");
      }
    }
    if (!value) setNewPasswordErr("NewPasswordIsRequired");
    setNewPassword(value);
    let hasUpperCase = /[A-Z]/.test(value);
    let hasLowerCase = /[a-z]/.test(value);
    let hasNumber = /\d/.test(value);
    let hasSymbol = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value);
    let hasMin8 = value.length >= 8;

    setUpperCase(hasUpperCase ? "success" : "error");
    setLowerCase(hasLowerCase ? "success" : "error");
    setNumber(hasNumber ? "success" : "error");
    setSymbol(hasSymbol ? "success" : "error");
    setMin8(hasMin8 ? "success" : "error");
    if (value === "") {
      setLowerCase("");
      setUpperCase("");
      setSymbol("");
      setNumber("");
      setMin8("");
    }
  };

  return (
    <>
      <Dialog
        visible={true}
        onHide={() => handleClose()}
        draggable={false}
        resizable={false}
        closable={false}
        showHeader={false}
        className="dialog-407"
      >
        <img src={modalClose} alt="close" className="modal-close" onClick={() => handleClose()} />
        <img src={shippingIcon} alt="shipping" className="icon48" />
        <h3 className="h3">{t("ChangePassword")}</h3>
        <p className="p2 mb-4">{t("Noworriesyoucanchangethepasswordanytime")}</p>
        <div className="input-parent mb-4">
          <span className="p-float-label ">
            <Password
              className={OldPasswordErr ? "p-invalid" : null}
              id="OldPassword"
              value={OldPassword}
              onChange={(e) => {
                setOldPasswordErr("");
                if (!e.target.value.trimStart()) setOldPasswordErr("OldPasswordIsRequired");
                setOldPassword(e.target.value.trimStart());
              }}
              feedback={false}
              toggleMask
            />
            <label htmlFor="OldPassword">
              {t("OldPassword")} <span className="req-star">*</span>
            </label>
          </span>
          {OldPasswordErr && <div className="p-error">{t(OldPasswordErr)}</div>}
        </div>
        <div className="input-parent mb-3">
          <span className="p-float-label ">
            <Password
              className={NewPasswordErr ? "p-invalid" : null}
              id="NewPassword"
              value={NewPassword}
              onChange={(e) => {
                setNewPasswordErr("");
                // setNewPassword(e.target.value.trimStart())
                handleChangeNewPassword(e.target.value.trimStart());
              }}
              feedback={false}
              toggleMask
            />
            <label htmlFor="NewPassword">
              {t("NewPassword")} <span className="req-star">*</span>
            </label>
          </span>
          {NewPasswordErr && NewPasswordErr !== "  " && <div className="p-error">{t(NewPasswordErr)}</div>}
        </div>

        {/* new password strength */}
        <div className="error-parent">
          <p className={`p3 ${upperCase}`}>
            <i className="pi pi-check-circle"></i>
            <span>{t("Uppercase")}</span>
          </p>
          <p className={`p3 ${lowerCase}`}>
            <i className="pi pi-check-circle"></i>
            <span>{t("Lowercase")}</span>
          </p>
          <p className={`p3 ${number}`}>
            <i className="pi pi-check-circle"></i>
            <span>{t("Number")}</span>
          </p>
          <p className={`p3 ${symbol}`}>
            <i className="pi pi-check-circle"></i>
            <span>{t("SpecialCharacter")} </span>
          </p>
          <p className={`p3 ${min8}`}>
            <i className="pi pi-check-circle"></i>
            <span>{t("Min8Characters")}</span>
          </p>
        </div>

        <div className="input-parent">
          <span className="p-float-label">
            <Password
              className={ConfirmPasswordErr ? "p-invalid" : null}
              id="ConfirmPassword"
              value={ConfirmPassword}
              onChange={(e) => {
                setConfirmPasswordErr("");
                if (NewPassword) {
                  if (e.target.value.trimStart() !== NewPassword) {
                    setConfirmPasswordErr("PasswordAndConfirmPasswordShouldBeSame");
                  }
                } else if (!validatePassword(e.target.value.trimStart())) {
                  setConfirmPasswordErr("PasswordShouldBeWithUppercaseLowercaseNumberSymbolandMin8Characters");
                }
                if (!e.target.value.trimStart()) {
                  setConfirmPasswordErr("ConfirmPasswordIsRequired");
                }
                setConfirmPassword(e.target.value.trimStart());
              }}
              feedback={false}
              toggleMask
            />

            <label htmlFor="ConfirmPassword">
              {t("ConfirmPassword")} <span className="req-star">*</span>
            </label>
          </span>
          {ConfirmPasswordErr && <div className="p-error">{t(ConfirmPasswordErr)}</div>}
        </div>
        <Button
          disabled={loading}
          severity="Success"
          size="large"
          className="w-full mt-30"
          label={t("SavePassword")}
          onClick={handleChangePassword}
        />
      </Dialog>
    </>
  );
};

export default ChangePassword;
