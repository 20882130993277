/** @format */

import "./ProductDetailPage.scss";

import { useTranslation } from "react-i18next";
const ProductDetailPage = ({ brand = "", name = "" }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="information-box__row">
        <div className="information-box__row__title">{brand}</div>
        <div className="information-box__row__content">
          <p className="p2">{name}</p>
        </div>
      </div>
    </>
  );
};

export default ProductDetailPage;
