/** @format */
import "./cms.scss";
import FrontToolbar from "../../global-modules/FrontToolbar";
import NewsLatter from "../layout/footer/NewsLatter";
import Footer from "../layout/footer/Footer";
import topBanner from "../../assets/images/structure/static1.png";
import StaticBanner from "./CmsBanner";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../redux/reducers/loader";
import { API_ROUTES } from "../../Enum";
import { setSnackbar } from "../../redux/reducers/snackbar";
import { useCallback, useEffect, useState } from "react";
import globalRequest from "../../prototype/globalRequest";
import { t } from "i18next";

const Home = () => {
  const dispatch = useDispatch();
  const [textContent, setTextContent] = useState("");
  const lang = localStorage.getItem("i18nextLng");

  const getTextContent = useCallback(() => {
    dispatch(changeLoader(true));
    const params = {
      page: "delivery_policy",
    };
    globalRequest("get", API_ROUTES.GET_CMS_POLICY, {}, { params: params })
      .then((data) => {
        dispatch(changeLoader(false));
        if (data?.ack == 1) {
          if (lang == "ar") {
            setTextContent(data?.data?.description_ar);
          } else {
            setTextContent(data?.data?.description_en);
          }
        } else {
          setTextContent("");
        }
      })
      .catch((error) => {
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            isOpen: true,
            state: "error",
            message: t("SomethingWentWrong"),
          })
        );
      });
  });

  useEffect(() => {
    getTextContent();
  }, [lang]);
  return (
    <>
      <FrontToolbar />
      <div className="container pt-4">
        <StaticBanner
          imgBanner={topBanner}
          title="Delivery"
          paragraph="Buy wireless Route from TP-Link, D-Link, Linksys, etc. online from faitein.com"
        />
        <div className="static-content">
          {textContent ? (
            <div
              className="term-cond-sec2"
              dangerouslySetInnerHTML={{
                __html: textContent,
              }}
            ></div>
          ) : null}
        </div>
      </div>
      <NewsLatter />
      <Footer />
    </>
  );
};

export default Home;
