import React from "react";
import { useTranslation } from "react-i18next";

export const OrderTracking = ({
  orderStatus,
  deliveryType,
  orderTrackingDates,
}) => {
  // console.log(orderStatus, deliveryType, orderTrackingDates);
  const { t } = useTranslation();
  return (
    <>
      {/* if order status ==>confirmed */}
      {orderStatus === "confirmed" && (
        <>
          {deliveryType === "delivery" ? (
            <>
              <div className="order-tracking-list finish">
                <small className="dot-circle"></small>
                <span className="p2">{t("confirmed")}</span>
                <span className="p3">{orderTrackingDates?.confirmed}</span>
              </div>
              <div className="order-tracking-list">
                <small className="dot-circle"></small>
                <span className="p2">{t("shipped")}</span>
              </div>
              <div className="order-tracking-list">
                <small className="dot-circle"></small>
                <span className="p2">{t("delivered")}</span>
              </div>
            </>
          ) : deliveryType === "pickup" ? (
            <>
              <div className="order-tracking-list finish">
                <small className="dot-circle"></small>
                <span className="p2">{t("confirmed")}</span>
                <span className="p3">{orderTrackingDates?.confirmed}</span>
              </div>
              <div className="order-tracking-list">
                <small className="dot-circle"></small>
                <span className="p2">{t("pickedup")}</span>
              </div>
            </>
          ) : null}
        </>
      )}

      {/* if order status ==>shipped */}
      {orderStatus === "shipped" && (
        <>
          <div className="order-tracking-list finish success">
            <small className="dot-circle"></small>
            <span className="p2">{t("confirmed")}</span>
            <span className="p3">{orderTrackingDates?.confirmed}</span>
          </div>
          <div className="order-tracking-list finish">
            <small className="dot-circle"></small>
            <span className="p2">{t("shipped")}</span>
            <span className="p3">{orderTrackingDates?.shipped}</span>
          </div>
          <div className="order-tracking-list">
            <small className="dot-circle"></small>
            <span className="p2">{t("delivered")}</span>
          </div>
        </>
      )}

      {/* if order status ==>delivered */}
      {orderStatus === "delivered" && (
        <>
          <div className="order-tracking-list finish success">
            <small className="dot-circle"></small>
            <span className="p2">{t("confirmed")}</span>
            <span className="p3">{orderTrackingDates?.confirmed}</span>
          </div>
          <div className="order-tracking-list finish success">
            <small className="dot-circle"></small>
            <span className="p2">{t("shipped")}</span>
            <span className="p3">{orderTrackingDates?.shipped}</span>
          </div>
          <div className="order-tracking-list finish">
            <small className="dot-circle"></small>
            <span className="p2">{t("delivered")}</span>
            <span className="p3">{orderTrackingDates?.delivered}</span>
          </div>
        </>
      )}

      {/* if order status ==>canceled */}
      {orderStatus === "canceled" && (
        <>
          <div className="order-tracking-list finish success">
            <small className="dot-circle"></small>
            <span className="p2">{t("confirmed")}</span>
            <span className="p3">{orderTrackingDates?.confirmed}</span>
          </div>
          <div className="order-tracking-list finish success">
            <small className="dot-circle"></small>
            <span className="p2">{t("cancelled")}</span>
            <span className="p3">{orderTrackingDates?.cancelled}</span>
          </div>
        </>
      )}

      {/* if order status ==>returned */}
      {orderStatus === "returned" && (
        <>
          {deliveryType === "delivery" ? (
            <>
              <div className="order-tracking-list finish success">
                <small className="dot-circle"></small>
                <span className="p2">{t("confirmed")}</span>
                <span className="p3">{orderTrackingDates?.confirmed}</span>
              </div>
              <div className="order-tracking-list finish success">
                <small className="dot-circle"></small>
                <span className="p2">{t("shipped")}</span>
                <span className="p3">{orderTrackingDates?.shipped}</span>
              </div>
              <div className="order-tracking-list finish success">
                <small className="dot-circle"></small>
                <span className="p2">{t("delivered")}</span>
                <span className="p3">{orderTrackingDates?.delivered}</span>
              </div>
              <div className="order-tracking-list finish success">
                <small className="dot-circle"></small>
                <span className="p2">{t("returned")}</span>
                <span className="p3">{orderTrackingDates?.returned}</span>
              </div>
            </>
          ) : deliveryType === "pickup" ? (
            <>
              <div className="order-tracking-list finish success">
                <small className="dot-circle"></small>
                <span className="p2">{t("confirmed")}</span>
                <span className="p3">{orderTrackingDates?.confirmed}</span>
              </div>
              <div className="order-tracking-list finish success">
                <small className="dot-circle"></small>
                <span className="p2">{t("pickedup")}</span>
                <span className="p3">{orderTrackingDates?.pickup}</span>
              </div>
              <div className="order-tracking-list finish success">
                <small className="dot-circle"></small>
                <span className="p2">{t("returned")}</span>
                <span className="p3">{orderTrackingDates?.returned}</span>
              </div>
            </>
          ) : null}
        </>
      )}

      {/* if order status ==>pickup*/}
      {orderStatus === "pickup" && (
        <>
          <div className="order-tracking-list finish success">
            <small className="dot-circle"></small>
            <span className="p2">{t("confirmed")}</span>
            <span className="p3">{orderTrackingDates?.confirmed}</span>
          </div>
          <div className="order-tracking-list finish success">
            <small className="dot-circle"></small>
            <span className="p2">{t("pickedup")}</span>
            <span className="p3">{orderTrackingDates?.pickup}</span>
          </div>
        </>
      )}

      {/* if order status ==> return refund*/}
      {/* <div className="order-tracking-list finish success">
        <small className="dot-circle"></small>
        <span className="p2">{t("confirmed")}</span>
        <span className="p3">{orderTrackingDates?.confirmed}</span>
      </div>
      <div className="order-tracking-list finish success">
        <small className="dot-circle"></small>
        <span className="p2">{t("shipped")}</span>
        <span className="p3">{orderTrackingDates?.shipped}</span>
      </div>
      <div className="order-tracking-list finish success">
        <small className="dot-circle"></small>
        <span className="p2">{t("delivered")}</span>
        <span className="p3">{orderTrackingDates?.delivered}</span>
      </div>
      <div className="order-tracking-list finish success">
        <small className="dot-circle"></small>
        <span className="p2">{t("returnRequested")}</span>
        <span className="p3">{orderTrackingDates?.returnRequested}</span>
      </div>
      <div className="order-tracking-list finish success">
        <small className="dot-circle"></small>
        <span className="p2">{t("productPickedUp")}</span>
        <span className="p3">{orderTrackingDates?.productPickedUp}</span>
      </div>
      <div className="order-tracking-list finish success">
        <small className="dot-circle"></small>
        <span className="p2">{t("refunded")}</span>
        <span className="p3">{orderTrackingDates?.refunded}</span>
      </div> */}
    </>
  );
};

export const ReturnOrderTracking = ({ trackingLogs }) => {
  const { t } = useTranslation();
  //convert time stamps
  const convertTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const period = hour >= 12 ? "PM" : "AM";
    const formattedHour = hour % 12 || 12;

    const formattedDate = `${day} ${month} ${year} - ${formattedHour}:${minute
      .toString()
      .padStart(2, "0")} ${period}`;

    // return formattedDate;
    return (
      <span style={{ direction: "ltr", display: "inline-block" }}>
        <span>{`${day} ${t(month)} ${year}`}</span> -
        <span>{`${formattedHour}:${minute
          .toString()
          .padStart(2, "0")} ${period}`}</span>
      </span>
    );
  };
  return (
    <>
      {trackingLogs?.map((item, index) => {
        return (
          <div className="order-tracking-list finish success" key={index}>
            <small className="dot-circle"></small>
            <span className="p2 capitalize-first-letter">
              {item?.status === "return_requested" && t("returned")}
              {item?.status === "picked_up" && t("pickedup")}
              {item?.status === "refunded" && t("refunded")}
              {item?.status === "return_collected" && t("refunded")}
            </span>
            <span className="p3">{convertTimestamp(item?.createdAt)}</span>
          </div>
        );
      })}
    </>
  );
};
