import React, { useState } from "react";
import "./BundleDetailPage.scss";
import FrontToolbar from "../../../global-modules/FrontToolbar";
import Footer from "../../layout/footer/Footer";
import Breadcrumb from "../../../global-modules/Breadcrumb";
import { Divider } from "primereact/divider";
import { useTranslation } from "react-i18next";
import noImage from "../../../assets/images/products/noImage.webp";
import shareIcon from "../../../assets/images/structure/share-icon.svg";
import { Button } from "primereact/button";
import { changeLoader, currentLoader } from "../../../redux/reducers/loader";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { API_ROUTES, STORAGE } from "../../../Enum";
import globalRequest from "../../../prototype/globalRequest";
import addDeleteGetLocalStorage from "../../../prototype/addDeleteGetLocalStorage";
import { useCallback } from "react";
import { useEffect } from "react";
import ZoomSliderDetail from "../ZoomSlider/ZoomSliderDetail";
import { setSnackbar } from "../../../redux/reducers/snackbar";
import { fetchUserProductsCount } from "../../../redux/reducers/userProductsCount";
import facebookIcon from "../../../assets/images/structure/facebook-icon.svg";
import twitterIcon from "../../../assets/images/structure/twitter-icon.svg";
import instagramIcon from "../../../assets/images/structure/instagram-icon.svg";
import pintrestIcon from "../../../assets/images/structure/pintrest.svg";
import { currencyCoversionObject } from "../../../redux/reducers/currencyConversion";
import {
  convertCurrency,
  returnRandomGuest,
} from "../../../Helpers/HelperFunctions";
import { Base64 } from "js-base64";
import NotifyMe from "../../../DialogBox/NotifyMe";
import { Skeleton } from "primereact/skeleton";
const BundleDetailPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [IncrDecr, setIncrDecr] = useState(1);
  let token = JSON.parse(
    addDeleteGetLocalStorage(STORAGE.AL_MAHARA_USER, {}, "get")
  )?.token;
  let currency_am = addDeleteGetLocalStorage(STORAGE.CURRENCY, {}, "get");
  const country =
    addDeleteGetLocalStorage(STORAGE.COUNTRY_DB, {}, "get") || "Iraq";
  const location = useParams();
  let productSlug = location?.slug;
  let IdOFproduct = location?.id;
  let locationName = window.location.href;
  const conversionRate = useSelector(currencyCoversionObject);

  //states
  const [productImage, setProductImage] = useState([]);
  const [productId, setProductId] = useState(null);
  const [productData, setProductData] = useState({
    product_images: [],
    id: 0,
    title: "",
    product_specification: [],
    stock: false,
    selling_price: 0,
    discount_price: 0,
  });
  const [isClamped, setIsClamped] = useState(true);
  const [isClamped1, setIsClamped1] = useState(true);
  const [bundleProducts, setBundleProducts] = useState([]);
  const [socialIcons, setSocialIcons] = useState(false);
  const [isInStock, setIsInStock] = useState(false);
  const [notifyMeBtn, setNotifyMeBtn] = useState(false);
  const [notifyMeProductId, setNotifyMeProductId] = useState(null);
  const loader = useSelector(currentLoader);

  const socialIconClick = () => {
    setSocialIcons(!socialIcons);
  };
  const toggleTextClamp = () => {
    setIsClamped(!isClamped);
  };
  const toggleTextClamp1 = () => {
    setIsClamped1(!isClamped1);
  };
  const routerProductDetail = (slug, id) => {
    navigate(
      `/product-detail-page/p/${slug}/${Base64.encode(encodeURIComponent(id))}`
    );
  };

  const handleIncrement = () => {
    setIncrDecr(IncrDecr + 1);
  };
  const handleDecrement = () => {
    setIncrDecr(IncrDecr <= 1 ? 1 : IncrDecr - 1);
  };

  const productApi = useCallback(() => {
    let params = {};
    let localGuestId = addDeleteGetLocalStorage(STORAGE.GUEST_ID, {}, "get");
    if (!token && localGuestId) {
      params = { ...params, guestid: localGuestId };
    }
    dispatch(changeLoader(true));
    globalRequest(
      "get",
      API_ROUTES.BUNDLE_DETAIL(`${productSlug}/${IdOFproduct}`),
      {},
      { params: params },
      token ? true : false
    )
      .then((data) => {
        if (data?.ack == 1) {
          let bundleDetail = data?.data?.bundleDetails;
          let bundleProducts = data?.data?.bundleProducts;
          setBundleProducts(bundleProducts);
          setProductData({
            product_images: bundleDetail?.product_images,
            id: bundleDetail?.id,
            long_description: bundleDetail?.long_description,
            totalProduct: bundleProducts?.length,
            title: bundleDetail?.title,
            short_description: bundleDetail?.short_description,
            discount_price: bundleDetail?.discount_price,
            selling_price: bundleDetail?.selling_price,
            product_type: bundleDetail?.product_type,
            is_in_cart: bundleDetail?.is_in_cart,
            stock: bundleDetail?.stock || true,
          });
          setIsInStock(data?.data?.checkstock);
        }
      })
      .catch((err) => {
        navigate("/");
      })
      .finally(() => {
        dispatch(changeLoader(false));
      });
  });

  //notify me function
  const notifyMeFunction = useCallback((id) => {
    setNotifyMeProductId(id);
    setNotifyMeBtn(true);
  });

  useEffect(() => {
    productApi();
  }, []);

  ///add to cart function
  const addToCart = useCallback(async (item, goToCart = null) => {
    let localGuestId = addDeleteGetLocalStorage(STORAGE.GUEST_ID, {}, "get");
    let guestId = localGuestId ? localGuestId : returnRandomGuest();
    let data = {
      productId: item?.id,
      quantity: +IncrDecr,
      price: item?.discount_price,
      productType: item?.product_type,
    };
    if (!token && guestId) {
      data = { ...data, guestid: token ? "" : guestId.trim() };
    }
    dispatch(changeLoader(true));
    try {
      let res = await globalRequest(
        "post",
        API_ROUTES.ADD_TO_CART,
        data,
        {},
        token ? true : false
      );
      if (res?.ack == 1) {
        dispatch(changeLoader(false));
        addDeleteGetLocalStorage(STORAGE.GUEST_ID, guestId, "add", "single");
        dispatch(
          setSnackbar({
            isOpen: true,
            state: "success",
            message: t("AddedToCartSuccessfully"),
          })
        );
        let productTemp = { ...productData };
        productTemp.is_in_cart = 1;
        setProductData(productTemp);
        dispatch(fetchUserProductsCount());
        if (goToCart) {
          navigate("/cart");
        }
      } else {
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            isOpen: true,
            state: "error",
            message: t("SomethingWentWrong"),
          })
        );
      }
    } catch (err) {
      dispatch(changeLoader(false));
      dispatch(
        setSnackbar({
          isOpen: true,
          state: "error",
          message: err?.response?.data?.msg || t("SomethingWentWrong"),
        })
      );
    }
  });

  useEffect(() => {
    if (productData?.product_images !== undefined) {
      setProductImage(productData?.product_images);
    }
    if (productData?.id) {
      setProductId(productData?.id);
    }
  }, [productData]);

  const handleChangeInputQuantity = (val) => {
    if (val > 0) {
      if (productData?.stock?.stock < val) {
        dispatch(
          setSnackbar({
            isOpen: true,
            state: "error",
            message:
              t("Only") +
              " " +
              productData?.stock?.stock +
              " " +
              t("ItemsAreAvailable"),
          })
        );
      } else {
        setIncrDecr(val);
      }
    }
  };

  return (
    <>
      <FrontToolbar />
      <div className="container">
        <Breadcrumb
          title={"Bundle"}
          li={[{ title: productData?.title, path: "Bundle" }]}
        />
        <section className="bundle-detail-section1">
          {loader && !productData?.title ?
          <div className="grid">
          <div className="col-12 sm:col-12 md:col-12 lg:col-7">
            <div className="flex flex-column align-items-center gap-3 py-5">
              <Skeleton className="w20rem border-round h-29rem" />
            </div>
          </div>
          <div className="col-12 sm:col-12 md:col-12 lg:col-5">
          <div className="flex flex-column align-items-center gap-3 py-5">
                  <Skeleton className="w-full border-round h-2rem" />
                  <Skeleton className="w-full border-round h-12rem" />
                  <Skeleton className="w-full border-round h-2rem" />
                  <Skeleton className="w-full border-round h-1rem" />
                  <Skeleton className="w-full border-round h-3rem" />
              </div>
          </div>
        </div>: 
          <div className="grid">
            <div className="col-12 sm:col-12 md:col-12 lg:col-7">
              <div className="mb-5">
                {/* <ZoomSlider /> */}
                <ZoomSliderDetail
                  productImages={productImage}
                  productId={productId}
                />
              </div>

              <div className="description-wrap mb-5">
                <h3 className="h3 mb-3">{t("Description")}</h3>
                <div
                  // className={isClamped ? "line-ellips4" : ""}
                  dangerouslySetInnerHTML={{
                    __html: isClamped
                      ? productData?.long_description?.slice(0, 200) +
                        `${
                          productData?.long_description?.length > 200
                            ? "..."
                            : ""
                        }`
                      : productData?.long_description,
                  }}
                ></div>
                {String(productData?.long_description).length > 200 ? (
                  <span className="read-more" onClick={toggleTextClamp}>
                    {isClamped ? t("ReadMore") : t("ReadLess")}
                  </span>
                ) : null}
              </div>

              <div className="description-wrap">
                <h3 className="h3 mb-3">
                  {t("ThisBundlePackContains")}{" "}
                  {productData?.totalProduct ? productData?.totalProduct : 0}{" "}
                  {productData?.totalProduct < 2 ? t("Item") : t("Items")}{" "}
                </h3>
                <p className="p2">
                  <span className="wraplist mb-2">{t("BundleListPrice")}</span>{" "}
                  : {currency_am}{" "}
                  {+convertCurrency(productData?.selling_price, conversionRate)}
                </p>
                <p className="p2">
                  <span className="wraplist mb-2">{t("ComboPrice")}</span> :{" "}
                  {currency_am}{" "}
                  {
                    +convertCurrency(
                      productData?.discount_price,
                      conversionRate
                    )
                  }
                </p>
                <p className="p2">
                  <span className="wraplist mb-2">{t("YouSave")}</span> :{" "}
                  {currency_am}{" "}
                  {
                    +convertCurrency(
                      productData?.selling_price - productData?.discount_price,
                      conversionRate
                    )
                  }
                </p>
              </div>
            </div>
            <div className="col-12 sm:col-12 md:col-12 lg:col-5">
              <h3 className="h3 mb-3">{productData?.title}</h3>
              <div className="share-compare-row">
                <p className="p2" onClick={socialIconClick}>
                  <img src={shareIcon} alt="share" className="icon24" />
                  <span>{t("Share")}</span>
                </p>
                {socialIcons && (
                  <div className="socail-box-parent" onClick={socialIconClick}>
                    <img
                      src={facebookIcon}
                      alt="facebook"
                      onClick={() => {
                        let url = `https://www.facebook.com/sharer/sharer.php?u=${locationName}`;
                        window.open(url, "_blank");
                      }}
                      className="social-icon"
                    />
                    <img
                      src={twitterIcon}
                      onClick={() => {
                        let url = `http://www.twitter.com/share?url=${locationName}`;
                        window.open(url, "_blank");
                      }}
                      alt="twitter"
                      className="social-icon"
                    />
                    <img
                      src={instagramIcon}
                      onClick={() => {
                        // let url = `https://www.instagram.com/?url=${locationName}`;
                        // window.open(url, "_blank");
                        const instagramShareUrl = `https://www.instagram.com/create/story/?url=${locationName}&caption=${productData?.title}`;
                        window.open(instagramShareUrl, "_blank");
                      }}
                      alt="instagram"
                      className="social-icon"
                    />
                    <img
                      src={pintrestIcon}
                      onClick={() => {
                        // let url = `http://pinterest.com/pin/create/button/?url=${locationName}`;
                        // window.open(url, "_blank");
                        const pinterestShareUrl = `https://www.pinterest.com/pin/create/button/?url=${locationName}&media=${productData?.product_images[0]?.image_url}&description=${productData?.title}`;
                        window.open(pinterestShareUrl, "_blank");
                      }}
                      style={{ height: "25px" }}
                      alt="youtube"
                      className="social-icon"
                    />
                  </div>
                )}
              </div>

              <p className="p2 sort-description">
                <span>
                  <div>
                    {isClamped1
                      ? productData?.short_description?.slice(0, 140) +
                        `${
                          productData?.short_description?.length > 140
                            ? "..."
                            : ""
                        }`
                      : productData?.short_description}
                  </div>
                </span>{" "}
                {String(productData?.short_description).length > 140 ? (
                  <span className="read-more" onClick={toggleTextClamp1}>
                    {isClamped1 ? t("ReadMore") : t("ReadLess")}
                  </span>
                ) : null}
              </p>

              {isInStock ? (
                <p className="p2 in-stock">
                  <i className="pi pi-check"></i> <span>{t("InStock")}</span>
                </p>
              ) : (
                <p className="p2 outOff-stock">
                  <i className="pi pi-check"></i> <span>{t("OutofStock")}</span>
                </p>
              )}

              <div className="price-row">
                <h6 className="h6">
                  {currency_am +
                    " " +
                    convertCurrency(
                      productData?.discount_price,
                      conversionRate
                    )}
                </h6>

                {productData?.discount_price !== productData?.selling_price ? (
                  <p
                    className={`p3 ${
                      productData?.discount_price !== productData?.selling_price
                        ? "cross"
                        : ""
                    }`}
                  >
                    {currency_am +
                      " " +
                      convertCurrency(
                        productData?.selling_price,
                        conversionRate
                      )}
                  </p>
                ) : null}
              </div>
              {country === "UAE" && (
                <p
                  className="p3 mt-1"
                  style={{
                    color: "#f9b000",
                    fontFamily: "OpenSans-Medium",
                  }}
                >
                  {t("Exclusiveoftax")}
                </p>
              )}
              <div className="quantity-row">
                {isInStock && productData?.is_in_cart == 0 ? (
                  <>
                    <div className="quantity-box">
                      {/* <input type="number" value={IncrDecr} /> */}
                      <input
                        type="number"
                        className="input"
                        min={1}
                        max={100}
                        value={IncrDecr}
                        onChange={(e) => {
                          let num = +e.target.value;
                          handleChangeInputQuantity(num);
                        }}
                      />
                      <div className="quantity-btn">
                        <i
                          className="pi pi-angle-up"
                          onClick={handleIncrement}
                        ></i>
                        <i
                          className="pi pi-angle-down"
                          onClick={handleDecrement}
                        ></i>
                      </div>
                    </div>
                    <Button
                      size="large"
                      severity="Success"
                      label={t("AddtoCart")}
                      onClick={() => addToCart(productData)}
                    />
                    <Button
                      onClick={() => {
                        addToCart(productData, "goToCart");
                      }}
                      size="large"
                      className="w-full sm:mx-2"
                      severity="danger"
                      outlined
                      label={t("BuyitNow")}
                    />
                  </>
                ) : isInStock && productData?.is_in_cart == 1 ? (
                  <Button
                    onClick={() => {
                      navigate("/cart");
                    }}
                    size="large"
                    className="w-full"
                    severity="Success"
                    label={t("GoToCart")}
                  />
                ) : (
                  <Button
                    size="large"
                    className="w-full"
                    severity="Success"
                    label={t("NotifyMe")}
                    onClick={() => {
                      notifyMeFunction(productData?.id);
                    }}
                  />
                )}
              </div>

              <div className="product-features">
                <p className="p2">
                  <i className="pi pi-check"></i>{" "}
                  <span>{t("ClickwithconfidenceHasslefreereturns")}</span>
                </p>
                <p className="p2">
                  <i className="pi pi-check"></i>{" "}
                  <span>{t("Unparalleledqualityunmatchedperformance")}</span>
                </p>
                <p className="p2">
                  <i className="pi pi-check"></i>{" "}
                  <span>{t("Highendexpertiseatyourfingertips")}</span>
                </p>
                <p className="p2">
                  <i className="pi pi-check"></i>{" "}
                  <span>
                    {" "}
                    <b>{t("100%SatisfactionGuaranteed")}</b>
                  </span>
                </p>
              </div>
            </div>
            <div className="col-12 sm:col-12 md:col-12 lg:col-12">
              <Divider />
            </div>
            <div className="col-12 sm:col-12 md:col-12 lg:col-7">
              {bundleProducts?.length > 0 &&
                bundleProducts?.map((item, index) => {
                  let featuredImg;
                  let arr = item?.product?.product_images || [];
                  arr.map((ele, i) => {
                    if (ele.is_featured == 1) {
                      featuredImg = ele.image_url;
                    }
                  });
                  return (
                    <div className="flex align-items-start mb-5" key={index}>
                      <img
                        src={featuredImg}
                        alt="product image"
                        className="product-image"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = noImage;
                        }}
                      />
                      <div className="content-block">
                        <p className="p2 p2-bold mb-3">
                          {item?.product?.title}
                        </p>
                        <p className="p2 p2-bold mb-3">
                          {currency_am +
                            " " +
                            convertCurrency(
                              item?.product?.discount_price,
                              conversionRate
                            )}
                        </p>
                        <ul className="pl-4">
                          {item?.product?.product_specification.length > 0 &&
                            item?.product?.product_specification?.map(
                              (spec, inde) => {
                                if (inde < 5)
                                  return (
                                    <li key={inde} className="p2 mb-2">
                                      <span className="wraplist p2">
                                        {spec?.name} :{" "}
                                      </span>
                                      {
                                        spec?.product_specification_value
                                          ?.specification_value
                                      }
                                      {inde ==
                                        item?.product?.product_specification
                                          ?.length -
                                          1 || inde == 4 ? (
                                        <span
                                          className="read-more"
                                          style={{ color: "#EFB34C" }}
                                          onClick={() => {
                                            routerProductDetail(
                                              item?.product?.slug,
                                              item?.product?.id
                                            );
                                          }}
                                        >
                                          ... {t("ReadMore")}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </li>
                                  );
                              }
                            )}
                          {item?.product?.product_specification.length == 0 && (
                            <li>
                              <span
                                className="read-more"
                                onClick={() => {
                                  routerProductDetail(
                                    item?.product?.slug,
                                    item?.product?.id
                                  );
                                }}
                              >
                                ... {t("ReadMore")}
                              </span>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        }
        </section>
      </div>
      <Footer />
      {notifyMeBtn && (
        <NotifyMe
          handleClose={() => setNotifyMeBtn(false)}
          productId={notifyMeProductId}
        />
      )}
    </>
  );
};

export default BundleDetailPage;
