import { createSlice } from "@reduxjs/toolkit";

export const categoryList = createSlice({
  name: "categoryList",
  initialState: {
    value: {
       categories: [],
       selectedCategory: null,
       selectedSubCategoryArrayOfIds: [],
       priceRange:[0,300000],
       productType: "",
       sortBy: "recommended",
    },
  },
  reducers: {
    setCategoryList: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setCategoryList } = categoryList.actions;
export const categoryListObject = (state) => state.categoryList.value;
export default categoryList.reducer;


