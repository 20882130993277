/** @format */
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "libphonenumber-js";
import "./cms.scss";
import FrontToolbar from "../../global-modules/FrontToolbar";
import NewsLatter from "../layout/footer/NewsLatter";
import Footer from "../layout/footer/Footer";
import topBanner from "../../assets/images/structure/contact-static.png";
import mailIcon from "../../assets/images/structure/mail-icon.svg";
import callIcon from "../../assets/images/structure/call-icon.svg";
import facebookIcon from "../../assets/images/structure/facebook-icon.svg";
import twitterIcon from "../../assets/images/structure/twitter-icon.svg";
import instagramIcon from "../../assets/images/structure/instagram-icon.svg";
import youtubeIcon from "../../assets/images/structure/youtube-icon.svg";
import StaticBanner from "./CmsBanner";
import { useState } from "react";
import { removeHyphens, validateEmail } from "../../Helpers/HelperFunctions";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../redux/reducers/loader";
import globalRequest from "../../prototype/globalRequest";
import { API_ROUTES } from "../../Enum";
import { setSnackbar } from "../../redux/reducers/snackbar";
import { socialMediaLinksObject } from "../../redux/reducers/socialMediaLink";
import { Link } from "react-router-dom";
import { contactInfoObject } from "../../redux/reducers/contactInfo";
// images

const Home = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const allLinks = useSelector(socialMediaLinksObject);
  const contactInfo = useSelector(contactInfoObject);
  let emails = contactInfo?.emails;
  let mobiles = contactInfo?.mobile_numbers;

  //states
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [name, setname] = useState("");
  const [message, setMessage] = useState("");
  //errors
  const [nameErr, setNameErr] = useState("");
  const [mobileErr, setMobileErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [messageErr, setMessageErr] = useState("");

  ///manage phone number input
  const handlePhoneNumberChange = (value) => {
    setMobileErr("");
    if (!value) {
      setMobile("");
      return;
    }
    if (!isValidPhoneNumber(value)) {
      setMobileErr("EnterValidMobileNumber");
    }
    setMobile(value);
  };

  const createStateEmpty = () => {
    setMobile("");
    setEmail("");
    setname("");
    setMessage("");
    setNameErr("");
    setMobileErr("");
    setEmailErr("");
    setMessageErr("");
  };

  //onSubmit
  const onSubmitForm = () => {
    let error = false;
    if (name.trimStart()?.length > 200) {
      setNameErr("Max200Characters");
      error = true;
    }
    if (!name.trimStart()) {
      setNameErr("NameIsRequired");
      error = true;
    }
    if (!validateEmail(email)) {
      setEmailErr("EnterValidEmailAddress");
      error = true;
    }
    if (!email.trimStart()) {
      setEmailErr("EmailIsRequired");
      error = true;
    }
    if (!mobile.trimStart()) {
      setMobileErr("MobileNumberIsRequired");
      error = true;
    }
    if (mobile) {
      if (!isValidPhoneNumber(removeHyphens(mobile))) {
        setMobileErr("EnterValidMobileNumber");
        error = true;
      }
    }
    if (!message.trimStart()) {
      setMessageErr("MessageIsRequired");
      error = true;
    }
    if (message) {
      if (message.trimStart().length > 2000) {
        setMessageErr("Max2000Characters");
        error = true;
      }
    }
    if (error) return;
    let temp_mo = mobile.substring(1);
    const data = {
      full_name: name,
      email: email,
      phone: temp_mo ? removeHyphens(temp_mo) : "",
      message: message,
    };
    dispatch(changeLoader(true));
    globalRequest("post", API_ROUTES.SEND_CONATCT_US_REQUEST, data, {})
      .then((res) => {
        dispatch(changeLoader(false));
        if (res?.ack == 1) {
          dispatch(
            setSnackbar({
              message: "YourRequestHasBeenSent",
              isOpen: true,
              state: "success",
            })
          );
          createStateEmpty();
        } else {
          dispatch(
            setSnackbar({
              message: "SomethingWentWrong",
              isOpen: true,
              state: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            message: "SomethingWentWrong",
            isOpen: true,
            state: "error",
          })
        );
      });
  };

  return (
    <>
      <FrontToolbar />
      <div className="container pt-4">
        <StaticBanner
          imgBanner={topBanner}
          title="Contact"
          paragraph="Buy wireless Route from TP-Link, D-Link, Linksys, etc. online from faitein.com"
        />

        <div className="contact-box">
          <div className="contact-left">
            <div className="relative">
              <h2 className="h2 mb-3">{t("ContactInformation")}</h2>
              <p className="p2">{t("contactUsPageDetail")}</p>
              {/* <p className="p2">{contactInfo?.company_details?.value}</p> */}
              {/* {mobiles?.length > 0 &&
                mobiles.map((item, index) => {
                  if (!item?.value) return null;
                  return (
                    <div key={index} className="footer-info">
                      <img src={callIcon} alt="call" className="icon24" />
                      <span className="info-content">{item?.value}</span>
                    </div>
                  );
                })} */}
              {/* {emails?.length > 0 &&
                emails.map((item, index) => {
                  if (!item?.value) return null;
                  return (
                    <div key={index} className="footer-info">
                      <img src={mailIcon} alt="mail" className="icon24" />
                      <span className="info-content">{item?.value}</span>
                    </div>
                  );
                })} */}
            </div>
            <div className="social-bottom mt-auto">
              <h6 className="h6 mb-3">{t("FollowUs")}</h6>
              <div className="relative">
                <Link
                  target="_blank"
                  to={allLinks?.facebook}
                  className="social-icon"
                >
                  <img
                    src={facebookIcon}
                    alt="facebook"
                    className="social-icon"
                  />
                </Link>
                <Link
                  target="_blank"
                  to={allLinks?.twitter}
                  className="social-icon"
                >
                  <img
                    src={twitterIcon}
                    alt="twitter"
                    className="social-icon"
                  />
                </Link>
                <Link
                  target="_blank"
                  to={allLinks?.insta}
                  className="social-icon"
                >
                  <img
                    src={instagramIcon}
                    alt="instagram"
                    className="social-icon"
                  />
                </Link>
                <Link
                  target="_blank"
                  to={allLinks?.youtube}
                  className="social-icon"
                >
                  <img
                    src={youtubeIcon}
                    alt="youtube"
                    className="social-icon"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="contact-right">
            <form>
              <div className="mb-4">
                <span className="p-float-label">
                  <InputText
                    className={nameErr ? "p-invalid" : null}
                    value={name}
                    onChange={(e) => {
                      setNameErr("");
                      if (!e.target.value.trimStart().length) {
                        setNameErr("NameIsRequired");
                      }
                      if (e.target.value.trimStart().length > 200) {
                        setNameErr("Max200Characters");
                      }
                      setname(e.target.value.trimStart());
                    }}
                    id="name"
                    placeholder={t("name")}
                  />
                  <label htmlFor="name">
                    {t("name")} <span className="req-star">*</span>
                  </label>
                </span>
                {nameErr && <div className="p-error">{t(nameErr)}</div>}
              </div>
              <div className="mb-4">
                <span className="p-float-label">
                  <InputText
                    className={emailErr ? "p-invalid" : null}
                    value={email}
                    onChange={(e) => {
                      setEmailErr("");
                      if (!validateEmail(e.target.value.trimStart())) {
                        setEmailErr("EnterValidEmailAddress");
                      }
                      if (!e.target.value.trimStart().length) {
                        setEmailErr("EmailIsRequired");
                      }
                      setEmail(e.target.value.trimStart());
                    }}
                    id="Email"
                    placeholder={t("Email")}
                  />
                  <label htmlFor="OTP"></label>
                  <label htmlFor="Email">
                    {t("Email")} <span className="req-star">*</span>
                  </label>
                </span>
                {emailErr && <div className="p-error">{t(emailErr)}</div>}
              </div>
              <div className="mb-4">
                <div className="input-parent mobile-input-parent">
                  <PhoneInput
                    style={{ border: mobileErr ? "1px solid red" : null }}
                    international
                    defaultCountry="AE"
                    value={mobile}
                    onChange={handlePhoneNumberChange}
                    placeholder={t("MobileNumber")}
                  />
                  <label
                    className={
                      mobile ? "mobile-input label-top" : "mobile-input"
                    }
                  >
                    {t("MobileNumber")}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  {/**add this class .label-top */}
                </div>
                {mobileErr && <div className="p-error">{t(mobileErr)}</div>}
              </div>
              <div className="mb-4">
                <span className="p-float-label">
                  <InputTextarea
                    required={false}
                    id="Message"
                    rows={5}
                    cols={30}
                    value={message}
                    onChange={(e) => {
                      setMessageErr("");
                      if (e.target.value.trimStart().length > 2000) {
                        setMessageErr("Max2000Characters");
                      }
                      setMessage(e.target.value.trimStart());
                    }}
                    className={messageErr ? "p-invalid" : null}
                  />
                  <label htmlFor="Message">
                    {t("message")}
                    <span className="req-star">*</span>
                  </label>
                </span>
                {messageErr && <div className="p-error">{t(messageErr)}</div>}
              </div>
              <Button
                severity="Success"
                size="large"
                className="w-full mt-1"
                label={t("Submit")}
                onClick={(e) => {
                  e.preventDefault();
                  onSubmitForm();
                }}
              />
            </form>
          </div>
        </div>
      </div>
      <NewsLatter />
      <Footer />
    </>
  );
};

export default Home;
