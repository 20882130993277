import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import modalClose from "../assets/images/structure/modal-close.svg";
import shippingIcon from "../assets/images/structure/shipping-icon.svg";
import { validateEmail } from "../Helpers/HelperFunctions";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader, currentLoader } from "../redux/reducers/loader";
import { setSnackbar } from "../redux/reducers/snackbar";
import { API_ROUTES, STORAGE } from "../Enum";
import addDeleteGetLocalStorage from "../prototype/addDeleteGetLocalStorage";
import globalRequest from "../prototype/globalRequest";

const ForgotPassword = ({ handleClose, openReset }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loader = useSelector(currentLoader);
  //get existing email from local storage
  const existingEmail = addDeleteGetLocalStorage(
    STORAGE.FORGOT_MAIL,
    {},
    "get"
  );
  //states
  const [email, setEmail] = useState(existingEmail ? existingEmail : "");
  const [emailErr, setEmailErr] = useState("");

  const handleSubmit = async () => {
    let error = false;
    if (!validateEmail(email)) {
      setEmailErr("EnterValidEmailAddress");
      error = true;
    }
    if (!email) {
      setEmailErr("EmailIsRequired");
      error = true;
    }
    if (error) return;

    //call api here and then in response
    //open reset password popup
    dispatch(changeLoader(true));
    try {
      const res = await globalRequest(
        "post",
        API_ROUTES.FORGOT_PASSWORD,
        { email },
        {},
        false
      );
      if (res.ack == 1) {
        dispatch(changeLoader(false));
        // dispatch(
        //   setSnackbar({
        //     message: res.msg,
        //     isOpen: true,
        //     state: "success",
        //   })
        // );
        addDeleteGetLocalStorage(STORAGE.FORGOT_MAIL, email, "add", "single");
        openReset();
        handleClose();
      } else {
        setEmailErr(res.msg);
        dispatch(changeLoader(false));
      }
    } catch (err) {
      dispatch(changeLoader(false));
      dispatch(
        setSnackbar({
          isOpen: true,
          state: "error",
          message: err.response.data.msg || t("SomethingWentWrong"),
        })
      );
    }
  };
  return (
    <>
      <Dialog
        visible={true}
        onHide={() => handleClose()}
        draggable={false}
        resizable={false}
        closable={false}
        showHeader={false}
        className="dialog-407"
      >
        <img
          src={modalClose}
          alt="close"
          className="modal-close"
          onClick={() => handleClose()}
        />
        <img src={shippingIcon} alt="shipping" className="icon48" />
        <h3 className="h3">{t("ForgotPassword_")}</h3>
        <p className="p2 mb-4">{t("Noworrieswellsendtouresetinstructions")}</p>
        <div className="input-parent">
          <span className="p-float-label">
            <InputText
              className={emailErr ? "p-invalid" : null}
              value={email}
              onChange={(e) => {
                setEmailErr("");
                if (!validateEmail(e.target.value.trimStart()))
                  setEmailErr("EnterValidEmailAddress");
                if (!e.target.value.trimStart()) setEmailErr("EmailIsRequired");
                setEmail(e.target.value.trimStart());
              }}
              id="Email"
              placeholder={t("Email")}
            />
            <label htmlFor="Email">
              {t("Email")} <span className="req-star">*</span>
            </label>
          </span>
          {emailErr && <div className="p-error">{t(emailErr)}</div>}
        </div>
        <Button
          disabled={loader}
          severity="Success"
          size="large"
          className="w-full mt-30"
          onClick={() => {
            handleSubmit();
          }}
          label={t("ResetPassword")}
        />
      </Dialog>
    </>
  );
};

export default ForgotPassword;
