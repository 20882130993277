import React from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import "./footer.scss"

import { useTranslation } from "react-i18next";
import globalRequest from "../../../prototype/globalRequest";
import { API_ROUTES } from "../../../Enum";
import { useDispatch, useSelector } from "react-redux";
import { currentAuth } from "../../../redux/reducers/auth";
import { changeLoader } from "../../../redux/reducers/loader";
import { setSnackbar } from "../../../redux/reducers/snackbar";
import { validateEmail } from "../../../Helpers/HelperFunctions";

const NewsLatter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [email, setEmail] = React.useState("");
  const [emailErr, setEmailErr] = React.useState("");
  const auth = useSelector(currentAuth);
  let token = auth?.token;

  const handleSubmit = ()=>{
    let err = false;
    if(!email){
      setEmailErr("EmailIsRequired");
      err = true;
    }
    if (!validateEmail(email)) {
      setEmailErr("EnterValidEmailAddress");
      err = true;
    }
    if(err) return;

    dispatch(changeLoader(true));
    globalRequest("post", API_ROUTES.NEWS_LETTER, {email}, {}, token?true:false)
    .then((res)=>{
      dispatch(changeLoader(false));
      if(res?.ack == 1){
        dispatch(
          setSnackbar({
            message: "NewsletterSubscriptionEnabledSuccessfully",
            isOpen: true,
            state: "success",
          })
        );
        setEmail("");
      }else{
        dispatch(
          setSnackbar({
            message: res?.msg?res?.msg:"SomethingWentWrong",
            isOpen: true,
            state: "error",
          })
        );    
      }

    }).catch((err)=>{
      dispatch(changeLoader(false));
      dispatch(
        setSnackbar({
          message: "SomethingWentWrong",
          isOpen: true,
          state: "error",
        })
      );    
    })


  }

  return (
    <>
      <section className="news-latter">
        <div className="">
          <h3 className="h3">
            {t("SubscribeToOur")} <span>{t("Newsletter")}</span>
          </h3>
          <p className="p2">{t("SubscribeToOurNewsletterToReceive_text")}</p>
        </div>
        <div className="new-latter-input">
          <InputText 
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailErr("");
            }}
             placeholder={t("EnterEmail")}
           />
          {emailErr && <p className="absolute mt-0 p-error text-sm">{t(emailErr)}</p>}
          <Button 
          onClick={handleSubmit}
          label={t("Subscribe")} 
          size="sm" />
        </div>
      </section>
    </>
  );
};

export default NewsLatter;