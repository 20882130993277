import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import "./ChooseReturnOrder.scss";
import { API_ROUTES } from "../../Enum";
import noImage from "../../assets/images/products/noImage.webp";
import { useDispatch } from "react-redux";
import Footer from "../layout/footer/Footer";
import { InputNumber } from "primereact/inputnumber";
import { Checkbox } from "primereact/checkbox";
import FrontToolbar from "../../global-modules/FrontToolbar";
import { changeLoader } from "../../redux/reducers/loader";
import globalRequest from "../../prototype/globalRequest";
import { setSnackbar } from "../../redux/reducers/snackbar";

const ChooseReturnOrder = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useParams();
  let orderId = location?.slug;

  const returnReason = [
    { name: t("Foundadifferentproductthatbettersuitsmyneeds") },
    { name: t("Nolongerinterestedintheitem") },
    { name: t("Dissatisfactionwithoverallproductratings") },
    { name: t("ProductIsDamaged") },
    { name: t("Other") },
  ];

  //states
  const [productList, setProductList] = useState([]);
  const [returnTillDate, setReturnTillDate] = useState("");
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [orderDetails, setOrderDetails] = useState({});

  //given by backend developer to find last request
  function findLastRequestByProductId(returnRequests, productId) {
    let lastRequest = null;
    returnRequests.forEach((request) => {
      request.request_detail.forEach((detail) => {
        if (detail.product_id === productId) {
          if (
            !lastRequest ||
            new Date(request.createdAt) > new Date(lastRequest.createdAt)
          ) {
            lastRequest = request;
          }
        }
      });
    });
    return lastRequest;
  }

  //order details
  const orderDetailsApi = useCallback(() => {
    dispatch(changeLoader(true));
    globalRequest("get", API_ROUTES.ORDER_DETAILS(orderId), {}, {}, true)
      .then((data) => {
        if (data?.ack == 1) {
          let orderDetails = data?.data;
          setOrderDetails(orderDetails);
          let list = [];

          let orderTracking = orderDetails?.order_status_logs;
          let returnDate;
          let orderDate;
          orderTracking?.forEach((item) => {
            if (item?.status == "delivered") {
              returnDate = new Date(item.createdAt);
              orderDate = new Date(item?.createdAt);
            }
          });
          //return till date
          returnDate.setDate(orderDate.getDate() + 15);
          setReturnTillDate(formatDate(returnDate));
          let isReturnAble = calculateDateDifference(returnDate);

          orderDetails?.order_details?.map((item) => {
            let featuredImg;
            let arr = item?.product?.product_images;
            arr?.map((ele1) => {
              if (ele1?.is_featured == 1) {
                featuredImg = ele1?.image_url;
              }
            });
            let lastRequestLog = null;
            if (orderDetails?.return_request?.length) {
              lastRequestLog = findLastRequestByProductId(
                orderDetails?.return_request,
                item?.product_id
              )?.request_status_log;
            }
            list.push({
              id: item?.product_id,
              title: item?.product_title,
              price: item?.product_price,
              img: featuredImg,
              totalQuantity: item?.approveQty,
              returnRequestedQuantity: item?.returnQty,
              availableQuantity: isReturnAble
                ? item?.approveQty - item?.returnQty
                : 0, //if return date cross then no need to show available quantity
              lastStatus: lastRequestLog
                ? lastRequestLog[lastRequestLog?.length - 1]?.status
                : null,
              lastUpdatedDate: lastRequestLog
                ? lastRequestLog[lastRequestLog?.length - 1]?.updatedAt
                : null,
              currency: orderDetails?.currency,
              isSelected: false,
              requestQuantity: 0,
              reason: null,
            });
          });
          setProductList(list);
        } else {
          navigate(-1);
        }
      })
      .catch((err) => {
        console.error("error", err);
        navigate(-1);
      })
      .finally(() => {
        dispatch(changeLoader(false));
      });
  });

  //update product return quantity
  const handleUpdateReturnedQuantity = (index, value) => {
    const updatedProductList = [...productList];
    const updatedProduct = { ...updatedProductList[index] };
    const newRequestQuantity = updatedProduct.requestQuantity + value;

    if (
      newRequestQuantity < 1 ||
      newRequestQuantity > updatedProduct.availableQuantity
    ) {
      return; // Don't update if the new quantity is out of bounds
    }

    updatedProduct.requestQuantity = newRequestQuantity;
    updatedProductList[index] = updatedProduct;

    setProductList(updatedProductList);
  };

  //update status
  const handleCheckboxChange = (index) => {
    const updatedProductList = [...productList];
    const updatedProduct = { ...updatedProductList[index] };

    // Toggle the isSelected value
    updatedProduct.isSelected = !updatedProduct.isSelected;

    updatedProductList[index] = updatedProduct;

    setProductList(updatedProductList);
  };

  //handle reason
  const handleReasonChange = (index, reason) => {
    const updatedProductList = [...productList];
    const updatedProduct = { ...updatedProductList[index] };

    // Update the reason for the selected product
    updatedProduct.reason = reason;

    updatedProductList[index] = updatedProduct;

    setProductList(updatedProductList);
  };

  //handle submit request
  const handleSubmitRequest = () => {
    // check product selection
    const hasSelectedItem = productList?.some((item) => item?.isSelected);
    if (!hasSelectedItem) {
      dispatch(
        setSnackbar({
          isOpen: true,
          state: "error",
          message: t("PleaseSelectProductRoReturn"),
        })
      );
      return;
    }

    //check quantity and reason selection
    const submitProductList = productList
      .filter(
        (item) =>
          item.isSelected && item.requestQuantity !== 0 && item.reason !== null
      )
      .map((item) => ({
        product_id: item.id,
        reason: item.reason?.name,
        quantity: item.requestQuantity,
      }));

    if (submitProductList.length === 0) {
      dispatch(
        setSnackbar({
          isOpen: true,
          state: "error",
          message: t("PleaseSelectQuantityAndReasonToReturn"),
        })
      );
      return;
    }

    const data = {
      orderId: +orderId,
      status: "returned",
      productDetails: submitProductList || [],
    };
    dispatch(changeLoader(true));
    globalRequest("post", API_ROUTES.CHANGE_ORDER_STATUS, data, {}, true)
      .then((data) => {
        if (data?.ack == 1) {
          dispatch(
            setSnackbar({
              isOpen: true,
              state: "success",
              message: t("ReturnOrderRequestSentSuccessfully"),
            })
          );
          navigate(-1);
        }
      })
      .catch((err) => {
        console.error(err);
        dispatch(
          setSnackbar({
            isOpen: true,
            state: "error",
            message: t("somethingWentWrong"),
          })
        );
      })
      .finally(() => {
        dispatch(changeLoader(false));
      });
  };

  //helper function
  const formatDate = (inputDateStr) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const inputDate = new Date(inputDateStr);
    const day = inputDate.getDate();
    const month = months[inputDate.getMonth()];
    const year = inputDate.getFullYear();
    return `${day} ${month} ${year}`;
  };

  function calculateDateDifference(date1Str) {
    const date1 = new Date(date1Str);
    const date2 = new Date();
    return date1 > date2;
  }

  useEffect(() => {
    orderDetailsApi();
  }, []);

  useEffect(() => {
    const hasSelectedItem = productList?.some((item) => item?.isSelected);
    if (hasSelectedItem) {
      setDisabledBtn(false);
    } else {
      setDisabledBtn(true);
    }
  }, [productList]);

  return (
    <>
      <FrontToolbar />

      <div className="container">
        <h4 className="h4 mt-4">{t("chooseItemsToReturn")}</h4>
        <div className="cart-parent return-page">
          <div className="cart-left">
            {productList?.map((product, index) => {
              return (
                <div key={`${product?.title}${index}`}>
                  <div className="cart-content-row">
                    <div className="cart-column-left cart-product-list">
                      {product?.availableQuantity > 0 && (
                        <Checkbox
                          onChange={() => {
                            handleCheckboxChange(index);
                          }}
                          checked={product?.isSelected}
                          style={{ margin: "4px" }}
                        />
                      )}
                      <div className="cart-image">
                        <img
                          src={product?.img}
                          alt="product"
                          className="product-image"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = noImage;
                          }}
                        />
                      </div>
                      <div className="cart-content-text">
                        <>
                          <h5 className="h5 capitalize-first-letter mb-1">
                            {product?.title}
                          </h5>
                          <div className="flex">
                            <p className="p2">
                              {t(product?.currency)}{" "}
                              <span className="font-semibold">
                                {parseFloat(product?.price).toFixed(2)}
                              </span>
                            </p>
                            <p className="p2 mx-5">
                              {t("totalQTY")}:{" "}
                              <span className="font-semibold">
                                {product?.totalQuantity}
                              </span>
                            </p>
                          </div>
                        </>
                      </div>
                    </div>
                    <div className="cart-column-right relative flex flex-column">
                      <div className="relative mb-2">
                        {product?.returnRequestedQuantity > 0 && (
                          <>
                            <h5
                              className={`h5 ${
                                product?.lastStatus === "return_requested"
                                  ? "yellow"
                                  : "green"
                              } flex align-items-center gap-2`}
                            >
                              <i className="pi pi-check-circle"></i>
                              {product?.lastStatus === "return_requested"
                                ? t("ReturnRequested") + " "
                                : null}
                              {product?.lastStatus === "refunded"
                                ? t("RefundIssued") + " "
                                : null}
                              {`(${product?.returnRequestedQuantity})`}
                            </h5>
                            <p className="p2 gray my-1">
                              {product?.lastStatus === "return_requested"
                                ? t("returnRequestedOn")
                                : t("refundIssuedReturnReceivedOn")}{" "}
                              {formatDate(product?.lastUpdatedDate)}
                            </p>
                          </>
                        )}
                      </div>
                      {product?.availableQuantity > 0 && product?.isSelected ? (
                        <div className="relative flex flex-row x">
                          <div className="mx-2">
                            <label className="p3 mb-1 block">
                              {t("returnQTY")}
                            </label>
                            <div className="quantity-box">
                              <InputNumber
                                className="input"
                                style={{ pointerEvents: "none" }}
                                max={product?.availableQuantity}
                                min={1}
                                value={product?.requestQuantity}
                              />
                              <div className="quantity-btn">
                                <i
                                  className="pi pi-angle-up"
                                  onClick={() =>
                                    handleUpdateReturnedQuantity(index, 1)
                                  }
                                />
                                <i
                                  className="pi pi-angle-down"
                                  onClick={() =>
                                    handleUpdateReturnedQuantity(index, -1)
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div className="w-12rem relative ">
                            <label className="p3  mb-1 block">
                              {t("selectYourReason")}
                            </label>
                            <Dropdown
                              value={product?.reason}
                              onChange={(e) => {
                                handleReasonChange(index, e?.value);
                              }}
                              options={returnReason}
                              optionLabel="name"
                              placeholder={t("selectYourReason")}
                              size={"small"}
                              className="p-inputtext-sm w-12rem"
                              style={{ lineHeight: 2.5 }}
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <Divider />
                </div>
              );
            })}
          </div>
          <div className="cart-right">
            <div className="cart-right-box">
              <div className="flex flex-column ">
                <div className="flex mb-1 flex-wrap">
                  <span className="p2 p2-semibold pr-2">
                    Delivered Address :
                  </span>
                  <p className="p2">
                    <span className="capitalize-first-letter">
                      {orderDetails?.customer_name}
                    </span>
                    {orderDetails?.mobile && <> | {orderDetails?.mobile}</>}
                    {orderDetails?.address_name && (
                      <span className="capitalize-first-letter">
                        {" "}
                        | {orderDetails?.address_name}
                      </span>
                    )}
                  </p>
                </div>
                <div className="flex mb-1">
                  <p className="p2">
                    {orderDetails?.address ? orderDetails?.address : ""}
                    {orderDetails?.address2
                      ? `, ${orderDetails?.address2}`
                      : ""}
                    {orderDetails?.city ? `, ${orderDetails?.city}` : ""}
                    {orderDetails?.state ? `, ${orderDetails?.state}` : ""}
                    {orderDetails?.country ? `, ${orderDetails?.country}` : ""}
                    {orderDetails?.postal_code
                      ? `, ${orderDetails?.postal_code}`
                      : ""}
                  </p>
                </div>
              </div>

              <Button
                className="w-full mt-4 mb-4"
                severity="Success"
                size="large"
                disabled={disabledBtn}
                onClick={handleSubmitRequest}
              >
                {t("continue")}
              </Button>
              <p className="p2">
                {t("returnEligibleThrough")} <b>{returnTillDate}</b>
              </p>

              <Divider />
              {productList?.some((item) => item?.isSelected) && (
                <>
                  <p className="p2 font-semibold mb-2 block">
                    {t("itemsYouAreReturning")}
                  </p>
                  <div
                    className="cart-image"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "column",
                    }}
                  >
                    {productList?.map((item, index) => {
                      if (item?.isSelected) {
                        return (
                          <div key={`${index}${item?.title}`}>
                            <img
                              src={item?.img}
                              alt="product"
                              className="product-image"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = noImage;
                              }}
                            />
                          </div>
                        );
                      }
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ChooseReturnOrder;
