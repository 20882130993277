import React from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { ReturnOrderTracking } from "./OrderTracking";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useTranslation } from "react-i18next";

export const ReturnRequestLogs = ({
  orderDetails,
  returnRequestList,
  formatDate,
}) => {
  const { t } = useTranslation();
  
  const productBodyTemplate = (rowData) => {
    let featuredImg;
    let arr = rowData?.product?.product_images;
    arr?.map((ele1) => {
      if (ele1?.is_featured == 1) {
        featuredImg = ele1?.image_url;
      }
    });
    return (
      <div className="order-outline-box flex flex-column sm:flex-row sm:align-items-start justify-content-between px-0 py-0">
        <div className="flex align-items-start justify-content-between order-dtl-productLftCol">          
          <div className="hand-on">
            <p className="p2 p2-semibold mb-1">
              {rowData?.product_title}{" "}
            </p>
            <p className="p2 wrapcontent w-210">
              {rowData?.product?.short_description}{" "}
            </p>
          </div>
        </div>
      </div>
    );
  };
  const productPrice = (rowData) => {
    return (
      <>
        {orderDetails?.currency}{" "}
        {parseFloat(rowData?.expected_return_amount).toFixed(2)}
      </>
    );
  };
  const showIndex = (rowData, obj) => {
    return <>#{obj?.rowIndex + 1}</>;
  };

  return (
    <div>
      <Accordion className="order-detail-accordion" activeIndex={0}>
        {returnRequestList?.map((item, index) => {
          const array = item?.request_status_log;
          let hasRefunded = false;

          if (array) {
            array.forEach((item) => {
              if (item.status === "refunded") {
                hasRefunded = true;
              }
            });
          }
          return (
            <AccordionTab
              key={index}
              header={
                <div className="flex align-items-center justify-content-between">
                  <p className="p2 ">
                    <span className="font-semibold">{t("ReturnID")}</span> - #{" "}
                    {item?.id}
                  </p>
                  <p className="p2 ">
                    <span className="font-semibold">{t("Return Date")}</span> -{" "}
                    {formatDate(item?.createdAt)}
                  </p>
                </div>
              }
            >
              <div className="m-0">
                <DataTable value={item?.request_detail}>
                  <Column field="id" body={showIndex} header="#"></Column>
                  <Column field="product_title" header={t("product")} body={productBodyTemplate}></Column>
                  <Column
                    field="return_quantity"
                    header={t("ReturnQuantity")}
                  ></Column>
                  <Column field="return_reason" header={t("reason")}></Column>
                  <Column
                    field="expected_return_amount"
                    body={productPrice}
                    header={t("Price")}
                  ></Column>
                </DataTable>
                {hasRefunded && (
                  <div className="flex align-items-start justify-content-between gap-2 mt-3">
                    <h5 className="h5">
                      <span className="font-semibold">{t("RefundID")}</span> - #
                      {item?.reference_number}
                    </h5>
                    <h5 className="h5">
                      <span className="font-semibold">
                        {t("AmountReceived")}
                      </span>{" "}
                      - {orderDetails?.currency}{" "}
                      {parseFloat(item?.refund_amount).toFixed(2)}
                    </h5>
                  </div>
                )}

                <div className="flex align-items-start justify-content-between gap-2 mt-3">
                  {item?.admin_remark ? (
                    <h5 className="h5">
                      <span className="font-semibold">
                        {t("AdminComments")}{" "}
                      </span>{" "}
                      - {item?.admin_remark}{" "}
                    </h5>
                  ) : (
                    <h5 className="h5"></h5>
                  )}
                  {hasRefunded ? (
                    <h5 className="h5">
                      <span className="font-semibold">{t("RefundDate")}</span> -{" "}
                      {formatDate(item?.updatedAt)}
                    </h5>
                  ) : (
                    <h5 className="h5"></h5>
                  )}
                </div>
              </div>
              <div className="order-tracking-wrapper">
                <div className="tracking-box">
                  <p className="p2 mb-3 p-bold">{t("returnTracking")}</p>
                  <div className="order-tracking-block">
                    <ReturnOrderTracking
                      trackingLogs={item?.request_status_log}
                    />
                  </div>
                </div>
              </div>
            </AccordionTab>
          );
        })}
      </Accordion>
    </div>
  );
};
