import { createSlice } from "@reduxjs/toolkit";

export const searchProducts = createSlice({
  name: "searchProducts",
  initialState: {
    value: {
       products: [],
       total: 0,
       page: 1,
       limit: 10,
       searchInput: "",
    },
  },
  reducers: {
    setSearchProducts: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setSearchProducts } = searchProducts.actions;
export const searchProductsObject = (state) => state.searchProducts.value;
export default searchProducts.reducer;


