import { createSlice } from "@reduxjs/toolkit";
import { API_ROUTES, STORAGE } from "../../Enum";
import globalRequest from "../../prototype/globalRequest";
import addDeleteGetLocalStorage from "../../prototype/addDeleteGetLocalStorage";

const getCurrencyConversion = async () => {
  try {
    const res = await globalRequest(
      "get",
      API_ROUTES.GET_CURRENCY_CONVERSION,
      {},
      {}
    );
    // return res?.data; // Return the data object
    let AllCurrencies = res?.data;
    let currency = {
      aed_to_usd: 0,
      usd_to_ikd: 0,
      conversion_fee: 0,
      gateway: "",
      admin_fee: 0,
      tax: 0,
    };
    AllCurrencies?.map((item) => {
      if (item?.type === "aed_to_usd") {
        currency.aed_to_usd = +item?.value;
      }
      if (item?.type === "usd_to_ikd") {
        currency.usd_to_ikd = +item?.value;
      }
      if (item?.type === "conversion_fee") {
        currency.conversion_fee = +item?.value;
      }
      if (item?.type === "gateway") {
        currency.gateway = item?.value;
      }
      if (item?.type === "admin_fee") {
        currency.admin_fee = +item?.value;
      }
      if (item?.type === "tax") {
        currency.tax = +item?.value;
      }
    });    
    addDeleteGetLocalStorage(STORAGE.CURRENCY_CONVERSION, currency, "add", "single");
    return currency;
  } catch (error) {
    return {
      aed_to_usd: 0,
      usd_to_ikd: 0,
      conversion_fee: 0,
      gateway: "",
      admin_fee: 0,
      tax: 0,
    }; // Return default values on error
  }
};

export const fetchCurrencyConverionApi = () => async (dispatch) => {
  const data = await getCurrencyConversion();
  dispatch(setCurrencyConverion(data)); // Dispatch the retrieved data to the store
};

//get previous data from local storage
const localCurrencyConversion = addDeleteGetLocalStorage(STORAGE.CURRENCY_CONVERSION,{}, "get");
const parseData = localCurrencyConversion? JSON.parse(localCurrencyConversion):null;

export const currencyConversion = createSlice({
  name: "currencyConversion",
  initialState: {
    value:parseData?parseData:{
      aed_to_usd: 0,
      usd_to_ikd: 0,
      conversion_fee: 0,
      gateway: "",
      admin_fee: 0,
      tax: 0,
    },
  },
  reducers: {
    setCurrencyConverion: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setCurrencyConverion } = currencyConversion.actions;
export const currencyCoversionObject = (state) =>
  state?.currencyConversion?.value;
export default currencyConversion.reducer;
