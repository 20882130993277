import { useState } from "react";
import { MURCHAND_ID } from "../../../BaseUrl";
import { API_ROUTES, STORAGE } from "../../../Enum";
import globalRequest from "../../../prototype/globalRequest";
import { currentPlaceOrderData } from "../../../redux/reducers/placeOrderData";
import { useSelector } from "react-redux";
import addDeleteGetLocalStorage from "../../../prototype/addDeleteGetLocalStorage";
import { useEffect } from "react";
let timeout = null;
export const PaymentIframe = ({ placeOrderApi }) => {
  const placeData = useSelector(currentPlaceOrderData);
  // let currency_am = addDeleteGetLocalStorage(STORAGE.CURRENCY, {}, "get");
  let DB = addDeleteGetLocalStorage(STORAGE.COUNTRY_DB, {}, "get");
  let currency_am = DB === "Iraq" ? "USD" : "AED";
  const [encriptedData, setEncriptedData] = useState("");
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);

  const getEncriptedDataApi = () => {
    let userDetails = placeData?.userDetails;
    let data = {
      // redirect_url: '',
      // cancel_url: "",
      order_id: placeData?.cartId, //"ORD0001",
      currency: currency_am,
      // amount: placeData?.totalAfterShipping, //placeData?.totalAmount,
      amount:
        placeData?.subtotalAmount -
        placeData?.discountAmount +
        placeData?.taxAmount +
        placeData?.shippingCharge,
      billing_name: userDetails?.name ? userDetails?.name : "Al-mahara User",
      billing_address: userDetails?.address ? userDetails?.address : "Address",
      billing_city: userDetails?.city ? userDetails?.city : "Dubai",
      billing_country: userDetails?.country ? userDetails?.country : "UAE",
      billing_tel: userDetails?.mobile ? userDetails?.mobile : "1234567890",
      billing_email: userDetails?.email
        ? userDetails?.email
        : "email@email.com",
      integration_type: "iframe_normal",
      location: DB,
    };
    globalRequest("post", API_ROUTES.GET_ENCRIPTED_DATA, data, {}, true)
      .then((res) => {
        if (res?.ack === 1) {
          setEncriptedData(res?.encRequest);
          placeOrderApi();
        } else {
          console.error("error in getting encripted data");
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsIframeLoaded(false);
      });
  };

  //load iframe only once when variable change multiple times
  useEffect(() => {
    setIsIframeLoaded(true);
  }, [
    placeData?.subtotalAmount,
    placeData?.discountAmount,
    placeData?.taxAmount,
  ]);

  useEffect(() => {
    if (isIframeLoaded) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        getEncriptedDataApi();
      }, 500);
    }
  }, [isIframeLoaded, placeData?.shippingCharge, placeData?.taxAmount]);

  return (
    <div className="paymentinfobox">
      {encriptedData && MURCHAND_ID ? (
        <iframe
          width="482"
          height="500"
          scrolling="No"
          frameBorder="0"
          id="paymentFrame"
          className="testing"
          src={`https://secure.ccavenue.ae/transaction/transaction.do?command=initiateTransaction&merchant_id=${MURCHAND_ID}&encRequest=${encriptedData}&access_code=${MURCHAND_ID}`}
        ></iframe>
      ) : null}
    </div>
  );
};
