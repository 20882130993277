import { Button } from "primereact/button";
import React from "react";
import SocialLogin from "react-social-login";

class SocialButton extends React.Component {
  render() {
    const { children, triggerLogin,icon, ...props } = this.props;
    return (
      <Button className="login-with-item" onClick={triggerLogin} {...props} >
      <img src={icon} alt="..." className="icon24" />
      <span>{children}</span>
      </Button>
    );
  }
}

export default SocialLogin(SocialButton);
