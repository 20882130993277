import { configureStore } from "@reduxjs/toolkit";
import loader from "./reducers/loader";
import snackbar from "./reducers/snackbar";
import searchProducts from "./reducers/searchProducts";
import categoryList from "./reducers/categoryList";
import productList  from "./reducers/productsList";
import auth from "./reducers/auth";
import userProductsCount from "./reducers/userProductsCount";
import placeOrderData from "./reducers/placeOrderData";
import socialMediaLinks from "./reducers/socialMediaLink";
import currencyConversion from "./reducers/currencyConversion";
import contactInfo from "./reducers/contactInfo";

export default configureStore({
  reducer: {
    snackbar: snackbar,
    loader: loader,
    searchProducts: searchProducts,
    categoryList: categoryList,
    productList: productList,
    auth : auth,
    userProductsCount: userProductsCount,
    placeOrderData : placeOrderData,
    socialMediaLinks: socialMediaLinks,
    currencyConversion: currencyConversion,
    contactInfo: contactInfo,
  },
});
