import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
// images
import bundleIcon from "../assets/images/structure/bundle-yellow.svg";
import imgNotFound from "../assets/images/products/imgNotFound.webp";
import { useDispatch, useSelector } from "react-redux";
import { axiosConstant } from "../Helpers/Axios/Constants";
import {
  categoryListObject,
  setCategoryList,
} from "../redux/reducers/categoryList";

const FrontToolbar = () => {
  const { t } = useTranslation();
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let language = localStorage.getItem("i18nextLng");
  const currentUrl = window.location.href.split("/");
  const active_link = `${currentUrl[currentUrl.length - 1]}`;

  //get all categories from redux store
  const categoryObj = useSelector(categoryListObject);
  let categories = categoryObj?.categories || [];
  let selectedCategory = categoryObj?.selectedCategory;

  useEffect(()=>{
    if(!selectedCategory){
      categories.map((item) => {
        if(item?.name_en?.toLowerCase().replace(/ /g, "_") == active_link){
          dispatch(
            setCategoryList({
              ...categoryObj,
              selectedCategory: item,
            })
          );
        }
      })
    }
  },[categories])

  var settings = {
    slidesToScroll: 1,
    dots: false,
    infinite: false,
    speed: 500,
    arrows: true,
    centerMode: false,
    variableWidth: true
  }
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Check if the user has scrolled down a certain amount (e.g., 100 pixels)
      if (window.scrollY > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className={`toolbar-sticky ${isScrolled ? 'scrolled' : ''}`} >
        <div className="container">
          <div className="topbarStickyBox-nw toolbar-content">
            {/* <Slider {...settings} className="toolbar-content"> */}
              {categories.length > 0 &&
                categories.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        active_link ==
                        item?.name_en.toLowerCase().replace(/ /g, "_")
                          ? "toolbar-link active"
                          : "toolbar-link"
                      }
                      onClick={() => {
                        // dispatch({type:"SELECT_CATEGORY", payload:item})
                        dispatch(
                          setCategoryList({
                            ...categoryObj,
                            selectedCategory: item,
                            selectedSubCategoryArrayOfIds: [],
                            priceRange:[0,300000],
                            productType: "",
                            sortBy: "recommended",
                          })
                        );
                        navigate(
                          `/${item?.name_en?.toLowerCase().replace(/ /g, "_")}`
                        ); //replace space with underscore
                      }}
                    >
                      <img
                         src={item?.iconImage}
                        className="icon24"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = imgNotFound;
                        }}
                      />
                      <span className="tool-content capitalize-first-letter">
                        {language == "en"
                          ? t(item?.name_en)
                          : t(item?.name_ar)}
                      </span>
                    </div>
                  );
                })}
              <Link
                to="/bundle"
                className={
                  active_link == "bundle" ? "toolbar-link active" : "toolbar-link"
                }
              >
                <img src={bundleIcon} className="icon24" />
                <span className="tool-content">{t("Bundle")}</span>
              </Link>
            {/* </Slider> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default FrontToolbar;
