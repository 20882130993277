import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input"; //"AE"=> "971"-UAE,"QA"=>"974"-Qatar
import { isValidPhoneNumber } from "libphonenumber-js";
import modalClose from "../assets/images/structure/modal-close.svg";
import editIcon from "../assets/images/structure/edit-icon.svg";
import {
  addHyphens,
  removeHyphens,
  validateEmail,
  validatePhoneNumber,
} from "../Helpers/HelperFunctions";
import addDeleteGetLocalStorage from "../prototype/addDeleteGetLocalStorage";
import { API_ROUTES, STORAGE } from "../Enum";
import { decodedToken } from "../Helpers/TokenDecode";
import { useEffect } from "react";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { changeLoader } from "../redux/reducers/loader";
import { setSnackbar } from "../redux/reducers/snackbar";
import globalRequest from "../prototype/globalRequest";

const MyProfile = ({ handleClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const inputref = useRef(null);
  const mobileInterface = useRef(null);
  let token = JSON.parse(
    addDeleteGetLocalStorage(STORAGE.AL_MAHARA_USER, {}, "get")
  )?.token;
  //states
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState();
  const [mobile, setMobile] = useState("");
  const [email_edit, setEmail_edit] = useState(false);
  const [get_otp, setGet_otp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [verified, setVerified] = useState(false);
  const [firstMailId, setFirstMailId] = useState(null);
  //for update email
  const [prev_email, setPrev_email] = useState("");
  const [send_otp, setSend_otp] = useState("GET OTP");
  //errors
  const [firstNameErr, setFirstNameErr] = useState("");
  const [lastNameErr, setLastNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [otpErr, setOtpErr] = useState("");
  const [mobileErr, setMobileErr] = useState();
  const decoded = decodedToken(token);
  //for mobile verify
  const [getMobileOtp, setGetMobileOtp] = useState(false);
  const [otpMobile, setOtpMobile] = useState();
  const [verifiedMobile, setVerifiedMobile] = useState(false);
  const [otpMobileErr, setOtpMobileErr] = useState("");
  const [countdownMobile, setCountdownMobile] = useState(0);
  const [mobileEdit, setMobileEdit] = useState(false);
  const [previousMobileNumber, setPreviousMobilNumber] = useState();
  const [sendMobileOtp, setSendMobileOtp] = useState("GET OTP");

  //if user come from social-media login then not able to change email id
  const showNotApplicableToChangeMail = () => {
    dispatch(
      setSnackbar({
        isOpen: true,
        state: "error",
        message: t("YouCantChangeEmailWithSocialMediaLogIn"),
      })
    );
  };

  ///send otp to email
  const sen_OTP = async () => {
    if (decoded?.loginby) {
      showNotApplicableToChangeMail();
      return;
    }
    let error = false;
    if (!validateEmail(email)) {
      setEmailErr("EnterValidEmailAddress");
      error = true;
    }
    if (!email) {
      setEmailErr("EmailIsRequired");
      error = true;
    }
    if (error) return;
    dispatch(changeLoader(true));
    try {
      const res = await globalRequest(
        "post",
        API_ROUTES.SEND_EMAIL_VERIFICATION_OTP,
        { email },
        {},
        true
      );
      if (res.ack == 1) {
        //show success message
        setGet_otp(true);
        setSend_otp("");
        dispatch(changeLoader(false));
        // Start the countdown timer
        setCountdown(30);
        const countdownInterval = setInterval(() => {
          setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);

        // After 30 seconds, clear the interval, re-enable the button and reset the countdown
        setTimeout(() => {
          clearInterval(countdownInterval);
          setCountdown(0);
        }, 30000);
      } else {
        //show error message
        dispatch(changeLoader(false));
        if (res?.errMsg[0]?.email) {
          setEmailErr(res.errMsg[0].email);
        }
      }
    } catch (err) {
      dispatch(changeLoader(false));
      dispatch(
        setSnackbar({
          isOpen: true,
          state: "error",
          message: err.response.data.msg || t("SomethingWentWrong"),
        })
      );
    }
  };

  //send otp to mail
  const send_Mobile_Otp = async () => {
    return
    let error = false;
    if (!isValidPhoneNumber(mobile)) {
      setMobileErr("error");
      error = true;
    }
    if (!mobile) {
      setMobileErr("required");
      error = true;
    }
    if (error) return;
    dispatch(changeLoader(true));
    try {
      const res = await globalRequest(
        "post",
        API_ROUTES.SEND_OTP,
        { mobile: mobile },
        {},
        true
      );
      if (res.ack == 1) {
        //show success message
        setGetMobileOtp(true);
        setSendMobileOtp("");
        dispatch(changeLoader(false));
        // Start the countdown timer
        setCountdownMobile(30);
        const countdownInterval = setInterval(() => {
          setCountdownMobile((prevCountdown) => prevCountdown - 1);
        }, 1000);

        // After 30 seconds, clear the interval, re-enable the button and reset the countdown
        setTimeout(() => {
          clearInterval(countdownInterval);
          setCountdownMobile(0);
        }, 30000);
      } else {
        //show error message
        dispatch(changeLoader(false));
        if (res?.errMsg[0]?.mobile) {
          setMobileErr(res.errMsg[0].mobile);
        }
      }
    } catch (err) {
      dispatch(changeLoader(false));
      dispatch(
        setSnackbar({
          isOpen: true,
          state: "error",
          message: err.response.data.msg || t("SomethingWentWrong"),
        })
      );
    }
  };

  ///verify email otp
  const handleVerifyEmail = async () => {
    if (decoded?.loginby) {
      showNotApplicableToChangeMail();
      return;
    }
    if (!otp) {
      setOtpErr("OtpIsRequired");
      return;
    }
    dispatch(changeLoader(true));
    try {
      const res = await globalRequest(
        "post",
        API_ROUTES.EMAIL_VERIFICATION,
        { email, otp },
        {},
        true
      );
      if (res.ack == 1) {
        setVerified(true);
        setEmail_edit(false);
        dispatch(changeLoader(false));
      } else {
        setOtpErr(res.msg);
        dispatch(changeLoader(false));
      }
    } catch (err) {
      dispatch(changeLoader(false));
      dispatch(
        setSnackbar({
          isOpen: true,
          state: "error",
          message: err.response.data.msg || t("SomethingWentWrong"),
        })
      );
    }
  };

  //verify mobile otp
  const handleVerifyMobile = async () => {
    return
    if (!otpMobile) {
      setOtpMobileErr("OtpIsRequired");
      return;
    }
    dispatch(changeLoader(true));
    try {
      const res = await globalRequest(
        "post",
        API_ROUTES.VERIFY_OTP,
        {
          mobile: mobile,
          otp: otpMobile,
        },
        {},
        true
      );
      if (res.ack == 1) {
        setVerifiedMobile(true);
        setMobileEdit(false);
        dispatch(changeLoader(false));
      } else {
        setOtpMobileErr(res.msg);
        dispatch(changeLoader(false));
      }
    } catch (err) {
      dispatch(changeLoader(false));
      dispatch(
        setSnackbar({
          isOpen: true,
          state: "error",
          message: err.response.data.msg || t("SomethingWentWrong"),
        })
      );
    }
  };

  ///GET USER PROFILE DETAILS
  const getUserProfile = async () => {
    //call api here and then in response
    dispatch(changeLoader(true));
    try {
      const res = await globalRequest(
        "get",
        API_ROUTES.GET_USER_PROFILE,
        {},
        {},
        true
      );
      if (res?.ack == 1) {
        dispatch(changeLoader(false));
        setFirstName(res?.data?.first_name);
        setLastName(res?.data?.last_name);
        setEmail(res?.data?.email);
        setPrev_email(res?.data?.email);
        setFirstMailId(res?.data?.email);
        if (
          res?.data?.mobile_no &&
          res?.data?.mobile_no != 0 &&
          res?.data?.mobile_no != ""
        ) {
          let mobile = addHyphens(res?.data?.mobile_no.toString());
          setMobile("+" + mobile);
          setPreviousMobilNumber("+" + mobile);
        }
      } else {
        dispatch(changeLoader(false));
      }
    } catch (err) {
      dispatch(changeLoader(false));
      dispatch(
        setSnackbar({
          isOpen: true,
          state: "error",
          message: err.response.data.msg || t("SomethingWentWrong"),
        })
      );
    }
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  ///submit function
  const handleSubmit = async () => {
    if (decoded?.loginby && email !== firstMailId) {
      showNotApplicableToChangeMail();
      return;
    }
    let error = false;
    if (firstName.length > 200) {
      setFirstNameErr("Max200Characters");
      error = true;
    }
    if (lastName.length > 200) {
      setLastNameErr("Max200Characters");
      error = true;
    }
    if (!firstName) {
      setFirstNameErr("FirstNameIsRequired");
      error = true;
    }
    if (!lastName) {
      setLastNameErr("LastNameIsRequired");
      error = true;
    }
    if (mobile) {
      if (!isValidPhoneNumber(removeHyphens(mobile))) {
        setMobileErr("EnterValidMobileNumber");
        error = true;
      }
    }
    if (!validateEmail(email)) {
      setEmailErr("EnterValidEmailAddress");
      error = true;
    }
    if (prev_email != email) {
      if (!email) {
        setEmailErr("EmailIsRequired");
        error = true;
      }
    }
    if (get_otp) {
      if (!verified) {
        setOtpErr("ClickToVerifyButton");
        error = true;
      }
      if (!validateEmail(email)) {
        setEmailErr("EnterValidEmailAddress");
        error = true;
      }
      if (!email) {
        setEmailErr("EmailIsRequired");
        error = true;
      }
      if (!otp) {
        setOtpErr("OtpIsRequired");
        error = true;
      }
    }
    //after backend
    // if (getMobileOtp) {
    //   if (!verifiedMobile) {
    //     setOtpMobileErr("ClickToVerifyButton");
    //     error = true;
    //   }
    //   if (!isValidPhoneNumber(removeHyphens(mobile))) {
    //     setMobileErr("EnterValidMobileNumber");
    //     error = true;
    //   }
    //   if (!mobile) {
    //     setMobileErr("MobileIsRequired");
    //   }
    //   if (!otpMobile) {
    //     setOtpMobileErr("OtpIsRequired");
    //   }
    // }

    if (error) return;
    let temp_mo = mobile.substring(1); //remove + from mobile number

    let data = {
      first_name: firstName,
      last_name: lastName,
      mobile_no: temp_mo ? removeHyphens(temp_mo) : "",
    };
    if (prev_email != email) {
      data = {
        ...data,
        email: email,
        otp: otp ? otp : "",
      };
    }
    //after backend
    // if (removeHyphens(previousMobileNumber) != removeHyphens(mobile)) {
    //   data = {
    //     ...data,
    //     mobile_no: temp_mo ? removeHyphens(temp_mo) : "",
    //     verifyMobileOtp: otpMobile,
    //   };
    // }
    setLoading(true);
    dispatch(changeLoader(true));
    try {
      const res = await globalRequest(
        "post",
        API_ROUTES.UPDATE_USER_PROFILE,
        data,
        {},
        true
      );
      if (res.ack == 1) {
        setLoading(false);
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({ message: res.msg, isOpen: true, state: "success" })
        );
        handleClose();
      } else {
        dispatch(changeLoader(false));
        setLoading(false);
        if (res.ack == 0) {
          if (res?.msg) {
            dispatch(
              setSnackbar({ message: res.msg, isOpen: true, state: "error" })
            );
          }
          if (res?.errMsg[0]) {
            if (res?.errMsg[0]?.otp) {
              // setOtpErr(res?.errMsg[0]?.otp)
              setEmailErr("PleaseVerifyYourEmailFirst");
            }
            0;
            if (res?.errMsg[0]?.email) {
              dispatch(
                setSnackbar({
                  message: res?.errMsg[0]?.email,
                  isOpen: true,
                  state: "error",
                })
              );
            }
            if (res?.errMsg[0]?.mobile) {
              dispatch(
                setSnackbar({
                  message: res?.errMsg[0]?.mobile,
                  isOpen: true,
                  state: "error",
                })
              );
            }
          }
        }
      }
    } catch (err) {
      dispatch(changeLoader(false));
      dispatch(
        setSnackbar({
          isOpen: true,
          state: "error",
          message: err.response.data.msg || t("SomethingWentWrong"),
        })
      );
    }
  };

  ///manage phone number input
  const handlePhoneNumberChange = (value) => {
    setMobileErr("");
    if (!value) {
      setMobile("");
      return;
    }
    if (!isValidPhoneNumber(value)) {
      setMobileErr("EnterValidMobileNumber");
    }
    setMobile(value);
  };

  return (
    <>
      <Dialog
        visible={true}
        onHide={() => handleClose()}
        draggable={false}
        resizable={false}
        closable={false}
        showHeader={false}
        className="dialog-407"
      >
        <img
          src={modalClose}
          alt="close"
          className="modal-close"
          onClick={() => handleClose()}
        />
        <h3 className="h3 mb-4">{t("MyProfile")}</h3>
        <form>
          <div className="input-parent mb-4">
            <span className="p-float-label">
              <InputText
                className={firstNameErr ? "p-invalid" : null}
                value={firstName}
                onChange={(e) => {
                  setFirstNameErr("");
                  if (!e.target.value.trimStart().length) {
                    setFirstNameErr("FirstNameIsRequired");
                  }
                  if (e.target.value.trimStart().length > 200) {
                    setFirstNameErr("Max200Characters");
                  }
                  setFirstName(e.target.value.trimStart());
                }}
                id="FirstName"
                placeholder={t("FirstName")}
              />
              <label htmlFor="FirstName">
                {t("FirstName")} <span className="req-star">*</span>
              </label>
            </span>
            {firstNameErr && <div className="p-error">{t(firstNameErr)}</div>}
          </div>
          <div className="input-parent mb-4">
            <span className="p-float-label">
              <InputText
                className={lastNameErr ? "p-invalid" : null}
                value={lastName}
                onChange={(e) => {
                  setLastNameErr("");
                  if (!e.target.value.trimStart().length) {
                    setLastNameErr("LastNameIsRequired");
                  }
                  if (e.target.value.trimStart().length > 200) {
                    setLastNameErr("Max200Characters");
                  }
                  setLastName(e.target.value.trimStart());
                }}
                id="LastName"
                placeholder={t("LastName")}
              />
              <label htmlFor="LastName">
                {t("LastName")} <span className="req-star">*</span>
              </label>
            </span>
            {lastNameErr && <div className="p-error">{t(lastNameErr)}</div>}
          </div>
          <div className="input-parent mb-4 changeemilfield">
            <span className="p-float-label otpbox-p">
              <InputText
                ref={inputref}
                className={emailErr ? "p-invalid" : null}
                value={email}
                onChange={(e) => {
                  setEmailErr("");
                  if (!validateEmail(e.target.value.trimStart())) {
                    setEmailErr("EnterValidEmailAddress");
                  }
                  if (!e.target.value.trimStart().length) {
                    setEmailErr("EmailIsRequired");
                  }
                  setEmail(e.target.value.trimStart());
                }}
                id="Email"
                placeholder={t("EnterNewEmail")}
                readOnly={!email_edit}
              />
              {email_edit &&
              validateEmail(email) &&
              email !== prev_email &&
              send_otp ? (
                <span
                  onClick={() => {
                    //call api to send otp to the enter E-mail and in api response
                    //make email non editable and show add otp field
                    setEmailErr("");
                    setOtpErr("");
                    sen_OTP();
                  }}
                  className="otp-input-text"
                >
                  {t("GET_OTP")}
                </span>
              ) : countdown == 0 && !send_otp && !verified ? (
                <span
                  onClick={() => {
                    //call api to send otp to the enter E-mail and in api response
                    setOtp();
                    setOtpErr("");
                    sen_OTP();
                    // setGet_otp(false);
                    // setEmail_edit(false)
                  }}
                  className="otp-input-text"
                >
                  {t("RESEND_OTP")}
                </span>
              ) : null}

              {!email_edit && !get_otp && !decoded?.loginby ? (
                <img
                  onClick={() => {
                    setEmail_edit(true);
                    inputref.current.focus();
                  }}
                  src={editIcon}
                  alt="edit"
                  className="otp-input-text"
                />
              ) : null}

              {verified && (
                <span className="otp-input-text">
                  <Button
                    disabled
                    icon="pi pi-check"
                    severity="Success"
                    rounded
                    text
                    aria-label="Filter"
                  />
                </span>
              )}

              <label htmlFor="Email">
                {t("Email")} <span className="req-star">*</span>
              </label>
            </span>
            {emailErr && <div className="p-error">{t(emailErr)}</div>}
          </div>
          {get_otp && !verified && (
            <div className="input-parent mb-4">
              <span className="p-float-label">
                <InputNumber
                  className={otpErr ? "p-invalid" : null}
                  value={otp}
                  onChange={(e) => {
                    setOtpErr("");
                    setOtp(e.value);
                  }}
                  useGrouping={false}
                  id="OTP"
                  placeholder={t("OTP")}
                  min={0}
                />
                {countdown !== 0 && (
                  <span className="otp-input-text">
                    00:{countdown > 9 ? countdown : "0" + countdown}
                  </span>
                )}

                <label htmlFor="OTP">
                  {t("OTP")} <span className="req-star">*</span>
                </label>
                {!verified && (
                  <div onClick={handleVerifyEmail} className="verify-otp">
                    {t("Verify")}
                  </div>
                )}
              </span>
              {otpErr && <div className="p-error">{t(otpErr)}</div>}
            </div>
          )}
          <div className="input-parent mb-4 mobile-input-parent">
            {/* <PhoneInput international defaultCountry="IN" value={mobile} onChange={handlePhoneNumberChange} placeholder="Enter Mobile Number" /> */}
            {/* after backend */}
            {/* <span className="otp-input-text">
              {!mobileEdit && !getMobileOtp ? (
                <img
                  onClick={() => {
                    setMobileEdit(true);
                    mobileInterface.current.focus();
                  }}
                  src={editIcon}
                  alt="edit"
                  className="otp-input-text"
                />
              ) : null}
              {mobileEdit &&
              // validateEmail(email) &&
              removeHyphens(mobile) !== removeHyphens(previousMobileNumber) &&
              sendMobileOtp ? (
                <span
                  onClick={() => {
                    setMobileErr("");
                    setOtpMobileErr("");
                    send_Mobile_Otp();
                  }}
                  className="otp-input-text"
                >
                  {t("GET_OTP")}
                </span>
              ) : countdownMobile == 0 && !sendMobileOtp && !verifiedMobile ? (
                <span
                  onClick={() => {
                    //call api to send otp to the enter E-mail and in api response
                    setOtpMobile();
                    setOtpMobileErr("");
                    send_Mobile_Otp();
                  }}
                  className="otp-input-text"
                >
                  {t("RESEND_OTP")}
                </span>
              ) : null}
              {verifiedMobile && (
                <Button
                  icon="pi pi-check"
                  severity="Success"
                  rounded
                  text
                  aria-label="Filter"
                  disabled
                  style={{ cursor: "auto" }}
                />
              )}
            </span> */}
            <PhoneInput
              ref={mobileInterface}
              style={{ border: mobileErr ? "1px solid red" : null }}
              international
              defaultCountry="AE"
              value={mobile}
              onChange={handlePhoneNumberChange}
              placeholder={t("MobileNumber")}
              // disabled={!mobileEdit}///enable only when from backend side done
            />
            <label
              className={mobile ? "mobile-input label-top" : "mobile-input"}
            >
              {t("MobileNumber")}
            </label>
            {/**add this class .label-top */}
            {mobileErr && <div className="p-error">{t(mobileErr)}</div>}
          </div>

          {getMobileOtp && !verifiedMobile && (
            <div className="input-parent mb-4">
              <span className="p-float-label">
                <InputNumber
                  className={otpMobileErr ? "p-invalid" : null}
                  value={otpMobile}
                  onChange={(e) => {
                    setOtpMobileErr("");
                    setOtpMobile(e.value);
                  }}
                  useGrouping={false}
                  id="OTP"
                  placeholder={t("OTP")}
                  min={0}
                />
                {countdownMobile !== 0 && (
                  <span className="otp-input-text">
                    00:
                    {countdownMobile > 9
                      ? countdownMobile
                      : "0" + countdownMobile}
                  </span>
                )}

                <label htmlFor="OTP">
                  {t("OTP")} <span className="req-star">*</span>
                </label>
                {!verifiedMobile && (
                  <div onClick={handleVerifyMobile} className="verify-otp">
                    {t("Verify")}
                  </div>
                )}
              </span>
              {otpMobileErr && <div className="p-error">{t(otpMobileErr)}</div>}
            </div>
          )}

          <Button
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            disabled={loading}
            severity="Success"
            size="large"
            className="w-full mt-1"
            // unfield
            label={t("Save")}
          />
        </form>
      </Dialog>
    </>
  );
};

export default MyProfile;
