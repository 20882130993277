import { Dialog } from "primereact/dialog";
import { useTranslation } from "react-i18next";
import modalClose from "../assets/images/structure/modal-close.svg";
import visaWhite from "../assets/images/structure/visa-white.svg";

const OrderSummary = ({ closePopup, orderSummary }) => {
  const { t } = useTranslation();
  return (
    <>
      <Dialog
        visible={true}
        onHide={() => closePopup()}
        draggable={false}
        resizable={false}
        closable={false}
        showHeader={false}
        className="dialog-407"
      >
        <img
          src={modalClose}
          alt="close"
          className="modal-close"
          onClick={() => closePopup()}
        />
        <h3 className="h3 mb-4">{t("orderSummary")}</h3>
        <div className="flex justify-content-between mb-3">
          <span className="p2">{t("subtotal")}</span>
          <span className="p2">
            {orderSummary?.currency}{" "}
            {parseFloat(orderSummary?.subTotal).toFixed(2)}
          </span>
        </div>
        {+orderSummary?.discount > 0 && (
          <div className="flex justify-content-between mb-3">
            <span className="p2">
              {t("couponDiscount")}
              {orderSummary?.couponName && (
                <span className="p2" style={{ color: "#EFB34C" }}>
                  {" "}
                  ({orderSummary?.couponName})
                </span>
              )}
            </span>
            <span className="p2" style={{ color: "#EFB34C" }}>
              - {orderSummary?.currency}{" "}
              {parseFloat(orderSummary?.discount).toFixed(2)}
            </span>
          </div>
        )}
        <div className="flex justify-content-between mb-3">
          <span className="p2">
            {t("shipping")}
            {orderSummary?.shippingCompany && (
              <span
                className="p2 capitalize-first-letter"
                style={{ color: "#EFB34C" }}
              >
                {" "}
                ({orderSummary?.shippingCompany})
              </span>
            )}
          </span>
          <span className="p2">
            {orderSummary?.currency}{" "}
            {parseFloat(orderSummary?.shipping).toFixed(2)}
          </span>
        </div>
        <div className="flex justify-content-between mb-3">
          <span className="p2">
            {t("tax")}{" "}
            {orderSummary?.taxInPercentage > 0
              ? ` (${orderSummary?.taxInPercentage}%)`
              : ""}
          </span>
          <span className="p2">
            {orderSummary?.currency} {parseFloat(orderSummary?.tax).toFixed(2)}
          </span>
        </div>
        {orderSummary?.paymentType == "cash" && orderSummary?.codCharge > 0 && (
          <div className="flex justify-content-between mb-3">
            <span className="p2">{t("codCharges")}</span>
            <span className="p2">
              {orderSummary?.currency}{" "}
              {parseFloat(orderSummary?.codCharge).toFixed(2)}
            </span>
          </div>
        )}
        <div
          className="flex justify-content-between border-top-1 mb-3 pt-3 mt-3"
          style={{ borderColor: "#EDF4F4" }}
        >
          <span className="p2 p2-semibold">{t("total")}</span>
          <span className="p2 p2-semibold">
            {orderSummary?.currency}{" "}
            {parseFloat(orderSummary?.total).toFixed(2)}
          </span>
        </div>
        <div className="bottom-bg-box flex mt-4 align-items-center">
          {orderSummary?.paymentType == "cash" ? (
            <>
              <div>{t("cashOnDelivery")}</div>
            </>
          ) : (
            <>
              <img src={visaWhite} alt="" />
              <div className="pl-2">
                <p className="p2">
                  {t("PaidbyCard")}
                  {orderSummary?.cardNumber &&
                    ` ${t("endingin")} xxxx ${orderSummary?.cardNumber
                      ?.toString()
                      ?.slice(-4)}`}
                </p>
                {/* <p className="p2">{t("transactionID")} #547924398645</p> */}
              </div>
            </>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default OrderSummary;
