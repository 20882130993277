/**
 * @file SignUp.js
 * @description This file contains the sign up page.
 */

import { useState } from 'react';
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import "../signUp/SignUp.scss";
import { useTranslation } from "react-i18next";

import LeftContent from "../LeftContent";

// images
import shippingIcon from "../../../assets/images/structure/shipping-icon.svg";

const UpdatePassword = () => {

	const { t } = useTranslation();

	const [NewPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	return (
    <>
      <section className="signup-section">
        <div className="signup-left">
          <LeftContent />
        </div>
        <div className="signup-right">
          <div className="signup-right-content max-width340">
            <div className="text-center">
              <img src={shippingIcon} alt="shipping" className="icon66" />
              <h3 className="h3">{t("UpdatePassword")}</h3>
              <p className="p2">{t("Noworriesyoucanchangethepasswordanytime")}</p>
            </div>
            <div className="input-parent mb-4">
              <span className="p-float-label ">
                <Password
                  id="NewPassword"
                  value={NewPassword}
                  onChange={(e) => setNewPassword(e.target.NewPassword)}
                  feedback={false}
                  toggleMask
                />
                <label htmlFor="NewPassword">
                  {t("NewPassword")} <span className="req-star">*</span>
                </label>
              </span>
            </div>
            <div className="input-parent mb-6">
              <span className="p-float-label ">
                <Password
                  id="ConfirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.ConfirmPassword)}
                  feedback={false}
                  toggleMask
                />
                <label htmlFor="ConfirmPassword">
                  {t("ConfirmPassword")} <span className="req-star">*</span>
                </label>
              </span>
            </div>
            <Button
              severity="Success"
              size="large"
              className="w-full"
              label={t("UpdatePassword")}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default UpdatePassword;