import { createSlice } from "@reduxjs/toolkit";

export const productList = createSlice({
  name: "productList",
  initialState: {
    value: {
       products: [],
       totalProducts: 0,
    },
  },
  reducers: {
    setProductList: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setProductList } = productList.actions;
export const productListObject = (state) => state.productList.value;
export default productList.reducer;


