import { useTranslation } from "react-i18next";
import "./ProductPage.scss";
import FrontToolbar from "../../../global-modules/FrontToolbar";
import Footer from "../../layout/footer/Footer";
import Breadcrumb from "../../../global-modules/Breadcrumb";
import Filter from "../Filter/Filter";
import Banner from "../Banner/Banner";
import SliderSlick from "../SliderSlick/SliderSlick";
import SortedProducts from "../SortedProducts/SortedProducts";
// images
import { useDispatch, useSelector } from "react-redux";
import { axiosConstant } from "../../../Helpers/Axios/Constants";
import { useEffect, useState } from "react";
import Loaders from "../../../Loaders";
import _, { get } from "lodash";
import { setSnackbar } from "../../../redux/reducers/snackbar";
import { useCallback } from "react";
import { changeLoader, currentLoader } from "../../../redux/reducers/loader";
import {
  productListObject,
  setProductList,
} from "../../../redux/reducers/productsList";
import {
  categoryListObject,
  setCategoryList,
} from "../../../redux/reducers/categoryList";
import { currentAuth } from "../../../redux/reducers/auth";
import {
  convertToOriginalCurrency,
  productPrice,
} from "../../../Helpers/HelperFunctions";
import { currencyCoversionObject } from "../../../redux/reducers/currencyConversion";
import addDeleteGetLocalStorage from "../../../prototype/addDeleteGetLocalStorage";
import { API_ROUTES, STORAGE } from "../../../Enum";
import globalRequest from "../../../prototype/globalRequest";
const ProductPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector(currentAuth);
  let token = auth?.token;

  let language = localStorage.getItem("i18nextLng");
  let productObj = useSelector(productListObject);
  let categoryObj = useSelector(categoryListObject);
  let selectedCategory = categoryObj?.selectedCategory;
  let selectedSubCategoryArrayOfIds =
    categoryObj?.selectedSubCategoryArrayOfIds;
  let totalProducts = productObj?.totalProducts || 0;
  const loading = useSelector(currentLoader);
  const conversionRate = useSelector(currencyCoversionObject);

  //states for filter
  let [subcategory, setSubcategory] = useState(
    selectedSubCategoryArrayOfIds.length ? selectedSubCategoryArrayOfIds : []
  );
  let [limit, setLimit] = useState(20);
  let [offset, setOffset] = useState(0);
  let [sortby, setSortby] = useState(
    categoryObj?.sortBy ? categoryObj?.sortBy : "recommended"
  );
  let [filter, setFilter] = useState(
    categoryObj?.productType ? categoryObj?.productType : null
  );
  let [pricerange, setPricerange] = useState(
    categoryObj?.priceRange?.length ? categoryObj?.priceRange : [0, 300000]
  );
  const [hasValueChanged, setHasValueChange] = useState(false);
  const [filteredProductList, setFilteredProductList] = useState([]);

  // Create debounced versions of the onChange handlers
  const debouncedOnChange = _.debounce((value, number) => {
    let temp = [...pricerange];
    if (number == "first") temp[0] = +value;
    if (number == "second") temp[1] = +value;
    if (number == "both") temp = value;
    setPricerange(temp);
    let price = [...pricerange];
    if (pricerange[0] == undefined || pricerange[0] == null) price[0] = 0;
    if (pricerange[1] == undefined || pricerange[1] == null) price[1] = 300000;
    price = JSON.stringify(price);
    dispatch(
      setCategoryList({
        ...categoryObj,
        priceRange: JSON.parse(price),
      })
    );
  }, 200); // Debounce for 200 milliseconds

  //get all products and store to the redux store
  const getAllProductListApi = useCallback((params) => {
    dispatch(changeLoader(true));
    globalRequest(
      "get",
      API_ROUTES.GET_PRODUCT_LIST,
      {},
      { params: params },
      token ? true : false
    )
      .then((data) => {
        dispatch(changeLoader(false));
        if (data?.ack == 1) {
          let temp = [...data?.data];
          temp.map((obj) => {
            obj["isShowAddMinusBtn"] = false;
            //check if product is in compare list or not
            let getCompareProducts = addDeleteGetLocalStorage(
              STORAGE.COMPARE_PRODUCT,
              {},
              "get"
            );
            if (getCompareProducts)
              getCompareProducts = JSON.parse(getCompareProducts);
            let isExist = getCompareProducts?.find(
              (item) => item?.id == obj?.id
            );
            if (isExist) obj["isInCompare"] = true;
          });

          /// update product with respect to wholesale price
          let allProducts = temp;
          allProducts?.forEach((item) => {
            item.stock =
              item?.stock !== null
                ? item?.stock?.stock - item?.stock?.reserve_stock > 0
                  ? item?.stock
                  : null
                : null;
            item?.product_type == "product" &&
              (item.discount_price = productPrice(
                item?.is_todays_offer === 1
                  ? item?.discount_price
                  : item?.selling_price,
                1,
                item?.price_ranges,
                item?.is_wholesale
              ));
          });

          setFilteredProductList(allProducts);
          dispatch(
            setProductList({
              ...productObj,
              totalProducts: data?.count,
            })
          );
          setHasValueChange(false);
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            message: "Something went wrong with the API " + err,
            isOpen: true,
            state: "error",
          })
        );
      });
  });

  //when change subcategory dispatch new request
  useEffect(() => {
    setHasValueChange(true);
  }, [subcategory, sortby, filter, pricerange, limit]);

  let timeout = null;
  useEffect(() => {
    if (hasValueChanged) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        callApiWhenValueChange();
      }, 500);
    }
  }, [hasValueChanged, selectedCategory]);

  const callApiWhenValueChange = () => {
    let price = [...pricerange];
    if (pricerange[0] == undefined || pricerange[0] == null) price[0] = 0;
    if (pricerange[1] == undefined || pricerange[1] == null) price[1] = 300000;
    //convert to original currency to send to the api
    price = [
      convertToOriginalCurrency(+price[0], conversionRate),
      convertToOriginalCurrency(+price[1], conversionRate),
    ];
    price = JSON.stringify(price);

    let subCat = [...subcategory];
    if (subcategory.length == 0) subCat = "";
    else {
      subCat = JSON.stringify(subcategory);
    }

    let params = {
      Category: selectedCategory?.id,
      Subcategory: subCat,
      limit: limit,
      offset: offset,
      sortby: sortby,
      filter: filter,
      pricerange: price,
    };

    let localGuestId = addDeleteGetLocalStorage(STORAGE.GUEST_ID, {}, "get");
    if (!token && localGuestId) {
      params = { ...params, guestid: localGuestId };
    }

    if (selectedCategory?.id) {
      getAllProductListApi(params);
    }
  };

  //if select new category reset all filters
  useEffect(() => {
    setSubcategory(
      selectedSubCategoryArrayOfIds.length ? selectedSubCategoryArrayOfIds : []
    );
    setSortby(categoryObj?.sortBy ? categoryObj?.sortBy : "recommended");
    setFilter(categoryObj?.productType ? categoryObj?.productType : null);
    setOffset(0);
    setLimit(20);
    setPricerange(
      categoryObj?.priceRange?.length ? categoryObj?.priceRange : [0, 300000]
    );
  }, [selectedCategory]);

  //if select from catalog page
  useEffect(() => {
    setSubcategory(selectedSubCategoryArrayOfIds);
  }, [selectedSubCategoryArrayOfIds]);

  return (
    <>
      {loading && <Loaders />}
      <FrontToolbar />
      <div className="container">
        <Breadcrumb
          title={
            language == "en"
              ? t(
                  `${
                    selectedCategory?.name_en ? selectedCategory?.name_en : ""
                  }`
                )
              : t(
                  `${
                    selectedCategory?.name_ar ? selectedCategory?.name_ar : ""
                  }`
                )
          }
        />
        <section className="hardware-section1">
          <Banner
            // imgBanner={topBanner}
            imgBanner={selectedCategory?.bannerImage}
            title={
              language == "en"
                ? t(`${selectedCategory?.name_en}`)
                : t(`${selectedCategory?.name_ar}`)
            }
            // paragraph={t(`${selectedCategory?.slug}`)}
          />
          <SliderSlick selectedCategory={selectedCategory} />
          <div className="grid">
            <div className="col-12 sm:col-12 md:col-5 lg:col-3">
              <Filter
                setSubcategory={setSubcategory}
                subcategory={subcategory}
                setLimit={setLimit}
                setOffset={setOffset}
                setFilter={setFilter}
                filter={filter}
                setPricerange={setPricerange}
                debouncedOnChange={debouncedOnChange}
                pricerange={pricerange}
              />
            </div>
            <div className="col-12 sm:col-12 md:col-7 lg:col-8 ml-auto">
              <SortedProducts
                loading={loading}
                products={filteredProductList}
                setFilteredProductList={setFilteredProductList}
                totalProducts={totalProducts}
                setSortby={setSortby}
                setLimit={setLimit}
                limit={limit}
              />
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default ProductPage;
