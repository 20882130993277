/**
 * @description this file used to store common helper functions here
 */

// import { useSelector } from "react-redux";
import { currencyCoversionObject } from "../redux/reducers/currencyConversion";
import addDeleteGetLocalStorage from "../prototype/addDeleteGetLocalStorage";
import { STORAGE } from "../Enum";
import uniqid from "uniqid";

/**
 * @description email validation
 * @param {*} email
 * @returns
 */
export const validateEmail = (email) => {
  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
};

/**
 * @description phone number validation
 * @param {*} phoneNumber
 * @returns
 */
export const validatePhoneNumber = (phoneNumber) => {
  const phoneNumberRegex = /^\d{13}$/;
  return phoneNumberRegex.test(phoneNumber);
};

/**
 * @description validate Password based on regex pattern
 * @should contain at least 8 characters and at most 20
 *  characters and must contain at least one numeric digit and any string of characters and (@, #, $, *, _,)
 * @param {*} password
 * @returns boolean
 */
export const validatePassword = (password) => {
  // const passwordRegex = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9@#$*_]+){8,20}$/;

  // Check password length
  if (password.length < 8) {
    return false;
  }
  // Check if password has a mix of uppercase, lowercase letters, numbers and symbols
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return passwordRegex.test(password);
};

/**
 * @description function to get last 4 digits of card number
 * @param {*} cardNumber
 * @returns last 4 digits of card number
 * @example getLastFourDigitsOfCardNumber('1234567890123456') => '3456'
 */
export const getLastFourDigitsOfCardNumber = (cardNumber) => {
  return cardNumber.slice(-4);
};

//remove "-" from phone number
export const removeHyphens = (phoneNumber) => {
  if (phoneNumber == undefined) return phoneNumber;
  return phoneNumber.replace(/-/g, "");
};

//add "-" to phone number
export const addHyphens = (phoneNumber) => {
  let formatted = "";
  if (phoneNumber.length > 0) {
    formatted += phoneNumber.substring(0, 3);
  }
  if (phoneNumber.length > 3) {
    formatted += "-" + phoneNumber.substring(3, 6);
  }
  if (phoneNumber.length > 6) {
    formatted += "-" + phoneNumber.substring(6, 9);
  }
  if (phoneNumber.length > 9) {
    formatted += "-" + phoneNumber.substring(9, 13);
  }
  return formatted;
};

/// compair with todfays date
export const isSameDate = (dateString) => {
  const dateToCompare = new Date(dateString);
  const today = new Date();

  return (
    dateToCompare.getUTCFullYear() === today.getUTCFullYear() &&
    dateToCompare.getUTCMonth() === today.getUTCMonth() &&
    dateToCompare.getUTCDate() === today.getUTCDate()
  );
};

// currency conversion function
export const convertCurrency = (price, conversionRate) => {
  if (typeof price === "string") {
    price = parseFloat(price);
  }
  let convertedCurrency = 0;
  // let conversionRate = useSelector(currencyCoversionObject);
  let currency_am = addDeleteGetLocalStorage(STORAGE.CURRENCY, {}, "get");
  let country_db = addDeleteGetLocalStorage(STORAGE.COUNTRY_DB, {}, "get");

  if (country_db === "UAE" && currency_am === "AED") {
    //the value is already in AED
    convertedCurrency = price;
  } else if (country_db === "UAE" && currency_am === "USD") {
    convertedCurrency = price * conversionRate.aed_to_usd;
  } else if (country_db === "Iraq" && currency_am === "USD") {
    //the value is already in USD
    convertedCurrency = price;
  } else if (country_db === "Iraq" && currency_am === "AED") {
    //convert USD to AED
    convertedCurrency = price / conversionRate.aed_to_usd;
  } else if (country_db === "Iraq" && currency_am === "IQD") {
    //convert USD to IQD
    convertedCurrency = price * conversionRate.usd_to_ikd;
  }

  return convertedCurrency.toFixed(2);
};

//convert to original currency
export const convertToOriginalCurrency = (price, conversionRate) => {
  let originalCurrency = 0;
  // let conversionRate = useSelector(currencyCoversionObject);
  let currency_am = addDeleteGetLocalStorage(STORAGE.CURRENCY, {}, "get");
  let country_db = addDeleteGetLocalStorage(STORAGE.COUNTRY_DB, {}, "get");

  if (country_db === "UAE" && currency_am === "AED") {
    //the value is already in AED
    originalCurrency = price;
  } else if (country_db === "UAE" && currency_am === "USD") {
    originalCurrency = price / conversionRate.aed_to_usd;
  } else if (country_db === "Iraq" && currency_am === "USD") {
    //the value is already in USD
    originalCurrency = price;
  } else if (country_db === "Iraq" && currency_am === "AED") {
    //convert USD to AED
    originalCurrency = price * conversionRate.aed_to_usd;
  } else if (country_db === "Iraq" && currency_am === "IQD") {
    //convert USD to IQD
    originalCurrency = price / conversionRate.usd_to_ikd;
  }

  return originalCurrency;
};

//product price with respect to price range
export const productPrice = (price, quantity, price_ranges, is_wholesale) => {
  let priceRange = price_ranges?.find(
    (obj) => obj?.start_range <= quantity && obj?.end_range >= quantity
  );
  if (priceRange && is_wholesale == 1) {
    return priceRange?.wholesale_price;
  } else {
    return price;
  }
};

//return random number
export const returnRandomGuest = () => {
  return (
    uniqid("guest_") +
    uniqid("") +
    Math.floor(Math.random() * 10000000000000).toString()
  );
};
