import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TieredMenu } from "primereact/tieredmenu";
import MyProfile from "../../../DialogBox/MyProfile";
import ChangePassword from "../../../DialogBox/ChangePassword";
import Login from "../../../DialogBox/Login";
import ResetPassword from "../../../DialogBox/ResetPassword";
import ForgetPassword from "../../../DialogBox/ForgotPassword";
import Logout from "../../../DialogBox/Logout";
import OtpVerify from "../../../DialogBox/OtpVerify";

// images
import dawnArrowBlack from "../../../assets/images/structure/down-arrow.svg";
import userIcon from "../../../assets/images/structure/user-white.svg";
// other
import "./header.scss";
import { STORAGE } from "../../../Enum";
import addDeleteGetLocalStorage from "../../../prototype/addDeleteGetLocalStorage";
import { useNavigate } from "react-router-dom";
import { decodedToken } from "../../../Helpers/TokenDecode";
import SendChangePasswordUrl from "../../../DialogBox/SendChangePasswordUrl";

export default function Header({ setIsChangeLang }) {
  const { t, i18n } = useTranslation();
  /// integration
  const navigate = useNavigate();
  let token = JSON.parse(
    addDeleteGetLocalStorage(STORAGE.AL_MAHARA_USER, {}, "get")
  )?.token;
  let [isLogedin, setLogedin] = useState(token);
  useEffect(() => {
    setLogedin(token);
  }, [token]);

  const [openLogin, setOpenLogin] = useState(true);
  const [openForgetPassword, setOpenForgetPassword] = useState(false);
  const [openResetPassword, setOpenResetPassword] = useState(false);
  const [openLogout, setOpenLogout] = useState(false);
  const [openVerifyOtp, setOpenVerifyOtp] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setOpenLogin(false);
    }, 30);
  }, []);

  const [openMyProfile, setOpenMyProfile] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);

  const menu = useRef(null);
  const decoded = decodedToken(token);
  const initialItemsWithoutSocial = [
    {
      label: t("MyProfile"),
      icon: "pi pi-user",
      command: () => {
        openMyProfile ? setOpenMyProfile(false) : setOpenMyProfile(true);
      },
    },
    {
      label: t("MyOrder"),
      icon: "pi pi-truck",
      command: () => {
        navigate("/my-order");
      },
    },
    // {
    //   label: t("AppLanguage"),
    //   icon: "pi pi-globe",
    //   items: [
    //     {
    //       label: t("headerEnglish"),
    //       command: () => {
    //         i18n.changeLanguage("en");
    //         localStorage.setItem("i18nextLng", "en");
    //         setIsChangeLang();
    //       },
    //     },
    //     {
    //       label: t("headerQatar"),
    //       command: () => {
    //         i18n.changeLanguage("ar");
    //         localStorage.setItem("i18nextLng", "ar");
    //         setIsChangeLang();
    //       },
    //     },
    //   ],
    // },
    {
      label: t("ChangePassword"),
      icon: "pi pi-lock",
      command: () => {
        openChangePassword
          ? setOpenChangePassword(false)
          : setOpenChangePassword(true);
      },
    },
    {
      label: t("Logout"),
      icon: "pi pi-sign-out",
      command: () => {
        // localStorage.removeItem("al_mahar_user")
        setOpenLogout(true);
        // setLogedin(false)
      },
    },
  ];

  const initialItemsWithSocial = [
    {
      label: t("MyProfile"),
      icon: "pi pi-user",
      command: () => {
        openMyProfile ? setOpenMyProfile(false) : setOpenMyProfile(true);
      },
    },
    {
      label: t("MyOrder"),
      icon: "pi pi-truck",
      command: () => {
        navigate("/my-order");
      },
    },
    // {
    //   label: t("AppLanguage"),
    //   icon: "pi pi-globe",
    //   items: [
    //     {
    //       label: t("headerEnglish"),
    //       command: () => {
    //         i18n.changeLanguage("en");
    //         localStorage.setItem("i18nextLng", "en");
    //         setIsChangeLang();
    //       },
    //     },
    //     {
    //       label: t("headerQatar"),
    //       command: () => {
    //         i18n.changeLanguage("ar");
    //         localStorage.setItem("i18nextLng", "ar");
    //         setIsChangeLang();
    //       },
    //     },
    //   ],
    // },
    {
      label: t("Logout"),
      icon: "pi pi-sign-out",
      command: () => {
        // localStorage.removeItem("al_mahar_user")
        setOpenLogout(true);
        // setLogedin(false)
      },
    },
  ];

  return (
    <>
      <div className="header__user">
        <TieredMenu
          className="header__user-drop"
          model={
            decoded?.loginby
              ? initialItemsWithSocial
              : initialItemsWithoutSocial
          }
          popup
          ref={menu}
        />
        <span
          onClick={(e) => {
            if (!isLogedin) {
              setOpenLogin(true);
            } else {
              menu.current.toggle(e);
            }
          }}
        >
          {!isLogedin && <img src={userIcon} alt="user" className="icon24" />}
          {isLogedin && decoded?.first_name ? (
            <span
              className="ml-2 mr-2 capitalize-first-letter"
              style={{ color: "#fff", fontSize: "14px" }}
            >
              {decoded?.first_name}
            </span>
          ) : null}
          {isLogedin && (
            <img src={dawnArrowBlack} alt="arrow-down" className="down-arrow" />
          )}
        </span>
      </div>

      {openMyProfile ? (
        <MyProfile
          handleClose={() => {
            setOpenMyProfile(false);
          }}
        />
      ) : null}
      {openChangePassword ? (
        <ChangePassword
          handleClose={() => {
            setOpenChangePassword(false);
          }}
        />
      ) : null}

      {openLogin ? (
        <Login
          handleClose={() => {
            setOpenLogin(false);
          }}
          openForget={() => {
            setOpenForgetPassword(true);
            setOpenLogin(false);
          }}
        />
      ) : null}
      {openForgetPassword ? (
        <ForgetPassword
          handleClose={() => {
            setOpenForgetPassword(false);
          }}
          openReset={() => {
            setOpenResetPassword(true);
            setOpenForgetPassword(false);
          }}
          openVerifyOtp={() => {
            setOpenVerifyOtp(true);
            setOpenForgetPassword(false);
          }}
        />
      ) : null}
      {openResetPassword ? (
        <SendChangePasswordUrl
          handleClose={() => {
            setOpenResetPassword(false);
          }}
        />
      ) : null}
      {openLogout ? (
        <Logout
          handleClose={() => {
            setOpenLogout(false);
          }}
        />
      ) : null}
      {openVerifyOtp ? (
        <OtpVerify
          handleClose={() => {
            setOpenVerifyOtp(false);
          }}
          openReset={() => {
            setOpenResetPassword(true);
            setOpenVerifyOtp(false);
          }}
        />
      ) : null}
    </>
  );
}
