import React from "react";
import "./cms.scss";

const StaticBanner = (props) => {
  const { imgBanner, title, paragraph } = props;
  return (
    <>
      <div className="static-image-box">
        <img src={imgBanner} alt={title} className="static-img" />
        <div className="overlay-text">
          <h2 className="h2">{title} </h2>
          <p className="p2">{paragraph}</p>
        </div>
      </div>
    </>
  );
};
export default StaticBanner;
