import { createSlice } from "@reduxjs/toolkit";

export const initialPlaceOrderData = {
  cartId: "",
  addressId: "",
  couponId: "",
  totalAmount: "",
  subtotalAmount: "",
  discountAmount: "",
  shippingCharge: 0,
  taxAmount: "",
  codCharge: "",
  currency: "",
  paymentType: "",
  deliveryType: "",
  shippingCompany: "",
  trackingId: "UBCN88983",
  paymentStatus: "pending",
  trnNumber: "",
  companyName: "",
  userDetails: {},
  taxPercentage:"",
}
export const placeOrderData = createSlice({
  name: "placeOrderData",
  initialState: {
    value: initialPlaceOrderData,
  },
  reducers: {
    setPlaceOrderData: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setPlaceOrderData } = placeOrderData.actions;
export const currentPlaceOrderData = (state) => state.placeOrderData.value;
export default placeOrderData.reducer;
