import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import modalClose from "../assets/images/structure/modal-close.svg";
import notificationIcon from "../assets/images/structure/notification.svg";
import { validateEmail } from "../Helpers/HelperFunctions";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader, currentLoader } from "../redux/reducers/loader";
import globalRequest from "../prototype/globalRequest";
import { API_ROUTES } from "../Enum";
import { currentAuth } from "../redux/reducers/auth";
import { setSnackbar } from "../redux/reducers/snackbar";

const NotifyMe = ({ handleClose, productId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loader = useSelector(currentLoader);
  const auth = useSelector(currentAuth);
  let token = auth?.token;
  //states
  const [email, setEmail] = useState(auth?.email || "");
  const [emailErr, setEmailErr] = useState("");

  const handleSubmit = async () => {
    let error = false;
    if (!validateEmail(email)) {
      setEmailErr("EnterValidEmailAddress");
      error = true;
    }
    if (!email) {
      setEmailErr("EmailIsRequired");
      error = true;
    }
    if (error) return;
    //call api here and then in response
    let data = {
      email: email,
      productId: productId,
    };
    dispatch(changeLoader(true));
    globalRequest(
      "post",
      API_ROUTES.NOTIFY_ME_PRODUCT,
      data,
      {},
      token ? true : false
    )
      .then((res) => {
        dispatch(changeLoader(false));
        if (res?.ack == 1) {
          dispatch(
            setSnackbar({
              message: "WeWillNotifyYou",
              isOpen: true,
              state: "success",
            })
          );
          handleClose();
        } else {
          dispatch(
            setSnackbar({
              message: "SomethingWentWrong",
              isOpen: true,
              state: "error",
            })
          );
          handleClose();
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            message: "SomethingWentWrong",
            isOpen: true,
            state: "error",
          })
        );
      });
  };
  return (
    <>
      <Dialog
        visible={true}
        onHide={() => handleClose()}
        draggable={false}
        resizable={false}
        closable={false}
        showHeader={false}
        className="dialog-407"
      >
        <img
          src={modalClose}
          alt="close"
          className="modal-close"
          onClick={() => handleClose()}
        />
        <img src={notificationIcon} alt="shipping" className="icon48" />
        <h3 className="h3">{t("NotifyMe")}</h3>
        <p className="p2 mb-4">
          {t("Wewillnotifyyouviamailonceproductisavailable")}
        </p>
        <div className="input-parent">
          <span className="p-float-label">
            <InputText
              className={emailErr ? "p-invalid" : null}
              value={email}
              onChange={(e) => {
                setEmailErr("");
                if (!validateEmail(e.target.value.trimStart()))
                  setEmailErr("EnterValidEmailAddress");
                if (!e.target.value.trimStart()) setEmailErr("EmailIsRequired");
                setEmail(e.target.value.trimStart());
              }}
              id="Email"
              placeholder={t("Email")}
            />
            <label htmlFor="Email">
              {t("Email")} <span className="req-star">*</span>
            </label>
          </span>
          {emailErr && <div className="p-error">{t(emailErr)}</div>}
        </div>
        <Button
          disabled={loader}
          severity="Success"
          size="large"
          className="mt-30 w-full"
          onClick={() => {
            handleSubmit();
          }}
          label={t("confirm")}
        />
      </Dialog>
    </>
  );
};

export default NotifyMe;
