import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import modalClose from "../assets/images/structure/modal-close.svg";
import facebookIcon from "../assets/images/structure/facebook-round-icon.svg";
import googleIcon from "../assets/images/structure/google-round-icon.svg";
import { validateEmail, validatePassword } from "../Helpers/HelperFunctions";
import SocialButton from "../Helpers/Social-Media/SocialButton";
import { axiosConstant } from "../Helpers/Axios/Constants";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader, currentLoader } from "../redux/reducers/loader";
import { setSnackbar } from "../redux/reducers/snackbar";
import { API_ROUTES, STORAGE } from "../Enum";
import addDeleteGetLocalStorage from "../prototype/addDeleteGetLocalStorage";
import { setData } from "../redux/reducers/auth";
import {
  fetchUserProductsCount,
  userProductsObj,
} from "../redux/reducers/userProductsCount";
import axios from "axios";

//google login imports
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import globalRequest from "../prototype/globalRequest";

const Login = ({ handleClose, openForget }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loader = useSelector(currentLoader);
  const currentPath = useLocation();

  //states
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [show_NPass, setShowNPass] = useState(true);
  const productsCount = useSelector(userProductsObj);
  const [visibleReplaceCartAction, setVisibleReplaceCartAction] =
    useState(false);
  const [userToken, setUserToken] = useState(null);
  //errors
  const [passwordErr, setPasswordErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [invalid_credentialErr, setInvalid_credentialErr] = useState("");

  //login function
  const handleLogin = async (replaceCart = false) => {
    let error = false;
    if (!validateEmail(email)) {
      setEmailErr("EnterValidEmailAddress");
      error = true;
    }
    if (!email) {
      setEmailErr("EmailIsRequired");
      error = true;
    }
    if (!validatePassword(password)) {
      setPasswordErr("EnterValidPassword");
      error = true;
    }
    if (!password) {
      setPasswordErr("PasswordIsRequired");
      error = true;
    }
    if (error) return;
    ///call api here and then in response
    const formData = {
      email,
      password,
    };
    dispatch(changeLoader(true));
    try {
      const res = await globalRequest(
        "post",
        API_ROUTES.LOGIN,
        formData,
        {},
        false
      );
      if (res.ack == 1) {
        dispatch(changeLoader(false));
        addDeleteGetLocalStorage(STORAGE.FORGOT_MAIL, {}, "delete");
        addDeleteGetLocalStorage(
          STORAGE.AL_MAHARA_USER,
          { token: res?.token, email: res?.email },
          "add",
          "single"
        );
        dispatch(setData());
        // dispatch(
        //   setSnackbar({
        //     message: "LoggedinSuccessfully",
        //     isOpen: true,
        //     state: "success",
        //   })
        // );
        dispatch(fetchUserProductsCount());
        setUserToken(res?.token);
        //after login ask for replace cart items
        if (productsCount?.cartcount > 0) {
          setVisibleReplaceCartAction(true);
          return;
        } else {
          replaceCartItems(res?.token, "no");
        }
      } else {
        dispatch(changeLoader(false));
        if (res?.msg) {
          if (res?.msg === "User is inactive.") {
            setInvalid_credentialErr("AccountIsInactivePleaseContactToAdmin");
          } else {
            setInvalid_credentialErr("EmailAndPasswordDoesNotMatch");
          }
        }
      }
    } catch (err) {
      dispatch(changeLoader(false));
      dispatch(
        setSnackbar({
          isOpen: true,
          state: "error",
          message: err?.response?.data?.msg || t("SomethingWentWrong"),
        })
      );
    }
  };

  // replace cart Items with login user cart items
  const replaceCartItems = async (signupToken = null, remove = "no") => {
    // if remove is no then remove all cart items
    if (remove == "no") {
      setVisibleReplaceCartAction(false);
      dispatch(fetchUserProductsCount());
      handleClose();
      if (currentPath?.pathname === "/signup") {
        navigate("/");
      } else {
        window.location.reload();
      }
      return;
    }
    if (!signupToken) return;
    let guestId = addDeleteGetLocalStorage(STORAGE.GUEST_ID, {}, "get");
    if (!guestId) return;

    const data = {
      guestid: guestId,
      type: "new",
    };
    dispatch(changeLoader(true));
    let getA = addDeleteGetLocalStorage(STORAGE.COUNTRY_DB, {}, "get");
    let url = axiosConstant.BASE_URL + API_ROUTES.REPLACE_LOGIN_USER_CART;
    axios
      .post(url, data, {
        headers: {
          "x-api-key": axiosConstant.API_KEY,
          location: getA ? (getA == "Iraq" ? "baghdad" : "dubai") : "baghdad",
          authorization: "Bearer " + signupToken,
        },
      })
      .then((res) => {
        dispatch(changeLoader(false));
        setVisibleReplaceCartAction(false);
        dispatch(fetchUserProductsCount());
        handleClose();
        if (res?.data?.ack == 1) {
          //delete guestId and wishlist from local storage
          localStorage.removeItem(STORAGE.GUEST_ID);
          localStorage.removeItem(STORAGE.GUEST_WHISLIST_LOCAL);
          if (currentPath?.pathname === "/signup") {
            navigate("/");
          } else {
            window.location.reload();
          }
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        //delete guestId from local storage
        localStorage.removeItem(STORAGE.GUEST_ID);
        console.error("error while updating userId", err);
      });
  };

  //social login api
  const socialLoginApi = async (data) => {
    await globalRequest("post", API_ROUTES.SOCIAL_LOGIN, data, {}, false)
      .then((res) => {
        if (res.status == 200) {
          let data = res.data;
          if (data?.user?.status == "inactive") {
            dispatch(
              setSnackbar({
                message: t("AccountIsInactivePleaseContactToAdmin"),
                isOpen: true,
                state: "error",
              })
            );
            return;
          }
          addDeleteGetLocalStorage(STORAGE.FORGOT_MAIL, {}, "delete");
          addDeleteGetLocalStorage(
            STORAGE.AL_MAHARA_USER,
            { token: data?.token, email: data?.user?.email },
            "add",
            "single"
          );
          dispatch(setData());
          {
            data?.flage == 0 &&
              dispatch(
                setSnackbar({
                  message:
                    data?.flage == 1
                      ? t("LoggedinSuccessfully")
                      : t("RegisteredSuccessfully"),
                  isOpen: true,
                  state: "success",
                })
              );
          }
          dispatch(fetchUserProductsCount());
          setUserToken(data?.token);
          //after login ask for replace cart items
          if (productsCount?.cartcount > 0 && data?.flage == 1) {
            setVisibleReplaceCartAction(true);
            return;
          } else if (productsCount?.cartcount > 0 && data?.flage == 0) {
            replaceCartItems(res?.token, "yes");
          } else {
            replaceCartItems(res?.token, "no");
          }
        } else {
          dispatch(changeLoader(false));
          if (res?.msg) {
            dispatch(
              setSnackbar({
                isOpen: true,
                state: "error",
                message: res?.msg || t("SomethingWentWrong"),
              })
            );
          }
        }
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            isOpen: true,
            state: "error",
            message: err?.message || t("SomethingWentWrong"),
          })
        );
      });
  };

  //google login
  const handleGoogleLogin = (token) => {
    var decoded = jwt_decode(token.credential);
    const data = {
      first_name: decoded.given_name,
      last_name: decoded.family_name,
      email: decoded.email,
      social_id: decoded.sub,
      social_media_type: "google",
    };

    socialLoginApi(data);
  };

  //fb login
  const handleSocialLogin = async (user) => {
    const data = {
      first_name: user.profile.firstName,
      last_name: user.profile.lastName,
      email: user.profile.email,
      social_id: user.profile.id,
      social_media_type: user.provider,
    };
    socialLoginApi(data);
  };

  const handleSocialLoginFailure = (err) => {
    console.error("err", err);
  };

  //useEffect
  useEffect(() => {
    addDeleteGetLocalStorage(STORAGE.FORGOT_MAIL, {}, "delete");
  }, []);

  return (
    <>
      <Dialog
        visible={true}
        onHide={() => handleClose()}
        draggable={false}
        resizable={false}
        closable={false}
        showHeader={false}
        className="dialog-407"
      >
        <img
          src={modalClose}
          alt="close"
          className="modal-close"
          onClick={() => handleClose()}
        />
        <h3 className="h3">{t("Welcome!")}</h3>
        <p className="p2 mb-5">{t("Pleaseenterbelowdetailstologin")}</p>
        <div className="input-parent mb-4">
          <span className="p-float-label ">
            <InputText
              className={emailErr ? "p-invalid" : null}
              value={email}
              onChange={(e) => {
                setInvalid_credentialErr("");
                setEmailErr("");
                setEmail(e.target.value.trimStart());
                addDeleteGetLocalStorage(
                  STORAGE.FORGOT_MAIL,
                  e.target.value.trimStart(),
                  "add",
                  "single"
                );
              }}
              id="Email"
              placeholder={t("Email")}
              error={emailErr}
            />
            <label htmlFor="Email">
              {t("Email")} <span className="req-star">*</span>
            </label>
          </span>
          {emailErr && <div className="p-error">{t(emailErr)}</div>}
        </div>
        <div className="input-parent">
          <span className="p-float-label">
            <InputText
              type={show_NPass ? "password" : "text"}
              className={passwordErr ? "p-invalid" : null}
              id="Password"
              value={password}
              onChange={(e) => {
                setInvalid_credentialErr("");
                setPasswordErr("");
                setPassword(e.target.value.trimStart());
              }}
              autoComplete="off"
            />
            <label htmlFor="Password">
              {t("Password")} <span className="req-star">*</span>
            </label>
            <i
              className={
                !show_NPass
                  ? "pi pi-eye passwordIcon"
                  : "pi pi-eye-slash passwordIcon"
              }
              onClick={() => {
                setShowNPass(!show_NPass);
              }}
            ></i>
          </span>
          {passwordErr && <div className="p-error">{t(passwordErr)}</div>}
          {invalid_credentialErr && (
            <div className="p-error">{t(invalid_credentialErr)}</div>
          )}
        </div>
        <div className="forgot-password">
          <span className="p3" onClick={openForget}>
            {t("Forgotpassword_")}
          </span>
        </div>

        <Button
          disabled={loader}
          onClick={() => {
            handleLogin();
          }}
          severity="Success"
          size="large"
          className="w-full mt-3"
          label={t("LogIn")}
        />
        <div className="with-account-div">
          <span>{t("orloginwith")}</span>
        </div>

        <div className="login-with" style={{ flexDirection: "column" }}>
          <SocialButton
            provider="facebook"
            appId={axiosConstant.FACEBOOK_SOCIAL_KEY}
            onLoginSuccess={handleSocialLogin}
            onLoginFailure={handleSocialLoginFailure}
            icon={facebookIcon}
          >
            {t("Facebook")}
          </SocialButton>
          {/* <SocialButton
            provider="google"
            appId={axiosConstant.GOOGLE_SOCIAL_KEY}
            onLoginSuccess={handleSocialLogin}
            onLoginFailure={handleSocialLoginFailure}
            icon={googleIcon}
          >
            {t("Google")}
          </SocialButton> */}
          <div className="gBtn mt-3">
            <GoogleLogin
              className="social-google"
              buttonText="Sign up"
              onSuccess={handleGoogleLogin}
              onFailure={handleSocialLoginFailure}
              size="large"
            />
          </div>
        </div>
        <p className="p2 mt-4 text-center">
          {t("Donthaveanaccount_")}{" "}
          <Link
            onClick={() => handleClose()}
            to="/signup"
            className="yellow underline-none"
          >
            {t("Signup")}{" "}
          </Link>
          {t("here")}
        </p>
      </Dialog>

      {/* cart item replacement action */}
      <Dialog
        visible={visibleReplaceCartAction}
        onHide={() => handleClose()}
        draggable={false}
        resizable={false}
        closable={false}
        showHeader={false}
        className="dialog-340"
      >
        <h3 className="h3 mb-2">{t("DoYouWantToReplaceCart?")}</h3>
        <p className="p2 mb-4">
          {t(
            "ThereAreAlreadyItemsAvailableInYourCartDoYouWantToReplaceYourCartAndAddNewItems?"
          )}
        </p>
        <div className="modal-end-btn">
          <Button
            label={t("No")}
            className="gray"
            onClick={() => {
              replaceCartItems(userToken, "no");
            }}
            text
          />
          <Button
            label={t("Yes")}
            className="yellow"
            onClick={() => {
              replaceCartItems(userToken, "yes");
            }}
            text
          />
        </div>
      </Dialog>
    </>
  );
};

export default Login;
