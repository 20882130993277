import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
// images
import modalClose from "../assets/images/structure/modal-close.svg";
import { setSnackbar } from "../redux/reducers/snackbar";
import { useDispatch } from "react-redux";
import { STORAGE } from "../Enum";
import addDeleteGetLocalStorage from "../prototype/addDeleteGetLocalStorage";
import { setData } from "../redux/reducers/auth";
import { useNavigate } from "react-router-dom";
import { fetchUserProductsCount } from "../redux/reducers/userProductsCount";
import { returnRandomGuest } from "../Helpers/HelperFunctions";

const Logout = ({ handleClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSubmit = async () => {
    // localStorage.removeItem("al_mahar_user");

    addDeleteGetLocalStorage(STORAGE.AL_MAHARA_USER, {}, "delete");
    dispatch(setData());
    // dispatch(
    //   setSnackbar({
    //     message: "LoggedOutSuccessfully",
    //     isOpen: true,
    //     state: "success",
    //   })
    // );
    handleClose();
    navigate("/");

     //check if guest id is present in local storage or not
     const localGuestUser = addDeleteGetLocalStorage(
      STORAGE.GUEST_ID,
      {},
      "get"
    );
    let guestId = localGuestUser
      ? localGuestUser
      : returnRandomGuest();
    addDeleteGetLocalStorage(STORAGE.GUEST_ID, guestId, "add", "single"); //add guest id in local storage

    // get product count for guest user
    const token = JSON.parse(
      addDeleteGetLocalStorage(STORAGE.AL_MAHARA_USER, {}, "get")
    )?.token;
    const localGuestId = addDeleteGetLocalStorage(STORAGE.GUEST_ID, {}, "get");
    if (!token && localGuestId) {
      dispatch(fetchUserProductsCount());
    }
  };
  return (
    <>
      <Dialog
        visible={true}
        onHide={() => handleClose()}
        draggable={false}
        resizable={false}
        closable={false}
        showHeader={false}
        className="dialog-340"
      >
        <img
          src={modalClose}
          alt="close"
          className="modal-close"
          onClick={() => handleClose()}
        />
        <h3 className="h3 mb-2">{t("Logout")}</h3>
        <p className="p2 mb-4">{t("Areyousureyouwanttologout_")}</p>
        <div className="modal-end-btn">
          <Button
            label={t("No")}
            className="gray"
            onClick={() => handleClose()}
            text
          />
          <Button
            label={t("Yes")}
            className="yellow"
            onClick={() => {
              handleSubmit();
            }}
            text
          />
        </div>
      </Dialog>
    </>
  );
};

export default Logout;
