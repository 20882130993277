import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./top-toolbar.scss";
import dawnArrowBlack from "../../../../assets/images/structure/down-arrow.svg";
import qatarLangIcon from "../../../../assets/images/language/qatar-flag-icon.svg";
import englishLangIcon from "../../../../assets/images/language/english.svg";
import englishicon from "../../../../assets/images/structure/english-icon.svg";
import arabicicon from "../../../../assets/images/structure/arabic-icon.svg";
import iraqflag from "../../../../assets/images/structure/iraq-flag.png";
import uaeflag from "../../../../assets/images/structure/uae-flag.png";

import usdIcon from "../../../../assets/images/structure/usdIcon.svg";
import aedICon from "../../../../assets/images/structure/aedICon.svg";
import iqdIcon from "../../../../assets/images/structure/iqdIcon.svg";

import { useTranslation } from "react-i18next";
import { Menu } from "primereact/menu";
import { STORAGE } from "../../../../Enum";
import addDeleteGetLocalStorage from "../../../../prototype/addDeleteGetLocalStorage";

const TopToolbar = ({ isChangeLang }) => {
  const { t, i18n } = useTranslation();
  const menuCountry = useRef(null);
  const menuLanguage = useRef(null);
  const menuCurrency = useRef(null);
  const navigate = useNavigate();

  // select country from dropdown
  const Country = [
    {
      label: (
        <span className="flex align-items-center">
          <img
            src={iraqflag}
            className="language-img mr-2 ml-2"
            alt="language-icon"
          />
          {t("Iraq")}
        </span>
      ),
      code: "Iraq",
      command: () => {
        changeDB("Iraq");
        setSelectedDB("Iraq");
      },
    },
    {
      label: (
        <span className="flex align-items-center">
          <img
            src={uaeflag}
            className="language-img mr-2 ml-2"
            alt="language-icon"
          />
          {t("UAE")}
        </span>
      ),
      code: "UAE",
      command: () => {
        changeDB("UAE");
        setSelectedDB("UAE");
      },
    },
  ];

  const [selectedDB, setSelectedDB] = useState("Iraq");
  const changeDB = (DB) => {
    let prevDb = addDeleteGetLocalStorage(STORAGE.COUNTRY_DB, {}, "get");
    if (prevDb === DB) return;
    setSelectedDB(DB);
    addDeleteGetLocalStorage(STORAGE.COUNTRY_DB, DB, "add", "single");
    addDeleteGetLocalStorage(STORAGE.COMPARE_PRODUCT, {}, "delete"); //remove compare product from localstorage
    if (DB === "UAE") {
      addDeleteGetLocalStorage(STORAGE.CURRENCY, "AED", "add", "single");
    }
    if (DB === "Iraq") {
      addDeleteGetLocalStorage(STORAGE.CURRENCY, "USD", "add", "single");
    }
    navigate("/");
    window.location.reload();
  };
  //select currency from dropdown
  const Currency = [
    {
      label: (
        <div className="currency-dropdown-item">
          <img src={usdIcon} className="language-img" alt="language-icon" />
          {t("USD")}
        </div>
      ),
      command: () => {
        changeCurrency("USD");
      },
      parent: ["Iraq", "UAE"],
      code: "USD",
    },
    {
      label: (
        <div className="currency-dropdown-item">
          <img src={aedICon} className="language-img" alt="language-icon" />
          {t("AED")}
        </div>
      ),
      command: () => {
        changeCurrency("AED");
      },
      parent: ["UAE", "Iraq"],
      code: "AED",
    },
    {
      label: (
        <div className="currency-dropdown-item">
          <img src={iqdIcon} className="language-img" alt="language-icon" />
          {t("IQD")}
        </div>
      ),
      command: () => {
        changeCurrency("IQD");
      },
      parent: ["Iraq"],
      code: "IQD",
    },
  ];

  const [selectedCurrency, setSelectedCurrency] = useState("USD");
  const changeCurrency = (currency) => {
    setSelectedCurrency(currency);
    addDeleteGetLocalStorage(STORAGE.CURRENCY, currency, "add", "single");
    window.location.reload();
  };

  //select language from dropdown
  const languages = [
    {
      label: (
        <span>
          <img src={englishicon} className="language-img" alt="language-icon" />
          {t("headerEnglish")}
        </span>
      ),
      code: "en",
      command: () => {
        setSelectedLang("en");
        changeLanguage("en");
      },
    },
    {
      label: (
        <span>
          <img src={arabicicon} className="language-img" alt="language-icon" />
          {t("headerQatar")}
        </span>
      ),
      code: "ar",
      command: () => {
        setSelectedLang("ar");
        changeLanguage("ar");
      },
    },
  ];

  const [lang, setLang] = useState(false);
  const [selectedLang, setSelectedLang] = useState("ar");

  const changeLanguage = (lang) => {
    setSelectedLang(lang);
    i18n.changeLanguage(lang);
    localStorage.setItem("i18nextLng", lang);
    setLang(!lang);
  };
  useEffect(() => {
    // set default language
    if (localStorage.getItem("i18nextLng")) {
      changeLanguage(localStorage.getItem("i18nextLng"));
    } else {
      changeLanguage("ar");
    }
    // set default country
    if (addDeleteGetLocalStorage(STORAGE.COUNTRY_DB, {}, "get")) {
      setSelectedDB(addDeleteGetLocalStorage(STORAGE.COUNTRY_DB, {}, "get"));
    } else {
      setSelectedDB("Iraq");
    }
    // set default currency
    if (addDeleteGetLocalStorage(STORAGE.CURRENCY, {}, "get")) {
      setSelectedCurrency(
        addDeleteGetLocalStorage(STORAGE.CURRENCY, {}, "get")
      );
    } else {
      setSelectedCurrency("USD");
    }
  }, [isChangeLang]);

  return (
    <>
      <div className="container">
        <div className="top-toolbar">
          <div className="top-toolbar-left">
            <div className="top-toolbar-left-item">
              <i className="pi pi-check"></i>
              <p className="top-toolbar-left-item-content">
                <Link style={{ color: "#E8336E" }} to={"/"}>
                  {t("FreeExchange")}
                </Link>{" "}
                {t("OfProductsWithin30Days")}
              </p>
            </div>
            <div className="top-toolbar-left-item">
              <i className="pi pi-check"></i>
              <p className="top-toolbar-left-item-content">
                <Link to={"/"}>{t("PremiumBrandProducts")}</Link>{" "}
                {t("ForAnAffordablePrice")}
              </p>
            </div>
          </div>
          <div className="top-toolbar-right">
            <div className="top-toolbar-right-item">
              <Menu
                model={Country}
                popup
                ref={menuCountry}
                className="tool-dropdown"
              />
              <div
                className="currency-drop"
                onClick={(e) => menuCountry.current.toggle(e)}
              >
                <span className="currency-text">
                  {/* {t("Iraq")}  */}
                  {Country.find((db) => db.code === selectedDB)?.label}
                </span>
                <img
                  src={dawnArrowBlack}
                  alt="dawnArrowBlack"
                  className="dawnArrowBlack"
                />
              </div>
            </div>

            <div className="top-toolbar-right-item">
              <Menu
                model={languages}
                popup
                ref={menuLanguage}
                className="language-menu"
              />
              <div
                className="language-drop"
                onClick={(e) => menuLanguage.current.toggle(e)}
              >
                <span className="language-text">
                  {languages.find((lang) => lang.code === selectedLang)?.label}
                </span>
                <img
                  src={dawnArrowBlack}
                  className="dawnArrowBlack"
                  alt="dawnArrowBlack"
                  onClick={() => setLang(!lang)}
                />
              </div>
            </div>

            <div className="top-toolbar-right-item">
              <Menu
                model={Currency}
                popup
                ref={menuCurrency}
                className="tool-dropdown"
              />
              <div
                className="currency-drop"
                onClick={(e) => menuCurrency.current.toggle(e)}
              >
                <span className="currency-text">
                  {
                    Currency.find((lang) => lang.code === selectedCurrency)
                      ?.label
                  }
                </span>
                <img
                  src={dawnArrowBlack}
                  alt="dawnArrowBlack"
                  className="dawnArrowBlack"
                  onClick={() => setLang(!lang)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopToolbar;
