/** @format */
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "primereact/button";
import "./ProductDetailPage.scss";
import FrontToolbar from "../../../global-modules/FrontToolbar";
import Footer from "../../layout/footer/Footer";
import Breadcrumb from "../../../global-modules/Breadcrumb";
import ProductInformation from "./ProductInformation";
import { useTranslation } from "react-i18next";
import ZoomSliderDetail from "../ZoomSlider/ZoomSliderDetail";
import shareIcon from "../../../assets/images/structure/share-icon.svg";
import infoIcon from "../../../assets/images/structure/info-yellow.svg";
import facebookIcon from "../../../assets/images/structure/facebook-icon.svg";
import twitterIcon from "../../../assets/images/structure/twitter-icon.svg";
import instagramIcon from "../../../assets/images/structure/instagram-icon.svg";
import pintrestIcon from "../../../assets/images/structure/pintrest.svg";
import { useCallback } from "react";
import globalRequest from "../../../prototype/globalRequest";
import { API_ROUTES, STORAGE } from "../../../Enum";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../../redux/reducers/snackbar";
import addDeleteGetLocalStorage from "../../../prototype/addDeleteGetLocalStorage";
import { changeLoader, currentLoader } from "../../../redux/reducers/loader";
import { fetchUserProductsCount } from "../../../redux/reducers/userProductsCount";
import NotifyMe from "../../../DialogBox/NotifyMe";
import {
  convertCurrency,
  returnRandomGuest,
} from "../../../Helpers/HelperFunctions";
import { currencyCoversionObject } from "../../../redux/reducers/currencyConversion";
import { Skeleton } from 'primereact/skeleton';

const ProductDetailPage = () => {
  const { t } = useTranslation();
  const [IncrDecr, setIncrDecr] = useState(1);
  let token = JSON.parse(
    addDeleteGetLocalStorage(STORAGE.AL_MAHARA_USER, {}, "get")
  )?.token;
  const language = localStorage.getItem("i18nextLng");
  const location = useParams();
  let productSlug = location?.slug;
  let IdOFproduct = location?.id;
  const [productImages, setProductImage] = useState(null);
  const [productId, setProductId] = useState(0);
  const [isClamped, setIsClamped] = useState(true);
  const [isClamped1, setIsClamped1] = useState(true);
  const [socialIcons, setSocialIcons] = useState(false);
  let locationName = window.location.href;
  const [notifyMeBtn, setNotifyMeBtn] = useState(false);
  const [notifyMeProductId, setNotifyMeProductId] = useState(null);
  const conversionRate = useSelector(currencyCoversionObject);
  let currency_am = addDeleteGetLocalStorage(STORAGE.CURRENCY, {}, "get");
  const loader = useSelector(currentLoader);

  const socialIconClick = () => {
    setSocialIcons(!socialIcons);
  };

  const toggleTextClamp = (ind) => {
    if (ind == 0) {
      setIsClamped(!isClamped);
    }
    if (ind == 1) {
      setIsClamped1(!isClamped1);
    }
  };

  const [productData, setProductData] = useState({
    category_details: {},
    subcategory_details: {},
    product_images: [],
    id: 0,
    title: "",
    product_specification: [],
    stock: false,
    selling_price: 0,
    discount_price: 0,
  });
  const dispatch = useDispatch();
  const country =
    addDeleteGetLocalStorage(STORAGE.COUNTRY_DB, {}, "get") || "Iraq";

  const handleIncrement = () => {
    setIncrDecr(IncrDecr + 1);
  };

  const handleDecrement = () => {
    setIncrDecr(IncrDecr <= 1 ? 1 : IncrDecr - 1);
  };

  const navigate = useNavigate();

  const productApi = useCallback(() => {
    let params = {};
    let localGuestId = addDeleteGetLocalStorage(STORAGE.GUEST_ID, {}, "get");
    if (!token && localGuestId) {
      params = { guestid: localGuestId };
    }
    dispatch(changeLoader(true));
    globalRequest(
      "get",
      API_ROUTES.PRODUCT_DETAIL(`${productSlug}/${IdOFproduct}`),
      {},
      { params: params },
      token ? true : false
    )
      .then((data) => {
        if (data?.ack === 1) {
          let productDetailTemp = { ...data?.data };

          // manage specifications data
          let product_specification = [];
          if (productDetailTemp?.product_specification?.length) {
            product_specification =
              productDetailTemp?.product_specification?.filter((obj) => {
                return obj?.product_specification_value;
              });
          }
          productDetailTemp["product_specification"] = product_specification;
          productDetailTemp["stock"] =
            productDetailTemp?.stock !== null
              ? productDetailTemp?.stock?.stock -
                  productDetailTemp?.stock?.reserve_stock >
                0
                ? productDetailTemp?.stock
                : null
              : null;
          setProductData(productDetailTemp);

          // check if the product is present in the compare list or not
        }
      })
      .catch((err) => {
        navigate("/");
      })
      .finally(() => {
        dispatch(changeLoader(false));
      });
  });

  useEffect(() => {
    productApi();
  }, []);

  useEffect(() => {
    if (
      productData?.product_images !== undefined &&
      productData?.product_images.length
    ) {
      setProductImage(productData?.product_images);
    } else {
      setProductImage([]);
    }

    if (productData?.id) {
      setProductId(productData?.id);
    }
  }, [productData]);

  ///add to cart function
  const addToCart = useCallback(async (item, goToCart = null) => {
    let localGuestId = addDeleteGetLocalStorage(STORAGE.GUEST_ID, {}, "get");
    let guestId = localGuestId ? localGuestId : returnRandomGuest();
    let sendPrice = productPrice(
      item?.is_todays_offer === 1 ? item?.discount_price : item?.selling_price,
      IncrDecr
    );
    let data = {
      productId: item?.id,
      quantity: +IncrDecr,
      price: sendPrice, //item?.discount_price,//send price with respect to quantity
      productType: item?.product_type,
    };
    if (!token && guestId) {
      data = { ...data, guestid: token ? "" : guestId.trim() };
    }

    dispatch(changeLoader(true));
    try {
      let res = await globalRequest(
        "post",
        API_ROUTES.ADD_TO_CART,
        data,
        {},
        token ? true : false
      );
      if (res?.ack == 1) {
        dispatch(changeLoader(false));
        addDeleteGetLocalStorage(STORAGE.GUEST_ID, guestId, "add", "single");
        dispatch(
          setSnackbar({
            isOpen: true,
            state: "success",
            message: t("AddedToCartSuccessfully"),
          })
        );
        dispatch(fetchUserProductsCount());
        let productDetailTemp = { ...productData };
        productDetailTemp.is_in_cart = 1;
        setProductData(productDetailTemp);
        if (goToCart) {
          navigate("/cart");
        }
      } else {
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            isOpen: true,
            state: "error",
            message: t("SomethingWentWrong"),
          })
        );
      }
    } catch (err) {
      dispatch(changeLoader(false));
      dispatch(
        setSnackbar({
          isOpen: true,
          state: "error",
          message: err.response.data.msg || t("SomethingWentWrong"),
        })
      );
    }
  });

  //notify me function
  const notifyMeFunction = useCallback((id) => {
    setNotifyMeProductId(id);
    setNotifyMeBtn(true);
  });

  //product price with respect to price range
  const productPrice = (price, quantity) => {
    //
    let priceRange = productData?.price_ranges?.find(
      (obj) => obj?.start_range <= quantity && obj?.end_range >= quantity
    );
    if (priceRange && productData?.is_wholesale == 1) {
      return priceRange?.wholesale_price;
    } else {
      return price;
    }
  };

  const handleChangeInputQuantity = (val) => {
    if (val > 0) {
      if (productData?.stock?.stock < val) {
        dispatch(
          setSnackbar({
            isOpen: true,
            state: "error",
            message:
              t("Only") +
              " " +
              productData?.stock?.stock +
              " " +
              t("ItemsAreAvailable"),
          })
        );
      } else {
        setIncrDecr(val);
      }
    }
  };

  return (
    <>
      <FrontToolbar />
      <div className="container">
        <Breadcrumb
          title={
            language == "en"
              ? productData?.category_details?.name_en
              : productData?.category_details?.name_ar
          }
          li={[
            {
              title:
                language == "en"
                  ? productData?.subcategory_details?.name_en
                  : productData?.subcategory_details?.name_ar,
              path: productData?.category_details?.id,
            },
            {
              title: productData?.title,
            },
          ]}
        />
        <section className="detail-section1">
          {loader && !productData?.title ?
          <div className="grid">
          <div className="col-12 sm:col-12 md:col-12 lg:col-7">
            <div className="flex flex-column align-items-center gap-3 py-5">
              <Skeleton className="w20rem border-round h-29rem" />
            </div>
          </div>
          <div className="col-12 sm:col-12 md:col-12 lg:col-5">
          <div className="flex flex-column align-items-center gap-3 py-5">
                  <Skeleton className="w-full border-round h-2rem" />
                  <Skeleton className="w-full border-round h-12rem" />
                  <Skeleton className="w-full border-round h-2rem" />
                  <Skeleton className="w-full border-round h-1rem" />
                  <Skeleton className="w-full border-round h-3rem" />
              </div>
          </div>
        </div> : <><div className="grid">
          <div className="col-12 sm:col-12 md:col-12 lg:col-7">
            <ZoomSliderDetail
              productImages={productImages}
              productId={productId}
            />
            <div className="logDescription-d">
              {productData?.long_description ? (
                <div className="description-wrap mb-5">
                  <h3 className="h3 mb-3">{t("Description")}</h3>
                  {/* <div
                    className={isClamped ? "line-ellips4" : ""}
                    dangerouslySetInnerHTML={{
                      __html: productData?.long_description,
                    }}
                  ></div> */}

                  <div
                    dangerouslySetInnerHTML={{
                      __html: isClamped
                        ? productData?.long_description?.slice(0, 200) +
                          `${
                            productData?.long_description?.length > 200
                              ? "..."
                              : ""
                          }`
                        : productData?.long_description,
                    }}
                  ></div>

                  {String(productData?.long_description).length > 200 ? (
                    <span
                      className="read-more"
                      onClick={() => {
                        toggleTextClamp(0);
                      }}
                    >
                      {isClamped ? t("ReadMore") : t("ReadLess")}
                    </span>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
          <div className="col-12 sm:col-12 md:col-12 lg:col-5">
            <div className="product-top-text">
              <p
                className="p2 green truncated-text"
                style={{ maxWidth: "30%", wordBreak: "break-word" }}
              >
                {language == "en"
                  ? productData?.subcategory_details?.name_en
                  : productData?.subcategory_details?.name_ar}
              </p>
              &nbsp;|&nbsp;
              <p
                className="p2 truncated-text"
                style={{ maxWidth: "70%", wordBreak: "break-word" }}
              >
                {productData?.model_number}
              </p>
            </div>
            <h3 className="h3 my-3 capitalize-first-letter">
              {productData?.title}
            </h3>
            <div className="share-compare-row">
              <p className="p2" onClick={socialIconClick}>
                <img src={shareIcon} alt="share" className="icon24" />
                <span>{t("Share")}</span>
              </p>
              {socialIcons && (
                <div className="socail-box-parent" onClick={socialIconClick}>
                  <img
                    src={facebookIcon}
                    alt="facebook"
                    onClick={() => {
                      let url = `https://www.facebook.com/sharer/sharer.php?u=${locationName}`;
                      window.open(url, "_blank");
                    }}
                    className="social-icon"
                  />
                  <img
                    src={twitterIcon}
                    onClick={() => {
                      let url = `http://www.twitter.com/share?url=${locationName}`;
                      window.open(url, "_blank");
                    }}
                    alt="twitter"
                    className="social-icon"
                  />
                  <img
                    src={instagramIcon}
                    onClick={() => {
                      // let url = `https://www.instagram.com/?url=${locationName}`;
                      // window.open(url, "_blank");
                      const instagramShareUrl = `https://www.instagram.com/create/story/?url=${locationName}&caption=${productData?.title}`;
                      window.open(instagramShareUrl, "_blank");
                    }}
                    alt="instagram"
                    className="social-icon"
                  />
                  <img
                    src={pintrestIcon}
                    onClick={() => {
                      // let url = `http://pinterest.com/pin/create/button/?url=${locationName}`;
                      // window.open(url, "_blank");
                      const pinterestShareUrl = `https://www.pinterest.com/pin/create/button/?url=${locationName}&media=${productData?.product_images[0]?.image_url}&description=${productData?.title}`;
                      window.open(pinterestShareUrl, "_blank");
                    }}
                    style={{ height: "25px" }}
                    alt="youtube"
                    className="social-icon"
                  />
                </div>
              )}
              {productData?.price_ranges?.length &&
              productData?.is_wholesale == 1 ? (
                <div className="wholesale-box">
                  <p className="p2 yellow ">
                    <span>{t("WholesalePrice")}</span>
                    <img src={infoIcon} alt="info" className="icon24" />
                  </p>
                  <div className="wholesale-tool-box">
                    <div className="wholesale-tool-head">
                      <p className="p2">{t("QuantityRange")}</p>
                      <p className="p2">{t("Price")}</p>
                    </div>
                    <div className="wholesale-tool-body">
                      {productData?.price_ranges?.map((obj, ind) => {
                        return (
                          <div className="wholesale-tool-row" key={ind}>
                            <p className="p2">
                              {obj?.start_range} to {obj?.end_range}
                            </p>
                            <p className="p2">
                              {currency_am +
                                " " +
                                convertCurrency(
                                  obj?.wholesale_price,
                                  conversionRate
                                )}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <p className="p2 sort-description">
              <span>
                <div>
                  {isClamped1
                    ? productData?.short_description?.slice(0, 140) +
                      `${
                        productData?.short_description?.length > 140
                          ? "..."
                          : ""
                      }`
                    : productData?.short_description}
                </div>
              </span>{" "}
              {String(productData?.short_description).length > 140 ? (
                <span
                  className="read-more"
                  onClick={() => {
                    toggleTextClamp(1);
                  }}
                >
                  {isClamped1 ? t("ReadMore") : t("ReadLess")}
                </span>
              ) : null}
            </p>
            {productData?.stock !== null ? (
              <p className="p2 in-stock">
                <i className="pi pi-check"></i> <span>{t("InStock")}</span>
              </p>
            ) : (
              <p className="p2 outOff-stock">
                <i className="pi pi-check"></i> <span>{t("outOfStock")}</span>
              </p>
            )}

            <div className="price-row">
              {/* new price data with conditions */}
              <>
                <h6 className="h6">
                  {currency_am +
                    " " +
                    convertCurrency(
                      productPrice(
                        productData?.is_todays_offer === 1
                          ? productData?.discount_price
                          : productData?.selling_price,
                        IncrDecr
                      ),
                      conversionRate
                    )}
                </h6>

                {productData?.is_todays_offer === 1 && (
                  <>
                    {productData?.discount_price !==
                    productData?.selling_price ? (
                      <p
                        className={`p3 ${
                          productData?.discount_price !==
                          productData?.selling_price
                            ? "cross"
                            : ""
                        }`}
                      >
                        {currency_am +
                          " " +
                          convertCurrency(
                            productData?.selling_price,
                            conversionRate
                          )}
                      </p>
                    ) : null}
                  </>
                )}
              </>
            </div>
            {country === "UAE" && (
              <p
                className="p3 mt-1"
                style={{
                  color: "#f9b000",
                  fontFamily: "OpenSans-Medium",
                }}
              >
                {t("Exclusiveoftax")}
              </p>
            )}
            <div className="quantity-row">
              {productData?.stock !== null && productData.is_in_cart == 0 ? (
                <>
                  <div className="quantity-box">
                    {/* <input type="number" value={IncrDecr} /> */}
                    <input
                      type="number"
                      className="input"
                      min={1}
                      value={IncrDecr}
                      onChange={(e) => {
                        let num = +e.target.value;
                        handleChangeInputQuantity(num);
                      }}
                    />
                    <div className="quantity-btn">
                      <i
                        className="pi pi-angle-up"
                        onClick={() => {
                          // check if stock is greater than increment
                          if (productData?.stock?.stock > IncrDecr) {
                            handleIncrement();
                          } else {
                            dispatch(
                              setSnackbar({
                                isOpen: true,
                                state: "error",
                                message:
                                  t("Only") +
                                  " " +
                                  productData?.stock?.stock +
                                  " " +
                                  t("ItemsAreAvailable"),
                              })
                            );
                          }
                        }}
                      ></i>
                      <i
                        className="pi pi-angle-down"
                        onClick={handleDecrement}
                      ></i>
                    </div>
                  </div>
                  <Button
                    size="large"
                    severity="Success"
                    label={t("AddtoCart")}
                    onClick={() => addToCart(productData)}
                  />
                  <Button
                    size="large"
                    onClick={() => {
                      addToCart(productData, "goToCart");
                    }}
                    className="w-full sm:mx-2"
                    severity="danger"
                    outlined
                    label={t("BuyitNow")}
                  />
                </>
              ) : productData?.stock !== null &&
                productData.is_in_cart == 1 ? (
                <Button
                  size="large"
                  className="w-full"
                  severity="Success"
                  onClick={() => {
                    navigate("/cart");
                  }}
                  label={t("GoToCart")}
                />
              ) : (
                <Button
                  size="large"
                  className="w-full"
                  severity="Success"
                  label={t("NotifyMe")}
                  onClick={() => {
                    notifyMeFunction(productData?.id);
                  }}
                />
              )}
            </div>

            <div className="product-features">
              <p className="p2">
                <i className="pi pi-check"></i>{" "}
                <span>{t("ClickwithconfidenceHasslefreereturns")}</span>
              </p>
              <p className="p2">
                <i className="pi pi-check"></i>{" "}
                <span>{t("Unparalleledqualityunmatchedperformance")}</span>
              </p>
              <p className="p2">
                <i className="pi pi-check"></i>{" "}
                <span>{t("Highendexpertiseatyourfingertips")}</span>
              </p>
              <p className="p2">
                <i className="pi pi-check"></i>{" "}
                <span>
                  {" "}
                  <b>{t("100%SatisfactionGuaranteed")}</b>
                </span>
              </p>
            </div>
            <div className="logDescription-m">
              {productData?.long_description ? (
                <div className="description-wrap mb-5">
                  <h3 className="h3 mb-3">{t("Description")}</h3>
                  {/* <div
                    className={isClamped ? "line-ellips4" : ""}
                    dangerouslySetInnerHTML={{
                      __html: productData?.long_description,
                    }}
                  ></div> */}

                  <div
                    dangerouslySetInnerHTML={{
                      __html: isClamped
                        ? productData?.long_description?.slice(0, 200) +
                          `${
                            productData?.long_description?.length > 200
                              ? "..."
                              : ""
                          }`
                        : productData?.long_description,
                    }}
                  ></div>

                  {String(productData?.long_description).length > 200 ? (
                    <span
                      className="read-more"
                      onClick={() => {
                        toggleTextClamp(0);
                      }}
                    >
                      {isClamped ? t("ReadMore") : t("ReadLess")}
                    </span>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {productData?.product_specification?.length ? (
          <div className="grid">
            <div className="col-12 sm:col-12 md:col-12 lg:col-12">
              <h3 className="h3 mb-4">{t("ProductInformation")}</h3>
              <div className="information-box">
                {productData?.product_specification?.map((obj, ind) => {
                  return (
                    <ProductInformation
                      key={ind}
                      brand={obj?.name}
                      name={
                        obj?.product_specification_value?.specification_value
                      }
                    />
                  );
                })}
              </div>
            </div>
          </div>
        ) : null}</> 
        }
        </section>
      </div>
      <Footer />
      {notifyMeBtn && (
        <NotifyMe
          handleClose={() => setNotifyMeBtn(false)}
          productId={notifyMeProductId}
        />
      )}
    </>
  );
};

export default ProductDetailPage;
