import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

// images
import modalClose from "../assets/images/structure/modal-close.svg";
import {
  currentPlaceOrderData,
  setPlaceOrderData,
} from "../redux/reducers/placeOrderData";
import { setSnackbar } from "../redux/reducers/snackbar";

const CompanyDetails = ({ closePopup }) => {
  const { t } = useTranslation();
  //states
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const currentOrder = useSelector(currentPlaceOrderData);
  const [companyName, setCompanyName] = useState(
    currentOrder?.companyName ? currentOrder?.companyName : ""
  );
  const [trnNumber, setTrnNumber] = useState(
    currentOrder?.trnNumber ? currentOrder?.trnNumber : ""
  );
  const [companyErrors, setCompanyErrors] = useState({
    companyName: "",
    trnNumber: "",
  });

  const submit = () => {
    let errors = false;
    let companyErrs = {...companyErrors};
    if (companyName == "") {
      companyErrs = {...companyErrs, companyName: "CompanyNameCannotBeBlank"}
      errors = true;
    }
    if (trnNumber == "") {
      companyErrs = {...companyErrs, trnNumber: "TrnNumberCannotBeBlank"}
      errors = true;
    }
    if (errors) {
      setCompanyErrors(companyErrs);
      return;
    }

    if (companyName && trnNumber) {
      dispatch(
        setPlaceOrderData({
          ...currentOrder,
          trnNumber: trnNumber,
          companyName: companyName,
        })
      );
      closePopup();
    }
  };

  return (
    <>
      <Dialog
        visible={true}
        onHide={() => closePopup()}
        draggable={false}
        resizable={false}
        closable={false}
        showHeader={false}
        className="dialog-407"
      >
        <img
          src={modalClose}
          alt="close"
          className="modal-close"
          onClick={() => closePopup()}
        />
        <h3 className="h3 mb-5">{t("companyDetails")}</h3>
        <div className="mb-4">
          <span className="p-float-label">
            <InputText
              className={companyErrors.companyName ? "p-invalid" : ""}
              id="companyName"
              value={companyName}
              onChange={(e) => {
                setCompanyErrors({ ...companyErrors, companyName: "" });
                let value = e.target.value;
                if (value.length > 100) {
                  return;
                }
                setCompanyName(value);
              }}
            />
            <label htmlFor="companyName">
              {t("companyName")} <span className="req-star">*</span>
            </label>
          </span>
          {companyErrors.companyName && (
            <small id="companyName-help" className="p-error p-d-block">
              {t(companyErrors.companyName)}
            </small>
          )}
        </div>
        <div className="mb-4">
          <span className="p-float-label">
            <InputText
              className={companyErrors.trnNumber ? "p-invalid" : ""}
              id="trnNumber"
              value={trnNumber}
              onChange={(e) => {
                setCompanyErrors({ ...companyErrors, trnNumber: "" });
                let value = e.target.value;
                if (value.length > 20) {
                  return;
                }
                setTrnNumber(value);
              }}
            />
            <label htmlFor="trnNumber">
              {t("trnNumber")} <span className="req-star">*</span>
            </label>
          </span>
          {companyErrors.trnNumber && (
            <small id="trnNumber-help" className="p-error p-d-block">
              {t(companyErrors.trnNumber)}
            </small>
          )}
        </div>
        <Button
          disabled={loading}
          severity="Success"
          size="large"
          className="w-full mt-2"
          label={t("confirm")}
          onClick={submit}
        />
      </Dialog>
    </>
  );
};

export default CompanyDetails;
