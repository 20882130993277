import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./MyOrder.scss";
import FrontToolbar from "../../global-modules/FrontToolbar";
import Footer from "../layout/footer/Footer";
import Breadcrumb from "../../global-modules/Breadcrumb";
import noImage from "../../assets/images/products/noImage.webp";
import { InputText } from "primereact/inputtext";
import { useCallback } from "react";
import globalRequest from "../../prototype/globalRequest";
import { changeLoader, currentLoader } from "../../redux/reducers/loader";
import { useDispatch, useSelector } from "react-redux";
import { API_ROUTES, STORAGE } from "../../Enum";
import { useState } from "react";
import { useEffect } from "react";
import addDeleteGetLocalStorage from "../../prototype/addDeleteGetLocalStorage";
import EmptyWishlist from "../Wishlist/Empty-wishlist";
import { axiosConstant } from "../../Helpers/Axios/Constants";
import visaWhite from "../../assets/images/structure/visa-white.svg";
const MyOrderNew = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loader = useSelector(currentLoader);
  let currency_am = addDeleteGetLocalStorage(STORAGE.CURRENCY, {}, "get");
  //stats
  const [allOrder, setAllOrder] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);

  const navigateToOrderDetail = (orderId) => {
    navigate(`/order-detail/${orderId}`);
  };

  const orderListApi = useCallback(() => {
    const params = {
      search: searchInput,
      limit: limit,
      offset: offset,
      sortby: "id",
      orderby: "DESC",
    };
    dispatch(changeLoader(true));
    globalRequest(
      "get",
      API_ROUTES.GET_ORDER_LIST,
      {},
      { params: params },
      true
    )
      .then((data) => {
        if (data?.ack == 1) {
          let allOrder = data?.data;
          setAllOrder(allOrder);
          setTotal(data?.count);
        } else {
          setAllOrder([]);
          setTotal(0);
        }
        dispatch(changeLoader(false));
      })
      .catch((err) => {
        // navigate("/");
        dispatch(changeLoader(false));
      });
  });

  useEffect(() => {
    orderListApi();
  }, [searchInput, limit]);

  //date formate
  const convertTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const period = hour >= 12 ? "PM" : "AM";
    const formattedHour = hour % 12 || 12;

    const formattedDate = `${day} ${month} ${year} - ${formattedHour}:${minute
      .toString()
      .padStart(2, "0")} ${period}`;

    // return formattedDate
    return (
      <span style={{ direction: "ltr", display: "inline-block" }}>
        <span>{`${day} ${month} ${year}`}</span> -
        <span>{`${formattedHour}:${minute
          .toString()
          .padStart(2, "0")} ${period}`}</span>
      </span>
    );
  };

  const handleSearch = (e) => {
    setSearchInput(e.target.value.trimStart());
  };

  ///pagination
  const [page, setPage] = useState(1);
  useEffect(() => {
    if (limit >= total) return;
    setLimit((prevLimit) => prevLimit + 10);
  }, [page]);

  useEffect(() => {
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: "20px", // Margin around the root (if you want to trigger earlier or later)
      threshold: 1.0, // Trigger when the entire target is visible
    };
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setPage((prevPage) => prevPage + 1);
      }
    }, options);
    // Set the target to observe
    const target = document.querySelector("#observe-target1");
    if (target) {
      observer.observe(target);
    }
    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, []);

  return (
    <>
      <FrontToolbar />
      <div className="container">
        <div className="flex flex-column sm:flex-row justify-content-between mb-4">
          <Breadcrumb title={t("myOrder")} />
          <span className="p-input-icon-right">
            {searchInput ? (
              <i
                className="pi pi-times"
                onClick={() => {
                  setSearchInput("");
                }}
              />
            ) : (
              <i className="pi pi-search" />
            )}
            <InputText
              value={searchInput}
              onChange={handleSearch}
              placeholder={t("searchAllOrders")}
              className="p-inputtext-sm"
            />
          </span>
        </div>
        <div className="grid" style={{ minHeight: "90vh" }}>
          <div className="col-12 sm:col-12 md:col-10 lg:col-12 mx-auto pb-5">
            <div className="order-boxes mb-4">
              <div className="order-gray-box flex-column sm:flex-row  flex align-items-start sm:align-items-center justify-content-between">
                <div className="">
                  <p className="p2 p2-bold mb-1">{t("orderPlaced")}</p>
                  <p className="p2">{t("OrderDate")} 12/12/2024 3:25 pm</p>
                </div>
                <p className="p2">{t("OrderId")}: #13546</p>
              </div>

              <div className="order-outline-box flex flex-column sm:flex-row sm:align-items-center justify-content-between">
                <div className="flex align-items-center justify-content-between">
                  <img
                    src={visaWhite}
                    alt="product image"
                    className="order-product-img"
                  />
                  <div className="width-set">
                    <p className="p2 p2-semibold mb-1">
                      <span className="max-350-ellipse">product title</span>
                      <span
                        className="p2 ml-2"
                        style={{ fontFamily: "OpenSans-Bold" }}
                      >
                        {` X 5`}
                      </span>
                    </p>
                    <p className="p2 wrapcontent">
                      15.40 cm (6.1-inch) Super Retina XDR display
                    </p>
                  </div>
                </div>
                <p className="p2 p2-semibold mt-2 sm:mt-0">USD 58999.00</p>
              </div>
            </div>
            <div className="order-boxes mb-4">
              <div className="order-gray-box flex-column sm:flex-row  flex align-items-start sm:align-items-center justify-content-between">
                <div className="">
                  <p className="p2 p2-bold mb-1">{t("Order Partially Confirmed")}</p>
                  <p className="p2">{t("OrderDate")}  12 January 2023 - 12:00 pm</p>
                </div>
                <p className="p2">{t("OrderId")}: #13546</p>
              </div>

              <div className="order-outline-box flex flex-column sm:flex-row sm:align-items-center justify-content-between">
                <div className="flex align-items-center justify-content-between">
                  <img
                    src={visaWhite}
                    alt="product image"
                    className="order-product-img"
                  />
                  <div className="width-set">
                    <p className="p2 p2-semibold mb-1">
                      <span className="max-350-ellipse">ASUS ROG Rapture GT-AX11000 AX11...  </span>
                      <span
                        className="p2 ml-2"
                        style={{ fontFamily: "OpenSans-Bold" }}
                      >
                        {` X 5`}
                      </span>
                    </p>
                    <p className="p2 wrapcontent">
                    Dual Core 880MHz CPU, 256MB RAM, 
                    </p>
                  </div>
                </div>
                <p className="p2 p2-semibold mt-2 sm:mt-0">AED 2500.00</p>
              </div>
            </div>
            {/* {allOrder.length > 0 &&
              allOrder?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="order-boxes mb-4"
                    onClick={() => {
                      navigateToOrderDetail(item?.id);
                    }}
                  >
                    <div className="order-gray-box flex-column sm:flex-row  flex align-items-start sm:align-items-center justify-content-between">
                      <div className="">
                        <p className="p2 p2-bold mb-1">
                          {item?.status == "placed" && t("orderPlaced")}
                          {item?.status == "confirmed" && t("orderConfirmed")}
                          {item?.status == "shipped" && t("orderShipped")}
                          {item?.status == "delivered" && t("orderDelivered")}
                          {item?.status == "canceled" && t("orderCancelled")}
                          {item?.status == "returned" && t("orderReturned")}
                          {item?.status == "pending" && t("orderPending")}
                        </p>
                        <p className="p2">
                          {t("OrderDate")} {convertTimestamp(item?.createdAt)}
                        </p>
                      </div>
                      <p className="p2">
                        {t("OrderId")}: #{item?.order_id}
                      </p>
                    </div>
                    {item?.order_details?.length > 0 &&
                      item?.order_details?.map((ele, ind) => {
                        let featuredImg;
                        let arr = ele?.product?.product_images;
                        arr?.map((ele1) => {
                          if (ele1.is_featured == 1) {
                            featuredImg = ele1.image_url;
                          }
                        });
                        return (
                          <div
                            key={ind}
                            className="order-outline-box flex flex-column sm:flex-row sm:align-items-center justify-content-between"
                          >
                            <div className="flex align-items-center justify-content-between">
                              <img
                                src={featuredImg}
                                alt="product image"
                                className="order-product-img"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = noImage;
                                }}
                              />
                              <div className="width-set">
                                <p className="p2 p2-semibold mb-1">
                                  <span className="max-350-ellipse">
                                    {ele?.product_title}{" "}
                                  </span>
                                  <span
                                    className="p2 ml-2"
                                    style={{ fontFamily: "OpenSans-Bold" }}
                                  >
                                    {` X ${ele?.quantity}`}
                                  </span>
                                </p>
                                <p className="p2 wrapcontent">
                                  {ele?.product?.short_description}{" "}
                                </p>
                              </div>
                            </div>
                            <p className="p2 p2-semibold mt-2 sm:mt-0">
                              {item?.currency}{" "}
                              {parseFloat(ele?.product_price).toFixed(2)}{" "}
                            </p>
                          </div>
                        );
                      })}
                  </div>
                );
              })} */}
            {!loader && allOrder.length == 0 && (
              <EmptyWishlist page="order_list" />
            )}
          </div>
        </div>
        <div id="observe-target1" style={{ height: "10px" }}></div>
      </div>
      <Footer />
    </>
  );
};
export default MyOrderNew;
