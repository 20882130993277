import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Login from "../../DialogBox/Login";
import ForgetPassword from "../../DialogBox/ForgotPassword";
import ResetPassword from "../../DialogBox/ResetPassword";
// images
import logo from "../../assets/images/logo/header-logo.svg";
import { currentAuth } from "../../redux/reducers/auth";
import { useSelector } from "react-redux";
import SendChangePasswordUrl from "../../DialogBox/SendChangePasswordUrl";

const LeftContent = ({}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useSelector(currentAuth);
  const [openLogin, setOpenLogin] = useState(false);
  const [openForgetPassword, setOpenForgetPassword] = useState(false);
  const [openResetPassword, setOpenResetPassword] = useState(false);

  // redirect to home if user is logged in and if user is not on the login page
  useEffect(() => {
    if (auth?.token && !openLogin) {
      navigate("/");
    }
  }, [auth, openLogin]);

  return (
    <>
      <div className="signup-left-content">
        <Link to="/" className="signup-left-logo">
          <img src={logo} alt="logo" />
        </Link>
        <div className="relative">
          <h1 className="h1 white">
            {t("Afewclicksawayfromyourshoppingexperience")}
          </h1>
          <p className="p2 white">
            {t("DiscoverthebestbrandsinProfessionalITworld")}
          </p>
        </div>
        <p className="p1 white">
          {t("Alreadyhaveanaccount")}{" "}
          <span className="yellow" onClick={() => setOpenLogin(true)}>
            {t("LogIn")}
          </span>
        </p>
      </div>
      {openLogin ? (
        <Login
          handleClose={() => {
            setOpenLogin(false);
          }}
          openForget={() => {
            setOpenForgetPassword(true);
            setOpenLogin(false);
          }}
        />
      ) : null}
      {openForgetPassword ? (
        <ForgetPassword
          handleClose={() => {
            setOpenForgetPassword(false);
          }}
          openReset={() => {
            setOpenResetPassword(true);
            setOpenForgetPassword(false);
          }}
        />
      ) : null}
      {openResetPassword ? (
        <SendChangePasswordUrl
          handleClose={() => {
            setOpenResetPassword(false);
          }}
        />
      ) : null}
    </>
  );
};

export default LeftContent;
