import { createSlice } from "@reduxjs/toolkit";
import { API_ROUTES } from "../../Enum";
import globalRequest from "../../prototype/globalRequest";

const getGetSocialMediaLinks = async () => {
  try {
    const res = await globalRequest(
      "get",
      API_ROUTES.GET_SOCIAL_MEDIA_LINKS,
      {},
      {}
    );
    // return res?.data; // Return the data object
    let allLinks = res?.data[0];
    return {
      insta: allLinks?.instagram_link,
      facebook: allLinks?.facebook_link,
      twitter: allLinks?.twitter_link,
      youtube: allLinks?.youtube_link,
    };
  } catch (error) {
    return {
      insta: "",
      facebook: "",
      twitter: "",
      youtube: "",
    }; // Return default values on error
  }
};

export const fetchSocialMediaLinks = () => async (dispatch) => {
  const data = await getGetSocialMediaLinks();
  dispatch(setSocialMediaLinks(data)); // Dispatch the retrieved data to the store
};

export const socialMediaLinks = createSlice({
  name: "socialMediaLinks",
  initialState: {
    value: {
      insta: "",
      facebook: "",
      twitter: "",
      youtube: "",
    },
  },
  reducers: {
    setSocialMediaLinks: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setSocialMediaLinks } = socialMediaLinks.actions;
export const socialMediaLinksObject = (state) => state.socialMediaLinks.value;
export default socialMediaLinks.reducer;
