import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Bundle.scss";
import FrontToolbar from "../../../global-modules/FrontToolbar";
import Footer from "../../layout/footer/Footer";
// images
import topBanner from "../../../assets/images/structure/bundle-banner.jpg";
import noImage from "../../../assets/images/products/noImage.webp";
import Breadcrumb from "../../../global-modules/Breadcrumb";
import Banner from "../Banner/Banner";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { useEffect } from "react";
import globalRequest from "../../../prototype/globalRequest";
import { changeLoader, currentLoader } from "../../../redux/reducers/loader";
import { setSnackbar } from "../../../redux/reducers/snackbar";
import { useState } from "react";
import { axiosConstant } from "../../../Helpers/Axios/Constants";
import { API_ROUTES, STORAGE } from "../../../Enum";
import addDeleteGetLocalStorage from "../../../prototype/addDeleteGetLocalStorage";
import EmptyWishlist from "../../Wishlist/Empty-wishlist";
import { currencyCoversionObject } from "../../../redux/reducers/currencyConversion";
import { convertCurrency } from "../../../Helpers/HelperFunctions";
import { Base64 } from "js-base64";
const Bundle = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let currency_am = addDeleteGetLocalStorage(STORAGE.CURRENCY, {}, "get");
  const routerProductDetail = (slug, id) => {
    navigate(
      `/bundle-detail-page/${slug}/${Base64.encode(encodeURIComponent(id))}`
    );
  };
  const loader = useSelector(currentLoader);
  const conversionRate = useSelector(currencyCoversionObject);
  let token = JSON.parse(
    addDeleteGetLocalStorage(STORAGE.AL_MAHARA_USER, {}, "get")
  )?.token;

  //states for bundle listing
  const limit = 12;
  const [offset, setOffset] = useState(0);
  const [bundleList, setBundleList] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);

  //get all products and store to the redux store
  const getAllProductListApi = useCallback((params) => {
    let localGuestId = addDeleteGetLocalStorage(STORAGE.GUEST_ID, {}, "get");
    if (!token && localGuestId) {
      params = { ...params, guestid: localGuestId };
    }
    dispatch(changeLoader(true));
    globalRequest(
      "get",
      API_ROUTES.GET_PRODUCT_LIST,
      {},
      { params: params },
      token ? true : false
    )
      .then((data) => {
        dispatch(changeLoader(false));
        if (data?.ack == 1) {
          setBundleList([...bundleList, ...data?.data]);
          setTotalProducts(data?.count);
        } else {
          setBundleList([]);
          setTotalProducts(0);
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            message: "Something went wrong with the API " + err,
            isOpen: true,
            state: "error",
          })
        );
      });
  });

  useEffect(() => {
    let params = {
      limit: limit,
      offset: offset,
      bundle: 1,
    };

    getAllProductListApi(params);
  }, [offset]);

  ///pagination
  const [page, setPage] = useState(1);
  useEffect(() => {
    if (bundleList.length >= totalProducts) return;
    setOffset((prevOffset) => prevOffset + 12);
  }, [page]);

  useEffect(() => {
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: "20px", // Margin around the root (if you want to trigger earlier or later)
      threshold: 1.0, // Trigger when the entire target is visible
    };
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setPage((prevPage) => prevPage + 1);
      }
    }, options);
    // Set the target to observe
    const target = document.querySelector("#observe-target");
    if (target) {
      observer.observe(target);
    }
    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, []);

  return (
    <>
      <FrontToolbar />
      <div className="container">
        <Breadcrumb title={t("Bundle")} />
        <section className="bundle-section1">
          <Banner
            imgBanner={topBanner}
            title={t("Bundle")}
            paragraph={t(
              "BuyBundlesWhichConsistOfProductsFromDifferentCategories"
            )}
          />
          <div className="product-row">
            {bundleList.length > 0 &&
              bundleList.map((item, index) => {
                return (
                  <div className="product-box" key={index}>
                    <div className="product-image-box">
                      <img
                        src={item?.product_images[0]?.image_url}
                        alt="product image"
                        className="product-image"
                        onClick={() => {
                          routerProductDetail(item?.slug, item?.id);
                        }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = noImage;
                        }}
                      />
                    </div>
                    <div
                      className="product-content"
                      onClick={() => {
                        routerProductDetail(item?.slug, item?.id);
                      }}
                    >
                      <h6 className="h6 capitalize-first-letter">
                        {item?.title}
                      </h6>
                      <p
                        className="p3 truncated-text"
                        dangerouslySetInnerHTML={{
                          __html: item?.short_description,
                        }}
                      ></p>
                      <div className="price-row">
                        <p className="p2">
                          {currency_am +
                            " " +
                            convertCurrency(
                              item?.discount_price,
                              conversionRate
                            )}
                        </p>
                        {item?.discount_price !== item?.selling_price ? 
                             <p className={`p3 ${item?.discount_price!==item?.selling_price ? "cross" : ""}`}>
                             {currency_am +
                               " " +
                               convertCurrency(
                                 item?.selling_price,
                                 conversionRate
                               )}
                           </p>: null
                            }
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          {!loader && bundleList.length == 0 && (
            <EmptyWishlist page="bundle_list" />
          )}
          <div id="observe-target" style={{ height: "10px" }}></div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Bundle;
