{
  /**
   *  this file contain all api routes
   */
}
export const API_ROUTES = {
  PRODUCT_DETAIL: (id) => `products/product-details/${id}`,
  BUNDLE_DETAIL: (id) => `products/bundle-product-details/${id}`,
  COMPARE_PRODUCT: "products/compare-product-name",
  P_FAVORITE_PRODUCT: "favourite-product/add-favorite-product",
  G_FAVORITE_PRODUCT: (id) =>
    `favourite-product/check-product-is-favorite-or-not/${id}`,
  ADD_TO_CART: `cart/add-to-cart`,
  GET_CART_PRODUCTS: `cart/get-cart-items`,
  REMOVE_CART_PRODUCT: (id) => `cart/remove-cart-item/${id}`,
  UPDATE_CART_PRODUCT: `cart/update-cart-item`,
  GET_COUPON_CODE: `coupons/get-coupons`,
  GET_ADDRESS: `address/get-address`,
  DELETE_ADDRESS: `address/delete-address`,
  CHECK_MOBILE_NUMBER: `address/check-mobile-verify-status`,
  SEND_OTP: `address/send-otp-on-Mobile`,
  ADD_ADDRESS: `address/add-address`,
  UPDATE_ADDRESS: `address/update-address`,
  PLACE_ORDER: "order/place-Order",
  GET_PICKUP_ADDRESS: "address/get-pickup-address",
  GET_ORDER_LIST: "order/get-order-list",
  ORDER_DETAILS: (id) => `order/get-order-details/${id}`,
  CHANGE_ORDER_STATUS: `order/update-order-status`,
  GET_CMS_POLICY: `cms/get-cms`,
  GET_SOCIAL_MEDIA_LINKS: `cms/get-social-links`,
  SEND_CONATCT_US_REQUEST: `cms/create-contact-us`,
  NEWS_LETTER: `news/add-news-latter`,
  NOTIFY_ME_PRODUCT: `products/add-notify`,
  GET_HOMEPAGE_PRODUCTS: `products/product-home-page`,
  GET_CURRENCY_CONVERSION: `currency-conversion/get-currency-conversion`,
  VERIFY_OTP: `address/verify-otp`,
  GET_ENCRIPTED_DATA: `order/encrypt-data`,
  CHECK_PRIVATE_COUPON: (coupon) => `coupons/check-coupon?code=${coupon}`,
  GET_CONTACT_INFORMATION: `admin-contact-information/admin-contact-information`,
  GET_ARAMEX_SHIPING_CHARGES: `shipping/aramax-calculation`,
  GET_SHIPPING_CHARGES: `shipping/shiping-calculation`,
  GET_CITY_IDS: `address/get-city-ids`,
  GET_COUNTY_IDS: (id) => `address/get-county?cityid=${id}`,
  GET_COMPARE_PRODUCT_DETAILS: `products/product-details-compare`,
  ADD_BANK_DETAIL: `order/bank-details`,
  //auth routes
  LOGIN: `auth/login`,
  SIGNUP: `auth/signup`,
  EMAIL_VERIFICATION: `auth/email-verify-otp`,
  SEND_EMAIL_VERIFICATION_OTP: `auth/email-verify`,
  FORGOT_PASSWORD: `auth/forgot-password`,
  FORGOT_PASSWORD_CHANGE: `auth/forgot-password-change`,
  SET_CHANGE_CUSTOMER_PASSWORD: (id) => `auth/set-password-user/${id}`,
  CHANG_PASSWORD: `user/change-password`,
  GET_USER_PROFILE: `user/profile`,
  UPDATE_USER_PROFILE: `user/update-profile`,
  SOCIAL_LOGIN: `auth/social-login`,
  //product routes
  SEARCH_PRODUCTS: `products/searching-product`,
  GET_PRODUCT_LIST: `products/product-listings`,
  GET_CATEGORY: `category/get-category`,
  GET_WISHLIST: `favourite-product/get-favorite-product`,
  REMOVE_FROM_WISHLIST: `favourite-product/delete-favorite-product`,
  GET_CART_WISHLIST_COUNT: `products/product-count`,
  PRODUCT_LIST_BY_HOME_CATEGORY: "products/product-list",
  UPDATE_USER_ID: `cart/cart-update-user-id`,
  REPLACE_LOGIN_USER_CART: `cart/login-update-cart`,
};

export const STORAGE = {
  COMPARE_PRODUCT: "fANOFEfEsr",
  AL_MAHARA_USER: "mIZWsYFClS", //al_mahar_user
  COUNTRY_DB: "IbieTGLUwk", //country_db
  SESSION_TIME: "KLlXoZeBxI", //session_time
  FORGOT_MAIL: "YOTLcYOlRq", //forgot_email
  CURRENCY: "ziZpDWRTHT", //currency_am
  LOCAL_FILTERS: "JeQZRHCTFD", //local_filters
  CURRENCY_CONVERSION: "PxbovgnCDQ", //currency_conversion
  IS_VISITED: "QXZQXZQXZQ", //is_visited
  GUEST_ID: "BBhExzePgp", //guest_id
  GUEST_WHISLIST: "eaVOWhFJxa", //guest_whislist
  GUEST_WHISLIST_LOCAL: "VZXMgnfbDW", //guest_whislist_local_with_db
  USER_LAT_LNG: "zMmOmpYKBr", //user location lat lng
};

// dzUIVkHsVu
// hWwWZKenQA
// lOKSbfYArV
// gTbTlgVQIz
// GLciMeNIfc
// HQVBhUSZcS
