import { createSlice } from "@reduxjs/toolkit";
import globalRequest from "../../prototype/globalRequest";
import addDeleteGetLocalStorage from "../../prototype/addDeleteGetLocalStorage";
import { API_ROUTES, STORAGE } from "../../Enum";

const getGetUserProductsCount = async () => {
  const user = JSON.parse(
    addDeleteGetLocalStorage(STORAGE.AL_MAHARA_USER, {}, "get")
  );
  let localGuestId = addDeleteGetLocalStorage(STORAGE.GUEST_ID, {}, "get");
  let wishlist =
    JSON.parse(
      addDeleteGetLocalStorage(STORAGE.GUEST_WHISLIST_LOCAL, {}, "get")
    ) || {};
  let dataBase = addDeleteGetLocalStorage(STORAGE.COUNTRY_DB, {}, "get");

  let params = {};
  if (!user?.token && localGuestId) params = { guestid: localGuestId }; // Set guest id if user token is not available
  if (!user?.token && !localGuestId) return { cartcount: 0, wishlistcount: 0 }; // Return default values if user token is not available
  try {
    const res = await globalRequest(
      "get",
      API_ROUTES.GET_CART_WISHLIST_COUNT,
      {},
      { params: params },
      user?.token ? true : false
    );
    // return cartcount from api and wishlistcount from localstorage if user is not logged in with respect to database
    if (!user?.token) {
      return {
        cartcount: res?.data?.cartcount,
        wishlistcount:
          dataBase == "Iraq"
            ? wishlist?.Iraq?.length
            : dataBase == "UAE"
            ? wishlist?.UAE?.length
            : 0,
      };
    }
    return res?.data; // Return the data object
  } catch (error) {
    return { cartcount: 0, wishlistcount: 0 }; // Return default values on error
  }
};

export const fetchUserProductsCount = () => async (dispatch) => {
  const data = await getGetUserProductsCount();
  dispatch(changeUserProductsCount(data)); // Dispatch the retrieved data to the store
};

export const userProductsCount = createSlice({
  name: "userProductsCount",
  initialState: {
    value: { cartcount: 0, wishlistcount: 0 },
  },
  reducers: {
    changeUserProductsCount: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeUserProductsCount } = userProductsCount.actions;
export const userProductsObj = (state) => state.userProductsCount.value;
export default userProductsCount.reducer;
