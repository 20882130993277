
import React from "react";
import { Link } from "react-router-dom";
import mailIcon from "../../../assets/images/structure/mail-icon.svg";
import callIcon from "../../../assets/images/structure/call-icon.svg";
import facebookIcon from "../../../assets/images/structure/facebook-icon.svg";
import twitterIcon from "../../../assets/images/structure/twitter-icon.svg";
import instagramIcon from "../../../assets/images/structure/instagram-icon.svg";
import youtubeIcon from "../../../assets/images/structure/youtube-icon.svg";   
import logoIcon from "../../../assets/images/logo/header-logo.svg"; 
import currencyIcon1 from "../../../assets/images/structure/currency-icon1.jpg"; 
import currencyIcon2 from "../../../assets/images/structure/currency-icon2.jpg";
import currencyIcon3 from "../../../assets/images/structure/currency-icon3.jpg";
import currencyIcon4 from "../../../assets/images/structure/currency-icon4.jpg";
import currencyIcon5 from "../../../assets/images/structure/currency-icon5.jpg";


import "./footer.scss"

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { socialMediaLinksObject } from "../../../redux/reducers/socialMediaLink";
import { contactInfoObject } from "../../../redux/reducers/contactInfo";

const Footer = () => {
  const { t } = useTranslation();
  const allLinks = useSelector(socialMediaLinksObject);
  const contactInfo = useSelector(contactInfoObject)
  let emails = contactInfo?.emails;
  let mobiles = contactInfo?.mobile_numbers;
  return (
    <>
      <div className="footer-wrapper">
        <div className="container">
          <div className="grid">
            <div className="col-12 sm:col-12 md:col-5 lg:col-4">
              <h6 className="h6">{t("CustomerSupport")}</h6>
              <p className="p2">{contactInfo?.company_details?.value}</p>
              <div className="footer-info">
                <img src={mailIcon} alt="mail" className="icon24" />
                <span className="info-content">
                  {emails[0]?.value ? `${emails[0]?.value}`:''}
                  {emails[1]?.value ? `, ${emails[1]?.value}`:''}
                  {emails[2]?.value ? `, ${emails[2]?.value}`:''}
                  </span>
              </div>
              <div className="footer-info">
                <img src={callIcon} alt="call" className="icon24" />
                <span className="info-content">
                  {mobiles[0]?.value ? `${mobiles[0]?.value}`:''}
                  {mobiles[1]?.value ? `, ${mobiles[1]?.value}`:''}
                  {mobiles[2]?.value ? `, ${mobiles[2]?.value}`:''}
                  </span>
              </div>
            </div>
            <div className="col-6 sm:col-6 md:col-4 lg:col-3">
              <h6 className="h6">{t("Support")}</h6>
              <Link to={"/contact"} className="footer-link">
                {t("ContactUs")}
              </Link>
              <Link to={"/terms-conditions"} className="footer-link">
                {t("TermsConditions")}
              </Link>
              <Link to={"/privacy-policy"} className="footer-link">
                {t("PrivacyPolicy")}
              </Link>
              <Link to={"/cookie-policy"} className="footer-link">
                {t("CookiePolicy")}
              </Link>
            </div>
            <div className="col-6 sm:col-6 md:col-3 lg:col-3">
              <h6 className="h6">{t("AboutUs")}</h6>
              <Link to={"/about"} className="footer-link">
                {t("About")}
              </Link>
              <Link to={"/delivery"} className="footer-link">
                {t("DeliveryPolicy")}
              </Link>
              <Link to={"/warranty"} className="footer-link">
                {t("WarrantyPolicy")}
              </Link>
              <Link to={"/return"} className="footer-link">
                {t("ReturnPolicy")}
              </Link>
              <Link to={"/shipping"} className="footer-link">
                {t("ShipingPolicy")}
              </Link>
            </div>
            <div className="col-12 sm:col-12 md:col-12 lg:col-2">
              <div className="right-side">
                <h6 className="h6">{t("FollowUs")}</h6>
                <div className="relative">
                  <Link target="_blank" to={allLinks?.facebook} className="social-icon">
                  <img src={facebookIcon} alt="facebook" className="social-icon" />
                  </Link>
                  <Link target="_blank" to={allLinks?.twitter} className="social-icon">
                  <img src={twitterIcon} alt="twitter" className="social-icon" />
                  </Link>
                  <Link target="_blank" to={allLinks?.insta} className="social-icon">
                  <img src={instagramIcon} alt="instagram" className="social-icon" />
                  </Link>
                  <Link target="_blank" to={allLinks?.youtube} className="social-icon">
                  <img src={youtubeIcon} alt="youtube" className="social-icon" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="footer-bottom-row">
            <div className="footer-bottom-left">
              <img src={logoIcon} alt="logo" className="logo-icon" />
              <span className="copyright-text">
                {t("©copyright")} {new Date().getFullYear() + " "} Bawabatek.com  {t("Allrightsreserved")}
              </span>
            </div>
            <div className="footer-bottom-right">
              <img src={currencyIcon1} alt="..." className="currency-icon" />
              <img src={currencyIcon2} alt="..." className="currency-icon" />
              <img src={currencyIcon3} alt="..." className="currency-icon" />
              <img src={currencyIcon4} alt="..." className="currency-icon" />
              <img src={currencyIcon5} alt="..." className="currency-icon" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;