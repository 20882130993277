import { createSlice } from "@reduxjs/toolkit";
import { API_ROUTES } from "../../Enum";
import globalRequest from "../../prototype/globalRequest";

const getContactInformation = async () => {
  try {
    const res = await globalRequest(
      "get",
      API_ROUTES.GET_CONTACT_INFORMATION,
      {},
      {}
    );
    // return res?.data; // Return the data object
    let contactInfo = res?.data;
    return {
      company_details: contactInfo?.company_details,
      mobile_numbers: contactInfo?.mobile_numbers,
      emails: contactInfo?.emails,
    };
  } catch (error) {
    return {
      company_details: {},
      mobile_numbers: {},
      emails: {},
    }; // Return default values on error
  }
};

export const fetchContactInformation = () => async (dispatch) => {
  const data = await getContactInformation();
  dispatch(setContactInformationAdmin(data)); // Dispatch the retrieved data to the store
};

export const contactInfo = createSlice({
  name: "contactInfo",
  initialState: {
    value: {
      company_details: {},
      mobile_numbers: {},
      emails: {},
    },
  },
  reducers: {
    setContactInformationAdmin: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setContactInformationAdmin } = contactInfo.actions;
export const contactInfoObject = (state) => state.contactInfo.value;
export default contactInfo.reducer;
