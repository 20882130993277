/**
 * @file App.js
 * @description This component is used to show app.
 * @exports App component
 * @fileContains App component and routes of app some default widgets like snack bar and loader
 */

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.scss";
import "./App.scss";
import "./assets/scss/direction.scss";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Header from "./pages/layout/header/Header";
// import pages
import Home from "./pages/Home/Home";
import PrivacyPolicy from "./pages/CMS/PrivacyPolicy";
import TermConditions from "./pages/CMS/TermConditions";
import CookiePolicy from "./pages/CMS/CookiePolicy";
import About from "./pages/CMS/About";
import Delivery from "./pages/CMS/Delivery";
import WarrantyReturns from "./pages/CMS/WarrantyReturns";
import Contact from "./pages/CMS/Contact";
import SignUp from "./pages/auth/signUp/SignUp";
import UpdatePassword from "./pages/auth/UpdatePassword/UpdatePassword";
import Wishlist from "./pages/Wishlist/Wishlist";
import SearchResults from "./pages/ProductListing/SearchResults/SearchResults";
import Bundle from "./pages/ProductListing/Bundle/Bundle";
import BundleDetailPage from "./pages/ProductDetail/BundleDetailPage/BundleDetailPage";
import ProductDetailPage from "./pages/ProductDetail/ProductDetailPage/ProductDetailPage";
import MyOrder from "./pages/MyOrder/MyOrder";
import MyOrderNew from "./pages/MyOrder/MyOrderNew";
import OrderDetailNew from "./pages/OrderDetail/OrderDetailNew";
import ScrollToTop from "./Styles-Elements/ScrollToTop/ScrollToTop";
import { useState } from "react";
import { Toast } from "primereact/toast";
import OrderPlaced from "./pages/CartCheckout/OrderPlaced";
import CompareProduct from "./pages/CompareProduct/CompareProduct";
import ProductPage from "./pages/ProductListing/ProductPage/ProductPage";
import Loaders from "./Loaders";
import { useDispatch, useSelector } from "react-redux";
import { currentLoader } from "./redux/reducers/loader";
import { setSnackbar, snackObj } from "./redux/reducers/snackbar";
import { STORAGE } from "./Enum";
import addDeleteGetLocalStorage from "./prototype/addDeleteGetLocalStorage";
import { setData } from "./redux/reducers/auth";
import PlaceOrder from "./pages/place-order/PlaceOrder";
import ShipingPolicy from "./pages/CMS/ShipingPolicy";
import ReturnPolicy from "./pages/CMS/ReturnPolicy";
import { fetchSocialMediaLinks } from "./redux/reducers/socialMediaLink";
import { fetchCurrencyConverionApi } from "./redux/reducers/currencyConversion";
import NewArrival from "./pages/ProductListing/NewArrival/NewArrival";
import Recommended from "./pages/ProductListing/Recommended/Recommended";
import TodaysOffer from "./pages/ProductListing/TodaysOffer/TodaysOffer";
import PaymentFailure from "./pages/PaymentFailure/PaymentFailure";
import ChooseReturnOrder from "./pages/ChooseReturnOrder/ChooseReturnOrder";
import { fetchContactInformation } from "./redux/reducers/contactInfo";
import NewsLatter from "./DialogBox/NewsLatter";
import { fetchUserProductsCount } from "./redux/reducers/userProductsCount";
import Geocode from "react-geocode";
import { GOOGLE_PLACES_API_KEY_2 } from "./BaseUrl";
import CustomerChangePassword from "./pages/auth/CustomerChangePassword/CustomerChangePassword";
import { returnRandomGuest } from "./Helpers/HelperFunctions";

const App = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const toast = useRef(null);
  const [showNewsLetter, setShowNewsLetter] = useState(false);
  const dispatch = useDispatch();
  const loader = useSelector(currentLoader);
  const snackbarObj = useSelector(snackObj);

  //change language
  const changeLanguage = (lang) => {
    localStorage.setItem("i18nextLng", lang);
    i18n.changeLanguage(lang);
  };
  //get current location of user
  const getAddressFromLatLng = async (lat, lng) => {
    let alreadyGetLocation = JSON.parse(
      addDeleteGetLocalStorage(STORAGE.USER_LAT_LNG, {}, "get")
    );
    if (alreadyGetLocation?.lat && alreadyGetLocation?.lng) return;
    const response = await Geocode.fromLatLng(
      lat,
      lng,
      GOOGLE_PLACES_API_KEY_2
    );
    const address = response?.results[0]?.formatted_address;
    if (
      response?.results &&
      response?.results[0] &&
      response?.results[0]?.address_components
    ) {
      const addressComponents = response?.results[0]?.address_components;
      const countryComponent = addressComponents.find((component) =>
        component?.types.includes("country")
      );

      if (countryComponent) {
        const country = countryComponent.long_name;
        if (country == "United Arab Emirates") {
          addDeleteGetLocalStorage(STORAGE.COUNTRY_DB, "UAE", "add", "single");
          addDeleteGetLocalStorage(STORAGE.CURRENCY, "AED", "add", "single");
          localStorage.setItem("i18nextLng", "en");
          changeLanguage("en");
          window.location.reload();
        }
        addDeleteGetLocalStorage(
          STORAGE.USER_LAT_LNG,
          { lat: lat, lng: lng },
          "add",
          "single"
        );
      } else {
        console.error("Country component not found in address components");
      }
    }
    return address;
  };

  useEffect(() => {
    ///set default language
    if (localStorage.getItem("i18nextLng")) {
      if (!["ar", "en"].includes(localStorage.getItem("i18nextLng"))) {
        localStorage.setItem("i18nextLng", "ar");
        changeLanguage("ar");
      }
      changeLanguage(localStorage.getItem("i18nextLng"));
    } else {
      localStorage.setItem("i18nextLng", "en");
      changeLanguage("en");
    }
    ///set current location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          getAddressFromLatLng(
            position.coords.latitude,
            position.coords.longitude
          );
        },
        function (error) {
          console.error(error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
    ///set default currency
    if (!addDeleteGetLocalStorage(STORAGE.CURRENCY, {}, "get")) {
      addDeleteGetLocalStorage(STORAGE.CURRENCY, "USD", "add", "single");
    }
    //set default db
    if (!addDeleteGetLocalStorage(STORAGE.COUNTRY_DB, {}, "get")) {
      addDeleteGetLocalStorage(STORAGE.COUNTRY_DB, "Iraq", "add", "single");
    }
    //get social media links
    dispatch(fetchSocialMediaLinks());
    //get currency conversion
    dispatch(fetchCurrencyConverionApi());
    //get contact information of admin
    dispatch(fetchContactInformation());
    //check if guest id is present in local storage or not
    const localGuestUser = addDeleteGetLocalStorage(
      STORAGE.GUEST_ID,
      {},
      "get"
    );
    let guestId = localGuestUser
      ? localGuestUser
      : returnRandomGuest();
    addDeleteGetLocalStorage(STORAGE.GUEST_ID, guestId, "add", "single"); //add guest id in local storage
    // get product count for guest user
    dispatch(fetchUserProductsCount());
  }, []);
  //session out
  const checkSessionTime = () => {
    const sessionTime = +addDeleteGetLocalStorage(
      STORAGE.SESSION_TIME,
      {},
      "get"
    ); // +localStorage.getItem("session_time");
    if (sessionTime) {
      const currentTime = new Date().getTime();
      if (currentTime - sessionTime > 30 * 60 * 1000) {
        // localStorage.clear();
        addDeleteGetLocalStorage(STORAGE.AL_MAHARA_USER, {}, "delete");
        addDeleteGetLocalStorage(STORAGE.SESSION_TIME, {}, "delete");
        dispatch(setData());
        window.location.href = "/";
      }
    }
  };
  useEffect(() => {
    checkSessionTime();
    setInterval(() => {
      checkSessionTime();
    }, 1000);
  }, []);

  //disable scroll when dialog is open
  useEffect(() => {
    const bodyElement = document.body;
    const observer = new MutationObserver(() => {
      const hasPDialogMask =
        bodyElement.querySelector(".p-dialog-mask") !== null;
      if (hasPDialogMask) bodyElement.classList.add("no-scroll");
      else bodyElement.classList.remove("no-scroll");
    });
    observer.observe(bodyElement, { childList: true, subtree: true });
    // Clean up the observer when the component is unmounted
    return () => {
      observer.disconnect();
    };
  });

  useEffect(() => {
    if (snackbarObj?.isOpen) {
      setTimeout(() => {
        dispatch(
          setSnackbar({
            ...snackbarObj,
            isOpen: false,
          })
        );
      }, 2000);
      toast.current.show({
        severity: snackbarObj?.state,
        summary: snackbarObj?.state,
        detail: t(snackbarObj?.message),
        life: 2000,
      });
    }
  }, [snackbarObj?.isOpen]);

  // show news letter popup when user visit first time
  useEffect(() => {
    let isVisited = addDeleteGetLocalStorage(STORAGE.IS_VISITED, {}, "get");
    if (!isVisited) {
      setShowNewsLetter(true);
    }
  }, []);

  return (
    <>
      <Toast ref={toast} />
      <Router>
        <ScrollToTop />
        <Routes>
          <Route exact path="/signup" element={<SignUp />} />
          <Route exact path="/update-password" element={<UpdatePassword />} />
          <Route
            exact
            path="/set-password/:token"
            element={<CustomerChangePassword />}
          />
          <Route path="/" element={<Header />}>
            <Route exact path="/" element={<Home />} />
            <Route exact path="privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="terms-conditions" element={<TermConditions />} />
            <Route exact path="cookie-policy" element={<CookiePolicy />} />
            <Route exact path="about" element={<About />} />
            <Route exact path="delivery" element={<Delivery />} />
            <Route exact path="warranty" element={<WarrantyReturns />} />
            <Route exact path="return" element={<ReturnPolicy />} />
            <Route exact path="shipping" element={<ShipingPolicy />} />
            <Route exact path="contact" element={<Contact />} />
            <Route exact path="wishlist" element={<Wishlist />} />
            <Route exact path="search-results" element={<SearchResults />} />
            <Route exact path="/:category" element={<ProductPage />} />
            <Route exact path="bundle" element={<Bundle />} />
            <Route exact path="new-arrival" element={<NewArrival />} />
            <Route exact path="recommended" element={<Recommended />} />
            <Route exact path="todays-offer" element={<TodaysOffer />} />
            <Route
              exact
              path="bundle-detail-page/:slug/:id"
              element={<BundleDetailPage />}
            />
            <Route
              exact
              path="product-detail-page/p/:slug/:id"
              element={<ProductDetailPage />}
            />
            <Route exact path="my-order" element={<MyOrder />} />
            <Route exact path="my-order-new" element={<MyOrderNew />} />
            {/* <Route exact path="order-detail/:slug" element={<OrderDetail />} /> */}
            <Route
              exact
              path="order-detail/:slug"
              element={<OrderDetailNew />}
            />
            <Route
              exact
              path="order-placed/:order_id"
              element={<OrderPlaced />}
            />
            <Route exact path="/payment-failure" element={<PaymentFailure />} />

            <Route
              exact
              path="/compare-product/:category_name"
              element={<CompareProduct />}
            />
            <Route path="/choose-return-order/:slug" element={<ChooseReturnOrder />} />
            {/* <Route path="*" element={<PageNotFound />} /> */}
          </Route>
          <Route path="/cart" element={<PlaceOrder />} />
          <Route path="/payment-failure" element={<PaymentFailure />} />
        </Routes>
      </Router>

      {loader ? <Loaders /> : null}

      {/* <Snackbars
        snackBarMessage={snackbarObj?.message}
        snackbarOpen={snackbarObj?.isOpen}
        snackBarState={snackbarObj?.state}
      /> */}

      {/* show news latter */}
      {showNewsLetter && (
        <NewsLatter
          handleClose={() => {
            //want to remove body scroll class when close dialog
            document.body.classList.remove("no-scroll");
            setShowNewsLetter(false);
            addDeleteGetLocalStorage(
              STORAGE.IS_VISITED,
              "true",
              "add",
              "single"
            );
          }}
        />
      )}
    </>
  );
};

export default App;
