/** @format */

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { useNavigate, useParams } from "react-router-dom";
import "./CartCheckout.scss";
import checkCircleWright from "../../assets/images/structure/check-circle-wright.svg";
import ReactCanvasConfetti from "react-canvas-confetti";

const canvasStyles = {
  position: "fixed",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
};

const OrderPlaced = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const routerHome = () => {
    navigate("/my-order");
  };

  const orderId = params.order_id;

  const refAnimationInstance = useRef(null);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
      });
  }, []);

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 60,
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }, [makeShot]);

  useEffect(() => {
    // Call the fire function when the component loads
    fire();
  }, [fire]);
  return (
    <>
      <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />

      <div
        className="empty-page flex flex-column align-items-center justify-content-center py-5 my-5 h-75vh"
        onClick={fire}
      >
        <img
          src={checkCircleWright}
          alt="Check Circle Wright"
          className="mb-4"
        />
        <h3 className="h3 mb-2">{t("orderPlaced")}!</h3>
        <p className="p2 text-center mb-5">
          {`${t("ThankYou")} ${t("YourOrder")}`}{" "}
          <span className="p2 p2-semibold">
            ({t("OrderId")}
            {orderId})
          </span>{" "}
          {`${t("HasBeenPlaced")} ${t("ForFurther")}`} <br />{" "}
          {t("informationVisitYourOrdersOrYouCanContinueShopping")}
        </p>
        <Button severity="Success" size="large" onClick={routerHome}>
          {t("Gotoorder")}
        </Button>
        <p
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/");
          }}
          className="p2 p2-semibold pt-4 yellow"
        >
          {t("continueShopping")}
        </p>
      </div>
    </>
  );
};

export default OrderPlaced;
