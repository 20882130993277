/**
 * @file Constants.js
 * @description This file contains all the constants
 * @version 1.0.0
 * @since 1.0.0
 * @exports {Object} axiosConstant
 */

import {
  API_KEY,
  BaseUrl,
  FB_SOCIAL_KEY,
  GOOGLE_PLACES_API_KEY,
  GOOGLE_SOCIAL_KEY,
} from "../../BaseUrl";

export const axiosConstant = {
  /**
   * @description This is the base url for the api
   */
  BASE_URL: BaseUrl,

  /**
   * @description This is the base url for the images
   */
  IMAGE_BASE_URL: (image) => BaseUrl + image,

  /**
   * @description This is the base url for the FRONT Website
   */
  FRONT_URL: "",

  /**
   * @description This is the base role for the user
   */
  ROLE: "user",

  /**
   * @description This is the days of the week
   */
  DAYS: [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ],

  /**
   * @description This is the months of the year
   */
  MONTH: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "Novenber",
    "December",
  ],

  /**
   * @description This is the api key for the google maps
   */
  API_KEY: API_KEY,
  FACEBOOK_SOCIAL_KEY: FB_SOCIAL_KEY,
  GOOGLE_SOCIAL_KEY: GOOGLE_SOCIAL_KEY,
  GOOGLE_PLACES_API_KEY: GOOGLE_PLACES_API_KEY,
};
