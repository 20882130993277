/** @format */
import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { useTranslation } from "react-i18next";
// images
import modalClose from "../assets/images/structure/modal-close.svg";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../redux/reducers/loader";
import globalRequest from "../prototype/globalRequest";
import { API_ROUTES, STORAGE } from "../Enum";
import { currentPlaceOrderData } from "../redux/reducers/placeOrderData";
import addDeleteGetLocalStorage from "../prototype/addDeleteGetLocalStorage";
import { setSnackbar } from "../redux/reducers/snackbar";

let searchTimer = null;

const ApplyCoupon = ({ handleClose, selectedCoupon }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [couponCodeList, setCouponCodeList] = useState([]);
  const [selectedUnLockCoupon, setSelectedUnLockCoupon] = useState(null);
  const [search, setSearch] = useState("");
  const placeData = useSelector(currentPlaceOrderData);
  const [maximumSaving, setMaximumSaving] = useState(0);
  let DB = addDeleteGetLocalStorage(STORAGE.COUNTRY_DB, {}, "get");
  let currency_am = DB === "Iraq" ? "USD" : "AED";
  const [noDataShow, setNoDataShow] = useState(false);

  const getCouponCode = () => {
    let params = {};
    if (search) {
      params.search = search.trim();
    }

    dispatch(changeLoader(true));
    globalRequest(
      "get",
      API_ROUTES.GET_COUPON_CODE,
      {},
      {
        params: params,
      },
      true
    )
      .then((res) => {
        let ack = res.ack;
        let data = res.data;
        if (ack == 1) {
          setCouponCodeList(data);
        }
      })
      .catch((e) => {})
      .finally(() => {
        setNoDataShow(true);
        dispatch(changeLoader(false));
      });
  };

  useEffect(() => {
    clearTimeout(searchTimer);
    searchTimer = setTimeout(() => {
      getCouponCode();
    }, 1000);
  }, [search]);

  //check private coupon
  const checkAndGetPrivateCoupon = (coupon, type = "get") => {
    if (!coupon) return;
    dispatch(changeLoader(true));
    globalRequest("get", API_ROUTES.CHECK_PRIVATE_COUPON(coupon), {}, {}, true)
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          setCouponCodeList([res.data]);
          if (type == "apply") {
            selectedCoupon(selectedUnLockCoupon);
          }
        } else if (res.ack == 0) {
          if (type == "get") {
            dispatch(
              setSnackbar({
                message: t("noCouponFound"),
                isOpen: true,
                state: "error",
              })
            );
          }
          if (type == "apply") {
            dispatch(
              setSnackbar({
                message: res.msg,
                isOpen: true,
                state: "error",
              })
            );
          }
        }
      })
      .catch((err) => {
        console.error(err);
        dispatch(changeLoader(false));
      });
  };

  return (
    <>
      <Dialog
        visible={true}
        onHide={() => handleClose()}
        draggable={false}
        resizable={false}
        closable={false}
        showHeader={false}
        className="apply-coupon"
      >
        <img
          src={modalClose}
          alt="close"
          className="modal-close"
          onClick={() => handleClose()}
        />
        <div className="apply-header">
          <h6 className="h6 mb-3">{t("ApplyCoupon")}</h6>
          <div className="input-parent">
            <span className="p-input-icon-right w-full">
              {search ? (
                <span
                  className="check-right"
                  onClick={() => {
                    checkAndGetPrivateCoupon(search.trim());
                  }}
                >
                  {t("Check")}
                </span>
              ) : null}
              <InputText
                placeholder={t("enterCoupon")}
                value={search}
                onChange={(e) => setSearch(e.target.value.trimStart())}
              />
            </span>
          </div>
        </div>
        {couponCodeList.length ? (
          <>
            <div className="apply-gray-row">
              <h6 className="h6">{t("UnlockCoupons")}</h6>
            </div>
            <div className="apply-body">
              <div className="flex flex-column">
                {couponCodeList.map((obj, ind) => {
                  return (
                    <div key={ind} className="flex coupon-rows">
                      <RadioButton
                        inputId={ind}
                        name="UnLockCoupon"
                        value={obj}
                        onChange={(e) => {
                          // if type is fixed_amount and subtotalAmount is less than max_amount then show error
                          if (
                            obj.coupon_type == "fixed_amount" &&
                            placeData.subtotalAmount < obj?.max_amount
                          ) {
                            dispatch(
                              setSnackbar({
                                message: t("cannotUseCoupon"),
                                isOpen: true,
                                state: "error",
                              })
                            );
                          } else {
                            setSelectedUnLockCoupon(e.value);
                            //if coupon type is fixed amount then directly set amount
                            //else if coupon type is percentage then find percentage of subtotalAmount
                            //if percentage is greater than max_amount then set max_amount else set percentage
                            if (obj?.coupon_type == "fixed_amount") {
                              setMaximumSaving(obj.amount);
                            } else if (obj?.coupon_type == "percentage") {
                              let value =
                                (obj?.amount / 100) * placeData.subtotalAmount;

                              if (value < obj.max_amount) {
                                setMaximumSaving(value);
                              } else {
                                setMaximumSaving(obj.max_amount);
                              }
                            }
                          }
                        }}
                        checked={selectedUnLockCoupon?.code === obj?.code}
                        className="mt-2"
                      />
                      <div className="">
                        <label htmlFor={obj.code} className="">
                          <span className="dashed-btn">{String(obj.code)}</span>
                          <span className="green-text">
                            {" "}
                            {t("Save")}{" "}
                            {obj.coupon_type == "fixed_amount"
                              ? `${currency_am} ${obj.amount}`
                              : `${obj.amount}%`}
                          </span>
                        </label>
                        {obj?.coupon_type == "percentage" && (
                          <p className="p3 mx-3 mt-2">
                            {/* //“50% off or USD 100 which will be minimum” */}
                            {`${obj.amount}% ${t("offOr")} ${currency_am} ${
                              obj.max_amount
                            } ${t("whichWillBeMinimum")}`}
                          </p>
                        )}
                        {obj?.coupon_type == "fixed_amount" && (
                          <p className="p3 mx-3 mt-2">
                            {`${currency_am} ${obj.amount} ${t(
                              "offOnMinimumPurchaseOf"
                            )} ${currency_am} ${obj.max_amount}`}
                          </p>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        ) : (
          <div className="apply-gray-row">
            {noDataShow && <h6 className="h6">{t("noCouponFound")}</h6>}
          </div>
        )}

        <div className="apply-footer">
          <div className="flex flex-column">
            <p className="p2 gray ">{t("MaximumSavings")} :</p>
            <p className="p2 ">
              {currency_am + " " + parseFloat(maximumSaving).toFixed(2)}
            </p>
          </div>
          <Button
            disabled={!selectedUnLockCoupon}
            label={t("Apply")}
            onClick={() => {
              checkAndGetPrivateCoupon(selectedUnLockCoupon?.code, "apply");
            }}
            severity="Success"
            className="w-full"
          />
        </div>
      </Dialog>
    </>
  );
};

export default ApplyCoupon;
