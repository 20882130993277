/** @format */

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { useNavigate, useParams } from "react-router-dom";
import failCircleWright from "../../assets/images/structure/crose-red-circle.svg";
const PaymentFailure = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const routerHome = () => {
    navigate("/my-order");
  };
  return (
    <>

      <div className="empty-page flex flex-column align-items-center justify-content-center py-5 my-5 h-75vh">
        <img src={failCircleWright} alt="fail Circle Wright" className="mb-4" />
        <h3 className="h3 mb-2">{t("PaymentFailed")}</h3>
        <p className="p2 text-center mb-5 max-w-500">{t("paymentFailureText")}</p>
        <Button severity="Success" size="large" onClick={routerHome}>
          {t("Retry")}
        </Button>
      </div>
    </>
  );
};

export default PaymentFailure;
