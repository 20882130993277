import jwt_decode from "jwt-decode";
import { STORAGE } from "../Enum";
import addDeleteGetLocalStorage from "../prototype/addDeleteGetLocalStorage";

const userToken = addDeleteGetLocalStorage(STORAGE.AL_MAHARA_USER, {}, "get");

export const decoded = () => {
  let decode = null;
  if (!userToken) {
    decode = null;
  } else {
    decode = jwt_decode(userToken);
  }
};

export const decodedToken = (token) => {
  if (!token) {
    return null;
  } else {
    return jwt_decode(token);
  }
};
