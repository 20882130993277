/** @format */
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
// images
import modalClose from "../assets/images/structure/modal-close.svg";

const RemoveModal = ({ handleClose, handleRemove, handleMoveToWishlist }) => {
  const { t } = useTranslation();
  return (
    <>
      <Dialog
        visible={true}
        onHide={() => handleClose()}
        draggable={false}
        resizable={false}
        closable={false}
        showHeader={false}
        className="dialog-340"
      >
        <img
          src={modalClose}
          alt="close"
          className="modal-close"
          onClick={() => handleClose()}
        />
        <h3 className="h3 mb-2">
          {t("Remove")} {t("productfromcart_")}
        </h3>
        <p className="p2 mb-4">
          {t("Areyousureyouwanttoremove")} {t("productfromcart_")}
        </p>
        <div className="modal-end-btn">
          <Button
            label={t("Remove")}
            className="gray"
            text
            onClick={() => handleRemove()}
          />
          <Button
            label={t("MovetoWishlist")}
            className="yellow"
            text
            onClick={() => handleMoveToWishlist()}
          />
        </div>
      </Dialog>
    </>
  );
};

export default RemoveModal;
