import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import "./Wishlist.scss";
import FrontToolbar from "../../global-modules/FrontToolbar";
import Breadcrumb from "../../global-modules/Breadcrumb";
import Footer from "../layout/footer/Footer";
import EmptyWishlist from "./Empty-wishlist";
// images
import basketIcon from "../../assets/images/structure/basket-white.svg";
import removeWishlistIcon from "../../assets/images/structure/X.svg";
import noImage from "../../assets/images/products/noImage.webp";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader, currentLoader } from "../../redux/reducers/loader";
import { useCallback } from "react";
import addDeleteGetLocalStorage from "../../prototype/addDeleteGetLocalStorage";
import { API_ROUTES, STORAGE } from "../../Enum";
import { useState } from "react";
import { useEffect } from "react";
import { setSnackbar } from "../../redux/reducers/snackbar";
import {
  categoryListObject,
  setCategoryList,
} from "../../redux/reducers/categoryList";
import { useNavigate } from "react-router-dom";
import {
  changeUserProductsCount,
  fetchUserProductsCount,
  userProductsObj,
} from "../../redux/reducers/userProductsCount";
import { currencyCoversionObject } from "../../redux/reducers/currencyConversion";
import { convertCurrency, productPrice, returnRandomGuest } from "../../Helpers/HelperFunctions";
import { Base64 } from "js-base64";
import globalRequest from "../../prototype/globalRequest";

const Wishlist = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let language = localStorage.getItem("i18nextLng");
  let token = JSON.parse(
    addDeleteGetLocalStorage(STORAGE.AL_MAHARA_USER, {}, "get")
  )?.token;
  let currency_am = addDeleteGetLocalStorage(STORAGE.CURRENCY, {}, "get");
  let DB = addDeleteGetLocalStorage(STORAGE.COUNTRY_DB, {}, "get");
  const categoryObj = useSelector(categoryListObject);
  const loader = useSelector(currentLoader);
  const conversionRate = useSelector(currencyCoversionObject);
  const productCount = useSelector(userProductsObj);
  //states
  const [wishlist, setWishlist] = useState([]);
  const [limit, setLimit] = useState(8);
  const [total, setTotal] = useState(0);

  const routerProductDetail = (slug, page, id) => {
    if (page == "bundle") {
      navigate(
        `/bundle-detail-page/${slug}/${Base64.encode(encodeURIComponent(id))}`
      );
    } else {
      navigate(
        `/product-detail-page/p/${slug}/${Base64.encode(
          encodeURIComponent(id)
        )}`
      );
    }
  };

  //remove id from local
  const removeDeletedBundleIdFromLocal = (newIds) => {
    //remove id from array
    let tempArray = [...newIds];
    tempArray?.reverse();
    //get wishlist from local
    let wishlistFromLocal =
      JSON.parse(
        addDeleteGetLocalStorage(STORAGE.GUEST_WHISLIST_LOCAL, {}, "get")
      ) || {};
    wishlistFromLocal[DB] = tempArray;
    //update wishlist count in redux
    dispatch(
      changeUserProductsCount({
        ...productCount,
        wishlistcount:
          DB == "Iraq"
            ? wishlistFromLocal?.Iraq?.length
            : DB == "UAE"
            ? wishlistFromLocal?.UAE?.length
            : 0,
      })
    );
    //update local storage
    addDeleteGetLocalStorage(
      STORAGE.GUEST_WHISLIST_LOCAL,
      wishlistFromLocal,
      "add",
      "single"
    );
  };

  //get all favorite listing from db
  const getAllWishlist = useCallback(() => {
    if (!token) return;
    dispatch(changeLoader(true));
    let params = {
      limit: limit,
      offset: 0,
    };
    globalRequest(
      "get",
      API_ROUTES.GET_WISHLIST,
      {},
      { params: params },
      token ? true : false
    )
      .then((data) => {
        if (data?.ack == 1) {
          //update product price with respect to wholesale price
          let allProducts = data?.data;
          allProducts?.forEach((item) => {
            item.product.stock =
              item?.product?.stock !== null
                ? item?.product?.stock?.stock -
                    item?.product?.stock?.reserve_stock >
                  0
                  ? item?.product?.stock
                  : null
                : null;
            item?.product?.product_type == "product" &&
              (item.product.discount_price = productPrice(
                item?.product?.is_todays_offer == 1
                  ? item?.product?.discount_price
                  : item?.product?.selling_price,
                1,
                item?.product?.price_ranges,
                item?.product?.is_wholesale
              ));
          });

          setWishlist(allProducts);
          setTotal(data?.count);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        dispatch(changeLoader(false));
      });
  });

  //get all favorite listing for guest //not done
  const getLocalFavoriteList = useCallback(async (ids) => {
    if (!ids.length) return;
    let params = {
      productId: JSON.stringify(ids),
    };

    let localGuestId = addDeleteGetLocalStorage(STORAGE.GUEST_ID, {}, "get");
    if (!token && localGuestId) {
      params = { ...params, guestid: localGuestId };
    }

    dispatch(changeLoader(true));
    globalRequest(
      "get",
      API_ROUTES.GET_COMPARE_PRODUCT_DETAILS,
      {},
      { params: params },
      token ? true : false
    )
      .then((res) => {
        dispatch(changeLoader(false));
        if (res?.ack == 1) {
          let allProducts = res?.data;
          if (allProducts.length !== ids?.length) {
            //remove id of deleted bundle from admin
            let newIds = [];
            allProducts?.map((item) => {
              newIds.push(item?.id);
            });
            removeDeletedBundleIdFromLocal(newIds);
          }
          let products = [];
          allProducts?.map((item) => {
            item.stock =
              item?.stock !== null
                ? item?.stock?.stock - item?.stock?.reserve_stock > 0
                  ? item?.stock
                  : null
                : null;
            item?.product_type == "product" &&
              (item.discount_price = productPrice(
                item?.is_todays_offer == 1
                  ? item?.discount_price
                  : item?.selling_price,
                1,
                item?.price_ranges,
                item?.is_wholesale
              ));
            products.push({
              id: item?.id,
              product: item,
            });
          });

          setWishlist(products);
        } else {
          console.error("err", res);
        }
      })
      .catch((err) => {
        console.error("err", err);
      });
  });

  // set all product
  useEffect(() => {
    let get =
      JSON.parse(
        addDeleteGetLocalStorage(STORAGE.GUEST_WHISLIST_LOCAL, {}, "get")
      ) || {};
    let guestID = addDeleteGetLocalStorage(STORAGE.GUEST_ID, {}, "get");
    //get all product ids from local storage with respect to database
    if (get[DB]) {
      let ids = get[DB];
      if (!token && guestID) {
        getLocalFavoriteList(ids);
      }
    }
  }, []);

  //remove from wishlist
  const removeFromWishlist = useCallback((id) => {
    if (!token) {
      // GET WISHLIST FROM LOCAL STORAGE AND IF PRODUCT IS ALREADY IN WISHLIST THEN REMOVE IT ELSE ADD IT with respect to the database
      let wishlistFromLocal =
        JSON.parse(
          addDeleteGetLocalStorage(STORAGE.GUEST_WHISLIST_LOCAL, {}, "get")
        ) || {};
      if (wishlistFromLocal) {
        const updatedWishlist = { ...wishlistFromLocal };

        if (DB === "Iraq" || DB === "UAE") {
          const countryWishlist = wishlistFromLocal[DB] || [];

          if (countryWishlist.includes(id)) {
            updatedWishlist[DB] = countryWishlist.filter((item) => item !== id);
          } else {
            updatedWishlist[DB] = [...countryWishlist, id];
          }
        }

        wishlistFromLocal = updatedWishlist;
      } else {
        wishlistFromLocal = {
          [DB]: [id],
        };
      }
      // update list of products
      let tempWishlist = [...wishlist];
      tempWishlist = tempWishlist.filter((item) => item?.id !== id);
      setWishlist(tempWishlist);
      //update wishlist count in redux
      dispatch(
        changeUserProductsCount({
          ...productCount,
          wishlistcount:
            DB == "Iraq"
              ? wishlistFromLocal?.Iraq?.length
              : DB == "UAE"
              ? wishlistFromLocal?.UAE?.length
              : 0,
        })
      );

      // Uncomment the following lines if you want to update the local storage
      addDeleteGetLocalStorage(
        STORAGE.GUEST_WHISLIST_LOCAL,
        wishlistFromLocal,
        "add",
        "single"
      );

      return;
    }
    dispatch(changeLoader(true));
    globalRequest(
      "delete",
      API_ROUTES.REMOVE_FROM_WISHLIST + "/" + id,
      {},
      {},
      token ? true : false
    )
      .then((data) => {
        if (data?.ack == 1) {
          getAllWishlist();
          dispatch(
            setSnackbar({
              message: t("ProductRemovedFromWishlist"),
              isOpen: true,
              state: "success",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            message: "Something went wrong with the API " + err,
            isOpen: true,
            state: "error",
          })
        );
      })
      .finally(() => {
        dispatch(changeLoader(false));
        dispatch(fetchUserProductsCount());
      });
  });

  ///add to cart function
  const addToCart = useCallback(async (item, index) => {
    //check if guest id is present in local storage or not
    let localGuestId = addDeleteGetLocalStorage(STORAGE.GUEST_ID, {}, "get");
    let guestId = localGuestId
      ? localGuestId
      : returnRandomGuest();
    let data = {
      productId: item?.id,
      quantity: 1,
      price:
        item?.is_todays_offer == 1 ? item?.discount_price : item?.selling_price,
      productType: item?.product_type,
    };
    if (!token && guestId) {
      data = { ...data, guestid: token ? "" : guestId.trim() };
    }

    dispatch(changeLoader(true));
    try {
      let res = await globalRequest(
        "post",
        API_ROUTES.ADD_TO_CART,
        data,
        {},
        token ? true : false
      );
      if (res?.ack == 1) {
        dispatch(changeLoader(false));
        addDeleteGetLocalStorage(STORAGE.GUEST_ID, guestId, "add", "single");
        dispatch(
          setSnackbar({
            isOpen: true,
            state: "success",
            message: t("AddedToCartSuccessfully"),
          })
        );
        dispatch(fetchUserProductsCount());
        let tempWishlist = [...wishlist];
        tempWishlist[index].product.is_in_cart = 1;
        setWishlist(tempWishlist);
      } else {
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            isOpen: true,
            state: "error",
            message: t("SomethingWentWrong"),
          })
        );
      }
    } catch (err) {
      dispatch(changeLoader(false));
      dispatch(
        setSnackbar({
          isOpen: true,
          state: "error",
          message: err.response.data.msg || t("SomethingWentWrong"),
        })
      );
    }
  });

  //show similar products on click of similar products
  const showSimilarProducts = useCallback((Category, SubCategory) => {
    dispatch(
      setCategoryList({
        ...categoryObj,
        selectedCategory: Category,
        selectedSubCategoryArrayOfIds: [SubCategory?.id],
      })
    );
    navigate(`/${Category?.name_en?.toLowerCase().replace(/ /g, "_")}`); //replace space with underscore
  });

  useEffect(() => {
    getAllWishlist();
  }, [limit]);

  ///pagination
  const [page, setPage] = useState(1);
  useEffect(() => {
    if (limit >= total) return;
    setLimit((prevLimit) => prevLimit + 8);
  }, [page]);

  useEffect(() => {
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: "20px", // Margin around the root (if you want to trigger earlier or later)
      threshold: 1.0, // Trigger when the entire target is visible
    };
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setPage((prevPage) => prevPage + 1);
      }
    }, options);
    // Set the target to observe
    const target = document.querySelector("#observe-target1");
    if (target) {
      observer.observe(target);
    }
    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, []);

  return (
    <>
      <FrontToolbar />
      <div className="container">
        <Breadcrumb title={t("Wishlist")} />
        <section className="wishlist-section1">
          <h2 className="h2">{t("MyWishlist")}</h2>
          <div className="wishlist-row">
            {wishlist?.length > 0 &&
              wishlist?.map((item, index) => {
                let featuredImage = noImage;
                item?.product?.product_images?.forEach((image) => {
                  if (image?.is_featured == 1) {
                    featuredImage = image?.image_url;
                  }
                });
                return (
                  <div key={index} className="wishlist-box">
                    <div className="wishlist-image-box">
                      <img
                        src={featuredImage}
                        alt="wishlist image"
                        className="wishlist-image"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = noImage;
                        }}
                        onClick={() => {
                          if (item?.product?.slug)
                            routerProductDetail(
                              item?.product?.slug,
                              item?.product?.product_type,
                              item?.product?.id
                            );
                        }}
                      />
                      <img
                        src={removeWishlistIcon}
                        alt="remove wishlist icon"
                        className="remove-wishlist-icon"
                        onClick={() => removeFromWishlist(item?.id)}
                      />
                      {!item?.product?.stock && (
                        <div className="out-of-stock">
                          <Button
                            className="out-of-stock-btn"
                            onClick={() => {
                              if (item?.product?.slug)
                                routerProductDetail(
                                  item?.product?.slug,
                                  item?.product?.product_type,
                                  item?.product?.id
                                );
                            }}
                          >
                            {t("outOfStock")}
                          </Button>
                        </div>
                      )}
                    </div>
                    <div className="wishlist-content">
                      {item?.product?.product_type == "bundle" && (
                        <p className="p3 ">{t("Bundle")}</p>
                      )}
                      {item?.product?.product_type == "product" && (
                        <p className="p3 capitalize-first-letter">
                          {language == "en"
                            ? t(
                                `${
                                  item?.product?.subcategory_details?.name_en
                                    ? item?.product?.subcategory_details
                                        ?.name_en
                                    : ""
                                }`
                              )
                            : t(
                                `${
                                  item?.product?.subcategory_details?.name_ar
                                    ? item?.product?.subcategory_details
                                        ?.name_ar
                                    : ""
                                }`
                              )}
                        </p>
                      )}
                      <h6 className="h6 truncated-text">
                        {item?.product?.title}
                      </h6>
                      <div className="price-row">
                        <p className="p2">
                          {currency_am +
                            " " +
                            convertCurrency(
                              productPrice(
                                item?.product?.is_todays_offer === 1
                                  ? item?.product?.discount_price
                                  : item?.product?.selling_price,
                                1,
                                item?.product?.price_ranges,
                                item?.product?.is_wholesale
                              ),
                              conversionRate
                            )}
                        </p>
                      </div>
                      {item?.product?.stock &&
                      item?.product?.is_in_cart == 0 ? (
                        <Button
                          className="add-to-card-btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            addToCart(item?.product, index);
                          }}
                        >
                          <img
                            src={basketIcon}
                            alt="basket icon"
                            className="basket-icon"
                          />
                          <span className="mx-2">{t("AddToCart")}</span>
                        </Button>
                      ) : item?.product?.stock &&
                        item?.product?.is_in_cart == 1 ? (
                        <Button
                          className="add-to-card-btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/cart`);
                          }}
                        >
                          <span className="mx-2">{t("GoToCart")}</span>
                        </Button>
                      ) : (
                        <Button
                          className="add-to-card-btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (item?.product?.product_type == "bundle") {
                              navigate(`/bundle`);
                            } else if (
                              item?.product?.product_type == "product"
                            ) {
                              showSimilarProducts(
                                item?.product?.category_details,
                                item?.product?.subcategory_details
                              );
                            }
                          }}
                        >
                          <span className="mx-2">{t("ViewSimilar")}</span>
                        </Button>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>

          <div id="observe-target1" style={{ height: "10px" }}></div>
          {wishlist?.length == 0 && !loader && (
            <EmptyWishlist page="whishlist" />
          )}
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Wishlist;
