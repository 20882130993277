/** @format */
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./home.scss";
import FrontToolbar from "../../global-modules/FrontToolbar";
import NewsLatter from "../layout/footer/NewsLatter";
import Footer from "../layout/footer/Footer";
import basketIcon from "../../assets/images/structure/basket-white.svg";
import home1 from "../../assets/images/products/home1.webp";
import imgNotFound from "../../assets/images/products/noImage.webp";
import shippingIcon from "../../assets/images/structure/shipping-icon.svg";
import shippingIConNw from "../../assets/images/structure/shippingICon.svg";
import genuineIcon from "../../assets/images/structure/genuine-icon.svg";
import returnIcon from "../../assets/images/structure/return-icon.svg";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../redux/reducers/loader";
import { API_ROUTES, STORAGE } from "../../Enum";
import { setSnackbar } from "../../redux/reducers/snackbar";
import globalRequest from "../../prototype/globalRequest";
import {
  categoryListObject,
  setCategoryList,
} from "../../redux/reducers/categoryList";
import NotifyMe from "../../DialogBox/NotifyMe";
import { fetchUserProductsCount } from "../../redux/reducers/userProductsCount";
import addDeleteGetLocalStorage from "../../prototype/addDeleteGetLocalStorage";
import { convertCurrency, productPrice, returnRandomGuest } from "../../Helpers/HelperFunctions";
import { currencyCoversionObject } from "../../redux/reducers/currencyConversion";
import { currentAuth } from "../../redux/reducers/auth";
import { Base64 } from "js-base64";

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let language = localStorage.getItem("i18nextLng");
  let currency_am = addDeleteGetLocalStorage(STORAGE.CURRENCY, {}, "get");
  const country =
    addDeleteGetLocalStorage(STORAGE.COUNTRY_DB, {}, "get") || "Iraq";
  const conversionRate = useSelector(currencyCoversionObject);
  const auth = useSelector(currentAuth);
  let token = auth?.token;

  const routerProductDetail = (slug, type, id) => {
    if (type == "bundle") {
      navigate(
        `/bundle-detail-page/${slug}/${Base64.encode(encodeURIComponent(id))}`
      );
    } else {
      navigate(
        `/product-detail-page/p/${slug}/${Base64.encode(
          encodeURIComponent(id)
        )}`
      );
    }
  };
  //from redux
  const categoryObj = useSelector(categoryListObject);
  let categories = categoryObj?.categories || [];
  const [todaysOffers, setTodaysOffers] = useState([]);
  const [newArrivals, setNewArrivals] = useState([]);
  const [recommended, setRecommended] = useState([]);
  const [notifyMeBtn, setNotifyMeBtn] = useState(false);
  const [notifyMeProductId, setNotifyMeProductId] = useState(null);

  //home page integration
  const getHomeProductsApi = useCallback(() => {
    let params = {};
    let localGuestId = addDeleteGetLocalStorage(STORAGE.GUEST_ID, {}, "get");
    if (!token && localGuestId) {
      params = { ...params, guestid: localGuestId };
    }
    dispatch(changeLoader(true));
    globalRequest(
      "get",
      API_ROUTES.GET_HOMEPAGE_PRODUCTS,
      {},
      { params: params },
      token ? true : false
    )
      .then((res) => {
        dispatch(changeLoader(false));
        if (res?.ack == 1) {
          ///set product price with respect to price range
          let todaysOffer = res?.data?.todaysoffers;
          todaysOffer?.forEach((item) => {
            item.stock =
              item?.stock !== null
                ? item?.stock?.stock - item?.stock?.reserve_stock > 0
                  ? item?.stock
                  : null
                : null;

            if (item?.product_type === "product") {
              item.discount_price = productPrice(
                item?.is_todays_offer === 1
                  ? item?.discount_price
                  : item?.selling_price,
                1,
                item?.price_ranges,
                item?.is_wholesale
              );
            }
          });

          let newArrivals = res?.data?.NewArrivals;
          newArrivals?.forEach((item) => {
            item.stock =
              item?.stock !== null
                ? item?.stock?.stock - item?.stock?.reserve_stock > 0
                  ? item?.stock
                  : null
                : null;

            if (item?.product_type === "product") {
              item.discount_price = productPrice(
                item?.is_todays_offer === 1
                  ? item?.discount_price
                  : item?.selling_price,
                1,
                item?.price_ranges,
                item?.is_wholesale
              );
            }
          });

          let recommended = res?.data?.RecommendedProducts;
          recommended?.forEach((item) => {
            item.stock =
              item?.stock !== null
                ? item?.stock?.stock - item?.stock?.reserve_stock > 0
                  ? item?.stock
                  : null
                : null;

            if (item?.product_type === "product") {
              item.discount_price = productPrice(
                item?.is_todays_offer === 1
                  ? item?.discount_price
                  : item?.selling_price,
                1,
                item?.price_ranges,
                item?.is_wholesale
              );
            }
          });

          setTodaysOffers(todaysOffer);
          setNewArrivals(newArrivals);
          setRecommended(recommended);
        } else {
          dispatch(
            setSnackbar({
              message: "SomethingWentWrong",
              isOpen: true,
              state: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            message: "SomethingWentWrong",
            isOpen: true,
            state: "error",
          })
        );
      });
  });

  ///add to cart function
  const addToCart = useCallback(async (item, index, type) => {
    let localGuestId = addDeleteGetLocalStorage(STORAGE.GUEST_ID, {}, "get");
    let guestId = localGuestId
      ? localGuestId
      : returnRandomGuest();
    let data = {
      productId: item?.id,
      quantity: 1,
      price:
        item?.is_todays_offer === 1
          ? item?.discount_price
          : item?.selling_price,
      productType: item?.product_type,
    };
    if (!token && guestId) {
      data = { ...data, guestid: token ? "" : guestId.trim() };
    }
    dispatch(changeLoader(true));
    try {
      let res = await globalRequest(
        "post",
        API_ROUTES.ADD_TO_CART,
        data,
        {},
        token ? true : false
      );
      if (res?.ack == 1) {
        dispatch(changeLoader(false));
        addDeleteGetLocalStorage(STORAGE.GUEST_ID, guestId, "add", "single");
        dispatch(
          setSnackbar({
            isOpen: true,
            state: "success",
            message: t("AddedToCartSuccessfully"),
          })
        );
        dispatch(fetchUserProductsCount());
        if (type == "recommended") {
          let tempRecommended = [...recommended];
          tempRecommended[index].is_in_cart = 1;
          setRecommended(tempRecommended);
        } else if (type == "todaysOffer") {
          let tempTodaysOffer = [...todaysOffers];
          tempTodaysOffer[index].is_in_cart = 1;
          setTodaysOffers(tempTodaysOffer);
        }
      } else {
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            isOpen: true,
            state: "error",
            message: t("SomethingWentWrong"),
          })
        );
      }
    } catch (err) {
      dispatch(changeLoader(false));
      dispatch(
        setSnackbar({
          isOpen: true,
          state: "error",
          message: err.response.data.msg || t("SomethingWentWrong"),
        })
      );
    }
  });

  //notify me function
  const notifyMeFuntion = useCallback((id) => {
    setNotifyMeProductId(id);
    setNotifyMeBtn(true);
  });

  useEffect(() => {
    getHomeProductsApi();
  }, []);

  return (
    <>
      <FrontToolbar />
      <div className="container">
        <section className="home-section1">
          <div className="grid">
            <div className="col-12 sm:col-12 md:col-6 lg:col-6">
              <div className="home1-image-box">
                <img src={home1} alt="product image" className="home1-img" />
                <div className="overlay-text">
                  <h2 className="h2">{t("DiscoverTheGreatITProducts")} </h2>
                  <p className="p2">
                    {t("ForITInfrastructurewithGuaranteedbestpriceoffers")}
                  </p>
                </div>
              </div>
            </div>
            {categoryObj?.categories.length > 0 &&
              categoryObj?.categories?.map((ele, index) => {
                if (index < 2) {
                  return (
                    <div
                      className="col-12 sm:col-6 md:col-3 lg:col-3"
                      key={index}
                    >
                      <div className="home2-image-box">
                        {/* <img src={home2} alt="product image" className="home1-img" /> */}
                        <img
                          src={ele?.thumbnailsImage}
                          className="home1-img"
                          alt="product image"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = imgNotFound;
                          }}
                        />
                        <div className="overlay-text">
                          <h3 className="h3 capitalize-first-letter">
                            {language == "en"
                              ? t(
                                  `${ele?.name_en
                                    ?.charAt(0)
                                    .toUpperCase()}${ele?.name_en.slice(1)}`
                                )
                              : t(
                                  `${ele?.name_ar
                                    ?.charAt(0)
                                    .toUpperCase()}${ele?.name_ar.slice(1)}`
                                )}
                          </h3>
                          <span
                            className="link"
                            onClick={() => {
                              dispatch(
                                setCategoryList({
                                  ...categoryObj,
                                  selectedCategory: ele,
                                  selectedSubCategoryArrayOfIds: [],
                                  priceRange: [0, 300000],
                                  productType: "",
                                  sortBy: "recommended",
                                })
                              );
                              navigate(
                                `/${ele?.name_en
                                  ?.toLowerCase()
                                  .replace(/ /g, "_")}`
                              ); //replace space with underscore
                            }}
                          >
                            {t("DiscoverNow")}{" "}
                            <span className="pi pi-angle-right"></span>
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        </section>

        {todaysOffers?.length > 0 && (
          <section className="home-section2">
            <div className="heading-row">
              <div className="heading-left">
                <h2 className="h2">{t("TodaysOffers")}</h2>
                <p className="p2">{t("Amazingdealsvalidfortodayonly")}</p>
              </div>
              <span
                className="link"
                onClick={() => {
                  navigate("/todays-offer");
                }}
              >
                <span className="link-content">{t("ViewAllProducts")}</span>
                <span className="pi pi-angle-right"></span>
              </span>
            </div>
            <div className="product-row">
              {todaysOffers?.length > 0 &&
                todaysOffers?.map((item, index) => {
                  let featuredImg = item?.product_images[0]?.image_url;
                  let discount_percentage = 0;
                  if (item?.selling_price - item?.discount_price > 0) {
                    discount_percentage =
                      ((item?.selling_price - item?.discount_price) /
                        item?.selling_price) *
                      100;
                    discount_percentage =
                      Math.floor(discount_percentage * 100) / 100;
                  }
                  if (index < 5) {
                    return (
                      <div className="product-box" key={index}>
                        <div className="product-image-box">
                          <div className="chips-parent">
                            {discount_percentage.toFixed(2) > 0 && (
                              <span className="chips error">
                                {`${t("Sale")} - ${discount_percentage.toFixed(
                                  2
                                )}%`}
                              </span>
                            )}
                          </div>
                          <img
                            src={featuredImg}
                            className="home1-img"
                            alt="product image"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = imgNotFound;
                            }}
                            onClick={() => {
                              routerProductDetail(
                                item?.slug,
                                item?.product_type,
                                item?.id
                              );
                            }}
                          />
                          {item?.is_in_cart == 0 && item?.stock !== null ? (
                            <Button
                              className="add-to-card-btn"
                              onClick={() =>
                                addToCart(item, index, "todaysOffer")
                              }
                            >
                              <img
                                src={basketIcon}
                                alt="basket icon"
                                className="basket-icon"
                              />
                              <span className="mx-2">{t("AddToCart")}</span>
                            </Button>
                          ) : item?.is_in_cart == 1 && item?.stock !== null ? (
                            <Button
                              className="add-to-card-btn"
                              onClick={() => {
                                navigate("/cart");
                              }}
                            >
                              <span className="mx-2">{t("GoToCart")}</span>
                            </Button>
                          ) : (
                            <Button
                              className="add-to-card-btn"
                              label={t("NotifyMe")}
                              onClick={() => {
                                notifyMeFuntion(item?.id);
                              }}
                            />
                          )}
                        </div>
                        <div
                          className="product-content"
                          onClick={() => {
                            routerProductDetail(
                              item?.slug,
                              item?.product_type,
                              item?.id
                            );
                          }}
                        >
                          <p className="p3 ">
                            {language == "en"
                              ? t(
                                  `${
                                    item?.subcategory_details?.name_en
                                      ? item?.subcategory_details?.name_en
                                      : ""
                                  }`
                                )
                              : t(
                                  `${
                                    item?.subcategory_details?.name_ar
                                      ? item?.subcategory_details?.name_ar
                                      : ""
                                  }`
                                )}
                          </p>
                          <h6 className="h6 capitalize-first-letter">
                            {item?.title}
                          </h6>
                          <div className="price-row">
                            {/* new price data with conditions */}
                            <>
                              <p className="p2">
                                {currency_am +
                                  " " +
                                  convertCurrency(
                                    item?.discount_price,
                                    conversionRate
                                  )}
                              </p>
                              {item?.is_todays_offer === 1 && (
                                <>
                                  {item?.discount_price !==
                                  item?.selling_price ? (
                                    <p
                                      className={`p3 ${
                                        item?.discount_price !==
                                        item?.selling_price
                                          ? "cross"
                                          : ""
                                      }`}
                                    >
                                      {currency_am +
                                        " " +
                                        convertCurrency(
                                          item?.selling_price,
                                          conversionRate
                                        )}
                                    </p>
                                  ) : null}
                                </>
                              )}
                            </>
                            {/* <p className="p2">
                              {currency_am +
                                " " +
                                convertCurrency(
                                  item?.discount_price,
                                  conversionRate
                                )}
                            </p>
                            {item?.discount_price !== item?.selling_price ? (
                              <p
                                className={`p3 ${
                                  item?.discount_price !== item?.selling_price
                                    ? "cross"
                                    : ""
                                }`}
                              >
                                {currency_am +
                                  " " +
                                  convertCurrency(
                                    item?.selling_price,
                                    conversionRate
                                  )}
                              </p>
                            ) : null} */}
                          </div>
                          {country === "UAE" && (
                            <p
                              className="p3 mt-1"
                              style={{
                                color: "#f9b000",
                                fontFamily: "OpenSans-Medium",
                              }}
                            >
                              {t("Exclusiveoftax")}
                            </p>
                          )}
                        </div>
                      </div>
                    );
                  }
                })}
            </div>
          </section>
        )}

        <section className="home-section3">
          <div className="sec-3-left-text">
            <h2 className="h2">{t("NewArrivals")}</h2>
            <p className="p2">
              {t(
                "NewSolutionsForYourEverydayNeedsGearUpForTheFutureWithTheLatestGadgets"
              )}
            </p>
            <span
              className="link"
              onClick={() => {
                navigate("/new-arrival");
              }}
            >
              <span className="link-content">{t("ViewAllProducts")}</span>
              <span className="pi pi-angle-right"></span>
            </span>
          </div>
          <div className="sec-3-row">
            {newArrivals.length > 0 &&
              newArrivals?.map((item, index) => {
                let featuredImg = item?.product_images[0]?.image_url;
                if (index < 4) {
                  return (
                    <div className="sec-3-box" key={index}>
                      <div className="sec-3-image-box">
                        <img
                          src={featuredImg}
                          className="sec-3-img"
                          alt="product image"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = imgNotFound;
                          }}
                          onClick={() => {
                            routerProductDetail(
                              item?.slug,
                              item?.product_type,
                              item?.id
                            );
                          }}
                        />
                      </div>
                      <div
                        className="sec-3-content"
                        onClick={() => {
                          routerProductDetail(
                            item?.slug,
                            item?.product_type,
                            item?.id
                          );
                        }}
                      >
                        <h6 className="h6 capitalize-first-letter">
                          {item.title}
                        </h6>
                        {/* new price data with conditions */}
                        <>
                          <h6 className="h6">
                            {currency_am +
                              " " +
                              convertCurrency(
                                item?.discount_price,
                                conversionRate
                              )}
                          </h6>
                        </>
                        {/* <h6 className="h6">
                          {currency_am +
                            " " +
                            convertCurrency(
                              item?.discount_price,
                              conversionRate
                            )}
                        </h6> */}
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        </section>

        <section className="home-section4">
          <div className="heading-row">
            <div className="heading-left">
              <h2 className="h2">{t("DiscoverbyCategories")}</h2>
              <p className="p2">{t("Shopourspecialproducts")}</p>
            </div>
          </div>
          <div className="product-row">
            {categories?.length > 0 &&
              categories?.map((item, index) => {
                if (index < 5) {
                  return (
                    <div
                      className="product-box"
                      key={index}
                      onClick={() => {
                        dispatch(
                          setCategoryList({
                            ...categoryObj,
                            selectedCategory: item,
                            selectedSubCategoryArrayOfIds: [],
                            priceRange: [0, 300000],
                            productType: "",
                            sortBy: "recommended",
                          })
                        );
                        navigate(
                          `/${item?.name_en?.toLowerCase().replace(/ /g, "_")}`
                        ); //replace space with underscore
                      }}
                    >
                      <div className="product-image-box">
                        <img
                          src={item?.thumbnailsImage}
                          alt="product image"
                          className="product-image"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = imgNotFound;
                          }}
                        />
                      </div>
                      <h6 className="h6 capitalize-first-letter">
                        {language == "en" ? t(item?.name_en) : t(item?.name_ar)}
                      </h6>
                    </div>
                  );
                }
              })}
          </div>
        </section>

        <section className="home-section5">
          <div className="heading-row-center">
            <h3 className="h3">{t("Whychooseus")}</h3>
            <p className="p2">{t("NewsLatterText")}</p>
          </div>
          <div className="sec-5-row">
            <div className="sec-5-box">
              <img
                src={shippingIConNw}
                alt="shipping icon"
                className="sec-5-icon"
              />
              <h6 className="h6 white">{t("RobustShipping")}</h6>
              <p className="p2 white">({t("withbestrates")})</p>
            </div>
            <div className="sec-5-box">
              <img
                src={genuineIcon}
                alt="Genuine icon"
                className="sec-5-icon"
              />
              <h6 className="h6 white">{t("QualityFirst")}</h6>
              <p className="p2 white">({t("testedandtrusted")})</p>
            </div>
            <div className="sec-5-box">
              <img src={returnIcon} alt="return icon" className="sec-5-icon" />
              <h6 className="h6 white">{t("14daysreturn")}</h6>
              <p className="p2 white">({t("buywithconfidence")})</p>
            </div>
          </div>
        </section>

        <section className="home-section6">
          <div className="heading-row">
            <div className="heading-left">
              <h2 className="h2">{t("RecommendedProducts")}</h2>
              <p className="p2">{t("SelectionOfOurFinestProducts")}</p>
            </div>
            <span
              className="link"
              onClick={() => {
                navigate("/recommended");
              }}
            >
              <span className="link-content">{t("ViewAllProducts")}</span>
              <span className="pi pi-angle-right"></span>
            </span>
          </div>
          <div className="product-row">
            {recommended.length > 0 &&
              recommended?.map((item, index) => {
                let featuredImg = item?.product_images[0]?.image_url;
                if (index < 5) {
                  return (
                    <div className="product-box" key={index}>
                      <div className="product-image-box">
                        <div className="chips-parent">
                          {item?.is_todays_offer == 1 && (
                            <span className="chips warning">
                              {t("TodaysOffers")}
                            </span>
                          )}
                        </div>
                        <img
                          src={featuredImg}
                          className="product-image"
                          alt="product image"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = imgNotFound;
                          }}
                          onClick={() => {
                            routerProductDetail(
                              item?.slug,
                              item?.product_type,
                              item?.id
                            );
                          }}
                        />
                        {item?.is_in_cart == 0 && item?.stock !== null ? (
                          <Button
                            className="add-to-card-btn"
                            onClick={() =>
                              addToCart(item, index, "recommended")
                            }
                          >
                            <img
                              src={basketIcon}
                              alt="basket icon"
                              className="basket-icon"
                            />
                            <span className="mx-2">{t("AddToCart")}</span>
                          </Button>
                        ) : item?.is_in_cart == 1 && item?.stock !== null ? (
                          <Button
                            className="add-to-card-btn"
                            onClick={() => {
                              navigate("/cart");
                            }}
                          >
                            <span className="mx-2">{t("GoToCart")}</span>
                          </Button>
                        ) : (
                          <Button
                            className="add-to-card-btn"
                            label={t("NotifyMe")}
                            onClick={() => {
                              notifyMeFuntion(item?.id);
                            }}
                          />
                        )}
                      </div>
                      <div
                        className="product-content"
                        onClick={() => {
                          routerProductDetail(
                            item?.slug,
                            item?.product_type,
                            item?.id
                          );
                        }}
                      >
                        <p className="p3 ">
                          {language == "en"
                            ? t(
                                `${
                                  item?.subcategory_details?.name_en
                                    ? item?.subcategory_details?.name_en
                                    : ""
                                }`
                              )
                            : t(
                                `${
                                  item?.subcategory_details?.name_ar
                                    ? item?.subcategory_details?.name_ar
                                    : ""
                                }`
                              )}
                        </p>
                        <h6 className="h6 capitalize-first-letter">
                          {item?.title}
                        </h6>
                        <p
                          className="p3 truncated-text"
                          dangerouslySetInnerHTML={{
                            __html: item?.short_description,
                          }}
                        ></p>
                        <div className="price-row">
                          {/* new price data with conditions */}
                          <>
                            <p className="p2">
                              {currency_am +
                                " " +
                                convertCurrency(
                                  item?.discount_price,
                                  conversionRate
                                )}
                            </p>
                            {item?.is_todays_offer === 1 && (
                              <>
                                {item?.discount_price !==
                                item?.selling_price ? (
                                  <p
                                    className={`p3 ${
                                      item?.discount_price !==
                                      item?.selling_price
                                        ? "cross"
                                        : ""
                                    }`}
                                  >
                                    {currency_am +
                                      " " +
                                      convertCurrency(
                                        item?.selling_price,
                                        conversionRate
                                      )}
                                  </p>
                                ) : null}
                              </>
                            )}
                          </>
                          {/* <p className="p2">
                            {currency_am +
                              " " +
                              convertCurrency(
                                item?.discount_price,
                                conversionRate
                              )}
                          </p>
                          {item?.discount_price !== item?.selling_price ? (
                            <p
                              className={`p3 ${
                                item?.discount_price !== item?.selling_price
                                  ? "cross"
                                  : ""
                              }`}
                            >
                              {currency_am +
                                " " +
                                convertCurrency(
                                  item?.selling_price,
                                  conversionRate
                                )}
                            </p>
                          ) : null} */}
                        </div>
                        {country === "UAE" && (
                          <p
                            className="p3 mt-1"
                            style={{
                              color: "#f9b000",
                              fontFamily: "OpenSans-Medium",
                            }}
                          >
                            {t("Exclusiveoftax")}
                          </p>
                        )}
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        </section>
      </div>
      <NewsLatter />
      <Footer />

      {notifyMeBtn && (
        <NotifyMe
          handleClose={() => setNotifyMeBtn(false)}
          productId={notifyMeProductId}
        />
      )}
    </>
  );
};

export default Home;
