import React, { useState } from "react";
import { RadioButton } from "primereact/radiobutton";
import { useTranslation } from "react-i18next";
import { Accordion, AccordionTab } from "primereact/accordion";
import "./PlaceOrder";
import payment from "../../assets/images/structure/payment.svg";
import fedex from "../../assets/images/structure/fedex.png";
import shopini from "../../assets/images/structure/shopini.webp";
import aramex from "../../assets/images/structure/aramex.webp";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  currentPlaceOrderData,
  setPlaceOrderData,
} from "../../redux/reducers/placeOrderData";
import { PaymentIframe } from "./ccavenue/paymentIframe";
import addDeleteGetLocalStorage from "../../prototype/addDeleteGetLocalStorage";
import { STORAGE } from "../../Enum";
import { currencyCoversionObject } from "../../redux/reducers/currencyConversion";
import { convertCurrency } from "../../Helpers/HelperFunctions";
import { setSnackbar } from "../../redux/reducers/snackbar";
const PaymentMode = ({ placeOrderApi }) => {
  const { t } = useTranslation();
  const conversionRate = useSelector(currencyCoversionObject);
  const [paymentMethod, setPaymentMethod] = useState("CardDetail");
  const dispatch = useDispatch();
  const placeData = useSelector(currentPlaceOrderData);
  // let currency_am = addDeleteGetLocalStorage(STORAGE.CURRENCY, {}, "get");
  let DB = addDeleteGetLocalStorage(STORAGE.COUNTRY_DB, {}, "get");
  let currency_am = DB === "Iraq" ? "USD" : "AED";
  const [selectedDeliveryOption, setSelectedDeliveryOption] = useState("");
  const [shippingCharge, setShippingCharge] = useState(null);
  //flag to update selected delivery option and shipping charge
  const [updateDoneFlag, setUpdateDoneFlag] = useState(false);

  useEffect(() => {
    if (
      placeData?.shippingCompany &&
      !updateDoneFlag &&
      placeData?.deliveryType !== "pickup"
    ) {
      setSelectedDeliveryOption(placeData?.shippingCompany);
      setShippingCharge(placeData?.shippingCharge);
      setUpdateDoneFlag(true);
    } else if (!updateDoneFlag && placeData?.deliveryType == "pickup") {
      setUpdateDoneFlag(true);
    }
  }, [placeData?.shippingCompany, updateDoneFlag]);

  useEffect(() => {
    dispatch(
      setPlaceOrderData({
        ...placeData,
        shippingCompany: selectedDeliveryOption
          ? selectedDeliveryOption
          : placeData?.shippingCompany,
        shippingCharge: shippingCharge
          ? shippingCharge
          : placeData?.shippingCharge,
      })
    );
  }, [selectedDeliveryOption, shippingCharge]);

  useEffect(() => {
    if (paymentMethod == "CardDetail") {
      dispatch(
        setPlaceOrderData({
          ...placeData,
          paymentType: "card",
          codCharge: 0,
        })
      );
    } else {
      dispatch(
        setPlaceOrderData({
          ...placeData,
          paymentType: "cash",
          codCharge: 0,
        })
      );
    }
  }, [paymentMethod]);

  return (
    <>
      <div className="cart-left">
        <div className="flex align-items-start checkout-heading mb-4">
          <img src={payment} alt="" />
          <div className="pl-3">
            <p className="p2 p2-bold mb-2">{t("paymentMode")}</p>
            <p className="p2 gray">{t("selectYourPreferredPaymentMode")}</p>
          </div>
        </div>
        <div className="accordion">
          <Accordion activeIndex={0}>
            <AccordionTab
              onClick={() => setPaymentMethod("CardDetail")}
              className="accordion-tab"
              header={
                <div className="flex align-items-start">
                  <RadioButton
                    inputId="paymentMethod1"
                    name="paymentMethod"
                    value="CardDetail"
                    onChange={(e) => setPaymentMethod(e.value)}
                    checked={paymentMethod === "CardDetail"}
                  />
                  <label htmlFor="paymentMethod1" className="pl-3 w-full">
                    <span className="vertical-align-middle p2 p2-semibold">
                      {t("debit_CreditCard")}
                    </span>
                    <p className="p2 gray mt-2" style={{ fontWeight: "400" }}>
                      {t("enterBelowYourCardDetails")}
                    </p>
                  </label>
                </div>
              }
            >
              {/* cc-avenue iFrame  */}
              {updateDoneFlag && (
                <PaymentIframe
                  placeOrderApi={() => {
                    placeOrderApi();
                  }}
                />
              )}
            </AccordionTab>
            <AccordionTab
              disabled={selectedDeliveryOption == "fedex" ? true : false}
              className="accordion-tab cashOnDelivery"
              onClick={() => {
                if (selectedDeliveryOption == "fedex") {
                  dispatch(
                    setSnackbar({
                      message: t(
                        "SorryCODpaymentsarenotacceptedforFedExshipmentspleaseusecardtothepayment"
                      ),
                      isOpen: true,
                      state: "error",
                    })
                  );
                  return;
                } else {
                  setPaymentMethod("cashOnDelivery");
                }
              }}
              header={
                <div className="flex align-items-start">
                  <RadioButton
                    inputId="paymentMethod9"
                    name="paymentMethod"
                    value="cashOnDelivery"
                    onChange={(e) => {
                      if (selectedDeliveryOption == "fedex") {
                        dispatch(
                          setSnackbar({
                            message: t(
                              "SorryCODpaymentsarenotacceptedforFedExshipmentspleaseusecardtothepayment"
                            ),
                            isOpen: true,
                            state: "error",
                          })
                        );
                        return;
                      } else {
                        setPaymentMethod(e.value);
                      }
                    }}
                    checked={paymentMethod === "cashOnDelivery"}
                  />
                  <label htmlFor="paymentMethod9" className="pl-3 w-full">
                    <span className="vertical-align-middle p2 p2-semibold">
                      {t("cashOnDelivery")}
                    </span>
                    <p className="p2 gray mt-2" style={{ fontWeight: "400" }}>
                      {t("doThePaymentAtTheTimeOfDelivery")}
                    </p>
                  </label>
                </div>
              }
            ></AccordionTab>
          </Accordion>
        </div>

        {placeData?.allShippingCharges?.length > 0 && (
          <div className="flex align-items-start checkout-heading mt-5 mb-2">
            <img src={payment} alt="" />
            <div className="pl-3">
              <p className="p2 p2-bold mb-2">{t("shippingCompany")}</p>
              <p className="p2 gray">{t("selectPreferredShippingCompany")}</p>
            </div>
          </div>
        )}
        {placeData?.allShippingCharges?.length > 0 &&
          placeData?.allShippingCharges?.map((item, index) => {
            return (
              <div key={index} className="pl-4 pt-3 flex align-items-center">
                <RadioButton
                  inputId="shopping1"
                  name="shopping"
                  value="shopping1"
                  checked={
                    selectedDeliveryOption == item?.shippingCompany
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    setUpdateDoneFlag(false);
                    if (e.target.checked) {
                      setSelectedDeliveryOption(item?.shippingCompany);
                      setShippingCharge(item?.shippingCharge);
                    }
                    if (
                      item?.shippingCompany === "fedex" &&
                      paymentMethod === "cashOnDelivery"
                    ) {
                      setPaymentMethod("CardDetail");
                      return;
                    }
                  }}
                />
                <label
                  htmlFor="shopping1"
                  className="flex align-items-center pl-2 w-full p2"
                >
                  <span className="vertical-align-middle p2 flex align-items-center">
                    {" "}
                    {item?.shippingCompany === "fedex" ? (
                      <>
                        <img
                          src={fedex}
                          width={"50px"}
                          className="mr-2"
                          alt=""
                        />
                        <span className="p2 gray">Fedex</span>
                      </>
                    ) : item?.shippingCompany === "shopini" ? (
                      <>
                        <img
                          src={shopini}
                          width={"50px"}
                          className="mr-2"
                          alt=""
                        />
                        <span className="p2 gray">ShopiniWorld</span>
                      </>
                    ) : item?.shippingCompany === "aramex" ? (
                      <>
                        <img
                          src={aramex}
                          width={"50px"}
                          className="mr-2"
                          alt=""
                        />
                        <span className="p2 gray">Aramex</span>
                      </>
                    ) : (
                      item?.shippingCompany
                    )}
                    :
                  </span>
                  {currency_am +
                    " " +
                    parseFloat(item?.shippingCharge).toFixed(2)}
                </label>
              </div>
            );
          })}
      </div>
    </>
  );
};
export default PaymentMode;
