import { Button } from "antd";
import compareProduct1 from "../../../assets/images/products/home2.webp";
import noImage from "../../../assets/images/products/noImage.webp";
import "./../SortedProducts/SortedProducts.scss";
import addDeleteGetLocalStorage from "../../../prototype/addDeleteGetLocalStorage";
import { STORAGE } from "../../../Enum";
import { useNavigate } from "react-router-dom";
import { Base64 } from "js-base64";
import { useEffect } from "react";
import { convertCurrency } from "../../../Helpers/HelperFunctions";
import { currencyCoversionObject } from "../../../redux/reducers/currencyConversion";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export const CompareBottomBar = ({
  products,
  setFilteredProductList,
  setVisibleBottom,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let getCompareProducts = addDeleteGetLocalStorage(
    STORAGE.COMPARE_PRODUCT,
    {},
    "get"
  );
  const conversionRate = useSelector(currencyCoversionObject);
  let currency_am = addDeleteGetLocalStorage(STORAGE.CURRENCY, {}, "get");
  if (getCompareProducts) getCompareProducts = JSON.parse(getCompareProducts);
  const localLength = getCompareProducts?.length;
  if (getCompareProducts?.length > 0) {
    if (getCompareProducts?.length < 5) {
      for (let i = 0; i < 5; i++) {
        if (!getCompareProducts[i]) {
          getCompareProducts[i] = {};
        }
      }
    }
  }

  //remove product from compare list
  const removeProductFromCompareList = (itemId) => {
    let tempProjectList = [...products];
    tempProjectList.map((obj) => {
      if (obj?.id == itemId) {
        obj["isInCompare"] = false;
      }
    });

    setFilteredProductList(tempProjectList);
    // remove from local storage
    addDeleteGetLocalStorage(STORAGE.COMPARE_PRODUCT, { id: itemId }, "remove");
  };

  //remove all items
  const removeCompareItems = async (items = []) => {
    //update product list
    let tempProjectList = [...products];
    tempProjectList.map((obj) => {
      items.map((productId) => {
        if (productId == obj?.id) {
          obj["isInCompare"] = false;
        }
      });
    });
    setFilteredProductList(tempProjectList);

    //remove from local
    items?.map((localId) => {
      addDeleteGetLocalStorage(
        STORAGE.COMPARE_PRODUCT,
        { id: localId },
        "remove"
      );
    });
  };

  //hide compare belowBar
  useEffect(() => {
    if (localLength <= 0) {
      setVisibleBottom(false);
    }
  }, [localLength]);

  //router product detail page
  const routerProductDetail = (slug, id) => {
    window.open(
      `${window.location.origin}/product-detail-page/p/${slug}/${Base64.encode(
        encodeURIComponent(id)
      )}`
    );
    // navigate(`/product-detail-page/p/${slug}`);
  };

  return (
    <div className="comapareProductRow flex">
      {getCompareProducts?.length > 0 &&
        getCompareProducts?.map((obj, index) => {
          if (obj?.id) {
            return (
              <div key={index} className="comapareProductCol flex">
                <div
                  className="deleteC_product align-items-center justify-content-center"
                  onClick={() => {
                    removeProductFromCompareList(obj?.id);
                  }}
                >
                  ×
                </div>
                <img
                  // src={compareProduct1}
                  className="c_productImg"
                  alt=""
                  src={obj?.image}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = noImage;
                  }}
                />
                <div className="pl-3">
                  <p
                    className="p2 capitalize-first-letter"
                    onClick={() => {
                      if (!obj?.slug) return;
                      routerProductDetail(obj?.slug, obj?.id);
                    }}
                  >
                    {obj?.title ? obj?.title : ""}
                  </p>
                  <h6 className="h6">
                    {currency_am +
                      " " +
                      convertCurrency(obj?.price, conversionRate)}{" "}
                    <del>
                      {currency_am +
                        " " +
                        convertCurrency(obj?.selling_price, conversionRate)}
                    </del>
                  </h6>
                </div>
              </div>
            );
          } else {
            if (index === 1) {
              return (
                <div
                  key={index}
                  className="comapareProductCol flex"
                  style={{ alignItems: "center", justifyContent: "center" }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    <p className="p2" style={{ color: "red" }}>
                      {t("Pleaseselectatleasttwoproductstocompare")}
                    </p>
                  </div>
                </div>
              );
            }
            return (
              <div
                key={index}
                className="comapareProductCol c_product_empty_col flex"
              >
                {index + 1}
              </div>
            );
          }
        })}

      <div className="compActopnCol mx-auto">
        <Button
          severity="Success"
          size="small"
          style={{
            borderRadius: "50px",
            fontFamily: "OpenSans-Bold !important",
            fontSize: "14px",
            cursor: "pointer",
          }}
          disabled={localLength < 2}
          onClick={() => {
            if (localLength < 2) return;
            let get = addDeleteGetLocalStorage(
              STORAGE.COMPARE_PRODUCT,
              {},
              "get"
            );
            if (get) get = JSON.parse(get);
            // refresh page if product not exist in local storage
            window.open(
              `${window.location.origin}/compare-product/${Base64.encode(
                encodeURIComponent(getCompareProducts[0]?.category_name)
              )}`,
              "_blank"
            );
            setVisibleBottom(false);
          }}
        >
          {t("Compare")}
        </Button>
        <p className="mb-0"
          style={{
            cursor: "pointer",
            color: "#ccc",
            textAlign: "center",
            fontSize: "14px",
          }}
          onClick={() => {
            let allIds = [];
            getCompareProducts?.map((obj) => {
              if (obj?.id) {
                allIds.push(obj?.id);
              }
            });
            removeCompareItems(allIds);
          }}
        >
          {t("ClearAll")}
        </p>
      </div>
    </div>
  );
};
