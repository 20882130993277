import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./TodaysOffer.scss";
import FrontToolbar from "../../../global-modules/FrontToolbar";
import Footer from "../../layout/footer/Footer";
import basketIcon from "../../../assets/images/structure/basket-white.svg";
import topBanner from "../../../assets/images/structure/securitybanner.jpg";
import { Button } from "primereact/button";
import Breadcrumb from "../../../global-modules/Breadcrumb";
import {
  convertCurrency,
  productPrice,
  returnRandomGuest,
} from "../../../Helpers/HelperFunctions";
import { API_ROUTES, STORAGE } from "../../../Enum";
import addDeleteGetLocalStorage from "../../../prototype/addDeleteGetLocalStorage";
import { useCallback, useState } from "react";
import globalRequest from "../../../prototype/globalRequest";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setSnackbar } from "../../../redux/reducers/snackbar";
import { changeLoader, currentLoader } from "../../../redux/reducers/loader";
import EmptyWishlist from "../../Wishlist/Empty-wishlist";
import noImage from "../../../assets/images/products/noImage.webp";
import { fetchUserProductsCount } from "../../../redux/reducers/userProductsCount";
import Banner from "../Banner/Banner";
import { currencyCoversionObject } from "../../../redux/reducers/currencyConversion";
import { currentAuth } from "../../../redux/reducers/auth";
import NotifyMe from "../../../DialogBox/NotifyMe";
import { Base64 } from "js-base64";

const TodaysOffer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const auth = useSelector(currentAuth);
  let token = auth?.token;

  let language = localStorage.getItem("i18nextLng");
  let currency_am = addDeleteGetLocalStorage(STORAGE.CURRENCY, {}, "get");
  const country =
    addDeleteGetLocalStorage(STORAGE.COUNTRY_DB, {}, "get") || "Iraq";
  const loader = useSelector(currentLoader);
  const conversionRate = useSelector(currencyCoversionObject);

  const [limit, setLimit] = useState(8);
  const [categoryProducts, setCategoryProducts] = useState([]);
  const [totalCategoryProducts, setTotalCategoryProducts] = useState(0);

  const [notifyMeBtn, setNotifyMeBtn] = useState(false);
  const [notifyMeProductId, setNotifyMeProductId] = useState(null);

  const routerProductDetail = (slug, type, id) => {
    if (type == "bundle") {
      navigate(
        `/bundle-detail-page/${slug}/${Base64.encode(encodeURIComponent(id))}`
      );
      return;
    } else if (type == "product") {
      navigate(
        `/product-detail-page/p/${slug}/${Base64.encode(
          encodeURIComponent(id)
        )}`
      );
    }
  };
  const getHomeCategoryProducts = useCallback(() => {
    let params = {
      type: "todaysoffers",
      limit: limit,
      offset: 0,
    };
    let localGuestId = addDeleteGetLocalStorage(STORAGE.GUEST_ID, {}, "get");
    if (!token && localGuestId) {
      params = { ...params, guestid: localGuestId };
    }
    dispatch(changeLoader(true));
    globalRequest(
      "get",
      API_ROUTES.PRODUCT_LIST_BY_HOME_CATEGORY,
      {},
      { params: params },
      token ? true : false
    )
      .then((data) => {
        dispatch(changeLoader(false));
        if (data?.ack == 1) {
          //update product price with respect to wholesale price
          let allProducts = data?.data;
          allProducts?.forEach((item) => {
            item.stock =
              item?.stock !== null
                ? item?.stock?.stock - item?.stock?.reserve_stock > 0
                  ? item?.stock
                  : null
                : null;
            item?.product_type == "product" &&
              (item.discount_price = productPrice(
                item?.is_todays_offer == 1
                  ? item?.discount_price
                  : item?.selling_price,
                1,
                item?.price_ranges,
                item?.is_wholesale
              ));
          });

          setCategoryProducts(allProducts); //set category products
          setTotalCategoryProducts(data?.count); //set total category products
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            message: "SomethingWentWrong",
            isOpen: true,
            state: "error",
          })
        );
      });
  });

  useEffect(() => {
    getHomeCategoryProducts();
  }, [limit]);

  ///add to cart function
  const addToCart = useCallback(async (item) => {
    let localGuestId = addDeleteGetLocalStorage(STORAGE.GUEST_ID, {}, "get");
    let guestId = localGuestId
      ? localGuestId
      : returnRandomGuest();
    let data = {
      productId: item?.id,
      quantity: 1,
      price:
        item?.is_todays_offer === 1
          ? item?.discount_price
          : item?.selling_price,
      productType: item?.product_type,
    };
    if (!token && guestId) {
      data = { ...data, guestid: token ? "" : guestId.trim() };
    }

    dispatch(changeLoader(true));
    try {
      let res = await globalRequest(
        "post",
        API_ROUTES.ADD_TO_CART,
        data,
        {},
        token ? true : false
      );
      if (res?.ack == 1) {
        dispatch(changeLoader(false));
        addDeleteGetLocalStorage(STORAGE.GUEST_ID, guestId, "add", "single");
        dispatch(
          setSnackbar({
            isOpen: true,
            state: "success",
            message: t("AddedToCartSuccessfully"),
          })
        );
        dispatch(fetchUserProductsCount());
        getHomeCategoryProducts();
      } else {
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            isOpen: true,
            state: "error",
            message: t("SomethingWentWrong"),
          })
        );
      }
    } catch (err) {
      dispatch(changeLoader(false));
      dispatch(
        setSnackbar({
          isOpen: true,
          state: "error",
          message: err.response.data.msg || t("SomethingWentWrong"),
        })
      );
    }
  });

  ///pagination
  const [page, setPage] = useState(1);
  useEffect(() => {
    if (limit >= totalCategoryProducts) return;
    setLimit((prevLimit) => prevLimit + 8);
  }, [page]);

  useEffect(() => {
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: "20px", // Margin around the root (if you want to trigger earlier or later)
      threshold: 1.0, // Trigger when the entire target is visible
    };
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setPage((prevPage) => prevPage + 1);
      }
    }, options);
    // Set the target to observe
    const target = document.querySelector("#observe-target1");
    if (target) {
      observer.observe(target);
    }
    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, []);

  //notify me function
  const notifyMeFunction = useCallback((id) => {
    setNotifyMeProductId(id);
    setNotifyMeBtn(true);
  });

  return (
    <>
      <FrontToolbar />
      <div className="container">
        <Breadcrumb title={t("TodaysOffer")} />
        <section className="searchresult-section1">
          <Banner
            imgBanner={topBanner}
            title={t("TodaysOffers")}
            paragraph={t("DiscoverTheNewCollectionOfTodaysOfferProducts")}
          />
          <div className="search-result-head"></div>
          <div className="product-row">
            {categoryProducts.length > 0 &&
              categoryProducts.map((item, index) => {
                let discount_percentage = 0;
                if (item?.selling_price - item?.discount_price > 0) {
                  discount_percentage =
                    ((item?.selling_price - item?.discount_price) /
                      item?.selling_price) *
                    100;
                  discount_percentage =
                    Math.floor(discount_percentage * 100) / 100;
                }
                return (
                  <div className="product-box" key={index}>
                    <div className="product-image-box">
                      <div className="chips-parent">
                        {discount_percentage.toFixed(2) > 0 && (
                          <span className="chips error">
                            {`${t("Sale")} - ${discount_percentage.toFixed(
                              2
                            )}%`}
                          </span>
                        )}
                      </div>
                      <img
                        src={item?.product_images[0]?.image_url}
                        alt="product image"
                        className="product-image"
                        onClick={(e) => {
                          routerProductDetail(
                            item?.slug,
                            item?.product_type,
                            item?.id
                          );
                        }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = noImage;
                        }}
                      />
                      {item?.is_in_cart == 0 && item?.stock !== null ? (
                        <Button
                          className="add-to-card-btn"
                          onClick={() => addToCart(item)}
                        >
                          <img
                            src={basketIcon}
                            alt="basket icon"
                            className="basket-icon"
                          />
                          <span className="mx-2">{t("AddToCart")}</span>
                        </Button>
                      ) : item?.is_in_cart == 1 && item?.stock !== null ? (
                        <Button
                          className="add-to-card-btn"
                          onClick={() => {
                            navigate("/cart");
                          }}
                        >
                          <span className="mx-2">{t("GoToCart")}</span>
                        </Button>
                      ) : (
                        <Button
                          className="add-to-card-btn"
                          label={t("NotifyMe")}
                          onClick={() => {
                            notifyMeFunction(item?.id);
                          }}
                        />
                      )}
                    </div>
                    <div
                      className="product-content"
                      onClick={(e) => {
                        routerProductDetail(
                          item?.slug,
                          item?.product_type,
                          item?.id
                        );
                      }}
                    >
                      <p className="p3 ">
                        {language == "en"
                          ? t(
                              `${
                                item?.subcategory_details?.name_en
                                  ? item?.subcategory_details?.name_en
                                  : ""
                              }`
                            )
                          : t(
                              `${
                                item?.subcategory_details?.name_ar
                                  ? item?.subcategory_details?.name_ar
                                  : ""
                              }`
                            )}
                      </p>
                      <h6 className="h6 capitalize-first-letter">
                        {item?.title}
                      </h6>
                      <p
                        className="p3 truncated-text"
                        dangerouslySetInnerHTML={{
                          __html: item?.short_description,
                        }}
                      ></p>
                      <div className="price-row">
                        {/* new price data with conditions */}
                        <>
                          <p className="p2">
                            {currency_am +
                              " " +
                              convertCurrency(
                                item?.discount_price,
                                conversionRate
                              )}
                          </p>
                          {item?.is_todays_offer === 1 && (
                            <>
                              {item?.discount_price !== item?.selling_price ? (
                                <p
                                  className={`p3 ${
                                    item?.discount_price !== item?.selling_price
                                      ? "cross"
                                      : ""
                                  }`}
                                >
                                  {currency_am +
                                    " " +
                                    convertCurrency(
                                      item?.selling_price,
                                      conversionRate
                                    )}
                                </p>
                              ) : null}
                            </>
                          )}
                        </>
                        {/* <p className="p2">
                          {currency_am +
                            " " +
                            convertCurrency(
                              item?.discount_price,
                              conversionRate
                            )}
                        </p>
                        {item?.discount_price !== item?.selling_price ? (
                          <p
                            className={`p3 ${
                              item?.discount_price !== item?.selling_price
                                ? "cross"
                                : ""
                            }`}
                          >
                            {currency_am +
                              " " +
                              convertCurrency(
                                item?.selling_price,
                                conversionRate
                              )}
                          </p>
                        ) : null} */}
                      </div>
                      {country === "UAE" && (
                        <p
                          className="p3 mt-1"
                          style={{
                            color: "#f9b000",
                            fontFamily: "OpenSans-Medium",
                          }}
                        >
                          {t("Exclusiveoftax")}
                        </p>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>

          <div id="observe-target1" style={{ height: "10px" }}></div>
          {!loader && categoryProducts.length == 0 && (
            <EmptyWishlist page="product_list" />
          )}
        </section>
      </div>
      <Footer />

      {notifyMeBtn && (
        <NotifyMe
          handleClose={() => setNotifyMeBtn(false)}
          productId={notifyMeProductId}
        />
      )}
    </>
  );
};

export default TodaysOffer;
